import { useCallback } from 'react'
import { type LayoutRectangle, Platform, useWindowDimensions, View, type ViewProps } from 'react-native'
import { KeyboardAvoidingView } from 'react-native-keyboard-controller'
import Animated, {
  interpolate,
  runOnUI,
  type SharedValue,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
} from 'react-native-reanimated'
import { useKeyboardAnimation, useTranslateAnimation } from './hooks'

export type MGCustomizedKeyboardAvoidingViewProps = {
  /**
   * Optional shared value for smart reply height. When provided, the view will
   * adjust its position based on both keyboard and smart reply heights.
   */
  smartReplyOpenedWithHeight: SharedValue<number>

  smartReplyOpenedWithHeightPrimitiveValue: number

  /**
   * Specify how to react to the presence of the keyboard.
   */
  behavior?: 'padding' | 'translate-with-padding'

  /**
   * The className of the component.
   */
  className?: string

  children?: React.ReactNode
}

const defaultLayout: LayoutRectangle = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
}

const MGCustomizedKeyboardAvoidingViewGeneric = ({
  behavior,
  className,
  children,
  smartReplyOpenedWithHeight,
}: MGCustomizedKeyboardAvoidingViewProps) => {
  const initialFrame = useSharedValue<LayoutRectangle | null>(null)
  const frame = useDerivedValue(() => {
    const v = initialFrame.value || defaultLayout
    console.log('[Frame] Derived', { v })
    return v
  })

  const { translate, padding } = useTranslateAnimation()
  const keyboard = useKeyboardAnimation()

  const { height: screenHeight } = useWindowDimensions()

  const relativeKeyboardHeight = useCallback(() => {
    'worklet'
    const keyboardY = screenHeight - keyboard.heightWhenOpened.value
    return Math.max(frame.value.y + frame.value.height - keyboardY, 0)
  }, [screenHeight])

  const interpolateToRelativeKeyboardHeight = useCallback(
    (value: number) => {
      'worklet'
      return interpolate(value, [0, 1], [0, relativeKeyboardHeight()])
    },
    [relativeKeyboardHeight],
  )

  const onLayoutWorklet = useCallback((layout: LayoutRectangle) => {
    'worklet'
    initialFrame.value = layout
  }, [])

  const onLayout = useCallback<NonNullable<ViewProps['onLayout']>>((e) => {
    runOnUI(onLayoutWorklet)(e.nativeEvent.layout)
  }, [])

  const animatedStyle = useAnimatedStyle(() => {
    const bottom = interpolateToRelativeKeyboardHeight(keyboard.progress.value)
    const translateY = interpolateToRelativeKeyboardHeight(translate.value)
    const paddingBottom = interpolateToRelativeKeyboardHeight(padding.value)

    // Add smart reply height if provided, with platform-specific handling
    const smartReplyHeight = smartReplyOpenedWithHeight?.value ?? 0

    const totalHeight = Math.max(bottom, smartReplyHeight)

    switch (behavior) {
      case 'padding':
        return { paddingBottom: totalHeight }

      case 'translate-with-padding':
        return {
          paddingTop: paddingBottom,
          transform: [{ translateY: -translateY }],
          paddingBottom: smartReplyHeight,
        }

      default:
        return {}
    }
  }, [behavior, interpolateToRelativeKeyboardHeight, smartReplyOpenedWithHeight])

  return (
    <Animated.View className={className} style={animatedStyle} onLayout={onLayout}>
      {children}
    </Animated.View>
  )
}

const MGCustomizedKeyboardAvoidingViewAndroid = ({
  className,
  children,
  smartReplyOpenedWithHeightPrimitiveValue,
}: MGCustomizedKeyboardAvoidingViewProps) => {
  return (
    <KeyboardAvoidingView className={className} behavior="padding">
      <View className="flex-1 flex-row" style={{ paddingBottom: smartReplyOpenedWithHeightPrimitiveValue }}>
        {children}
      </View>
    </KeyboardAvoidingView>
  )
}

export const MGCustomizedKeyboardAvoidingView =
  Platform.OS === 'android' ? MGCustomizedKeyboardAvoidingViewAndroid : MGCustomizedKeyboardAvoidingViewGeneric
