import Head from 'expo-router/head'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import ChatList from '../../../components/chat/ChatList'
import MGHeader from '../../../components/common/MGHeader'
import MGHeaderText from '../../../components/common/MGHeaderText'

export default function ChatScreen() {
  const { t } = useTranslation()

  return (
    <Fragment>
      <Head>
        <title>
          {t('tab.chat')} | {t('app.name')}
        </title>
      </Head>

      <SafeAreaView edges={['top', 'left', 'right']} className="size-full bg-background">
        <MGHeader disableBackButton headerBody={<MGHeaderText>{t('tab.chat')}</MGHeaderText>} />
        <View style={{ maxWidth: 728, width: '100%', height: '100%' }} className="mx-auto flex-1">
          <ChatList />
        </View>
      </SafeAreaView>
    </Fragment>
  )
}
