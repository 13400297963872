import * as Sentry from '@sentry/react-native'
import { toast } from 'burnt'
import clsx from 'clsx'
import * as Haptics from 'expo-haptics'
import { useSetAtom } from 'jotai'
import { useMemo, useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Platform, View } from 'react-native'
import { ErrorCode, PurchaseError } from 'react-native-iap'
import Animated, { Easing, useAnimatedStyle, withRepeat, withSequence, withTiming } from 'react-native-reanimated'
import { match } from 'ts-pattern'
import { useActiveSubscription } from '../../lib/hooks/useActiveSubscription'
import { useCurrentUser } from '../../lib/hooks/useAuth'
import { subscribe, useAvailableSubscriptions } from '../../lib/monetization/subscription'
import { purchaseSheetStateAtom } from '../../lib/purchaseSheet'
import { trpcReact, trpcUtils } from '../../lib/services/trpc'
import { MGCharactersMasonry } from '../common/MGCharactersMasonry'
import { MGGradientBackground } from '../common/MGGradientBackground'
import { MGIonicons } from '../common/MGIonicons'
import { MGModalButton } from '../common/MGModalButton'
import MGTab, { type MGTabItem } from '../common/MGTab'
import MGText from '../common/MGText'
import { LegalRow } from './LegalRow'
import { MoguPassBenefitsList } from './MoguPassBenefitsList'

// Skeleton loop animates opacity between 0.5 and 1
const Skeleton: FC<{ className?: string }> = ({ className }) => {
  const animatedStyle = useAnimatedStyle(() => ({
    opacity: withRepeat(
      withSequence(
        withTiming(0.2, { duration: 0 }),
        withTiming(1, {
          duration: 1200,
          easing: Easing.ease,
        }),
        withTiming(0.2, {
          duration: 1200,
          easing: Easing.ease,
        }),
      ),
      -1,
      false,
    ),
  }))

  return <Animated.View className={className} style={animatedStyle} />
}

const StripeCustomerPortalButton: FC = () => {
  const { t } = useTranslation()
  const { mutate: createCustomerPortalSession, isPending } =
    trpcReact.store.stripe.createCustomerPortalSession.useMutation()

  return (
    <MGModalButton
      onPress={() => {
        createCustomerPortalSession(undefined, {
          onSuccess: (data) => {
            window.open(data.url, '_blank')
          },
          onError: (error) => {
            console.error(error)
            Sentry.captureException(error)
            toast({
              preset: 'error',
              title: t('error.internal-error'),
              message: error.message,
            })
          },
        })
      }}
      loading={isPending}
    >
      {t('purchase.subscription.cta.manage-subscription')}
    </MGModalButton>
  )
}

type SubscribeButtonState = 'begin-free-trial' | 'subscribe' | 'already-subscribed' | 'loading'

export default function PurchaseSheetSubscription({
  className,
}: {
  className?: string
}) {
  const [interval, setInterval] = useState<'MONTHLY' | 'YEARLY'>('YEARLY')
  const { currentUserPrivilegeId, storePlans } = useAvailableSubscriptions()
  const { t } = useTranslation()
  const { data: user } = useCurrentUser()
  const [isSubscribing, setIsSubscribing] = useState(false)
  const setPurchaseSheetState = useSetAtom(purchaseSheetStateAtom)
  const activeSubscription = useActiveSubscription()

  const storePlan = storePlans?.find((p) => p.interval === interval)

  const onSubscribe = async () => {
    if (!storePlan) {
      toast({
        preset: 'error',
        title: t('error.internal-error'),
        message: 'Missing store plans',
      })
      return
    }
    if (!user?.id) {
      toast({
        preset: 'error',
        title: t('error.internal-error'),
        message: 'Missing user.id',
      })
      return
    }

    try {
      setIsSubscribing(true)
      await subscribe({
        userId: user.id,
        subscribeParams: storePlan.subscribeParams,
      })

      // polling
      const currentSubscriptionResult = await (async () => {
        for (let i = 0; i < 30; i++) {
          await trpcUtils.user.getViewerActiveSubscription.invalidate(undefined, {
            refetchType: 'none',
          })
          const data = await trpcUtils.user.getViewerActiveSubscription.fetch(undefined, {
            retry: false,
          })
          // TODO: handle interval change
          if (data?.privilegeId === 'privilege_mogupass') {
            return 'succeeded' as const
          }
          if (i === 10) {
            Sentry.captureEvent({
              level: 'warning',
              message: '[IAP] subscribe: still polling after 10 tries (at least 15s now)',
              extra: {
                storePlan,
              },
            })
          }
          await new Promise((resolve) => setTimeout(resolve, 2500))
        }

        return 'failed' as const
      })()

      if (currentSubscriptionResult === 'failed') {
        Sentry.captureEvent({
          level: 'error',
          message: '[IAP] subscribe: failed to poll current subscription despite user subscribed',
          extra: {
            storePlan,
          },
        })

        toast({
          preset: 'error',
          title: t('error.internal-error'),
          message: 'Failed to subscribe',
        })
      } else {
        trpcUtils.user.getViewerPrivileges.invalidate()
        toast({
          preset: 'done',
          title: t('purchase.subscription.success'),
          message: 'Subscription successful',
        })
        setPurchaseSheetState({ opened: false, selected: null })
      }
    } catch (error) {
      if (error instanceof PurchaseError) {
        if (error.code === ErrorCode.E_USER_CANCELLED) {
          // ignore user cancelled error
          return
        }

        if (error.code === ErrorCode.E_DEFERRED_PAYMENT) {
          Alert.alert(
            'Deferred payment method',
            'After your payment is confirmed, you will be receiving your subscription automatically. Please restart MoguChat after you complete your payment.',
          )
          return
        }

        // fallthrough
      }
      if (error instanceof Error && error.message === 'StripePageOpenedError') {
        // ignore StripePageOpenedError since it is expected
        return
      }

      console.error(error)
      Sentry.captureException(error)
      const errorMessage = error instanceof Error ? error.message : 'Unknown error'
      Alert.alert(
        'Failed to subscribe',
        `Error: ${errorMessage} If the problem persists, please contact support at [Profile] -> [Support].`,
      )
    } finally {
      setIsSubscribing(false)
    }
  }

  const yearlyStorePlan = storePlans?.find((p) => p.interval === 'YEARLY')
  const subscriptionOptions: MGTabItem[] = useMemo(
    () => [
      {
        value: 'MONTHLY',
        label: (selected: boolean) => (
          <MGText bold={selected} className="px-2 py-0.5 rounded-md">
            {t('purchase.subscription.interval.monthly')}
          </MGText>
        ),
      },
      {
        value: 'YEARLY',
        label: (selected: boolean) => (
          <View className="flex flex-col items-center justify-center relative size-full">
            <MGText
              bold={selected}
              className={clsx(
                'px-2 py-0.5 min-w-28 text-center rounded-md border border-primary-600 dark:border-primary-700',
                selected && 'bg-primary-500 dark:bg-primary-600 text-white',
              )}
            >
              {t('purchase.subscription.interval.yearly')}
            </MGText>

            {yearlyStorePlan?.discountPercentage ? (
              <View className="rounded-b-md px-1.5 py-0.5 text-center bg-primary-600 dark:bg-primary-700 flex flex-row items-center gap-1">
                <MGIonicons name="star-outline" size={12} color="#fff" className="opacity-80" />
                <MGText className="text-white text-xs" bold>{`${yearlyStorePlan.discountPercentage}% OFF`}</MGText>
              </View>
            ) : null}
          </View>
        ),
        isPrimary: true,
      },
    ],
    [t, yearlyStorePlan],
  )

  const ctaButtonState = useMemo<SubscribeButtonState>(() => {
    if (!storePlan || isSubscribing) {
      return 'loading'
    }

    if (currentUserPrivilegeId === 'privilege_mogupass') {
      return 'already-subscribed'
    }

    if (storePlan.eligibleForFreeTrialWithDays && storePlan.eligibleForFreeTrialWithDays > 0) {
      return 'begin-free-trial'
    }

    return 'subscribe'
  }, [storePlan, currentUserPrivilegeId, isSubscribing])

  const formattedPriceOptions = (() => {
    // hide price if already subscribed
    if (ctaButtonState === 'already-subscribed') return null

    return (
      <>
        <MGTab
          tabs={subscriptionOptions}
          activeTab={interval}
          onTabChange={(value) => {
            Haptics.selectionAsync()
            setInterval(value as 'MONTHLY' | 'YEARLY')
          }}
          className="mb-2"
        />

        {storePlan?.eligibleForFreeTrialWithDays || storePlan?.localizedPrice ? (
          <MGText
            bold
            className={clsx('text-2xl text-center leading-8', !storePlan?.eligibleForFreeTrialWithDays && 'mb-2')}
          >
            {storePlan?.eligibleForFreeTrialWithDays
              ? t('purchase.subscription.price-info.begin-free-trial', {
                  days: storePlan.eligibleForFreeTrialWithDays,
                  price: storePlan.localizedPrice,
                })
              : storePlan?.localizedPrice}
          </MGText>
        ) : (
          <Skeleton className="bg-neutral-200 rounded-sm w-24 h-8 mx-auto mb-2" />
        )}

        {storePlan?.eligibleForFreeTrialWithDays && (
          <MGText className="text-neutral-500 text-sm text-center">
            {t(
              {
                MONTHLY: 'purchase.subscription.product.mogupass.monthly.free-trial.description',
                YEARLY: 'purchase.subscription.product.mogupass.yearly.free-trial.description',
              }[interval],
              {
                days: storePlan.eligibleForFreeTrialWithDays,
                price: storePlan.localizedPrice,
              },
            )}
          </MGText>
        )}
      </>
    )
  })()

  return (
    <View className={clsx('w-full flex gap-2 mt-1', className)}>
      <View className="rounded-xl overflow-hidden">
        {/* this is because LinearGradient does not support overflow: hidden, so we have to wrap another View */}
        <MGGradientBackground>
          <View className="rounded-xl p-4 bg-white/50 dark:bg-black/60 gap-2">
            <MoguPassBenefitsList
              benefitKeys={[
                'purchase.subscription.benefits.remove-all-ads',
                'purchase.subscription.benefits.unlimited-chats',
                'purchase.subscription.benefits.unlimited-private-characters',
                'purchase.subscription.benefits.quicker-responses',
              ]}
            />

            <MGCharactersMasonry />
          </View>
        </MGGradientBackground>
      </View>

      <View className="w-full flex gap-2">
        {formattedPriceOptions}

        <MGModalButton
          foregroundAppearance={
            ctaButtonState === 'begin-free-trial' || ctaButtonState === 'subscribe' ? 'light' : undefined
          }
          className={clsx(
            'py-5',
            match(ctaButtonState)
              .with('loading', () => 'bg-neutral-400')
              .with('already-subscribed', () => 'bg-neutral-400')
              .with('begin-free-trial', 'subscribe', () => 'bg-primary-600')
              .exhaustive(),
          )}
          textClassName="text-lg"
          disabled={ctaButtonState === 'loading' || ctaButtonState === 'already-subscribed'}
          onPress={onSubscribe}
          loading={ctaButtonState === 'loading'}
          testID="purchase-sheet-subscription-cta"
        >
          {match(ctaButtonState)
            .with('loading', () => t('common.loading'))
            .with('already-subscribed', () => t('purchase.subscription.cta.already-subscribed'))
            .with('begin-free-trial', () => t('purchase.subscription.cta.free-trial'))
            .with('subscribe', () => t('purchase.subscription.cta.subscribe'))
            .exhaustive()}
        </MGModalButton>

        {Platform.OS === 'web' &&
          ctaButtonState === 'already-subscribed' &&
          activeSubscription.data?.platform === 'STRIPE' && <StripeCustomerPortalButton />}

        {/* hide subscription info if already subscribed */}
        {ctaButtonState !== 'already-subscribed' && (
          <MGText className="text-neutral-500 text-[9px] text-center">
            {t(
              {
                MONTHLY: 'purchase.subscription.product.mogupass.monthly.subscription-info',
                YEARLY: 'purchase.subscription.product.mogupass.yearly.subscription-info',
              }[interval],
            )}
          </MGText>
        )}
      </View>

      <LegalRow />
    </View>
  )
}
