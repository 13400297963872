import type { ConfigType, Dayjs } from 'dayjs'
import { dayjs } from '../lib/dayjs'

export const fromNowTime = (date: Dayjs, ignoreDate = false) => {
  if (ignoreDate) {
    return dayjs(date).format('HH:mm')
  }

  if (date.isBefore(dayjs().subtract(7, 'day'))) {
    // >7d ago
    const isSameYearAsNow = dayjs(date).isSame(dayjs(), 'year')
    return dayjs(date).format(isSameYearAsNow ? 'MM-DD HH:mm' : 'YYYY-MM-DD HH:mm')
  }

  return dayjs(date).fromNow()
}

export const formatTime = (date: Dayjs) => {
  return dayjs(date).format('YYYY-MM-DD HH:mm')
}

export const formatTimeLocalized = (date: ConfigType) => {
  if (!date) {
    return ''
  }
  return dayjs(date).format('llll')
}
