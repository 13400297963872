import Ionicons from '@expo/vector-icons/Ionicons'
import { Tabs, usePathname, useRootNavigationState, useRouter } from 'expo-router'
import { useColorScheme } from 'nativewind'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TabBarIcon from '../../../components/navigation/TabBarIcon'
import { regularFontFamily } from '../../../constants/Font'
import { useIsDesktop } from '../../../lib/hooks/useIsDesktop'
import { getHasChat } from '../../../lib/storage'

export default function TabLayout() {
  const { t } = useTranslation()
  const isDesktop = useIsDesktop()

  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const pathname = usePathname()
  const router = useRouter()
  const rootNavigationState = useRootNavigationState()

  const { colorScheme } = useColorScheme()

  useEffect(() => {
    // rootNavigationState.key === undefined means the router is not mounted yet
    if (!isFirstLoad || rootNavigationState.key === undefined) return
    setIsFirstLoad(false)

    const hasChat = getHasChat()
    if (hasChat && pathname === '/') {
      router.push('/chats')
    }
  }, [pathname, rootNavigationState.key])

  return (
    <Tabs
      screenOptions={{
        tabBarActiveTintColor: colorScheme === 'dark' ? '#e81a89' : '#f83caa',
        headerShown: false,
        // We do not customize tabBarButton anymore because it breaks navigation on web:
        // Switching tab causes the whole app to be reloaded (behaves like window.location.reload())
        headerShadowVisible: false,
        tabBarStyle: {
          display: isDesktop ? 'none' : 'flex',
          backgroundColor: colorScheme === 'dark' ? '#111111' : 'white',
          borderTopColor: colorScheme === 'dark' ? '#222222' : '#dddddd',
        },
        tabBarLabelStyle: {
          fontFamily: regularFontFamily,
        },
      }}
    >
      <Tabs.Screen
        name="index"
        options={{
          headerTitle: t('tab.home'),
          headerShown: false,
          title: t('tab.home'),
          tabBarIcon: (props) => (
            <TabBarIcon {...props}>
              <Ionicons name={props.focused ? 'compass' : 'compass-outline'} size={props.size} />
            </TabBarIcon>
          ),
          tabBarAccessibilityLabel: t('tab.home'),
        }}
      />
      <Tabs.Screen
        name="chats"
        options={{
          title: t('tab.chat'),
          headerShown: false,
          tabBarIcon: (props) => (
            <TabBarIcon {...props}>
              <Ionicons name={props.focused ? 'chatbubbles' : 'chatbubbles-outline'} size={props.size} />
            </TabBarIcon>
          ),
          tabBarAccessibilityLabel: t('tab.chat'),
        }}
      />
      <Tabs.Screen
        name="profile"
        options={{
          title: t('tab.profile'),
          headerShown: false,
          tabBarIcon: (props) => (
            <TabBarIcon {...props}>
              <Ionicons name={props.focused ? 'person' : 'person-outline'} size={props.size} />
            </TabBarIcon>
          ),
          tabBarAccessibilityLabel: t('tab.profile'),
        }}
      />
    </Tabs>
  )
}
