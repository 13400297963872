import { Platform } from 'react-native'

export const regularFontFamily = Platform.select({
  android: 'ZenMaruGothic_400Regular',
  ios: 'ZenMaruGothic-Regular',
  web: 'ZenMaruGothic-400Regular',
})

export const boldFontFamily = Platform.select({
  android: 'ZenMaruGothic_700Bold',
  ios: 'ZenMaruGothic-Bold',
  web: 'ZenMaruGothic-700Bold',
})

export const serifFontFamily = Platform.select({
  android: 'NotoSerifJP_400Regular',
  ios: 'NotoSerifJP-Regular',
  web: 'NotoSerifJP-Regular',
})

export const serifBoldFontFamily = Platform.select({
  android: 'NotoSerifJP_700Bold',
  ios: 'NotoSerifJP-Bold',
  web: 'NotoSerifJP-Bold',
})
