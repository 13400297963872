{
  "errors.custom": "Invalid input",
  "errors.invalid_arguments": "Invalid function arguments",
  "errors.invalid_date": "Invalid date",
  "errors.invalid_enum_value": "Invalid enum value. Expected {{- options}}, received '{{received}}'",
  "errors.invalid_intersection_types": "Intersection results could not be merged",
  "errors.invalid_literal": "Invalid literal value, expected {{expected}}",
  "errors.invalid_return_type": "Invalid function return type",
  "errors.invalid_string.cuid": "Invalid {{validation}}",
  "errors.invalid_string.datetime": "Invalid {{validation}}",
  "errors.invalid_string.email": "Invalid {{validation}}",
  "errors.invalid_string.endsWith": "Invalid input: must end with \"{{endsWith}}\"",
  "errors.invalid_string.regex": "Invalid",
  "errors.invalid_string.startsWith": "Invalid input: must start with \"{{startsWith}}\"",
  "errors.invalid_string.url": "Invalid {{validation}}",
  "errors.invalid_string.uuid": "Invalid {{validation}}",
  "errors.invalid_type": "Expected {{expected}}, received {{received}}",
  "errors.invalid_type_received_null": "Required",
  "errors.invalid_type_received_undefined": "Required",
  "errors.invalid_union": "Invalid input",
  "errors.invalid_union_discriminator": "Invalid discriminator value. Expected {{- options}}",
  "errors.not_finite": "Number must be finite",
  "errors.not_multiple_of": "Number must be a multiple of {{multipleOf}}",
  "errors.too_big.array.exact": "Array must contain exactly {{maximum}} element(s)",
  "errors.too_big.array.inclusive": "Array must contain at most {{maximum}} element(s)",
  "errors.too_big.array.not_inclusive": "Array must contain less than {{maximum}} element(s)",
  "errors.too_big.date.exact": "Date must be exactly {{- maximum, datetime}}",
  "errors.too_big.date.inclusive": "Date must be smaller than or equal to {{- maximum, datetime}}",
  "errors.too_big.date.not_inclusive": "Date must be smaller than {{- maximum, datetime}}",
  "errors.too_big.number.exact": "Number must be exactly {{maximum}}",
  "errors.too_big.number.inclusive": "Number must be less than or equal to {{maximum}}",
  "errors.too_big.number.not_inclusive": "Number must be less than {{maximum}}",
  "errors.too_big.set.exact": "Invalid input",
  "errors.too_big.set.inclusive": "Invalid input",
  "errors.too_big.set.not_inclusive": "Invalid input",
  "errors.too_big.string.exact": "String must contain exactly {{maximum}} character(s)",
  "errors.too_big.string.inclusive": "String must contain at most {{maximum}} character(s)",
  "errors.too_big.string.not_inclusive": "String must contain under {{maximum}} character(s)",
  "errors.too_small.array.exact": "Array must contain exactly {{minimum}} element(s)",
  "errors.too_small.array.inclusive": "Array must contain at least {{minimum}} element(s)",
  "errors.too_small.array.not_inclusive": "Array must contain more than {{minimum}} element(s)",
  "errors.too_small.date.exact": "Date must be exactly {{- minimum, datetime}}",
  "errors.too_small.date.inclusive": "Date must be greater than or equal to {{- minimum, datetime}}",
  "errors.too_small.date.not_inclusive": "Date must be greater than {{- minimum, datetime}}",
  "errors.too_small.number.exact": "Number must be exactly {{minimum}}",
  "errors.too_small.number.inclusive": "Number must be greater than or equal to {{minimum}}",
  "errors.too_small.number.not_inclusive": "Number must be greater than {{minimum}}",
  "errors.too_small.set.exact": "Invalid input",
  "errors.too_small.set.inclusive": "Invalid input",
  "errors.too_small.set.not_inclusive": "Invalid input",
  "errors.too_small.string.exact": "String must contain exactly {{minimum}} character(s)",
  "errors.too_small.string.inclusive": "String must contain at least {{minimum}} character(s)",
  "errors.too_small.string.not_inclusive": "String must contain over {{minimum}} character(s)",
  "errors.unrecognized_keys": "Unrecognized key(s) in object: {{- keys}}",
  "types.array": "array",
  "types.bigint": "bigint",
  "types.boolean": "boolean",
  "types.date": "date",
  "types.float": "float",
  "types.function": "function",
  "types.integer": "integer",
  "types.map": "map",
  "types.nan": "nan",
  "types.never": "never",
  "types.null": "null",
  "types.number": "number",
  "types.object": "object",
  "types.promise": "promise",
  "types.set": "set",
  "types.string": "string",
  "types.symbol": "symbol",
  "types.undefined": "undefined",
  "types.unknown": "unknown",
  "types.void": "void",
  "validations.cuid": "cuid",
  "validations.datetime": "datetime",
  "validations.email": "email",
  "validations.regex": "regex",
  "validations.url": "url",
  "validations.uuid": "uuid"
}
