import { z, type ZodError } from 'zod'
import { makeZodI18nMap } from 'zod-i18n-map'

z.setErrorMap(makeZodI18nMap())

export const formatZodError = (error: ZodError) => {
  return error.errors.map((err) => `${err.path.join('.')}: ${err.message}`).join('; ')
}

export { z }
