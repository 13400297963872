import { Image } from 'expo-image'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FlatList, RefreshControl, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import MGHeader from '../../../components/common/MGHeader'
import MGHeaderText from '../../../components/common/MGHeaderText'
import { MGLoading } from '../../../components/common/MGLoading'
import MGText from '../../../components/common/MGText'
import { dayjs } from '../../../lib/dayjs'
import { useFormatRelativeTime } from '../../../lib/hooks/useFormatRelativeTime'
import { trpcReact } from '../../../lib/services/trpc'

export default function UserInboxView() {
  const { t } = useTranslation()

  const {
    data: transactionsRes,
    isPending,
    refetch,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = trpcReact.transaction.items.list.useInfiniteQuery(
    {
      limit: 20,
    },
    {
      getNextPageParam: (lastPage) => lastPage.pagination.nextCursor,
    },
  )

  const transactionItems = useMemo(() => transactionsRes?.pages.flatMap((page) => page.items), [transactionsRes?.pages])

  return (
    <SafeAreaView edges={['top', 'left', 'right']} className="flex-1 bg-background">
      <MGLoading show={isPending} />
      <MGHeader headerBody={<MGHeaderText>{t('transaction.list.title')}</MGHeaderText>} />

      <FlatList
        refreshControl={<RefreshControl refreshing={isLoading} onRefresh={refetch} />}
        className="flex-1 px-6 mx-auto w-full"
        data={transactionItems}
        renderItem={({ item }) => <TransactionItem key={item.id} {...item} />}
        ListEmptyComponent={<MGText className="text-center text-neutral-500">{t('transaction.list.empty')}</MGText>}
        ItemSeparatorComponent={Separator}
        contentContainerStyle={{ paddingVertical: 8 }}
        onEndReached={() => {
          if (hasNextPage) {
            fetchNextPage()
          }
        }}
        style={{ maxWidth: 728 }}
        ListFooterComponent={hasNextPage ? <MGLoading show={isFetching} /> : null}
        contentInsetAdjustmentBehavior="automatic"
      />
    </SafeAreaView>
  )
}

const TransactionItem = ({
  createdAt,
  type,
  entityId,
  delta,
}: {
  createdAt: string
  type: string
  entityId: string
  delta: number
}) => {
  const currencyIcon =
    entityId === 'ecoentity_currency_okome'
      ? require('../../../assets/images/store/okome.webp')
      : entityId === 'ecoentity_currency_onigiri'
        ? require('../../../assets/images/store/onigiri.webp')
        : null

  const { t } = useTranslation()
  const formattedTime = useFormatRelativeTime(new Date(createdAt))

  return (
    <View className="w-full relative flex flex-col gap-2 h-12 justify-center">
      <View className="flex-row items-center gap-4">
        <View className="flex flex-col items-start gap-0.5">
          <MGText className="text-sm text-neutral-500 shrink-0">{formattedTime}</MGText>
          <MGText className="text-xs text-neutral-600 shrink-0">
            {dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss')}
          </MGText>
        </View>
        <MGText className="text-base text-foreground shrink-0">
          {t(`transaction.type.${type}`, {
            defaultValue: t('transaction.type.UNKNOWN'),
          })}
        </MGText>
        <View className="flex-1" />
        <View className="flex-row items-center gap-2">
          <MGText className="text-sm text-neutral-800 shrink-0">{delta > 0 ? `+${delta}` : delta}</MGText>
          {currencyIcon && (
            <Image
              source={currencyIcon}
              style={{ width: 24, height: 24, borderRadius: 9999, overflow: 'hidden' }}
              contentFit="cover"
            />
          )}
        </View>
      </View>
    </View>
  )
}

const Separator = () => {
  return <View className="w-full h-[1px] bg-neutral-200 my-2" />
}
