import Ionicons from '@expo/vector-icons/Ionicons'
import clsx from 'clsx'
import type { ComponentProps, FC, ReactNode } from 'react'
import { Modal, ScrollView, View, useWindowDimensions } from 'react-native'
import { KeyboardAvoidingView } from 'react-native-keyboard-controller'
import MGText from './MGText'

export interface MGBaseModalProps {
  /**
   * Whether the modal is visible
   */
  visible: boolean
  /**
   * Function to close the modal
   */
  onClose: () => void
  /**
   * The title of the modal
   */
  title: string
  /**
   * The text alignment of the title
   */
  textAlignment?: 'left' | 'center' | 'right'
  /**
   * The icon to display at the top of the modal
   */
  icon?: ComponentProps<typeof Ionicons>['name']
  /**
   * The color of the icon
   */
  iconColor?: string
  /**
   * The background color of the icon container
   */
  iconBgColor?: string
  /**
   * Override the icon with a custom component
   */
  overrideIcon?: ReactNode
  /**
   * The content of the modal
   */
  children: ReactNode
  /**
   * Whether the content requires a ScrollView
   */
  contentRequireScrollView?: boolean
  /**
   * Maximum height of the content as a percentage of the screen height
   */
  contentMaxHeightPercentage?: number
}

const MGBaseModal: FC<MGBaseModalProps> = ({
  visible,
  onClose,
  title,
  textAlignment = 'center',
  icon,
  iconColor = '#3b82f6',
  iconBgColor = 'bg-blue-100',
  overrideIcon,
  children,
  contentRequireScrollView = true,
  contentMaxHeightPercentage = 0.4,
}) => {
  const dimension = useWindowDimensions()

  let resolvedTextAlignmentClassName = 'text-center'
  let resolvedSelfAlignment = 'self-center'

  if (textAlignment === 'left') {
    resolvedTextAlignmentClassName = 'text-left'
    resolvedSelfAlignment = 'self-start'
  } else if (textAlignment === 'right') {
    resolvedTextAlignmentClassName = 'text-right'
    resolvedSelfAlignment = 'self-end'
  }

  return (
    <Modal visible={visible} transparent animationType="fade" onRequestClose={onClose}>
      <KeyboardAvoidingView behavior="padding" className="flex-1 justify-center items-center bg-black/50 p-4">
        <View className="bg-background rounded-3xl p-6 mx-4 gap-2 relative overflow-hidden min-w-96 w-full max-w-3xl">
          {/* Icon at the top */}
          <View className={clsx('rounded-full p-3', iconBgColor, resolvedSelfAlignment)}>
            {overrideIcon ?? (icon && <Ionicons name={icon} size={32} color={iconColor} />)}
          </View>

          {/* Background icon */}
          {icon && (
            <Ionicons
              name={icon}
              size={256}
              color={iconColor}
              className="absolute -top-16 right-0 -rotate-12 opacity-5"
            />
          )}

          {/* Title */}
          <MGText bold className={clsx('text-xl', resolvedTextAlignmentClassName)}>
            {title}
          </MGText>

          {/* Content */}
          <View
            className="w-full"
            style={{
              maxHeight: dimension.height * contentMaxHeightPercentage,
            }}
          >
            {contentRequireScrollView ? <ScrollView className="w-full">{children}</ScrollView> : children}
          </View>
        </View>
      </KeyboardAvoidingView>
    </Modal>
  )
}

export default MGBaseModal
