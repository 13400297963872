import clsx from 'clsx'
import { useColorScheme } from 'nativewind'
import type React from 'react'
import { type ReactNode, useEffect, useRef } from 'react'
import { type LayoutChangeEvent, TouchableHighlight, View } from 'react-native'
import Animated, {
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  withTiming,
} from 'react-native-reanimated'

export interface MGTabItem {
  value: string
  label: (selected: boolean) => ReactNode
  isPrimary?: boolean
}

interface MGTabProps {
  tabs: MGTabItem[]
  activeTab: string
  onTabChange: (value: string) => void
  className?: string
}

const MGTab: React.FC<MGTabProps> = ({ tabs, activeTab, onTabChange, className = '' }) => {
  const { colorScheme } = useColorScheme()
  const tabLayoutsValue = useSharedValue<Record<string, { x: number; width: number }>>({})
  const accumulatedTabLayouts = useRef<Record<string, { x: number; width: number }>>({})
  const shouldSkipAnimation = useRef(false)

  const activeTabValue = useSharedValue(activeTab)
  useEffect(() => {
    activeTabValue.value = activeTab
  }, [activeTab, activeTabValue])

  const indicatorStyle = useAnimatedStyle(() => {
    const activeTabItem = tabs.find((tab) => tab.value === activeTabValue.value)
    const tabItem = tabLayoutsValue.value[activeTabValue.value]
    if (!tabItem) {
      return {
        opacity: 0, // avoid first layout animation
      }
    }

    // Calculate the target position
    const targetPosition = tabItem.x - tabItem.width / 2 - 4 // -4: padding
    const translateValue = shouldSkipAnimation.current
      ? targetPosition
      : withSpring(targetPosition, {
          damping: 30,
          stiffness: 220,
          mass: 1,
        })

    const regularColor = colorScheme === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'

    const backgroundValue = shouldSkipAnimation.current
      ? regularColor
      : withTiming(interpolateColor(activeTabItem?.isPrimary ? 1 : 0, [0, 1], [regularColor, '#e81a89'], 'HSV'), {
          duration: 200,
        })

    return {
      opacity: 1,
      width: tabItem.width,
      transform: [{ translateX: translateValue }],
      backgroundColor: backgroundValue,
    }
  }, [activeTab, colorScheme, tabs])

  const handleTabLayout = (key: string, event: LayoutChangeEvent) => {
    const { x, width } = event.nativeEvent.layout

    tabLayoutsValue.value = accumulatedTabLayouts.current = { ...accumulatedTabLayouts.current, [key]: { x, width } }
  }

  return (
    <View className={clsx('flex flex-row justify-center w-full bg-neutral-200 rounded-2xl p-1', className)}>
      {/* Animated indicator */}
      <Animated.View className="absolute top-1 z-[1] h-[48px] rounded-xl" style={indicatorStyle} />

      {/* Tab buttons */}
      {tabs.map((tab) => (
        <TouchableHighlight
          key={tab.value}
          className={clsx(
            'flex-1 flex justify-center items-center rounded-xl h-[48px]',
            'z-10', // Ensure text is above the indicator
          )}
          underlayColor="rgba(0, 0, 0, 0.15)"
          onPress={() => onTabChange(tab.value)}
          onLayout={(event) => handleTabLayout(tab.value, event)}
          hitSlop={5}
        >
          {tab.label(activeTab === tab.value)}
        </TouchableHighlight>
      ))}
    </View>
  )
}

export default MGTab
