import { TRPCClientError, createTRPCClient, httpBatchLink, loggerLink } from '@trpc/client'
import { createTRPCQueryUtils, createTRPCReact, type inferReactQueryProcedureOptions } from '@trpc/react-query'
import type { inferRouterError, inferRouterInputs, inferRouterOutputs } from '@trpc/server'
import type { AppRouter } from '../../../backend/src/app/trpc/routers'
import { config } from '../../lib/config'
import { getToken, setToken } from '../../lib/storage'
import { queryClient } from './client'
import fetch from '../fetch'

export type ReactQueryOptions = inferReactQueryProcedureOptions<AppRouter>
export type RouterInputs = inferRouterInputs<AppRouter>
export type RouterOutputs = inferRouterOutputs<AppRouter>
export type RouterError = TRPCClientError<AppRouter>

console.log('config.apiBaseUrl', config.apiBaseUrl)

const commonLinks = [
  loggerLink({
    logger: (l) => {
      if (l.direction === 'up') {
        console.log('[tRPC] >>', `#${l.id}`, l.path, l.input)
      } else if (l.result instanceof TRPCClientError) {
        console.log('[tRPC] <<', `#${l.id}`, l.path, l.result, l.result.name, l.result.data)
      } else {
        console.log('[tRPC] <<', `#${l.id}`, l.path, JSON.stringify(l.result.result.data))
      }
    },
  }),
  httpBatchLink({
    url: `${config.apiBaseUrl}/trpc`,
    async headers() {
      const token = getToken()
      if (!token) {
        return {}
      }
      return {
        Authorization: `Bearer ${token}`,
      }
    },
    async fetch(input, init) {
      const res = await fetch(input, init)
      const newToken = res.headers.get('x-moguchat-set-token')
      if (newToken) {
        setToken(newToken)
      }
      return res
    },
  }),
]

export const trpcReact = createTRPCReact<AppRouter>()

export const trpcClient = createTRPCClient<AppRouter>({
  links: commonLinks,
})

export const trpcUtils = createTRPCQueryUtils({ queryClient, client: trpcClient })
