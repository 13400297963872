export type MessageContentPart = { type: 'plain-text'; content: string } | { type: 'thought'; content: string }

export const processMessageContent = (content: string): MessageContentPart[] => {
  const parts: MessageContentPart[] = []
  const matches = Array.from(content.matchAll(/\*\((.*?)\)\*|\*（(.*?)）\*/g))

  if (matches.length === 0) {
    return [{ type: 'plain-text', content: content.trim() }]
  }

  let lastIndex = 0

  for (const match of matches) {
    // Add text before the thought if exists
    const textBeforeThought = content.slice(lastIndex, match.index).trim()
    if (textBeforeThought) {
      parts.push({ type: 'plain-text', content: textBeforeThought })
    }

    // Add the thought
    const thoughtContent = match[1] || match[2]
    parts.push({ type: 'thought', content: thoughtContent })

    lastIndex = (match.index ?? 0) + match[0].length
  }

  // Add remaining text after last thought if exists
  const remainingText = content.slice(lastIndex).trim()
  if (remainingText) {
    parts.push({ type: 'plain-text', content: remainingText })
  }

  return parts
}
