import { useColorScheme, vars } from 'nativewind'
import { useEffect, type FC, type PropsWithChildren } from 'react'
import { Platform, View } from 'react-native'
import { themes as colorThemes } from '../../lib/theme'

const themes = {
  light: vars(colorThemes.light),
  dark: vars(colorThemes.dark),
}

export const NativeWindThemeVariableProvider: FC<PropsWithChildren> = ({ children }) => {
  const { colorScheme } = useColorScheme()
  const theme = themes[colorScheme ?? 'light']

  useEffect(() => {
    if (Platform.OS === 'web') {
      const root = document.body

      for (const [key, value] of Object.entries(theme)) {
        root.style.setProperty(key, value)
      }

      if (colorScheme === 'dark') {
        root.classList.add('dark')
      } else {
        root.classList.remove('dark')
      }
    }
  }, [theme])

  return (
    <View className="flex-1" id="nativewind-theme-variable-provider" style={theme}>
      {children}
    </View>
  )
}
