import { type FC, useEffect, useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { MGIonicons } from '../common/MGIonicons'
import MGText from '../common/MGText'

interface AccordionItemProps {
  isExpanded: boolean
  children: React.ReactNode
  onPress?: () => void
  title?: string
  description?: string
  hideButton?: boolean
}

export const AccordionItem: FC<AccordionItemProps> = ({
  isExpanded,
  children,
  onPress,
  title,
  description,
  hideButton = false,
}) => {
  const [height, setHeight] = useState(0)
  const animatedHeight = useSharedValue(0)
  const animatedRotation = useSharedValue(0)

  const bodyAnimatedStyles = useAnimatedStyle(() => ({
    height: withTiming(animatedHeight.value, {
      duration: 225,
    }),
  }))
  const iconAnimatedStyles = useAnimatedStyle(() => ({
    transform: [
      {
        rotate: withTiming(`${animatedRotation.value}deg`, {
          duration: 225,
        }),
      },
    ],
  }))

  useEffect(() => {
    if (isExpanded) {
      animatedHeight.value = height
      animatedRotation.value = 180
    } else {
      animatedHeight.value = 0
      animatedRotation.value = 0
    }
  }, [isExpanded, height])

  return (
    <View>
      {!hideButton && (
        <TouchableOpacity
          hitSlop={8}
          className="flex flex-row justify-between items-start w-full"
          onPress={onPress}
          accessibilityRole="button"
          accessibilityLabel={title}
        >
          <View className="flex-1">
            <MGText bold className="text-lg">
              {title}
            </MGText>
            <MGText className="text-neutral-500">{description}</MGText>
          </View>

          <Animated.View className="size-8 flex items-center justify-center" style={[iconAnimatedStyles]}>
            <MGIonicons name="chevron-down" size={20} accessibilityElementsHidden />
          </Animated.View>
        </TouchableOpacity>
      )}

      <Animated.View style={[{ overflow: 'hidden' }, bodyAnimatedStyles]}>
        <View
          style={{ position: 'absolute', width: '100%' }}
          onLayout={({ nativeEvent }) => {
            setHeight(nativeEvent.layout.height)
          }}
        >
          {children}
        </View>
      </Animated.View>
    </View>
  )
}
