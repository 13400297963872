import { useEffect, useState } from 'react'
import { dayjs } from '../dayjs'
import { fromNowTime } from '../../utils/time'

// Shared state to track subscribers
const subscribers = new Set<() => void>()
let intervalId: NodeJS.Timeout | null = null

const startInterval = () => {
  if (intervalId) return

  intervalId = setInterval(() => {
    for (const callback of subscribers) {
      callback()
    }
  }, 60_000) // Update every minute
}

const stopInterval = () => {
  if (!intervalId) return

  clearInterval(intervalId)
  intervalId = null
}

/**
 * A hook that formats a date into a relative time string that updates every minute.
 * The updates are synchronized across all instances of this hook to prevent performance issues.
 *
 * @param date The date to format
 * @param ignoreDate Whether to only show the time (HH:mm) for dates older than a day
 * @returns The formatted relative time string
 */
export const useFormatRelativeTime = (date: Date, ignoreDate = false) => {
  const [, setUpdateCounter] = useState(0)

  useEffect(() => {
    const callback = () => {
      setUpdateCounter((prev) => prev + 1)
    }

    subscribers.add(callback)
    startInterval()

    return () => {
      subscribers.delete(callback)
      if (subscribers.size === 0) {
        stopInterval()
      }
    }
  }, [])

  return fromNowTime(dayjs(date), ignoreDate)
}
