import { zodResolver } from '@hookform/resolvers/zod'
import { captureException } from '@sentry/react-native'
import { useMutation } from '@tanstack/react-query'
import * as Burnt from 'burnt'
import { router } from 'expo-router'
import { Fragment, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { KeyboardAvoidingView, KeyboardAwareScrollView } from 'react-native-keyboard-controller'
import { SafeAreaView } from 'react-native-safe-area-context'
import MGHeader from '../../../components/common/MGHeader'
import MGHeaderText from '../../../components/common/MGHeaderText'
import { MGModalButton } from '../../../components/common/MGModalButton'
import MGRadioCard from '../../../components/common/MGRadioCard'
import MGRemoteImageInput from '../../../components/common/MGRemoteImageInput'
import MGText from '../../../components/common/MGText'
import { MGTextInput } from '../../../components/common/MGTextInput'
import { MGActivityIndicator } from '../../../components/ui/MGActivityIndicator'
import { invalidateCurrentUser, useCurrentUser } from '../../../lib/hooks/useAuth'
import { trpcClient } from '../../../lib/services/trpc'
import { z } from '../../../lib/zod'

const editProfileSchema = z.object({
  // username: z
  //   .string()
  //   .regex(/^[a-z0-9-]+$/, 'Username can only contain lowercase letters, numbers, and hyphens')
  //   .min(3, 'Username must be at least 3 characters long')
  //   .max(20, 'Username must be at most 20 characters long'),
  displayName: z
    .string()
    .min(3, 'Display name must be at least 3 characters long')
    .max(35, 'Display name must be at most 35 characters long'),
  avatarUrl: z.string().optional(),
  genderInterest: z.enum(['for-male', 'for-female', 'all']),
  biography: z.string().max(1000).optional(),
})

type EditProfileFormData = z.infer<typeof editProfileSchema>

const GENDER_OPTIONS = [
  { value: 'for-male', label: 'profile.field.gender-interest.enum.for-male' },
  { value: 'for-female', label: 'profile.field.gender-interest.enum.for-female' },
  { value: 'all', label: 'profile.field.gender-interest.enum.all' },
] as const

export default function EditProfileScreen() {
  const { t } = useTranslation()
  const [pending, setPending] = useState(false)

  const { data: user, isLoading: isLoadingUser } = useCurrentUser()

  const { control, handleSubmit } = useForm<EditProfileFormData>({
    resolver: zodResolver(editProfileSchema),
    defaultValues: {
      displayName: user?.displayName,
      avatarUrl: user?.avatarUrl ?? undefined,
      genderInterest: user?.profile?.genderInterest ?? ('all' as const),
      biography: user?.profile?.biography ?? undefined,
    },
  })

  const updateProfileMutation = useMutation({
    mutationFn: async (data: EditProfileFormData) => {
      await trpcClient.user.updateProfile.mutate({
        displayName: data.displayName,
        avatarUrl: data.avatarUrl ?? undefined,
        genderInterest: data.genderInterest,
        biography: data.biography,
      })
    },
  })

  const onSubmit = async (data: EditProfileFormData) => {
    setPending(true)
    try {
      await updateProfileMutation.mutateAsync(data)
      await invalidateCurrentUser()
      Burnt.toast({
        title: t('profile.edit.success'),
        preset: 'done',
      })
      router.back()
    } catch (error) {
      Burnt.toast({
        title: t('error.update-profile-failed'),
        preset: 'error',
        message: error instanceof Error ? error.message : t('error.unknown-error'),
      })
      captureException(error)
      console.error(error)
    } finally {
      setPending(false)
    }
  }

  return (
    <SafeAreaView className="flex-1 bg-background">
      <MGHeader headerBody={<MGHeaderText>{t('profile.edit')}</MGHeaderText>} />

      <KeyboardAvoidingView className="flex-1" behavior="padding">
        <KeyboardAwareScrollView className="flex-1">
          <View className="p-4 relative size-full mx-auto" style={{ maxWidth: 728 }}>
            {isLoadingUser && (
              <View className="flex-1 items-center justify-center bg-black/50 size-full">
                <MGActivityIndicator size="small" />
              </View>
            )}

            <Controller
              control={control}
              name="avatarUrl"
              render={({ field: { value, onChange } }) => (
                <MGRemoteImageInput value={value} onChange={onChange} type="avatar" />
              )}
            />

            <View className="flex flex-col gap-4">
              <View>
                <MGText className="text-sm text-neutral-500 mb-1">{t('profile.field.nickname')}</MGText>
                <Controller
                  control={control}
                  name="displayName"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Fragment>
                      <MGTextInput
                        value={value}
                        onChangeText={onChange}
                        placeholder={t('profile.field.nickname.placeholder')}
                      />
                      {error && <MGText className="text-sm text-red-500 mt-1">{error.message}</MGText>}
                    </Fragment>
                  )}
                />
              </View>

              <View>
                <MGText className="text-sm text-neutral-500 mb-1">{t('profile.field.gender-interest')}</MGText>
                <Controller
                  control={control}
                  name="genderInterest"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Fragment>
                      <View className="flex flex-col gap-2">
                        {GENDER_OPTIONS.map((option) => (
                          <MGRadioCard
                            key={option.value}
                            label={t(option.label)}
                            selected={value === option.value}
                            onPress={() => onChange(option.value)}
                          />
                        ))}
                      </View>
                      {error && <MGText className="text-sm text-red-500 mt-1">{error.message}</MGText>}
                    </Fragment>
                  )}
                />
              </View>

              <View>
                <MGText className="text-sm text-neutral-500 mb-1">{t('profile.field.biography')}</MGText>
                <Controller
                  control={control}
                  name="biography"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Fragment>
                      <MGTextInput
                        value={value}
                        onChangeText={onChange}
                        placeholder={t('profile.field.biography.placeholder')}
                      />
                      {error && <MGText className="text-sm text-red-500 mt-1">{error.message}</MGText>}
                    </Fragment>
                  )}
                />
              </View>
            </View>

            <MGModalButton
              className="bg-primary-400 dark:bg-primary-500 rounded-full p-4 mt-8"
              onPress={handleSubmit(onSubmit)}
              disabled={pending}
              loading={pending}
            >
              {t('profile.action.save')}
            </MGModalButton>
          </View>
        </KeyboardAwareScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  )
}
