import type { FC } from 'react'
import Svg, { G, Path, Rect, type SvgProps } from 'react-native-svg'

export const TwitterIcon: FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 1800 1800" {...props}>
      <Path
        d="M1014.16 805.784 1460.89 286.5h-105.86L967.137 737.387 657.328 286.5H300l468.492 681.821L300 1512.87h105.866l409.625-476.15 327.179 476.15H1500l-485.86-707.086h.02ZM869.165 974.328l-47.468-67.894-377.686-540.24h162.604l304.797 435.991 47.468 67.894 396.2 566.721h-162.6L869.165 974.354v-.026Z"
        fill="currentColor"
      />
    </Svg>
  )
}

export const DiscordIcon: FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 1800 1800" {...props}>
      <Path
        d="M1410.23 423.417c-95.16-44.52-196.9-76.877-303.27-95.3-13.07 23.618-28.33 55.386-38.85 80.657-113.077-17.005-225.113-17.005-336.109 0-10.521-25.271-26.13-57.039-39.31-80.657a1193.236 1193.236 0 0 0-303.504 95.536c-191.93 290.032-243.959 572.859-217.945 851.667 127.298 95.07 250.666 152.81 371.951 190.6 29.946-41.21 56.653-85.02 79.662-131.2-43.82-16.65-85.79-37.19-125.447-61.05a617.886 617.886 0 0 0 30.754-24.33c241.877 113.14 504.688 113.14 743.668 0a745.65 745.65 0 0 0 30.76 24.33c-39.78 23.97-81.86 44.52-125.68 61.17 23.01 46.06 49.6 89.99 79.66 131.2 121.4-37.79 244.88-95.53 372.18-190.72 30.53-323.21-52.14-603.44-218.52-851.903ZM655.807 1103.86c-72.609 0-132.154-67.79-132.154-150.334 0-82.545 58.273-150.447 132.154-150.447 73.883 0 133.426 67.783 132.154 150.447.115 82.544-58.271 150.334-132.154 150.334Zm488.383 0c-72.61 0-132.16-67.79-132.16-150.334 0-82.545 58.28-150.447 132.16-150.447s133.42 67.783 132.15 150.447c0 82.544-58.27 150.334-132.15 150.334Z"
        fill="currentColor"
      />
    </Svg>
  )
}

export const LineIcon: FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 320 320" {...props}>
      <G>
        <Rect fill="#06c755" width="320" height="320" rx="72.14" />
        <Path
          d="M266.66 144.92c0-47.74-47.86-86.58-106.69-86.58S53.28 97.18 53.28 144.92c0 42.8 38 78.65 89.22 85.42 3.48.75 8.21 2.29 9.4 5.26 1.08 2.7.71 6.93.35 9.65 0 0-1.25 7.53-1.52 9.13-.47 2.7-2.15 10.55 9.24 5.76s61.44-36.18 83.82-61.95c15.46-16.95 22.87-34.19 22.87-53.27Z"
          fill="#fff"
        />
        <Path
          d="M231.16 172.49h-30a2 2 0 0 1-2-2v-46.55a2 2 0 0 1 2-2h30a2 2 0 0 1 2 2v7.57a2 2 0 0 1-2 2h-20.37v7.85h20.37a2 2 0 0 1 2 2V151a2 2 0 0 1-2 2h-20.37v7.86h20.37a2 2 0 0 1 2 2v7.56a2 2 0 0 1-2 2.07Zm-110.87 0a2 2 0 0 0 2-2v-7.56a2 2 0 0 0-2-2H99.92v-37a2 2 0 0 0-2-2h-7.6a2 2 0 0 0-2 2v46.53a2 2 0 0 0 2 2h30Z"
          fill="#06c755"
        />
        <Rect fill="#06c755" x="128.73" y="121.85" width="11.64" height="50.64" rx="2.04" />
        <Path
          fill="#06c755"
          d="M189.84 121.85h-7.56a2 2 0 0 0-2 2v27.66l-21.3-28.77a1.2 1.2 0 0 0-.17-.21l-.12-.12-.11-.09h-.06l-.11-.08h-.06l-.11-.06h-8.47a2 2 0 0 0-2 2v46.56a2 2 0 0 0 2 2h7.57a2 2 0 0 0 2-2v-27.93l21.33 28.8a2 2 0 0 0 .52.52l.12.08h.06l.1.05h.31a2.42 2.42 0 0 0 .54.07h7.52a2 2 0 0 0 2-2v-46.44a2 2 0 0 0-2-2.04Z"
        />
      </G>
    </Svg>
  )
}
