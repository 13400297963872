import Ionicons from '@expo/vector-icons/Ionicons'
import { toast } from 'burnt'
import clsx from 'clsx'
import { Image } from 'expo-image'
import { LinearGradient } from 'expo-linear-gradient'
import { Link, useRouter } from 'expo-router'
import Head from 'expo-router/head'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Animated, TouchableOpacity, View, useWindowDimensions } from 'react-native'
import { clamp } from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useCurrentUser } from '../../lib/hooks/useAuth'
import { useIsDesktop } from '../../lib/hooks/useIsDesktop'
import { type RouterOutputs, trpcClient, trpcUtils } from '../../lib/services/trpc'
import { setLoginRedirect } from '../../lib/storage'
import IconModal from '../common/IconModal'
import { MGLoading } from '../common/MGLoading'
import MGText from '../common/MGText'
import UserChip from '../user/UserChip'

export default function CharacterDetailScreen({
  character,
  setHeaderContentStyle,
  from,
  onClose,
}: {
  character: RouterOutputs['character']['get']
  setHeaderContentStyle?: (style: 'normal' | 'inverted') => void
  from?: string
  onClose?: () => void
}) {
  const isDesktop = useIsDesktop()
  const { data: user, isLoading: isLoadingUser } = useCurrentUser()
  const isNotLoggedIn = !user && !isLoadingUser
  const { t } = useTranslation()
  const inset = useSafeAreaInsets()
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const dimension = useWindowDimensions()
  const router = useRouter()

  const [isCreatingChat, setIsCreatingChat] = useState(false)

  if (!character) {
    return null
  }

  const handleDeleteCharacter = async () => {
    try {
      if (!character.id) {
        return
      }
      await trpcClient.character.delete.mutate({ id: character.id })
      onClose?.()
      router.back()
    } catch (error) {
      console.error(error)
    }
  }

  const onCreateChatClick = async () => {
    if (isNotLoggedIn) {
      setLoginRedirect(`/chat/create?characterId=${character.id}`)
      router.push('/login')
      onClose?.()
      return
    }

    if (from === 'chat' && router.canGoBack()) {
      router.back()
      return
    }
    setIsCreatingChat(true)
    try {
      if (!character.id) {
        return
      }
      const chat = await trpcClient.chat.create.mutate({ characterId: character.id })
      trpcUtils.chat.list.invalidate()
      onClose?.()
      router.push(`/chat/${chat.id}`)
    } catch (error) {
      console.error(error)
    } finally {
      setIsCreatingChat(false)
    }
  }

  const showReportModal = () => {
    Alert.alert(t('character.report'), t('character.report.description'), [
      {
        text: t('action.report'),
        onPress: () => {
          handleReport({
            refType: 'character',
            refId: character.id,
            reason: 'OTHER',
          })
        },
      },
      {
        text: t('action.cancel'),
        style: 'cancel',
      },
    ])
  }

  const showUserReportModal = () => {
    if (!character.author) {
      return
    }
    Alert.alert(t('character.author.report'), t('character.author.report.description'), [
      {
        text: t('action.report'),
        onPress: () => {
          handleReport({
            refType: 'user',
            refId: character.author!.id,
            reason: 'OTHER',
          })
        },
      },
      {
        text: t('action.cancel'),
        style: 'cancel',
      },
    ])
  }

  const handleReport = async ({
    refType,
    refId,
    reason,
  }: {
    refType: 'character' | 'user'
    refId: string
    reason: string
  }) => {
    await trpcClient.report.create.mutate({
      refType,
      refId,
      reason,
    })
    toast({
      title: t('character.report.success'),
      preset: 'done',
    })
  }
  return (
    <View className="size-full relative max-w-screen-lg mx-auto">
      <MGLoading show={isCreatingChat} />

      <Head>
        <title>
          {character.name} | {t('app.name')}
        </title>
        <meta name="description" content={character.description} />
        <meta property="og:title" content={character.name} />
        <meta property="og:description" content={character.description} />
      </Head>

      <View className={clsx('flex-1 flex flex-row items-start', isDesktop && 'rounded-3xl overflow-clip px-4')}>
        {isDesktop && (
          <View className="relative">
            <Image
              source={character.avatarUrl}
              contentFit="cover"
              placeholderContentFit="cover"
              contentPosition="center"
              className="rounded-2xl"
              style={{
                width: 300,
                aspectRatio: 1,
              }}
              placeholder={require('../../assets/images/character-avatar-placeholder.webp')}
              transition={100}
            />

            <LinearGradient
              className="rounded-b-2xl overflow-clip"
              colors={['transparent', 'rgba(0,0,0,0.8)']}
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: 120,
              }}
            />
            <View className="absolute bottom-0 left-0 right-0 p-4">
              <View className="flex flex-row justify-between items-center">
                <MGText bold className="text-3xl flex-1 text-white">
                  {character.name}
                </MGText>
              </View>
            </View>
          </View>
        )}
        <Animated.ScrollView
          className="flex-1"
          bounces={false}
          horizontal={false}
          onScroll={({ nativeEvent }) => {
            setHeaderContentStyle?.(
              nativeEvent.contentOffset.y > dimension.width - inset.top - 56 || nativeEvent.contentOffset.y < 0
                ? 'normal'
                : 'inverted',
            )
          }}
        >
          {!isDesktop && (
            <View className="w-full aspect-square relative">
              <Image
                source={character.avatarUrl}
                contentFit="cover"
                placeholderContentFit="cover"
                contentPosition="center"
                style={{
                  width: '100%',
                  aspectRatio: 1,
                  alignSelf: 'center',
                  overflow: 'hidden',
                }}
                placeholder={require('../../assets/images/character-avatar-placeholder.webp')}
                transition={100}
              />
              <LinearGradient
                colors={['transparent', 'rgba(0,0,0,0.8)']}
                style={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: 120 + 30 * clamp(character.name.length / 30 - 3, 0, 3),
                }}
              />
              <View className="absolute bottom-0 left-0 right-0 p-4">
                <View className="flex flex-row justify-between items-center">
                  <MGText bold className="text-3xl flex-1 text-white shadow-sm">
                    {character.name}
                  </MGText>
                </View>
              </View>
            </View>
          )}
          <View className="w-full px-4 flex flex-col gap-2 mt-4">
            <View className="flex flex-row items-center gap-2 justify-between">
              <View className="flex flex-row items-center gap-2">
                <MGText className="text-sm">{t('character.field.author')}</MGText>
                {character.author && <UserChip user={character.author} />}
              </View>
            </View>

            {character.tags.length > 0 && (
              <MGText className="text-neutral-600 py-1" numberOfLines={1}>
                {character.tags?.map((tag) => `#${tag.name}`).join(' ') || ''}
              </MGText>
            )}

            <View className="h-[1px] bg-neutral-100 mx-2 rounded-full my-2" />

            <MGText bold>{t('character.field.description')}</MGText>
            <MGText>{character.description}</MGText>

            <View className="flex flex-col items-center justify-center mt-8 gap-4 p-4">
              <TouchableOpacity className="flex flex-row items-center gap-2 justify-center" onPress={showReportModal}>
                <MGText className="text-neutral-300">{t('character.report')}</MGText>
              </TouchableOpacity>
              <TouchableOpacity
                className="flex flex-row items-center gap-2 justify-center"
                onPress={showUserReportModal}
              >
                <MGText className="text-neutral-300">{t('character.author.report')}</MGText>
              </TouchableOpacity>
            </View>

            {/* bottom padding for avoiding the absolute positioned button within the scrollable area */}
            {!isDesktop && <View style={{ height: inset.bottom + 100 }} />}
          </View>
        </Animated.ScrollView>
      </View>
      <View
        className={clsx(isDesktop ? 'bg-background flex flex-row gap-2 mt-6 px-4' : 'absolute bottom-0 left-0 right-0')}
        style={
          !isDesktop && {
            marginTop: 10,
            marginBottom: inset.bottom + 10,
            marginHorizontal: 10,
          }
        }
      >
        {character.author?.id === user?.id && (
          <View className={clsx('flex flex-row gap-2', isDesktop ? 'flex-1' : 'mb-2')}>
            <TouchableOpacity
              className="items-center justify-center"
              onPress={() => {
                setShowDeleteConfirmation(true)
              }}
            >
              <View className="aspect-square border-red-500 border rounded-full items-center justify-center p-2 bg-background">
                <Ionicons name="trash-outline" size={20} color="red" />
              </View>
            </TouchableOpacity>
            <Link href={`/character/${character.id}/edit`} asChild>
              <TouchableOpacity className="border border-primary-400 rounded-full py-2 flex items-center justify-center flex-1 bg-background">
                <MGText bold className="text-primary-400 text-center" style={{ fontSize: 16 }}>
                  {t('character.action.edit')}
                </MGText>
              </TouchableOpacity>
            </Link>
          </View>
        )}
        <TouchableOpacity
          className="bg-primary-400 rounded-full py-4 flex-1"
          onPress={onCreateChatClick}
          disabled={isCreatingChat}
        >
          <View className="flex flex-row items-center justify-center gap-2">
            <Ionicons name="chatbubble" size={20} color="white" />
            <MGText bold className="text-white text-center" style={{ fontSize: 16 }}>
              {t('character.action.chat')}
            </MGText>
          </View>
        </TouchableOpacity>
      </View>
      <IconModal
        visible={showDeleteConfirmation}
        severity="danger"
        title={t('character.action.delete.confirm.title')}
        icon="trash"
        description={t('character.action.delete.confirm.description')}
        primaryButtonContent={t('character.action.delete')}
        onConfirm={handleDeleteCharacter}
        onCancel={() => setShowDeleteConfirmation(false)}
      />
    </View>
  )
}
