import * as Notifications from 'expo-notifications'
import pRetry from 'p-retry'
import { useEffect } from 'react'
import { Platform } from 'react-native'
import { trpcClient } from './services/trpc'
import { useIsTokenSet } from './storage'

/**
 * This is to prevent excessive push token upsert requests to the server.
 * We only need to report the push token once per **session** (a.k.a app "cold" boot) if it does not change.
 * The reason we do not persist this to mmkv is because the last token may already be removed from the server DB due to the user restoring the device from a backup/token invalidation.
 */
let lastSuccessfullyPOSTedPushTokenOfThisSession: string | null = null

const CHANNEL_MAPPING = {
  ios: 'APPLE_APNS',
  android: 'GOOGLE_FCM',
} as const

/**
 * Reports the push token to the server in the background if it has not been reported yet in this session.
 *
 * @param pushToken - The device push token to report to the server
 */
export async function reportPushTokenToServer(pushToken: string) {
  if (lastSuccessfullyPOSTedPushTokenOfThisSession === pushToken) {
    console.info('[reportPushTokenToServer] Push token has not changed, skipping reporting')
    return
  }

  const channel = CHANNEL_MAPPING[Platform.OS as keyof typeof CHANNEL_MAPPING]
  if (!channel) {
    console.warn('[reportPushTokenToServer] Unsupported platform', Platform.OS)
    return
  }

  await pRetry(
    async () => {
      // push.associateDevice is idempotent, so we can safely retry it
      await trpcClient.push.associateDevice.mutate({
        channel,
        pushToken,
      })
    },
    {
      factor: 2,
      minTimeout: 1000,
      maxTimeout: 10000,
      retries: 3,
    },
  )

  lastSuccessfullyPOSTedPushTokenOfThisSession = pushToken
  console.info('[reportPushTokenToServer] Successfully reported push token to server', pushToken)
}

export async function dissociatePushTokenFromServer() {
  if (!lastSuccessfullyPOSTedPushTokenOfThisSession) {
    console.warn('[dissociatePushTokenFromServer] No push token to dissociate')
    return
  }

  await trpcClient.push.dissociateDevice.mutate({
    channel: CHANNEL_MAPPING[Platform.OS as keyof typeof CHANNEL_MAPPING],
    pushToken: lastSuccessfullyPOSTedPushTokenOfThisSession!,
  })

  lastSuccessfullyPOSTedPushTokenOfThisSession = null
  console.info('[dissociatePushTokenFromServer] Successfully dissociated push token from server')
}

/**
 * Requests push notification permissions from user and returns the device push token if permission is granted.
 * Push token is also reported to the server in the background.
 *
 * @returns device push token, or null if permission is not granted or device is not a physical device
 */
export async function registerAndSyncPushNotificationToken({
  askForPermissions = true,
}: {
  askForPermissions?: boolean
} = {}) {
  if (Platform.OS !== 'ios' && Platform.OS !== 'android') {
    console.info('[registerAndSyncPushNotificationToken] Must use physical device for push notifications')
    return null
  }

  const { status: existingStatus } = await Notifications.getPermissionsAsync()
  let finalStatus = existingStatus
  if (existingStatus !== Notifications.PermissionStatus.GRANTED && askForPermissions) {
    const { status } = await Notifications.requestPermissionsAsync({
      ios: {
        allowAlert: true,
        allowBadge: true,
        allowSound: true,
      },
    })
    finalStatus = status
  }
  if (finalStatus !== Notifications.PermissionStatus.GRANTED) {
    console.warn(
      '[registerAndSyncPushNotificationToken] Permission not granted to get push token for push notification',
    )
    return null
  }
  try {
    const pushToken = await Notifications.getDevicePushTokenAsync()
    if (Platform.OS !== pushToken.type) {
      console.warn(
        `[registerAndSyncPushNotificationToken] received device push token with wrong platform type. Current platform: ${Platform.OS}, token type: ${pushToken.type}`,
      )
      return null
    }
    const pushTokenString = pushToken.data as string
    console.info('[registerAndSyncPushNotificationToken] received device push token', pushTokenString)
    reportPushTokenToServer(pushTokenString) // we explicitly do not await this
    return pushTokenString
  } catch (e: unknown) {
    console.warn('[registerAndSyncPushNotificationToken] error getting device push token', e)
    return null
  }
}

/**
 * Registers and syncs the push notification token when the component mounts.
 * This is for use in the root layout so push token is updated on every app cold boot.
 * It will NOT ask for permissions if one is not granted.
 */
export const useRegisterAndSyncPushNotificationToken = () => {
  const signedIn = useIsTokenSet()
  useEffect(() => {
    if (!signedIn) return
    registerAndSyncPushNotificationToken({
      askForPermissions: false,
    })
  }, [signedIn])
}
