import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { TouchableHighlight, View } from 'react-native'
import { useTheme } from '../../lib/theme'
import { MGCharactersMasonry } from '../common/MGCharactersMasonry'
import { MGGradientBackground } from '../common/MGGradientBackground'
import { MGIonicons } from '../common/MGIonicons'
import MGText from '../common/MGText'
import { MoguPassBenefitsList } from './MoguPassBenefitsList'
import { MoguPassSVG } from './MoguPassSVG'

const SelectionScreen: FC<{ onSelect: (screen: 'subscribe' | 'store') => void }> = ({ onSelect }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <View className="flex gap-4">
      <View
        className="rounded-xl"
        style={{
          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: 1,
          },
          shadowOpacity: 0.2,
          shadowRadius: 1.41,
          elevation: 2,
        }}
      >
        <TouchableHighlight
          className="rounded-xl overflow-hidden"
          onPress={() => onSelect('subscribe')}
          hitSlop={6}
          underlayColor={theme['neutral-300']}
          activeOpacity={0.8}
        >
          <MGGradientBackground>
            <View className="flex flex-row items-center justify-between w-full p-4 bg-white/50 dark:bg-black/60">
              <View className="flex-1 flex gap-1">
                <View className="pt-1 pb-2 flex flex-row items-center justify-between">
                  <MoguPassSVG height={16} />

                  <MGIonicons name="chevron-forward" size={24} />
                </View>

                <MoguPassBenefitsList
                  benefitKeys={[
                    'purchase.subscription.benefits.remove-all-ads',
                    'purchase.subscription.benefits.unlimited-chats',
                    'purchase.subscription.benefits.unlimited-private-characters',
                    'purchase.subscription.benefits.quicker-responses',
                  ]}
                />

                <MGCharactersMasonry />
              </View>
            </View>
          </MGGradientBackground>
        </TouchableHighlight>
      </View>

      <TouchableHighlight
        className="bg-background rounded-xl border border-neutral-200"
        onPress={() => onSelect('store')}
        hitSlop={6}
        underlayColor={theme['neutral-300']}
      >
        <View className="flex flex-row items-center justify-between w-full p-4">
          <View className="flex-1">
            <MGText bold className="text-lg">
              {t('purchase.credit.store')}
            </MGText>
          </View>
          <MGIonicons name="chevron-forward" size={24} />
        </View>
      </TouchableHighlight>
    </View>
  )
}

export default SelectionScreen
