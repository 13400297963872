import { useColorScheme } from 'nativewind'
import colors from 'tailwindcss/colors'

export const themes = {
  light: {
    background: '#ffffff',
    foreground: '#000000',
    'neutral-50': colors.neutral['50'],
    'neutral-100': colors.neutral['100'],
    'neutral-200': colors.neutral['200'],
    'neutral-300': colors.neutral['300'],
    'neutral-400': colors.neutral['400'],
    'neutral-500': colors.neutral['500'],
    'neutral-600': colors.neutral['600'],
    'neutral-700': colors.neutral['700'],
    'neutral-800': colors.neutral['800'],
    'neutral-900': colors.neutral['900'],
    'neutral-950': colors.neutral['950'],
  },
  dark: {
    background: '#111111',
    foreground: '#f0f0f0',
    'neutral-50': colors.neutral['950'],
    'neutral-100': colors.neutral['900'],
    'neutral-200': colors.neutral['800'],
    'neutral-300': colors.neutral['700'],
    'neutral-400': colors.neutral['600'],
    'neutral-500': colors.neutral['500'],
    'neutral-600': colors.neutral['400'],
    'neutral-700': colors.neutral['300'],
    'neutral-800': colors.neutral['200'],
    'neutral-900': colors.neutral['100'],
    'neutral-950': colors.neutral['50'],
  },
} as const

export const useTheme = () => {
  const { colorScheme } = useColorScheme()
  return colorScheme === 'dark' ? themes.dark : themes.light
}
