import { Platform } from 'react-native'
import i18n from './i18n'
import { getNativeUserAgent } from './userAgent'

export default function modifiedFetch(input: RequestInfo | URL, init?: RequestInit): Promise<Response> {
  const modifiedInit =
    Platform.OS === 'web'
      ? init
      : {
          ...init,
          headers: (() => {
            const headers = new Headers(init?.headers)
            headers.set('user-agent', getNativeUserAgent())
            headers.set('x-moguchat-locale', i18n.language)
            return headers
          })(),
        }

  return fetch(input, modifiedInit)
}
