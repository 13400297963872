import { trpcReact, trpcUtils } from '../services/trpc'
import { useIsTokenSet } from '../storage'

export const useActiveSubscription = () => {
  const loggedIn = useIsTokenSet()
  const res = trpcReact.user.getViewerActiveSubscription.useQuery(undefined, {
    enabled: loggedIn,
  })

  return res
}

export const invalidateActiveSubscription = async () => {
  await trpcUtils.user.getViewerActiveSubscription.invalidate(undefined, {
    refetchType: 'active',
  })
  return trpcUtils.user.getViewerActiveSubscription.fetch()
}
