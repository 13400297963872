import { Image } from 'expo-image'
import { useTranslation } from 'react-i18next'
import Animated, { FadeIn, FadeInLeft, FadeOut, FadeOutRight } from 'react-native-reanimated'
import MGText from './MGText'

interface LoadingOverlayProps {
  visible: boolean
}

function MGLoadingOverlay({ visible }: LoadingOverlayProps) {
  const { t } = useTranslation()

  if (!visible) {
    return null
  }

  return (
    <Animated.View
      entering={FadeIn.duration(250)}
      exiting={FadeOut.duration(250).delay(100)}
      className="flex flex-col gap-2 items-center justify-center absolute top-0 left-0 right-0 bottom-0 bg-background bg-opacity-80 z-50"
    >
      <Animated.View entering={FadeInLeft.duration(250)} exiting={FadeOutRight.duration(250)}>
        <Image
          source={require('../../assets/images/status/loading.png')}
          style={{ width: 50, height: 50 }}
          autoplay={true}
        />
      </Animated.View>
      <MGText className="text-neutral-500">{t('common.loading')}</MGText>
    </Animated.View>
  )
}

export default MGLoadingOverlay
