import { useEffect } from 'react'
import { Platform, type EmitterSubscription } from 'react-native'
import {
  ErrorCode,
  type ProductPurchase,
  type PurchaseError,
  type SubscriptionPurchase,
  finishTransaction,
  flushFailedPurchasesCachedAsPendingAndroid,
  initConnection,
  purchaseErrorListener,
  purchaseUpdatedListener,
} from 'react-native-iap'
import { type RouterInputs, trpcClient } from '../services/trpc'
import pRetry from 'p-retry'
import * as Sentry from '@sentry/react-native'
import { inventoryChangeToastStateAtom } from '../inventoryChangeToast'
import { invalidateInventory } from '../hooks/useInventory'
import { store } from '../store'
import { useIsTokenSet } from '../storage'
import { queryClient } from '../services/client'
import { storeProductsQueryOptions } from './purchase'
import { storeSubscriptionsQueryOptions } from './subscription'

export const sendPurchaseCallback = async (input: RouterInputs['store']['purchase']['callback']) => {
  const rewardItems = await trpcClient.store.purchase.callback.mutate(input)

  const okome = rewardItems?.rewardItems.find((r) => r.entityId === 'ecoentity_currency_okome')
  const onigiri = rewardItems?.rewardItems.find((r) => r.entityId === 'ecoentity_currency_onigiri')

  store.set(inventoryChangeToastStateAtom, {
    delta: {
      okome: okome?.rewardAmount ?? 0,
      onigiri: onigiri?.rewardAmount ?? 0,
    },
  })

  invalidateInventory()
}

const isSubscriptionPurchase = (purchase: SubscriptionPurchase | ProductPurchase): purchase is SubscriptionPurchase => {
  return (
    'autoRenewingAndroid' in purchase || 'transactionReasonIOS' in purchase || 'originalTransactionDateIOS' in purchase
  )
}

export const useIAPPreload = () => {
  const isTokenSet = useIsTokenSet()

  useEffect(() => {
    if (!isTokenSet) return

    // queryClient.prefetchQuery(storeProductsQueryOptions()) // product purchases are generally intentional so users might willing to wait a bit longer.
    queryClient.prefetchQuery(storeSubscriptionsQueryOptions())
  }, [isTokenSet])
}

export const useIAP = () => {
  useEffect(() => {
    let purchaseUpdateSubscription: EmitterSubscription | null = null
    let purchaseErrorSubscription: EmitterSubscription | null = null

    const initializeIAP = async () => {
      if (Platform.OS === 'web') return

      try {
        await initConnection()

        try {
          // Clear any failed pending purchases from Android cache
          await flushFailedPurchasesCachedAsPendingAndroid()
        } catch (error) {
          // Ignore errors as they're expected if there are legitimate pending purchases
        }

        // Set up purchase listener
        purchaseUpdateSubscription = purchaseUpdatedListener(
          async (purchase: SubscriptionPurchase | ProductPurchase) => {
            if (Platform.OS !== 'ios' && Platform.OS !== 'android') {
              console.warn(
                '[IAP] purchaseUpdatedListener received new purchase, but platform is not supported',
                Platform.OS,
              )
              return
            }

            console.debug(
              '[IAP] purchaseUpdatedListener received new purchase',
              purchase.productId,
              new Date(purchase.transactionDate),
            )

            if (isSubscriptionPurchase(purchase)) {
              console.info(
                '[IAP] purchaseUpdatedListener received new subscription purchase. Ignoring since we are handling subscription purchases on server side via webhooks.',
              )
              return
            }

            try {
              // Here you would integrate with your backend API
              await pRetry(
                async () => {
                  const mutateInput = (() => {
                    if (Platform.OS === 'ios') {
                      if (!purchase.transactionId) throw new Error('No transactionId')
                      return {
                        platform: 'APP_STORE',
                        transactionId: purchase.transactionId,
                      } as const
                    }

                    if (!purchase.productId) throw new Error('No productId')
                    if (!purchase.purchaseToken) throw new Error('No purchaseToken')

                    return {
                      platform: 'GOOGLE_PLAY',
                      packageName: 'ai.moguchat.app',
                      productId: purchase.productId,
                      purchaseToken: purchase.purchaseToken,
                    } as const
                  })()

                  await sendPurchaseCallback(mutateInput)
                },
                {
                  factor: 2,
                  minTimeout: 1000,
                  maxTimeout: 10000,
                  retries: 5,
                },
              )

              await finishTransaction({ purchase, isConsumable: true })
            } catch (error) {
              console.error('Failed to process purchase:', error)

              Sentry.captureException(error)
            }
          },
        )

        // Set up error listener
        purchaseErrorSubscription = purchaseErrorListener((error: PurchaseError) => {
          if (error.code === ErrorCode.E_USER_CANCELLED) {
            // ignore user cancelled error
            return
          }

          console.warn('purchaseErrorListener', error)

          Sentry.captureException(error)
        })
      } catch (error) {
        console.error('Failed to initialize IAP:', error)
      }
    }

    initializeIAP()

    // Cleanup subscriptions
    return () => {
      if (purchaseUpdateSubscription) {
        purchaseUpdateSubscription.remove()
      }
      if (purchaseErrorSubscription) {
        purchaseErrorSubscription.remove()
      }
    }
  }, [])
}
