import Ionicons from '@expo/vector-icons/Ionicons'
import { Image } from 'expo-image'
import { Link } from 'expo-router'
import { useAtom } from 'jotai'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, TouchableOpacity, View } from 'react-native'
import { ampli } from '../../lib/ampli'
import { config } from '../../lib/config'
import { useIsDesktop } from '../../lib/hooks/useIsDesktop'
import { purchaseSheetStateAtom } from '../../lib/purchaseSheet'
import { MGBottomSheet } from '../common/MGBottomSheet'
import { MGHelpDialog } from '../common/MGHelpDialog'
import { MGIonicons } from '../common/MGIonicons'
import { MGSlideUpModal } from '../common/MGSlideUpModal'
import MGText from '../common/MGText'
import { DownloadAppGooglePlayIcon, DownloadAppStoreIcon } from '../common/StoreDownloadIcon'
import { InventoryChangeToast } from '../InventoryChangeToast'
import { Balance } from './Balance'
import { MoguPassSVG } from './MoguPassSVG'
import PurchaseSheetStore from './PurchaseSheetStore'
import PurchaseSheetSubscription from './PurchaseSheetSubscription'
import SelectionScreen from './SelectionScreen'

// delete this if web purchase is running smoothly
const ENABLE_PURCHASE_ON_WEB = true

export const PurchaseSheet = () => {
  const { t } = useTranslation()
  const [purchaseSheetState, setPurchaseSheetState] = useAtom(purchaseSheetStateAtom)
  const isOpen = purchaseSheetState.opened
  const isDesktop = useIsDesktop()

  useEffect(() => {
    if (isOpen) {
      ampli.addCreditBottomSheetOpened()
    }
  }, [isOpen])

  const content = (() => {
    if (Platform.OS === 'web' && !ENABLE_PURCHASE_ON_WEB) {
      // no matter what purchaseSheetState.selected is, we always show the error message if purchase is not enabled on web
      return (
        <View className="mx-auto text-center px-4">
          <MGText className="text-neutral-800 bg-blue-600/20 p-4 rounded-lg">
            {t('purchase.credit.only-available-on-mobile')}
          </MGText>

          <View className="flex flex-row items-center gap-4">
            <Image
              source={require('../../assets/images/mobile-app-lineup.webp')}
              style={{
                width: 1253 / 3,
                height: 1145 / 3,
              }}
              className="w-full"
            />

            <View className="flex flex-col items-start gap-2">
              <MGText className="text-2xl flex-1 mb-8" bold>
                {t('download-app.title')}
              </MGText>

              <Link href={config.appDownloadUrls.ios}>
                <DownloadAppStoreIcon className="h-12 hover:brightness-125 active:brightness-75" />
              </Link>
              <Link href={config.appDownloadUrls.android}>
                <DownloadAppGooglePlayIcon className="h-12 hover:brightness-125 active:brightness-75" />
              </Link>
            </View>
          </View>
        </View>
      )
    }

    if (!purchaseSheetState.selected) {
      return <SelectionScreen onSelect={(screen) => setPurchaseSheetState((s) => ({ ...s, selected: screen }))} />
    }

    return (
      <View className="w-full">
        <View className="flex flex-row items-center mb-2">
          <TouchableOpacity
            hitSlop={6}
            onPress={() => setPurchaseSheetState((s) => ({ ...s, selected: null }))}
            className="flex flex-row items-center justify-start gap-2 -ml-2"
          >
            <MGIonicons name="chevron-back" size={24} className="opacity-50" />
            <MGText bold className="text-2xl">
              {purchaseSheetState.selected === 'subscribe' ? <MoguPassSVG height={16} /> : t('purchase.credit.store')}
            </MGText>
          </TouchableOpacity>
        </View>

        {purchaseSheetState.selected === 'subscribe' && <PurchaseSheetSubscription />}
        {purchaseSheetState.selected === 'store' && <PurchaseSheetStore />}
      </View>
    )
  })()

  const BaseComponent = isDesktop ? MGSlideUpModal : MGBottomSheet

  return (
    <BaseComponent
      isOpen={isOpen}
      setIsOpen={() => setPurchaseSheetState((s) => ({ ...s, opened: false, selected: null }))}
      accessibilityLabel={t('purchase.credit.store')}
      accessible
    >
      <View className="p-6 w-full">
        {purchaseSheetState.selected !== 'subscribe' && (
          <View className="flex flex-row items-center justify-center gap-4 pb-4">
            <Balance />

            <MGHelpDialog title={t('purchase.credit.help.title')} content={t('purchase.credit.help.content')} />
          </View>
        )}

        {purchaseSheetState.reason && (
          <View className="flex flex-row items-start justify-start gap-2 bg-red-600/30 px-4 py-5 mb-4 rounded">
            <MGIonicons name="alert-sharp" size={24} color="#dc2626" />
            <MGText bold className="text-red-600 shrink text-base ">
              {t(purchaseSheetState.reason)}
            </MGText>
          </View>
        )}

        <InventoryChangeToast embedded />

        {content}
      </View>

      <View className="absolute top-0 right-0 p-6">
        <TouchableOpacity hitSlop={6} onPress={() => setPurchaseSheetState({ opened: false, selected: null })}>
          <Ionicons name="close-outline" size={24} color="#aaa" />
        </TouchableOpacity>
      </View>
    </BaseComponent>
  )
}
