import * as Burnt from 'burnt'
import { useSetAtom } from 'jotai'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dayjs } from '../../lib/dayjs'
import { useCurrentUser } from '../../lib/hooks/useAuth'
import { invalidateInventory } from '../../lib/hooks/useInventory'
import { inventoryChangeToastStateAtom } from '../../lib/inventoryChangeToast'
import { trpcClient, trpcReact, trpcUtils } from '../../lib/services/trpc'
import IconModal from '../common/IconModal'

// the "Daily Cutoff Time" globally is at 04:00 (Asia/Tokyo)
const isDailyCutoffTimeInBetween = (date1: Date, date2: Date) => {
  const cutoffTime = dayjs('04:00', 'HH:mm').tz('Asia/Tokyo').toDate()
  return date1 <= cutoffTime && date2 >= cutoffTime
}

export default function DailyBonus() {
  // get store.rewards.list, if dailyBonus.claimable: true, then show the modal
  // on click, store.rewards.claim.mutate({ type: 'daily_bonus' })
  const { data: user } = useCurrentUser()
  const { data: rewards } = trpcReact.store.rewards.list.useQuery(undefined, {
    enabled: !!user?.id,
  })
  const setInventoryChangeToastState = useSetAtom(inventoryChangeToastStateAtom)
  const dailyBonus = rewards?.dailyBonus

  const [isOpen, setIsOpen] = useState(false)
  const ignoredForDateRef = useRef<string | null>(null)

  const claimDailyBonus = async () => {
    if (!dailyBonus) return
    try {
      const claimed = await trpcClient.store.rewards.claim.mutate({ type: 'daily_bonus' })
      setInventoryChangeToastState({
        delta: {
          okome: claimed.find((item) => item.entityId === 'ecoentity_currency_okome')?.amount ?? 0,
          onigiri: claimed.find((item) => item.entityId === 'ecoentity_currency_onigiri')?.amount ?? 0,
        },
      })
      trpcUtils.store.rewards.list.invalidate()
      invalidateInventory()
      setIsOpen(false)
    } catch (error) {
      console.error(error)
      Burnt.toast({
        title: t('reward.daily-bonus.claim-error'),
        preset: 'error',
      })
    }
  }

  // === true is to avoid accidentally triggering useEffect when it evaluates to undefined
  // (so this transforms `undefined`, `true`, `false` into `true`, `false)
  const claimable = dailyBonus?.claimable === true
  useEffect(() => {
    if (claimable) {
      if (ignoredForDateRef.current && isDailyCutoffTimeInBetween(new Date(ignoredForDateRef.current), new Date())) {
        // if ignoredForDate is true (the user tapped cancel for today) then we don't show the modal anymore
        return
      }

      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [claimable])

  const { t } = useTranslation()

  return (
    <IconModal
      title={t('reward.daily-bonus')}
      description={t('reward.daily-bonus.description', { amount: dailyBonus?.rewardItems.at(0)?.amount ?? 0 })}
      icon="gift-outline"
      visible={isOpen}
      onCancel={() => {
        setIsOpen(false)
        ignoredForDateRef.current = new Date().toISOString()
      }}
      onConfirm={claimDailyBonus}
      severity="info"
      primaryButtonContent={t('reward.daily-bonus.claim')}
    />
  )
}
