import { Image, type ImageProps } from 'expo-image'
import { LinearGradient } from 'expo-linear-gradient'
import * as ScreenCapture from 'expo-screen-capture'
import { useColorScheme } from 'nativewind'
import type React from 'react'
import { Fragment, useEffect, useRef, useState } from 'react'
import { Platform, View } from 'react-native'
import QRCode from 'react-native-qrcode-svg'
import ViewShot from 'react-native-view-shot'
import { useTheme } from '../../lib/theme'
import MGText from '../common/MGText'
import { ScreenshotShareModal } from './ScreenshotShareModal'

type ScreenshotState = { type: 'processed'; screenshotUri: string } | { type: 'capturing' } | null

interface ChatScreenshotProps {
  character?: {
    id: string
    name: string
    avatarUrl?: string
  } | null
  children: React.ReactNode
}

const Avatar = ({ onDisplay, avatarUrl }: ImageProps & { avatarUrl?: string }) => {
  return avatarUrl ? (
    <Image source={{ uri: avatarUrl }} style={{ width: 32, height: 32, borderRadius: 9999 }} onDisplay={onDisplay} />
  ) : (
    <Image
      source={require('../../assets/images/character-avatar-placeholder.webp')}
      style={{ width: 32, height: 32, borderRadius: 9999 }}
      onDisplay={onDisplay}
    />
  )
}

export const ChatScreenshot: React.FC<ChatScreenshotProps> = ({ character, children }) => {
  const theme = useTheme()
  const { colorScheme } = useColorScheme()
  const [screenshotState, setScreenshotState] = useState<ScreenshotState>(null)
  const screenshotImageDisplayedCallbackRef = useRef<() => void>(() => {})
  const chatViewRef = useRef<ViewShot>(null)

  useEffect(() => {
    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      const subscription = ScreenCapture.addScreenshotListener(async () => {
        if (!character) return
        if (!chatViewRef.current?.capture) return
        if (screenshotState) return // only respond while idling

        try {
          const promise: Promise<void> = new Promise((resolve) => {
            const NUMBER_OF_IMAGE_VIEWS_TO_WAIT_FOR = 2
            let numberOfImageViewsDisplayed = 0
            screenshotImageDisplayedCallbackRef.current = () => {
              console.log('screenshotImageDisplayedCallbackRef')
              numberOfImageViewsDisplayed++
              if (numberOfImageViewsDisplayed === NUMBER_OF_IMAGE_VIEWS_TO_WAIT_FOR) {
                resolve()
              }
            }
          })

          setScreenshotState({ type: 'capturing' })

          await Promise.race([
            promise,
            new Promise<void>((resolve) => {
              setTimeout(() => {
                resolve()
              }, 5000) // wait for the avatar to render for at maximum 5 seconds
            }),
          ])
          console.log('promise resolved')

          screenshotImageDisplayedCallbackRef.current = () => {}

          const uri = await new Promise<string>((resolve, reject) => {
            requestAnimationFrame(async () => {
              console.log('requestAnimationFrame')
              // Take a screenshot of the chat view
              if (!chatViewRef.current?.capture) return reject(new Error('Chat view not found'))

              const uri = await chatViewRef.current.capture()
              if (!uri) return reject(new Error('Failed to capture screenshot'))

              resolve(uri)
            })
          })

          setScreenshotState({ type: 'processed', screenshotUri: uri })
        } catch (error) {
          console.error('Failed to capture screenshot:', error)
          setScreenshotState(null)
        }
      })

      return () => {
        subscription.remove()
      }
    }
  }, [character, screenshotState])

  return (
    <Fragment>
      <ViewShot
        ref={chatViewRef}
        style={{
          flex: 1,
          position: 'relative',
          flexDirection: 'column',
          backgroundColor: theme.background,
          marginTop: screenshotState?.type === 'capturing' ? -200 : 0, // expand container upwards
          paddingBottom: screenshotState?.type === 'capturing' ? 100 : 0, // leave room for bottom content (Logo & QR)
        }}
        options={{
          format: 'png',
          quality: 1,
          result: 'tmpfile',
        }}
      >
        {/* Header for screenshot */}
        {screenshotState?.type === 'capturing' && character && (
          <View className="px-4 py-2 flex-row items-center gap-2 border-b border-neutral-200">
            <Avatar
              avatarUrl={character.avatarUrl}
              onDisplay={() => {
                console.log('onDisplay header avatar')
                screenshotImageDisplayedCallbackRef.current()
              }}
            />
            <MGText bold className="text-lg text-foreground shrink" numberOfLines={1} lineBreakMode="tail">
              {character.name}
            </MGText>
          </View>
        )}

        {/* Gradient overlay for bottom content */}
        {screenshotState?.type === 'capturing' && (
          <View className="absolute bottom-0 left-0 right-0 h-[100px] z-[90]">
            <LinearGradient
              colors={[
                colorScheme === 'dark' ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)',
                colorScheme === 'dark' ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,1)',
                colorScheme === 'dark' ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,1)',
              ]}
              locations={[0, 0.8, 1]}
              style={{ height: '100%' }}
            />
          </View>
        )}

        {/* Invisible overlay that only appears in screenshot */}
        {screenshotState?.type === 'capturing' && (
          <View className="absolute bottom-4 right-4 items-center z-[100]">
            <View className="bg-white p-2 rounded-lg shadow">
              {character && <QRCode value={`https://moguchat.ai/character/${character.id}`} size={72} />}
            </View>
          </View>
        )}

        {/* App logo for screenshot */}
        {screenshotState?.type === 'capturing' && (
          <View className="absolute bottom-4 left-4 items-start z-[100] flex gap-2">
            <Image
              source={require('../../assets/images/icon.png')}
              style={{ width: 48, height: 48, borderRadius: 10, overflow: 'hidden' }}
              onDisplay={() => {
                console.log('onDisplay app logo')
                screenshotImageDisplayedCallbackRef.current()
              }}
            />
            <MGText>MoguChat.AI</MGText>
          </View>
        )}

        {children}
      </ViewShot>

      {screenshotState?.type === 'processed' && (
        <View className="absolute bottom-0 left-0 right-0 top-0">
          <ScreenshotShareModal
            isOpen={true}
            onClose={() => {
              setScreenshotState(null)
            }}
            imageUri={screenshotState.screenshotUri}
            characterId={character?.id!}
          />
        </View>
      )}
    </Fragment>
  )
}
