import clsx from 'clsx'
import { Image } from 'expo-image'
import type { FC } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { CharacterCreateFAB } from '../../../components/character/CharacterCreateFAB'
import MyCharactersRow from '../../../components/character/MyCharactersRow'
import { BadgeDisplay } from '../../../components/common/BadgeDisplay'
import ColorSchemeSelectorModal from '../../../components/common/ColorSchemeSelectorModal'
import LanguageSelectorModal from '../../../components/common/LanguageSelectorModal'
import MGHeader from '../../../components/common/MGHeader'
import MGHeaderText from '../../../components/common/MGHeaderText'
import MGText from '../../../components/common/MGText'
import SettingsSection from '../../../components/common/SettingsSection'
import { MoguPassBanner } from '../../../components/monetization/MoguPassBanner'
import { MoguPassSVG } from '../../../components/monetization/MoguPassSVG'
import { PurchaseRows } from '../../../components/monetization/PurchaseRows'
import { MGActivityIndicator } from '../../../components/ui/MGActivityIndicator'
import { useActiveSubscription } from '../../../lib/hooks/useActiveSubscription'
import { useCurrentUser } from '../../../lib/hooks/useAuth'
import { trpcReact } from '../../../lib/services/trpc'

const UserSubscriptionBadge: FC<{
  className?: string
}> = ({ className }) => {
  const { data, isLoading } = useActiveSubscription()

  if (isLoading) {
    return (
      <View className={clsx('rounded px-10 bg-neutral-100 h-10 flex items-center justify-center', className)}>
        <MGActivityIndicator size={20} />
      </View>
    )
  }

  if (data?.privilegeId === 'privilege_mogupass') {
    return (
      <View
        className={clsx('rounded-full px-5 h-10 flex items-center justify-center bg-primary-600', className)}
        accessibilityElementsHidden={false}
        accessibilityRole="text"
        accessibilityLabel="MoguPass"
      >
        <MoguPassSVG height={13} color="#ffffff" />
      </View>
    )
  }

  return <MoguPassBanner rounded="lg" />
}

const ProfileScreen: FC = () => {
  const { t } = useTranslation()
  const { data: user, isLoading } = useCurrentUser()
  const [isLanguageModalVisible, setIsLanguageModalVisible] = useState(false)
  const [isColorSchemeModalVisible, setIsColorSchemeModalVisible] = useState(false)

  const { data: inboxMessages } = trpcReact.mailbox.items.list.useQuery()
  const unreadCount = inboxMessages?.filter((message) => !message.readAt).length ?? 0

  if (isLoading || !user) {
    return (
      <SafeAreaView className="size-full">
        <View className="flex-1 items-center justify-center">
          <MGText>{t('common.loading')}</MGText>
        </View>
      </SafeAreaView>
    )
  }

  return (
    <View className="flex-1 bg-background">
      <SafeAreaView edges={['top', 'left', 'right']} className="flex-1">
        <MGHeader disableBackButton headerBody={<MGHeaderText>{t('tab.profile')}</MGHeaderText>} />

        <ScrollView
          className="flex-1 w-full mx-auto"
          contentContainerClassName="flex flex-col gap-4 pt-4 pb-24"
          style={{
            maxWidth: 728,
          }}
        >
          <View className="px-4 flex flex-row items-center justify-center gap-4">
            <Image
              source={user.avatarUrl}
              contentFit="cover"
              placeholderContentFit="cover"
              contentPosition="center"
              style={{
                width: 96,
                height: 96,
                alignSelf: 'center',
                borderRadius: 9999,
              }}
              placeholder={require('../../../assets/images/user-avatar-placeholder.png')}
            />

            <View className="flex flex-col flex-1 items-start justify-center gap-2">
              <View className="flex flex-row w-full items-center gap-2">
                <MGText bold className="text-xl" numberOfLines={1} lineBreakMode="tail">
                  {user.displayName}
                </MGText>

                {user.badges.map((badge) => (
                  <BadgeDisplay
                    key={badge.id}
                    imageUrl={badge.imageUrl}
                    description={badge.description}
                    name={badge.name}
                  />
                ))}
              </View>

              <UserSubscriptionBadge />
            </View>
          </View>

          <MyCharactersRow />

          <PurchaseRows />

          <SettingsSection
            items={[
              {
                title: t('user-inbox.title'),
                icon: 'mail-outline',
                href: '/user-inbox',
                badge: unreadCount > 0 ? unreadCount.toString() : undefined,
              },
              {
                title: t('transaction.list.title'),
                icon: 'document-outline',
                href: '/transaction',
              },
              {
                title: t('profile.edit'),
                icon: 'create-outline',
                href: '/profile/edit',
              },
            ]}
          />

          <SettingsSection
            items={[
              {
                title: t('app-settings.language'),
                icon: 'language',
                onPress: () => {
                  setIsLanguageModalVisible(true)
                },
              },
              {
                title: t('app-settings.color-scheme.title'),
                icon: 'contrast-outline',
                onPress: () => setIsColorSchemeModalVisible(true),
              },
            ]}
          />

          <SettingsSection
            items={[
              {
                title: t('support.title'),
                icon: 'help-circle-outline',
                href: '/support',
              },
            ]}
          />
        </ScrollView>
        <View className="absolute bottom-3 left-3 right-3">
          <CharacterCreateFAB size="lg" justify="end" />
        </View>

        <LanguageSelectorModal visible={isLanguageModalVisible} onClose={() => setIsLanguageModalVisible(false)} />
        <ColorSchemeSelectorModal
          visible={isColorSchemeModalVisible}
          onClose={() => setIsColorSchemeModalVisible(false)}
        />
      </SafeAreaView>
    </View>
  )
}

export default ProfileScreen
