{
  "action.back": "돌아가기",
  "action.cancel": "취소",
  "action.close": "닫기",
  "action.confirm": "확인",
  "action.report": "보고하다",
  "action.report-and-block": "보고하고 차단하기",
  "action.submit": "작성",
  "app-settings.color-scheme.enum.dark": "다크",
  "app-settings.color-scheme.enum.light": "라이트",
  "app-settings.color-scheme.enum.system": "시스템 기본값",
  "app-settings.color-scheme.title": "외관 모드",
  "app-settings.language": "언어",
  "app-settings.language-selector.title": "언어 선택",
  "app-settings.title": "앱 설정",
  "app.name": "MoguChat",
  "app.name.domain": "MoguChat.AI",
  "auth.legal-notice": "계속함으로써 MoguChat의 <tos>이용 약관</tos> 및 MoguChat의 <privacy>개인정보 처리방침</privacy>",
  "auth.login.apple": "Apple로 로그인",
  "auth.login.error": "로그인에 실패했습니다",
  "auth.login.google": "Google로 로그인",
  "auth.login.required.description": "로그인한 후 캐릭터와 대화합시다!",
  "auth.login.required.title": "로그인하세요",
  "auth.login.title": "로그인",
  "character.action": "작업",
  "character.action.chat": "채팅하기",
  "character.action.delete": "삭제",
  "character.action.delete.confirm.description": "삭제된 캐릭터는 복구할 수 없습니다!",
  "character.action.delete.confirm.title": "정말로 삭제하시겠습니까?",
  "character.action.delete.success": "캐릭터를 삭제했습니다",
  "character.action.edit": "편집",
  "character.action.edit.success": "편집 성공",
  "character.author.report": "작성자를 신고하기",
  "character.author.report.description": "이 캐릭터의 제작자를 MoguChat 공식에 보고하기",
  "character.create": "캐릭터 만들기",
  "character.create.complete": "캐릭터 생성 완료",
  "character.create.short-title": "만들기",
  "character.deleted-name": "캐릭터가 존재하지 않습니다",
  "character.edit": "편집 완료",
  "character.edit-complete.share": "공유하기",
  "character.edit-complete.start-chat": "채팅 시작하기",
  "character.edit-complete.submit-to-featured": "추천에 신청하기",
  "character.edit-complete.success-message": "캐릭터가 저장되었습니다",
  "character.edit-complete.title": "캐릭터 생성 완료",
  "character.edit.action.back": "돌아가기",
  "character.edit.action.continue": "다음",
  "character.edit.complete": "캐릭터 편집 완료",
  "character.edit.confirm.description": "여기서 중단하면 편집 내용이 손실될 수 있습니다.",
  "character.edit.confirm.title": "정말로 그만두시겠습니까?",
  "character.edit.error.title": "캐릭터를 만들 수 없었습니다.",
  "character.edit.submit.create": "작성",
  "character.edit.submit.update": "업데이트",
  "character.field.author": "작성자",
  "character.field.avatar": "아이콘",
  "character.field.background-setting": "배경 스토리 및 세계관",
  "character.field.background-setting.help.content": "캐릭터에 깊이를 더하기 위해 캐릭터 설정에 과거와 세계관을 설정합시다. 이 부분이 확실하면 캐릭터에 현실감이 생깁니다. 예: {{char}}는 현대 일본에 살고 있다. {{char}}는 대학 시절 육상부의 에이스로 활약했으나, 지금은 ○○사에서 영업부에 소속되어 있다. ○○사는 {{user}}와 룸쉐어로 맨션에 살고 있으며, 매일 그 근처 공원에서 조깅한다. {{user}}와 함께 살고 있는 것이 연애 관계가 드러나는 것을 두려워하여 항상 시간을 달리해서 출근하기 때문에 언제나 출근 시간은 아슬아슬하다.",
  "character.field.background-setting.help.title": "여기에 캐릭터가 있는 세계관과 캐릭터의 배경 이야기를 넣어봅시다",
  "character.field.background-setting.placeholder": "이 캐릭터는 어떤 세계에서 어떤 생활을 하고 있나요?",
  "character.field.character-setting": "기본 정보",
  "character.field.character-setting.help.content": "캐릭터 설정에서는 사용자의 유저를 {{user}}로 하고, AI 캐릭터의 이름을 {{char}}로 하면 시스템적으로 더 이해하기 쉬워집니다. 기초 설정에서 캐릭터의 기본적인 정보를 입력합시다. 캐릭터의 이름이나 나이, 성별, 특징 및 사용자와의 관계를 정하고 입력하는 것을 추천합니다.  \n예1:  \n- {{char}}의 이름: 사사키 토모타카  \n- {{char}}의 성별: 남성  \n- {{char}}의 나이: 25세  \n- {{char}}의 직업: ○○사의 평사원  \n- {{char}}와 {{user}}의 관계: 연인 사이로, 회사에 들키지 않도록 비밀리에 사귀고 있다.  \n예2: 나는 사사키 토모타카, 25세로 ○○사에 다니는 회사원이다. 22세에 대학을 졸업하고 나서는 계속 이 회사에서 일하고 있으며, 처음에는 동료들이 '토모키'라고 잘못 부르기도 했고, 지금은 거의 별명처럼 되어버렸다. {{user}}와는 사귄 지 2년째로, 여전히 주위에 관계를 숨기고 있다. 우리 회사는 사내 연애에 좀 엄격해서, 들킨다면 아마 그만두게 할지도 모른다.\n\n더 자세한 제작 방법을 알고 싶다면, MoguChat의 [공식 Discord](https://discord.com/invite/grjhdf4SNb)에 참여하거나, 이 [페이지](https://help.moguchat.ai/en-us/article/create-your-character-rt0b61/?bust=1743498807578)를 참조하십시오.",
  "character.field.character-setting.help.title": "캐릭터의 기본 정보가 되는 설정을 넣어봅시다",
  "character.field.character-setting.placeholder": "여기에 캐릭터의 기본 정보를 넣어봅시다. 이름이나 나이, 사용자와의 관계성을 설명해 주세요. 직접 나열하거나 자기소개 형식의 내용이 추천됩니다.",
  "character.field.chat-background": "채팅 배경 이미지",
  "character.field.chat-background.description": "이 캐릭터와 채팅할 때의 배경 이미지를 지정할 수 있습니다",
  "character.field.description": "캐릭터 소개",
  "character.field.description.description": "이 내용은 사용자에게 공개됩니다. 캐릭터를 소개해봅시다.",
  "character.field.example-conversation": "대화 예시",
  "character.field.example-conversation.help.content": "예:\n대화 예시\n\"오, 오늘도 활기차 보이네! 나도 기뻐!\"\n\"피곤해? 나한테 뭐든지 말해도 돼!\"\n\"{{user}}의 웃음이 나의 활력의 원천이야!\"\n\"어, 그거 재미있네! 더 자세히 말해줘!\"\n\"실망하지 마, 내가 곁에 있으니까!\"\n\n대화 예시\n{{user}}: 오늘 피곤해\n{{char}}: 수고했어, 무리한 거 아니야? 오늘은 푹 쉬자, 먼저 목욕할래?\n{{user}}: 요즘 일이 바빠서... 너무 피곤해서 움직일 수가 없어.\n{{char}}: 맞아, 오늘도 야근했네. 움직일 수 없다면 욕조까지 옮겨줄까?\n{{user}}: 옮겨줘!\n{{char}}: 네네, 정말 열심히 해서 대단하네. 내일부터 내가 저녁 준비할까?",
  "character.field.example-conversation.help.title": "여기에 캐릭터의 대사나 대화 샘플을 넣어봅시다",
  "character.field.example-conversation.placeholder": "이 캐릭터가 할 것 같은 대사나 대화는 어떤 느낌인가요? 구체적인 예를 작성해봅시다",
  "character.field.intro-message": "인사말・시작의 한마디",
  "character.field.intro-message.description": "캐릭터가 대화의 시작에 처음으로 하는 인사말을 입력해 주세요",
  "character.field.intro-message.help.content": "캐릭터가 이 대화에서 처음 보내는 메시지입니다. 기본적인 인사나 시나리오에 맞는 말을 추천합니다. 사용자와 처음 만나는 설정의 캐릭터라면 \"처음 뵙겠습니다\" 또는 이미 알고 있거나 꽤 친숙한 설정이라면 \"오늘 어땠어\"처럼 대화가 이어질 수 있는 내용을 부탁드립니다.",
  "character.field.intro-message.help.title": "대화의 시작에서 캐릭터가 발하는 말",
  "character.field.intro-message.placeholder": "안녕하세요",
  "character.field.name": "이름",
  "character.field.name.description": "이 캐릭터의 이름은?",
  "character.field.persona": "세부 설정",
  "character.field.persona.help.content": "캐릭터의 성격은 말투, 대화 톤 및 반응에 영향을 미칩니다. 여기서 세부적인 부분을 설정해 둡시다. 캐릭터의 성격과 좋아하는 것, 특기, 말버릇 등을 넣어 봅시다.  \n예:  \n{{char}}는 밝은 성격으로, 단 것을 좋아합니다. 경쾌하고 친근한 말투로 이야기하며 주변에서 친근감을 느끼고 있습니다. 사람에게 동의할 때 \"응응, 맞아!\"가 말버릇입니다.  \n\n더 자세한 방법을 알고 싶다면 MoguChat의 [공식 Discord](https://discord.com/invite/grjhdf4SNb)에 참여하거나, 이 [페이지](url)를 참조해 주세요.",
  "character.field.persona.help.title": "캐릭터 설정 내용",
  "character.field.persona.placeholder": "여기에 캐릭터의 성격 특징이나 선호, 말버릇 등 상세한 설정을 넣어 봅시다.",
  "character.field.personality.callout-style": "호칭",
  "character.field.personality.callout-style.description": "캐릭터가 다른 사람을 부르는 방법은?",
  "character.field.personality.description": "어조나 일인칭, 이인칭 등을 설정할 수 있습니다",
  "character.field.personality.first-person": "일인칭",
  "character.field.personality.first-person.description": "캐릭터가 사용하는 첫 번째 인칭은? 예: ぼく",
  "character.field.personality.second-person": "두 번째 인칭",
  "character.field.personality.second-person.description": "캐릭터가 사용하는 2인칭은?",
  "character.field.personality.speaking-tone": "어조・말투",
  "character.field.personality.speaking-tone.description": "캐릭터는 어떤 말투 또는 이야기 방식을 사용하나요?",
  "character.field.personality.title": "기타 설정",
  "character.field.tag": "태그",
  "character.field.tag.description": "캐릭터 태그를 선택하세요 (최대 5개)",
  "character.field.tag.max-length": "최대 5개까지 태그를 선택할 수 있습니다",
  "character.field.visibility": "공개 상태",
  "character.field.visibility.description": "캐릭터의 공개 상태를 선택하세요",
  "character.field.visibility.enum.private": "비공개",
  "character.field.visibility.enum.private.description": "나만 이용할 수 있습니다",
  "character.field.visibility.enum.public": "공개",
  "character.field.visibility.enum.public.description": "모든 사람들이 이용할 수 있습니다",
  "character.field.visibility.enum.unlisted": "한정 공개",
  "character.field.visibility.enum.unlisted.description": "자신과 공유 URL에서 접속한 사람만 사용할 수 있습니다",
  "character.filter.all": "추천",
  "character.filter.ugc": "사용자 생성",
  "character.form.auto-save": "임시 저장되었습니다",
  "character.meta.title": "{{authorName}}작의{{characterName}}",
  "character.my": "자작 캐릭터",
  "character.my.view-more": "더 보기",
  "character.no-owned-character": "생성된 캐릭터가 아직 없습니다",
  "character.not-found": "캐릭터를 찾을 수 없습니다",
  "character.popularity": "인기",
  "character.report": "캐릭터 신고하기",
  "character.report.description": "이 캐릭터를 MoguChat 공식에 보고하다",
  "character.report.success": "보고를 보냈습니다",
  "character.request-featured.action.request": "신청",
  "character.request-featured.hero-description": "\"자신의 자작 캐릭터를 더 많은 사람들에게 보여주고 싶다!\", \"자신 있는 작품을 모두에게 사용하게 하고 싶다!\"라고 희망하는 제작자님은, 이쪽을 통해 자신의 캐릭터를 홈페이지의 \"추천\"에 추가하도록 신청해 주세요. \"추천\"에 올리고 싶은 캐릭터를 선택하고 신청을 제출하면, 심사를 통과하는 대로 캐릭터는 추천에 추가됩니다.",
  "character.request-featured.hero-title": "자작 캐릭터를 추천에 올려 보세요!",
  "character.request-featured.no-characters.description": "추천 캐릭터를 신청하려면 캐릭터를 공개로 설정해야 합니다",
  "character.request-featured.no-characters.title": "신청 가능한 캐릭터가 없습니다",
  "character.request-featured.select-character": "신청할 캐릭터를 선택하십시오",
  "character.request-featured.state.featured": "추천에 추가했습니다!",
  "character.request-featured.state.pending": "신청 완료",
  "character.request-featured.title": "추천 캐릭터 신청",
  "character.search.input.placeholder": "｜・ω・)ノ 오늘은 누구와 이야기하고 싶은 기분이야?",
  "character.search.title": "대화 상대를 찾다",
  "character.share.copied": "링크가 클립보드에 복사되었습니다",
  "character.share.error-message": "나중에 다시 시도해 주세요",
  "character.share.error-title": "공유에 실패했습니다",
  "character.share.success": "공유했습니다",
  "character.share.title": "{{characterName}} | MoguChat.AI",
  "character.stats.conversations-created": "대화",
  "character.stats.messages-sent": "전송된 메시지",
  "character.submit-to-featured.error-title": "신청에 실패했습니다",
  "character.submit-to-featured.success-message": "심사 후, 추천에 표시됩니다",
  "character.submit-to-featured.success-title": "신청이 완료되었습니다",
  "character.temp-save": "임시 저장",
  "chat.action": "액션",
  "chat.action.delete": "리스트에서 삭제",
  "chat.action.delete.cancel": "취소",
  "chat.action.delete.confirm": "채팅 삭제",
  "chat.action.delete.description": "삭제한 채팅의 내용은 복구할 수 없습니다。",
  "chat.action.delete.title": "이 채팅을 정말로 삭제하시겠습니까?",
  "chat.action.description": "이 채팅을 어떻게 하시겠습니까?",
  "chat.action.restart": "다시 하기",
  "chat.action.restart.confirm": "다시 하기",
  "chat.action.restart.description": "되돌리기 기능에서는 캐릭터와의 모든 대화 기록과 데이터가 사라집니다. 데이터를 복원할 수 없습니다.",
  "chat.action.restart.title": "정말 이 대화를 다시 하시겠습니까?",
  "chat.action.title": "이 채팅을",
  "chat.ads-banner-description": "광고 없이 MoguChat을 더 즐기세요! MoguPass를 구독하세요",
  "chat.ai-warning": "캐릭터 답변 및 기타 콘텐츠 내용은 AI에 의해 생성됩니다. 내용에 오류나 부적절한 정보가 포함될 수 있으며, MoguChat 공식 견해와 입장을 나타내는 것은 아닙니다. 또한, 실제 인물이나 단체 등과 관계가 없습니다. 답변 내용에 부적절한 정보나 문제가 있다고 생각되면 해당 메시지를 길게 눌러 버그 신고를 해주시면 감사하겠습니다.",
  "chat.ai-warning.title": "주의",
  "chat.input.placeholder": "메시지를 입력하세요",
  "chat.list": "대화",
  "chat.list.empty": "대화가 없습니다",
  "chat.list.empty.action.find-character": "캐릭터를 찾다",
  "chat.message.action.capture": "스크린샷 공유",
  "chat.message.action.copy": "메시지 복사",
  "chat.message.action.delete": "메시지 삭제",
  "chat.message.action.delete.failed": "메시지를 삭제할 수 없습니다",
  "chat.message.action.edit": "메시지 편집",
  "chat.message.action.regenerate": "메시지 재생성",
  "chat.message.action.report": "답변에 불만이 있다",
  "chat.message.action.report.description": "더 나은 답변을 제공할 수 있도록 불만을 느끼는 이유를 알려주세요",
  "chat.message.action.report.failed": "메시지를 신고할 수 없습니다",
  "chat.message.action.report.reason.CHAT_CONTEXT_LOST": "이전에 대화한 내용이 기억나지 않는다",
  "chat.message.action.report.reason.EMPTY_RESPONSE": "답변 내용이 표시되지 않음",
  "chat.message.action.report.reason.MIXED_LANGUAGE": "답변에 이상한 외국어가 섞여 있다",
  "chat.message.action.report.reason.OTHERS": "기타",
  "chat.message.action.report.reason.REPETITIVE_RESPONSE": "비슷한 대사만 반복하다",
  "chat.message.action.report.reason.SETTINGS_CONTEXT_LOST": "배경이나 설정 내용과 맞지 않는다",
  "chat.message.action.report.success": "피드백을 보냈습니다",
  "chat.message.action.select": "선택하다",
  "chat.message.action.send.failed": "메시지를 보낼 수 없었습니다",
  "chat.message.no-more": "메시지가 없습니다",
  "chat.recreate": "채팅을 재개",
  "chat.recreate.description": "이 캐릭터와 새로운 대화를 시작하시겠습니까?",
  "chat.screenshot-share.more": "공유",
  "chat.screenshot-share.save-to-album.button": "저장",
  "chat.screenshot-share.save-to-album.success": "앨범에 저장되었습니다",
  "chat.screenshot-share.text-content": "MoguChat에서 멋진 대화를 즐기고 있어요!",
  "common.confirm.nonreversible": "이 작업은 되돌릴 수 없습니다!",
  "common.confirm.title": "확인하시겠습니까?",
  "common.countdown-button": "{{action}} ({{seconds}}초 후에 조작 가능)",
  "common.go-back": "돌아가기",
  "common.input.custom-value.option-label": "맞춤...",
  "common.input.custom-value.placeholder": "사용자 정의 옵션을 입력하세요",
  "common.input.custom-value.title": "사용자 지정 옵션 입력",
  "common.loading": "불러오는 중",
  "common.select.unselect": "선택 해제",
  "common.slogan": "Mogu에서 좋아하는 사람과 연결되고 대화의 마법을 언제 어디서나",
  "common.tracking-modal.description": "다음 화면에서 추적을 허용하면 무관한 광고가 표시되지 않게 됩니다. 다음 화면에서 추적을 허용하도록 선택해 주시기 바랍니다(´;ω;｀).",
  "common.tracking-modal.primary-button": "다음",
  "common.tracking-modal.title": "MoguChat의 요청",
  "common.upload-image.error": "이미지 업로드에 실패했습니다. 다시 시도해 주세요.",
  "common.upload-image.uploading": "업로드 중",
  "components.remote-image-input.permission-required.description": "사진을 선택하려면 사진 라이브러리에 대한 접근 권한이 필요합니다. 설정에서 MoguChat에 사진 라이브러리 접근을 허용해 주세요.\n\n선택되지 않은 사진 라이브러리 내의 항목은 업로드되지 않습니다.",
  "components.remote-image-input.permission-required.open-settings": "설정을 열기",
  "components.remote-image-input.permission-required.title": "사진 라이브러리에 대한 접근 권한",
  "download-app.description": "모바일에서 MoguChat을 체험할 수 있습니다",
  "download-app.title": "앱을 받으세요",
  "error.auth-error": "로그인 정보에 문제가 발생했을 가능성이 있습니다. 이 오류가 여러 번 발생하면 로그아웃하고 다시 로그인해 보세요.",
  "error.avatar-required": "아이콘이 필요합니다",
  "error.character-not-found": "캐릭터를 찾을 수 없습니다",
  "error.chat-not-found": "채팅을 찾을 수 없습니다",
  "error.empty-character-list": "캐릭터를 찾을 수 없습니다",
  "error.empty-character-list.description": "아직 찾고 있는 캐릭터가 없습니다(´;ω;｀)  \n직접 만들어 봅시다(｀･ω･´)",
  "error.general-error": "작업을 완료할 수 없었습니다",
  "error.internal-error": "서버가 사라졌어? Σ(･ω･ﾉ)ﾉ！",
  "error.invalid-input": "잘못된 입력",
  "error.message-not-found": "메시지를 찾을 수 없습니다",
  "error.moderation-flagged": "입력 내용이 전체 연령 대상이 아니라고 판단되었습니다",
  "error.name-required": "이름이 필요합니다",
  "error.no-chat": "대화가 없습니다",
  "error.no-chat.description": "아직 채팅이 없어(;´･ω･)",
  "error.no-owned-character": "아직 자작 캐릭터가 없습니다 ｡ﾟ(ﾟ´ω`ﾟ)ﾟ｡  \n좋아하는 캐릭터를 만들어 봅시다!",
  "error.not-authorized": "권한이 없습니다, 버그를 신고해 주세요",
  "error.page.back": "홈페이지로 돌아가기",
  "error.page.refresh": "리로드",
  "error.page.title": "문제가 발생했습니다",
  "error.permission-denied": "허가가 거부되었습니다",
  "error.save-character-failed": "캐릭터를 만들 수 없었습니다",
  "error.tags-limit": "이 이상 태그를 추가할 수 없습니다",
  "error.token-expired": "연결 시간이 초과되었습니다. 로그아웃 후 다시 로그인해 보세요.",
  "error.unknown-error": "알 수 없는 오류가 발생했습니다",
  "error.update-profile-failed": "알 수 없는 오류",
  "error.upload-failed": "업로드할 수 없었습니다",
  "error.upload-failed.require-permission": "권한이 부족하여 업로드할 수 없습니다",
  "event.nightclub.action.go-ranking-page": "랭킹",
  "event.nightclub.action.view-character": "지명하다",
  "event.nightclub.action.vote": "샴페인을 내리다",
  "event.nightclub.action.vote.ranking-page": "내리다",
  "event.nightclub.characters.title": "캐스트",
  "event.nightclub.introduction.content": "바쁜 일상에 쫓기며 몸이 무거운 매일. 그 발걸음으로 도착한 곳은 밤의 정적 속에 서 있는 하나의 문이었다. 여기는 나이트클럽 \"MG\"――물론 아직 이야기는 서막. 문 너머에서 당신을 기다리고 있는 것은, 다정한 말과 따뜻한 미소로 당신을 치유하는 \"캐스트\". 여기는 싫은 일을 모두 잊고, 살며시 눈을 감고 마음을 풀어놓을 수 있는 장소. 그래, 당신을 여기 \"MG\"에서 계속 기다려온 특별한 \"그\"의 곁에서――",
  "event.nightclub.introduction.title": "밤의 낙원에 오신 것을 환영합니다――당신을 부드럽게 감싸는 특별한 시간",
  "event.nightclub.ranking.reward-description": "랭킹의 TOP3 호스트는 이벤트 종료 후, \"남자친구 버전\"의 그가 되어, 신규 캐릭터로 추가합니다!",
  "event.nightclub.ranking.title": "랭킹",
  "event.voting.no-ticket": "투표 항목이 없습니다",
  "event.voting.send-button": "보내다",
  "event.voting.success": "투표했습니다",
  "event.voting.title": "샴페인을 내리다",
  "open-in-app.action.open": "열다",
  "open-in-app.download-dialog.title": "MoguChat 앱을 다운로드하세요",
  "open-in-app.title": "MoguChat앱으로 열기",
  "profile.action.save": "저장",
  "profile.edit": "프로필 편집",
  "profile.edit.success": "프로필을 업데이트했습니다",
  "profile.field.biography": "자신에 대해",
  "profile.field.biography.description": "여기에 설정을 입력하세요. 이 내용은 다른 사람에게 보이지 않습니다.",
  "profile.field.biography.placeholder": "대화할 때의 설정을 여기에 넣어봅시다",
  "profile.field.gender-interest": "관심 있는 카테고리를 선택하세요",
  "profile.field.gender-interest.description": "당신의 취향을 알려주세요",
  "profile.field.gender-interest.enum.all": "어느 쪽이든",
  "profile.field.gender-interest.enum.for-female": "여성용",
  "profile.field.gender-interest.enum.for-male": "남성용",
  "profile.field.nickname": "닉네임",
  "profile.field.nickname.description": "뭐라고 불리고 싶으세요?",
  "profile.field.nickname.hint": "닉네임의 길이는 2~20자여야 합니다。",
  "profile.field.nickname.placeholder": "닉네임을 입력하세요",
  "profile.field.username": "사용자 이름",
  "profile.field.username.placeholder": "사용자 이름을 입력하세요",
  "purchase.credit.ad-modal.description": "취소하면 채팅이 종료됩니다",
  "purchase.credit.ad-modal.error.rewarded.message": "문제로 인해 쌀을 추가할 수 없었습니다. 이 문제가 발생한 경우, 프로필 페이지의 문의를 통해 공식 직원에게 연락해 주시기 바랍니다.",
  "purchase.credit.ad-modal.error.rewarded.title": "쌀을 추가할 수 없었습니다",
  "purchase.credit.ad-modal.success.rewarded.message": "쌀을 추가했습니다. 채팅을 계속 즐기세요",
  "purchase.credit.ad-modal.success.rewarded.title": "쌀을 추가했습니다",
  "purchase.credit.ad-modal.title": "채팅을 계속하려면 CM 시청이 필요합니다",
  "purchase.credit.help.content": "※답변 메시지 1개당 쌀 1개가 소비됩니다。  \n※쌀이 없을 경우, 대신 주먹밥을 소비합니다。  \n※쌀은 무료로 획득할 수 있습니다。  \n※주먹밥에 유효기간은 없습니다。  \n※매일 오전 4시(일본 시간)에 그 이전에 획득한 쌀은 초기화됩니다。",
  "purchase.credit.help.title": "쌀과 주먹밥에 대하여",
  "purchase.credit.no-packs-available": "구입할 수 있는 아이템이 없습니다.",
  "purchase.credit.only-available-on-mobile": "앱 버전에서만 구매할 수 있습니다",
  "purchase.credit.pack.marketing.first-time-bonus": "첫 구매 2배!",
  "purchase.credit.pack.marketing.first-time-only": "한 번만 구매 가능",
  "purchase.credit.store": "스토어",
  "purchase.credit.watch-ads": "CM 시청",
  "purchase.credit.watch-ads.download-app-to-watch": "광고를 보기 위해 앱을 다운로드",
  "purchase.failed": "구매에 실패했습니다",
  "purchase.footer.privacy": "프라이버시",
  "purchase.footer.restore-purchase": "구매를 복원하다",
  "purchase.footer.terms": "이용 약관",
  "purchase.payment-callback.contact-support": "문의하기",
  "purchase.payment-callback.failed-to-verify.already-completed-the-payment.description": "안심하세요! 이미 결제가 완료된 경우에도 네트워크 문제나 기타 요인으로 인해 통신 정보가 지연될 수 있습니다. 현재 결제 제공업체와 확인 중이니, 잠시 후 페이지를 새로고침하여 결제가 완료되었는지 확인해 주세요. 페이지를 새로고침해도 해결되지 않는 경우에는 문의 페이지로 와주세요.",
  "purchase.payment-callback.failed-to-verify.already-completed-the-payment.title": "이미 결제를 완료하셨습니까?",
  "purchase.payment-callback.failed-to-verify.description": "결제를 확인할 수 없습니다. {{error}}",
  "purchase.payment-callback.failed-to-verify.title": "결제를 확인할 수 없습니다",
  "purchase.payment-callback.pending.description": "결제 확인을 기다려 주세요…",
  "purchase.payment-callback.pending.title": "처리 중",
  "purchase.payment-callback.refresh": "새로고침",
  "purchase.payment-callback.success.description": "특전이 계정에 추가되었습니다.",
  "purchase.payment-callback.success.go-to-chat": "계속하다",
  "purchase.payment-callback.success.title": "결제가 완료되었습니다",
  "purchase.price.monthly": "매월{{value}}",
  "purchase.price.weekly": "매주{{value}}",
  "purchase.price.yearly": "매년{{value}}",
  "purchase.privilege.no-ads": "광고 없음",
  "purchase.privilege.unlimited-characters": "무제한 캐릭터",
  "purchase.privilege.unlimited-messages": "메시지 수 제한 해제",
  "purchase.subscription.banner.content": "구독하면 광고가 제거되고 대화 무제한!",
  "purchase.subscription.benefits.ads-free": "모든<strong>광고를 제거</strong>하고<strong>무제한 대화</strong>",
  "purchase.subscription.benefits.bonus-credits": "매일 <strong>오니기리*3(유통기한 없음)</strong>을 획득",
  "purchase.subscription.benefits.quicker-responses": "<strong>빠른 응답 속도</strong>",
  "purchase.subscription.benefits.remove-all-ads": "모든<strong>광고 제거</strong>",
  "purchase.subscription.benefits.unlimited-chats": "<strong>무제한 대화</strong>",
  "purchase.subscription.benefits.unlimited-private-characters": "비공개 캐릭터를 <strong>무제한 생성</strong>",
  "purchase.subscription.cta.already-subscribed": "등록됨",
  "purchase.subscription.cta.free-trial": "무료 체험",
  "purchase.subscription.cta.manage-subscription": "구독 관리",
  "purchase.subscription.cta.subscribe": "등록하기",
  "purchase.subscription.interval.monthly": "월간 플랜",
  "purchase.subscription.interval.yearly": "연간 플랜",
  "purchase.subscription.price-info.begin-free-trial": "{{days}}일간 무료",
  "purchase.subscription.product.mogupass.monthly.free-trial.description": "{{days}}일간의 무료 체험 후 {{price}}가 청구됩니다",
  "purchase.subscription.product.mogupass.monthly.subscription-info": "매월 등록일에 플랜 요금이 청구됩니다. 플랜은 언제든지 변경하거나 취소할 수 있습니다.",
  "purchase.subscription.product.mogupass.yearly.free-trial.description": "{{days}}일간의 무료 체험 후 {{price}}가 청구됩니다",
  "purchase.subscription.product.mogupass.yearly.subscription-info": "매년 등록일에 요금이 청구되며, 플랜은 언제든지 변경하거나 취소할 수 있습니다.",
  "purchase.subscription.reason.insufficient-balance": "잔액이 부족합니다 - 무제한으로 광고 없이 채팅을 즐기기 위해 MoguPass에 등록하는 것을 추천합니다!",
  "purchase.subscription.reason.private-character-limit-reached": "생성할 수 있는 비공개 캐릭터 수의 한도에 도달했습니다. 무제한 캐릭터를 생성하려면 모구모구 패스에 등록하세요.",
  "purchase.subscription.success": "모구모구 퍼즐을 얻었습니다!",
  "purchase.subscription.title": "MoguPass",
  "purchase.unavailable.provider-mismatch.description": "다른 OS에서 등록되었을 가능성이 있습니다",
  "purchase.unavailable.provider-mismatch.title": "등록할 수 없습니다",
  "reward.daily-bonus": "로그인 보너스",
  "reward.daily-bonus.claim": "받다",
  "reward.daily-bonus.claim-error": "보너스를 받을 수 없었습니다. 다시 시도해 주세요.  \n여러 번 이 문제가 발생하면 문의해 주세요.",
  "reward.daily-bonus.claimed": "쌀 13개를 받았습니다",
  "reward.daily-bonus.description": "로그인 보너스 수령은 매일 오전 4시(일본 시간)에 초기화됩니다.",
  "settings": "설정",
  "settings.account.delete": "계정을 삭제",
  "settings.account.delete.confirm.button-countdown": "{{seconds}}초 후에 삭제할 수 있습니다",
  "settings.account.delete.confirm.description": "계정을 삭제하면 데이터는 복구할 수 없으므로 모든 대화 내용도 삭제됩니다。",
  "settings.account.delete.confirm.title": "정말로 계정을 삭제하시겠습니까?",
  "settings.account.delete.error.active-subscription.description": "플랜 등록 중이므로 계정을 삭제할 수 없습니다. 플랜이 만료된 후에만 계정을 삭제할 수 있습니다.",
  "settings.account.delete.error.active-subscription.title": "계정을 삭제할 수 없습니다",
  "settings.account.sign-out": "로그아웃",
  "settings.account.sign-out.confirm.description": "로그아웃하면 현재 데이터에 접근하기 위해 같은 방법으로 로그인해야 합니다.",
  "settings.account.sign-out.confirm.title": "정말 로그아웃하시겠습니까?",
  "settings.account.title": "계정",
  "settings.account.user-id": "사용자 ID",
  "settings.account.user-id.description": "문제를 보고할 때 위에 있는 사용자 ID를 첨부해 주시면 문제를 더 쉽게 특정할 수 있습니다.",
  "socials.discord.link": "https://discord.gg/grjhdf4SNb",
  "socials.discord.name": "공식 디스코드",
  "socials.instagram.link": "https://www.instagram.com/moguchat_/",
  "socials.instagram.name": "인스타그램",
  "socials.tiktok.link": "https://www.tiktok.com/@moguchatai",
  "socials.tiktok.name": "틱톡",
  "socials.twitter.link": "https://x.com/MoguChatJP",
  "socials.twitter.name": "공식 X (구 Twitter)",
  "socials.youtube.link": "https://www.youtube.com/@MoguChatJP",
  "socials.youtube.name": "유튜브",
  "support.chat": "채팅으로 문의",
  "support.title": "문의",
  "tab.chat": "대화",
  "tab.home": "홈",
  "tab.profile": "프로필",
  "tag.all": "추천",
  "transaction.list.empty": "아무것도 없어",
  "transaction.list.title": "거래명세서",
  "transaction.type.CONSOLE": "운영 변경",
  "transaction.type.EVENT_VOTE": "이벤트 투표",
  "transaction.type.PURCHASE": "팩 구매",
  "transaction.type.REGULAR_MESSAGE_COST": "응답 메시지",
  "transaction.type.REWARD_AD": "CM 시청 보너스",
  "transaction.type.REWARD_DAILY_BONUS": "로그인 보너스",
  "transaction.type.UNKNOWN": "불명",
  "update.available": "새로운 업데이트가 있습니다",
  "update.available.message": "다음에 앱을 열 때 업데이트합니다",
  "user-inbox.empty": "메일이 없습니다",
  "user-inbox.title": "메일박스"
}
