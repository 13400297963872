import type React from 'react'
import { Modal, type ModalProps, TouchableWithoutFeedback, View } from 'react-native'
import Animated, {
  FadeIn,
  FadeOut,
  useAnimatedReaction,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated'

interface MGSlideUpModalProps extends ModalProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  children: React.ReactNode
  maxWidth?: number
}

export const MGSlideUpModal: React.FC<MGSlideUpModalProps> = ({
  isOpen,
  setIsOpen,
  children,
  maxWidth = 768,
  ...props
}) => {
  const translateY = useSharedValue(100)
  const opacity = useSharedValue(0)

  // Spring animation configuration
  const springConfig = {
    damping: 40,
    stiffness: 450,
    mass: 1.2,
  }

  useAnimatedReaction(
    () => isOpen,
    (open) => {
      translateY.value = withSpring(open ? 0 : 100, springConfig)
      opacity.value = withSpring(open ? 1 : 0, springConfig)
    },
    [isOpen],
  )

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{ translateY: translateY.value }],
    opacity: opacity.value,
  }))

  return (
    <Modal transparent animationType="none" visible={isOpen} onRequestClose={() => setIsOpen(false)} {...props}>
      <TouchableWithoutFeedback onPress={() => setIsOpen(false)}>
        <View className="flex-1 justify-center items-center bg-black/50 px-4">
          <TouchableWithoutFeedback>
            <Animated.View
              className="bg-background rounded-3xl overflow-hidden w-full"
              style={[
                {
                  maxWidth,
                },
                animatedStyle,
              ]}
              entering={FadeIn.springify().damping(40).stiffness(450).mass(1.2)}
              exiting={FadeOut.springify().damping(40).stiffness(450).mass(1.2)}
            >
              {children}
            </Animated.View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  )
}
