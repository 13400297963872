import type { TextProps } from 'react-native'
import MGText from './MGText'

export default function MGHeaderText({
  children,
  style,
  ...props
}: {
  children: React.ReactNode
} & TextProps) {
  return (
    <MGText
      bold
      {...props}
      style={[
        {
          fontSize: 18,
        },
        style,
      ]}
    >
      {children}
    </MGText>
  )
}
