import { PortalHost } from '@rn-primitives/portal'
import * as Sentry from '@sentry/react-native'
import { TRPCClientError } from '@trpc/client'
import { Toaster } from 'burnt/web'
import { isRunningInExpoGo } from 'expo'
import { Stack, useNavigationContainerRef } from 'expo-router'
import Head from 'expo-router/head'
import { Provider as JotaiProvider } from 'jotai'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform } from 'react-native'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { KeyboardProvider } from 'react-native-keyboard-controller'
import 'react-native-reanimated'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import IconModal from '../components/common/IconModal'
import { NativeWindThemeVariableProvider } from '../components/common/NativeWindThemeVariableProvider'
import { WebFontsLoader } from '../components/common/WebFontsLoader'
import { InventoryChangeToast } from '../components/InventoryChangeToast'
import DailyBonus from '../components/monetization/DailyBonus'
import { PurchaseSheet } from '../components/monetization/PurchaseSheet'
import '../global.css'
import { ampli } from '../lib/ampli'
import { useInitAppLovin } from '../lib/applovin'
import { useSyncColorScheme, useSyncWebBackgroundColor } from '../lib/colorScheme'
import { config } from '../lib/config'
import { useAndroidNavigationBar } from '../lib/hooks/useAndroidNavigationBar'
import { useTrackPageView } from '../lib/hooks/useTrackPageView'
import '../lib/i18n'
import { useIAP, useIAPPreload } from '../lib/monetization'
import { useRegisterAndSyncPushNotificationToken } from '../lib/notification'
import QueryProvider from '../lib/providers/QueryProvider'
import { useReactQueryEffect } from '../lib/reactQueryEnhancer'
import type { RouterError } from '../lib/services/trpc'
import { store } from '../lib/store'

ampli.load({
  environment: 'remoguchat',
  disabled: __DEV__,
  client: {
    configuration: {
      serverUrl: config.amplitudeApiUrl,
      logLevel: __DEV__ ? 4 : 1, // __DEV__ ? LogLevel.Debug : LogLevel.Error
      useBatch: true,
    },
  },
})

const navigationIntegration = Sentry.reactNavigationIntegration({
  enableTimeToInitialDisplay: !isRunningInExpoGo(),
})

Sentry.init({
  enabled: !__DEV__,
  beforeSend(event, hint) {
    if (hint.originalException instanceof TRPCClientError) {
      const routerError = hint.originalException as RouterError
      // ignore INVENTORY::INSUFFICIENT_BALANCE error
      if (routerError.data?.code === 'INVENTORY::INSUFFICIENT_BALANCE') {
        return null
      }
    }

    return event
  },
  dsn: config.sentryDsn,
  tracesSampleRate: 0.1,
  integrations: [Sentry.sdkInfoIntegration(), navigationIntegration],
  enableNativeFramesTracking: !isRunningInExpoGo(),
  beforeBreadcrumb(breadcrumb) {
    // ignore ingest.us.sentry.io and applovin.com http request breadcrumbs
    if (
      breadcrumb.category === 'http' &&
      (breadcrumb.data?.url?.includes('ingest.us.sentry.io') ||
        breadcrumb.data?.url?.startsWith('https://ms.applovin.com'))
    ) {
      return null
    }

    // ignore tRPC console debug logs
    // if (
    //   breadcrumb.category === 'console' &&
    //   breadcrumb.data?.arguments &&
    //   breadcrumb.data.arguments.length > 0 &&
    //   (breadcrumb.data.arguments[0] === '[tRPC] <<' || breadcrumb.data.arguments[0] === '[tRPC] >>')
    // ) {
    //   return null
    // }
    return breadcrumb
  },
})

const AppLayout = () => {
  useIAP()
  useIAPPreload()
  useReactQueryEffect()

  return (
    <Fragment>
      <Head>
        <title>MoguChat</title>
      </Head>
      <Stack
        screenOptions={{
          headerShown: false,
          contentStyle: Platform.OS === 'web' ? { backgroundColor: 'var(--background)' } : undefined,
        }}
      >
        <Stack.Screen name="(app)" />
        <Stack.Screen name="(legal)/terms" />
        <Stack.Screen name="(legal)/privacy" />
        <Stack.Screen name="s/[shareId]" />
        <Stack.Screen name="login" options={{ headerShown: false, animation: 'none' }} />
        <Stack.Screen name="+not-found" options={{ headerShown: false, animation: 'none' }} />
      </Stack>
    </Fragment>
  )
}

function RootLayout() {
  const { t } = useTranslation()
  const [isShowingTrackingModal, setIsShowingTrackingModal] = useState(false)
  const modalPromiseRef = useRef<{
    // biome-ignore lint/suspicious/noConfusingVoidType: <explanation>
    resolve: (value: void) => void
  } | null>(null)

  const showTrackingModal = async () => {
    setIsShowingTrackingModal(true)
    return new Promise<void>((resolve) => {
      modalPromiseRef.current = { resolve }
    })
  }

  const handleModalDismiss = () => {
    setIsShowingTrackingModal(false)
    modalPromiseRef.current?.resolve()
    modalPromiseRef.current = null
  }

  useInitAppLovin({ showTrackingModal })
  useTrackPageView()
  const ref = useNavigationContainerRef()

  useEffect(() => {
    if (ref?.current) {
      navigationIntegration.registerNavigationContainer(ref)
    }
  }, [ref])

  useAndroidNavigationBar()
  useRegisterAndSyncPushNotificationToken()
  useSyncColorScheme()
  useSyncWebBackgroundColor()

  return (
    <JotaiProvider store={store}>
      <QueryProvider>
        <NativeWindThemeVariableProvider>
          <GestureHandlerRootView>
            <SafeAreaProvider>
              <KeyboardProvider>
                <AppLayout />
                <IconModal
                  visible={isShowingTrackingModal}
                  severity="info"
                  icon="analytics"
                  title={t('common.tracking-modal.title')}
                  description={t('common.tracking-modal.description')}
                  primaryButtonContent={t('common.tracking-modal.primary-button')}
                  hideCancelButton
                  onCancel={() => {}}
                  onConfirm={handleModalDismiss}
                />
                <PurchaseSheet />
                <InventoryChangeToast />
                <DailyBonus />
                <PortalHost />
              </KeyboardProvider>
            </SafeAreaProvider>
          </GestureHandlerRootView>
        </NativeWindThemeVariableProvider>
      </QueryProvider>
    </JotaiProvider>
  )
}

function RootLayoutWebWrapped() {
  return (
    <WebFontsLoader>
      <RootLayout />
      <Toaster position="bottom-center" />
    </WebFontsLoader>
  )
}

export default Sentry.wrap(Platform.OS === 'web' ? RootLayoutWebWrapped : RootLayout)
