import MaskedView from '@react-native-masked-view/masked-view'
import { LinearGradient } from 'expo-linear-gradient'
import type { FC } from 'react'
import { useEffect } from 'react'
import { Image, Platform, View } from 'react-native'
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withSequence,
  withTiming,
} from 'react-native-reanimated'

const MGCharactersMasonryWeb: FC = () => {
  const originalWidth = 4746
  const originalHeight = 512
  const displayHeight = 128
  const displayWidth = Math.round((originalWidth * displayHeight) / originalHeight)

  return (
    <View
      className="h-32 relative overflow-hidden"
      style={{
        // @ts-ignore - web-only styles
        maskImage: 'linear-gradient(to right, transparent, black 10%, black 90%, transparent 100%)',
        WebkitMaskImage: 'linear-gradient(to right, transparent, black 10%, black 90%, transparent 100%)',
        '--to-scroll-x': -displayWidth,
      }}
    >
      <View
        className="flex flex-row animate-scroll-x"
        style={{
          width: displayWidth * 2,
        }}
      >
        <Image
          source={require('../../assets/images/characters-masonry.webp')}
          style={{ width: displayWidth, height: displayHeight }}
          resizeMode="cover"
        />
        <Image
          source={require('../../assets/images/characters-masonry.webp')}
          style={{ width: displayWidth, height: displayHeight }}
          resizeMode="cover"
        />
      </View>
    </View>
  )
}

const MGCharactersMasonryNative: FC = () => {
  const originalWidth = 4746
  const originalHeight = 512
  const displayHeight = 128
  const displayWidth = Math.round((originalWidth * displayHeight) / originalHeight)
  const translateX = useSharedValue(-200) // this is to prevent the user from seeing the initial cut

  useEffect(() => {
    translateX.value = withSequence(
      withTiming(-displayWidth, {
        duration: 120_000,
        easing: Easing.linear,
      }),
      withTiming(0, {
        duration: 0,
        easing: Easing.linear,
      }),
      withRepeat(
        withTiming(-displayWidth, {
          duration: 120_000,
          easing: Easing.linear,
        }),
        -1,
        false,
      ),
    )
  }, [])

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{ translateX: translateX.value }],
  }))

  return (
    <MaskedView
      style={{ height: displayHeight }}
      maskElement={
        <View style={{ flex: 1, flexDirection: 'row' }}>
          <LinearGradient
            colors={['transparent', '#000000']}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
            style={{ width: 50, height: '100%' }}
          />
          <View style={{ flex: 1, backgroundColor: '#000000' }} />
          <LinearGradient
            colors={['#000000', 'transparent']}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
            style={{ width: 50, height: '100%' }}
          />
        </View>
      }
    >
      <Animated.View style={animatedStyle} className="flex flex-row">
        <Image
          source={require('../../assets/images/characters-masonry.webp')}
          style={{ width: displayWidth, height: displayHeight }}
          resizeMode="cover"
        />
        <Image
          source={require('../../assets/images/characters-masonry.webp')}
          style={{ width: displayWidth, height: displayHeight }}
          resizeMode="cover"
        />
      </Animated.View>
    </MaskedView>
  )
}

const MGCharactersMasonry: FC = () => {
  return Platform.OS === 'web' ? <MGCharactersMasonryWeb /> : <MGCharactersMasonryNative />
}

export { MGCharactersMasonry }
