import { trpcClient, trpcUtils, type RouterInputs } from './trpc'

export const initiateStripeCheckout = async (input: RouterInputs['store']['purchase']['initiate']) => {
  const session = await trpcClient.store.purchase.initiate.mutate(input)
  if (!session.url) throw new Error('Failed to create checkout session')

  window.open(session.url, '_blank')

  // so we could refetch user subscription if the user actually comes back to this same tab for some reason
  setTimeout(() => {
    trpcUtils.user.getViewerActiveSubscription.invalidate(undefined, {
      refetchType: 'active',
    })
  }, 1000)
}
