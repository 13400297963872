import Ionicons from '@expo/vector-icons/Ionicons'
import clsx from 'clsx'
import { Image } from 'expo-image'
import { Link, useRouter } from 'expo-router'
import { Fragment, useEffect, useMemo, useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { FlatList, RefreshControl, TouchableHighlight, TouchableOpacity, View } from 'react-native'
import Swipeable from 'react-native-gesture-handler/ReanimatedSwipeable'
import Animated, { interpolate, useAnimatedStyle, type SharedValue } from 'react-native-reanimated'
import { trpcClient, trpcReact, type RouterOutputs } from '../../lib/services/trpc'
import { setHasChat } from '../../lib/storage'
import { useTheme } from '../../lib/theme'
import IconModal from '../common/IconModal'
import { MGIonicons } from '../common/MGIonicons'
import MGText from '../common/MGText'
import NonIdealState from '../common/NonIdealState'
import ChatCard from './ChatCard'

type ChatListActiveAction =
  | { type: 'delete'; chatId: string }
  | { type: 'restart'; chatId: string; characterId: string }
  | null

const SwipeableActionButton = ({
  onPress,
  icon,
  label,
  bgColor: contentClassName,
}: { onPress: () => void; icon: keyof typeof Ionicons.glyphMap; label: string; bgColor: string }) => {
  const theme = useTheme()

  return (
    <TouchableHighlight
      onPress={onPress}
      className="h-full flex items-center justify-center"
      accessibilityRole="button"
      accessibilityLabel={label}
      activeOpacity={0.8}
      underlayColor={theme.background}
    >
      <View className={clsx('flex items-center justify-center gap-1 px-5 h-full', contentClassName)}>
        <MGIonicons name={icon} size={24} />
        <MGText className="text-xs">{label}</MGText>
      </View>
    </TouchableHighlight>
  )
}

interface ChatListItemProps {
  chat: RouterOutputs['chat']['list']['items'][number]
  selectedChatId?: string
  onActionPress: (action: ChatListActiveAction) => void
}

interface SwipeableActionsProps {
  dragAnimatedValue: SharedValue<number>
  chat: RouterOutputs['chat']['list']['items'][number]
  onActionPress: (action: ChatListActiveAction) => void
}

const SwipeableActions: FC<SwipeableActionsProps> = ({ dragAnimatedValue, chat, onActionPress }) => {
  const { t } = useTranslation()

  const animatedStyle = useAnimatedStyle(() => {
    const translateX = interpolate(-dragAnimatedValue.value, [0, 180], [60, 0])
    const opacity = interpolate(-dragAnimatedValue.value, [0, 100], [0.2, 1])

    return {
      transform: [{ translateX }],
      opacity,
    }
  })

  return (
    <Animated.View className="flex-row" style={animatedStyle}>
      <SwipeableActionButton
        onPress={() => {
          if (chat.character?.id) {
            onActionPress({ type: 'restart', chatId: chat.id, characterId: chat.character.id })
          }
        }}
        icon="refresh"
        label={t('chat.action.restart')}
        bgColor="bg-primary-900"
      />
      <SwipeableActionButton
        onPress={() => {
          onActionPress({ type: 'delete', chatId: chat.id })
        }}
        icon="trash"
        label={t('chat.action.delete')}
        bgColor="bg-red-500"
      />
    </Animated.View>
  )
}

const ChatListItem: FC<ChatListItemProps> = ({ chat, selectedChatId, onActionPress }) => {
  const { t } = useTranslation()

  const renderRightActions = (_progress: SharedValue<number>, dragAnimatedValue: SharedValue<number>) => (
    <SwipeableActions dragAnimatedValue={dragAnimatedValue} chat={chat} onActionPress={onActionPress} />
  )

  return (
    <Swipeable renderRightActions={renderRightActions} friction={1} overshootFriction={8}>
      <Link
        href={`/chat/${chat.id}`}
        className={clsx(
          'flex-1 bg-background px-2',
          selectedChatId === chat.id && 'web:bg-primary-100 web:dark:bg-primary-900',
        )}
      >
        <ChatCard
          lastActiveAt={chat.lastActiveAt}
          avatarUrl={chat.character?.avatarUrl}
          name={chat.character?.name ?? t('character.deleted-name')}
          lastMessage={chat.lastMessage}
        />
      </Link>
    </Swipeable>
  )
}

export default function ChatList({ selectedChatId }: { selectedChatId?: string }) {
  const router = useRouter()
  const [activeAction, setActiveAction] = useState<ChatListActiveAction>(null)

  const {
    data: chatsRes,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    refetch: refetchChats,
    isPending,
  } = trpcReact.chat.list.useInfiniteQuery(
    {
      limit: 20,
    },
    {
      getNextPageParam: (lastPage) => lastPage.pagination.nextCursor,
    },
  )

  useEffect(() => {
    if (chatsRes?.pages[0]?.items.length === 0) {
      setHasChat(false)
    } else {
      setHasChat(true)
    }
  }, [chatsRes])

  const { t } = useTranslation()
  const utils = trpcReact.useUtils()

  const handleDeleteChat = async (chatId: string) => {
    await trpcClient.chat.delete.mutate({
      id: chatId,
    })
    utils.message.list.invalidate({ chatId })
    utils.chat.get.invalidate({ id: chatId })
    utils.chat.list.invalidate()

    setActiveAction(null)
  }

  const handleRestartChat = async (chatId: string, characterId: string) => {
    await trpcClient.chat.restart.mutate({
      id: chatId,
    })

    utils.message.list.invalidate({ chatId })
    utils.chat.get.invalidate({ id: chatId })
    utils.chat.list.invalidate()

    setActiveAction(null)
  }

  const chats = useMemo(() => chatsRes?.pages?.flatMap((page) => page?.items), [chatsRes?.pages])

  const loadMore = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }
  }

  const ListEmptyComponent = () => {
    if (isPending)
      return (
        <View className="items-center justify-center w-full py-36">
          <Image
            source={require('../../assets/images/status/loading.png')}
            style={{ width: 50, height: 50 }}
            autoplay={true}
          />
        </View>
      )
    return (
      <View className="items-center justify-center w-full py-24">
        <NonIdealState title={t('error.no-chat')} description={t('error.no-chat.description')} />

        <View className="flex-row gap-2">
          <TouchableOpacity
            onPress={() => router.push('/')}
            className="mt-4 rounded-full bg-primary-400 px-4 py-3 flex items-center justify-center flex-row gap-2"
            accessibilityRole="button"
            accessibilityLabel={t('tab.home')}
          >
            <Ionicons name="compass-outline" size={24} color="white" />
            <MGText className="text-white">{t('tab.home')}</MGText>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => router.push('/character/search')}
            className="mt-4 rounded-full border border-primary-400 px-4 py-3 flex items-center justify-center flex-row gap-2"
            accessibilityRole="button"
            accessibilityLabel={t('character.search.title')}
          >
            <Ionicons name="search-outline" size={24} color="#fe68c4" />
            <MGText className="text-primary-400">{t('character.search.title')}</MGText>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  const ListFooterComponent = () =>
    isFetchingNextPage ? (
      <View className="py-4 items-center">
        <Image
          source={require('../../assets/images/status/loading.png')}
          style={{ width: 50, height: 50 }}
          autoplay={true}
        />
      </View>
    ) : null

  return (
    <Fragment>
      <FlatList
        data={chats}
        contentInsetAdjustmentBehavior="automatic"
        contentContainerClassName="pb-8"
        refreshing={isFetching}
        refreshControl={<RefreshControl refreshing={isFetching} onRefresh={refetchChats} />}
        onEndReached={loadMore}
        onEndReachedThreshold={0.5}
        ListEmptyComponent={ListEmptyComponent}
        ListFooterComponent={ListFooterComponent}
        ItemSeparatorComponent={() => <View className="h-[1px] bg-neutral-100 ml-20 rounded-full" />}
        renderItem={({ item: chat }) => (
          <ChatListItem chat={chat} selectedChatId={selectedChatId} onActionPress={setActiveAction} />
        )}
        keyExtractor={(chat) => chat.id}
      />
      <IconModal
        visible={activeAction?.type === 'restart'}
        severity="danger"
        title={t('chat.action.restart.title')}
        icon="refresh"
        description={t('chat.action.restart.description')}
        primaryButtonContent={t('chat.action.restart.confirm')}
        onConfirm={() => {
          if (activeAction?.type === 'restart') {
            void handleRestartChat(activeAction.chatId, activeAction.characterId)
          }
        }}
        onCancel={() => setActiveAction(null)}
      />
      <IconModal
        visible={activeAction?.type === 'delete'}
        severity="danger"
        title={t('chat.action.delete.title')}
        icon="trash"
        description={t('chat.action.delete.description')}
        primaryButtonContent={t('chat.action.delete.confirm')}
        onConfirm={() => {
          if (activeAction?.type === 'delete') {
            void handleDeleteChat(activeAction.chatId)
          }
        }}
        onCancel={() => setActiveAction(null)}
      />
    </Fragment>
  )
}
