import { toast } from 'burnt'
import clsx from 'clsx'
import * as Clipboard from 'expo-clipboard'
import type React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Modal, ScrollView, TextInput, TouchableHighlight, TouchableWithoutFeedback, View } from 'react-native'
import type { UseChatMessagesItem } from '../../app/(app)/chat/[chatId]'
import { ampli } from '../../lib/ampli'
import i18n from '../../lib/i18n'
import { trpcClient, trpcUtils } from '../../lib/services/trpc'
import { useTheme } from '../../lib/theme'
import { ChatMessage } from '../chat/ChatMessage'
import { MGActivityIndicator } from '../ui/MGActivityIndicator'
import MGBaseModal from './MGBaseModal'
import { MGIonicons } from './MGIonicons'
import MGLoadingOverlay from './MGLoadingOverlay'
import { MGModalButton } from './MGModalButton'
import MGText from './MGText'

export const MGMessageDialog = ({
  message,
  avatar,
  isOpen,
  setIsOpen,
  onStartMultiselect,
  chatId,
  isFirstMessage = false,
}: {
  message: UseChatMessagesItem | null
  avatar?: React.ReactNode
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  onStartMultiselect: () => void
  chatId: string
  isFirstMessage?: boolean
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const [isPending, setIsPending] = useState(false)

  const handleCopy = async () => {
    if (!message) return
    await Clipboard.setStringAsync(message.content)
    toast({ title: t('character.share.copied'), preset: 'done' })
    setIsOpen(false)
  }

  const handleRoll = async () => {
    if (!message) return
    setIsPending(true)
    try {
      await trpcClient.message.roll.mutate({
        chatId,
        messageId: message.id,
        locale: i18n.language,
      })
      await trpcUtils.message.list.invalidate({ chatId })
      setIsOpen(false)
    } catch (error) {
      toast({
        title: t('error.general-error'),
        preset: 'error',
      })
    } finally {
      setIsPending(false)
    }
  }

  const handleDelete = () => {
    if (!message) return
    Alert.alert(t('chat.message.action.delete'), t('common.confirm.nonreversible'), [
      {
        text: t('action.cancel'),
        style: 'cancel',
      },
      {
        text: t('chat.message.action.delete'),
        style: 'destructive',
        onPress: async () => {
          setIsPending(true)
          try {
            await trpcClient.message.delete.mutate({
              chatId,
              messageIds: [message.id],
            })
            await trpcUtils.message.list.invalidate({ chatId })
            setIsOpen(false)
          } catch (error) {
            toast({
              title: t('chat.message.action.delete.failed'),
              preset: 'error',
            })
          } finally {
            setIsPending(false)
          }
        },
      },
    ])
  }

  const showReportModal = () => {
    Alert.alert(t('chat.message.action.report'), t('chat.message.action.report.description'), [
      {
        text: t('chat.message.action.report.reason.MIXED_LANGUAGE'),
        onPress: () => {
          handleReport({
            reason: 'MIXED_LANGUAGE',
          })
        },
      },
      {
        text: t('chat.message.action.report.reason.EMPTY_RESPONSE'),
        onPress: () => {
          handleReport({
            reason: 'EMPTY_RESPONSE',
          })
        },
      },
      {
        text: t('chat.message.action.report.reason.REPETITIVE_RESPONSE'),
        onPress: () => {
          handleReport({
            reason: 'REPETITIVE_RESPONSE',
          })
        },
      },
      {
        text: t('chat.message.action.report.reason.SETTINGS_CONTEXT_LOST'),
        onPress: () => {
          handleReport({
            reason: 'SETTINGS_CONTEXT_LOST',
          })
        },
      },
      {
        text: t('chat.message.action.report.reason.CHAT_CONTEXT_LOST'),
        onPress: () => {
          handleReport({
            reason: 'CHAT_CONTEXT_LOST',
          })
        },
      },
      {
        text: t('chat.message.action.report.reason.OTHERS'),
        onPress: () => {
          handleReport({
            reason: 'OTHERS',
          })
        },
      },
      {
        text: t('action.cancel'),
        style: 'cancel',
      },
    ])
  }
  const handleReport = async ({
    reason,
  }: {
    reason: string
  }) => {
    if (!message) return

    setIsPending(true)
    try {
      await trpcClient.report.create.mutate({
        refType: 'message',
        refId: message.id,
        reason,
      })
      setIsOpen(false)
      toast({
        title: t('chat.message.action.report.success'),
        preset: 'done',
      })
    } catch (error) {
      toast({
        title: t('chat.message.action.report.failed'),
        preset: 'error',
      })
    } finally {
      setIsPending(false)
    }
  }

  const [isEditing, setIsEditing] = useState(false)
  const [editMessage, setEditMessage] = useState(message?.content)
  const [isSubmittingEdit, setIsSubmittingEdit] = useState(false)

  const handleEdit = () => {
    if (!message) return
    setIsEditing(true)
    setEditMessage(message.content)
  }

  const onEditConfirm = async () => {
    if (!message || !editMessage) {
      toast({
        title: t('error.general-error'),
        preset: 'error',
      })
      return
    }
    setIsSubmittingEdit(true)
    try {
      await trpcClient.message.edit.mutate({
        chatId,
        messageId: message.id,
        messageContent: editMessage,
      })
      await trpcUtils.message.list.invalidate({ chatId })
      setIsEditing(false)
      setIsOpen(false)
    } catch (error) {
      toast({
        title: t('error.general-error'),
        preset: 'error',
      })
    } finally {
      setIsSubmittingEdit(false)
    }
  }

  const menuSetting = [
    {
      icon: <MGIonicons name="copy-outline" size={24} />,
      title: t('chat.message.action.copy'),
      action: handleCopy,
      id: 'copy',
    },
    {
      icon: <MGIonicons name="refresh-outline" size={24} />,
      title: t('chat.message.action.regenerate'),
      action: handleRoll,
      hidden: message?.role !== 'CHARACTER' || isFirstMessage,
      id: 'regenerate',
    },
    {
      icon: <MGIonicons name="pencil-outline" size={24} />,
      title: t('chat.message.action.edit'),
      action: handleEdit,
      id: 'edit',
    },
    {
      icon: <MGIonicons name="trash-outline" size={24} color="#ef4444" />,
      title: t('chat.message.action.delete'),
      action: handleDelete,
      textClassName: 'text-red-500',
      hidden: isFirstMessage,
      id: 'delete',
    },
    {
      icon: <MGIonicons name="flag-outline" size={24} color="#ef4444" />,
      title: t('chat.message.action.report'),
      action: showReportModal,
      hidden: message?.role !== 'CHARACTER',
      textClassName: 'text-red-500',
      id: 'report',
    },
  ].filter((item) => !item.hidden)

  if (!message) return null

  if (isEditing) {
    return (
      <MGBaseModal
        visible={isOpen}
        onClose={() => {
          setIsEditing(false)
          setIsOpen(false)
        }}
        title={t('chat.message.action.edit')}
        icon="pencil-outline"
        iconColor="#3b82f6"
        iconBgColor="bg-blue-100"
        contentRequireScrollView={false}
      >
        <TextInput
          multiline
          value={editMessage}
          onChangeText={setEditMessage}
          className={clsx(
            'p-4 rounded-2xl border max-h-[200px] bg-neutral-50 text-foreground',
            message.role === 'CHARACTER' ? 'border-primary-600' : 'border-neutral-400',
          )}
        />

        <MGModalButton className="bg-primary-500 mt-4" onPress={onEditConfirm} disabled={isSubmittingEdit}>
          {isSubmittingEdit && <MGActivityIndicator size="small" />}
          <MGText className="text-center text-white">{t('action.confirm')}</MGText>
        </MGModalButton>

        <MGModalButton
          className="bg-neutral-100 p-4 rounded-full flex-row items-center justify-center mt-2"
          onPress={() => {
            setIsEditing(false)
          }}
        >
          <MGText className="text-center text-foreground">{t('action.cancel')}</MGText>
        </MGModalButton>
      </MGBaseModal>
    )
  }

  return (
    <Modal
      key={message.id}
      animationType="fade"
      transparent={true}
      visible={isOpen}
      onRequestClose={() => setIsOpen(false)}
    >
      <TouchableWithoutFeedback onPress={() => setIsOpen(false)}>
        <View className="flex-1 justify-center items-center bg-black/50 p-4">
          <TouchableWithoutFeedback>
            <View className="w-full flex flex-col gap-2 items-center">
              <View
                className="bg-background rounded-3xl w-full overflow-hidden"
                style={{
                  maxWidth: 400,
                }}
              >
                <ScrollView className="mb-4 p-4 m-6 bg-neutral-50 rounded-2xl border border-neutral-200 max-h-96">
                  <ChatMessage key={message.id} isInitialized message={message} avatar={avatar} disabled />
                </ScrollView>
                <View className="pb-4 relative">
                  {menuSetting.map((item) => (
                    <TouchableHighlight
                      key={item.title}
                      onPress={() => {
                        item.action()
                        ampli.messageDialogAction({
                          action: item.id,
                        })
                      }}
                      underlayColor={theme['neutral-300']}
                      activeOpacity={0.7}
                      disabled={isPending}
                      accessibilityRole="button"
                      accessibilityLabel={item.title}
                    >
                      <View className="flex flex-row gap-2 items-center py-3 px-6">
                        {item.icon}
                        <MGText className={item.textClassName}>{item.title}</MGText>
                      </View>
                    </TouchableHighlight>
                  ))}
                  <MGLoadingOverlay visible={isPending} />
                </View>
              </View>

              <TouchableHighlight
                onPress={() => {
                  onStartMultiselect()
                  setIsOpen(false)
                }}
                underlayColor={theme['neutral-300']}
                activeOpacity={0.7}
                disabled={isPending}
                style={{
                  maxWidth: 400,
                }}
                className="bg-background rounded-3xl w-full py-2"
                accessibilityRole="button"
                accessibilityLabel={t('chat.message.action.capture')}
              >
                <View className="flex flex-row gap-2 items-center py-3 px-6">
                  <MGIonicons name="crop-outline" size={24} />
                  <MGText>{t('chat.message.action.capture')}</MGText>
                </View>
              </TouchableHighlight>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  )
}
