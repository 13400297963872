import type React from 'react'
import { Modal, type ModalProps, TouchableWithoutFeedback, View } from 'react-native'
import Animated, {
  FadeIn,
  FadeOut,
  LinearTransition,
  useAnimatedReaction,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

interface MGBottomSheetProps extends ModalProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  children: React.ReactNode
  maxWidth?: number
}

export const MGBottomSheet: React.FC<MGBottomSheetProps> = ({
  isOpen,
  setIsOpen,
  children,
  maxWidth = 768,
  ...props
}) => {
  const translateY = useSharedValue(500)

  // Spring animation configuration
  const springConfig = {
    damping: 40,
    stiffness: 450,
    mass: 1.2,
  }

  useAnimatedReaction(
    () => isOpen,
    (open) => {
      translateY.value = withSpring(open ? 0 : 500, springConfig)
    },
    [isOpen],
  )

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{ translateY: translateY.value }],
  }))
  const inset = useSafeAreaInsets()

  // the outer Animated.View is required to prevent the modal from display incorrectly on Android
  // unsure if this is related but it seems to be: https://github.com/software-mansion/react-native-reanimated/issues/6659
  return (
    <Animated.View>
      <Modal transparent animationType="fade" visible={isOpen} onRequestClose={() => setIsOpen(false)} {...props}>
        <TouchableWithoutFeedback onPress={() => setIsOpen(false)}>
          <View
            className="flex-1 justify-end items-center bg-black/50 px-4"
            style={{ paddingBottom: Math.max(inset.bottom, 16) }} // make sure we at least got 16px padding bottom
          >
            <TouchableWithoutFeedback>
              <Animated.View
                className="bg-background rounded-3xl overflow-hidden w-full"
                layout={LinearTransition.springify().damping(40).stiffness(450).mass(1.2)}
                entering={FadeIn.springify().damping(40).stiffness(450).mass(1.2)}
                exiting={FadeOut.springify().damping(40).stiffness(450).mass(1.2)}
                style={[
                  {
                    maxWidth,
                  },
                  animatedStyle,
                ]}
              >
                {children}
              </Animated.View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    </Animated.View>
  )
}
