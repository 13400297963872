import Ionicons from '@expo/vector-icons/Ionicons'
import clsx from 'clsx'
import { Link, type Href } from 'expo-router'
import { Fragment, type FC } from 'react'
import { TouchableOpacity, View } from 'react-native'
import MGText from '../../components/common/MGText'
import { MGIonicons } from './MGIonicons'

interface SettingItemProps {
  title: string
  icon: keyof typeof Ionicons.glyphMap
  onPress?: () => void
  href?: Href
  badge?: string
  isDestructive?: boolean
  size?: 'x-small' | 'regular' | 'x-large'
}

export const SettingsItem: FC<SettingItemProps> = ({
  title,
  icon,
  href,
  onPress,
  badge,
  isDestructive,
  size = 'regular',
}: SettingItemProps) => {
  const inner = (
    <TouchableOpacity
      onPress={onPress}
      className={clsx('px-4', size !== 'x-small' && 'bg-neutral-100')}
      accessibilityRole="button"
      accessibilityLabel={title}
    >
      <View
        className={clsx('flex flex-row items-center gap-2 justify-between', {
          'py-2': size === 'x-small',
          'py-4': size === 'regular',
          'py-8': size === 'x-large',
        })}
      >
        <View className="flex flex-row items-center gap-2">
          <MGIonicons name={icon} size={16} color={isDestructive ? '#ef4444' : undefined} />
          <MGText className={clsx('text-base', isDestructive && 'text-red-500')}>{title}</MGText>
          {badge && (
            <View className="bg-primary-400 text-white flex items-center justify-center size-5 text-center rounded-full shrink-0">
              <MGText className="text-xs text-white" bold>
                {badge}
              </MGText>
            </View>
          )}
        </View>
        {href && <Ionicons name="chevron-forward-outline" className="shrink-0" size={24} color="#dddddd" />}
      </View>
    </TouchableOpacity>
  )

  if (href) {
    return (
      <Link href={href} asChild>
        {inner}
      </Link>
    )
  }

  return inner
}

const SettingsSection: FC<{ items: SettingItemProps[] }> = ({ items }) => (
  <View className="rounded-3xl overflow-hidden mx-4">
    <View className="flex flex-col">
      {items.map((setting, index) => (
        <Fragment key={setting.title}>
          <SettingsItem {...setting} />
          {index !== items.length - 1 && <View className="h-px bg-neutral-200" />}
        </Fragment>
      ))}
    </View>
  </View>
)

export default SettingsSection
