import clsx from 'clsx'
import { Image } from 'expo-image'
import { router } from 'expo-router'
import { type Dispatch, Fragment, type SetStateAction, memo, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlatList, RefreshControl, ScrollView, TouchableOpacity, View, useWindowDimensions } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { CharacterCreateIconOnlyFAB } from '../../../components/character/CharacterCreateFAB'
import { ListFooterComponent, renderSquareItem } from '../../../components/character/CharacterList'
import MGCharacterModal from '../../../components/common/MGCharacterModal'
import MGHeader from '../../../components/common/MGHeader'
import MGHeaderText from '../../../components/common/MGHeaderText'
import { MGIonicons } from '../../../components/common/MGIonicons'
import MGText from '../../../components/common/MGText'
import NonIdealState from '../../../components/common/NonIdealState'
import HomeCarousel from '../../../components/HomeCarousel'
import { ampli } from '../../../lib/ampli'
import { useIsDesktop } from '../../../lib/hooks/useIsDesktop'
import { trpcReact, trpcUtils } from '../../../lib/services/trpc'

const HomeScreenListHeaderComponent = memo(
  ({
    feedFilter,
    setFeedFilter,
  }: {
    feedFilter:
      | {
          feed: 'featured'
          tagId: string | null
        }
      | {
          feed: 'ugc'
          tagId: null
        }
    setFeedFilter: Dispatch<
      SetStateAction<
        | {
            feed: 'featured'
            tagId: string | null
          }
        | {
            feed: 'ugc'
            tagId: null
          }
      >
    >
  }) => {
    const { t } = useTranslation()
    const { data: tags } = trpcReact.tag.list.useQuery()

    return (
      <View className="flex flex-col">
        <HomeCarousel />
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          contentContainerClassName="flex flex-row items-center px-4 my-2"
        >
          <TouchableOpacity
            hitSlop={2}
            className={clsx(
              'bg-neutral-100 rounded-full py-3 mr-1 px-5 flex items-center justify-center',
              feedFilter.feed === 'featured' && feedFilter.tagId === null && 'bg-primary-400 text-white',
            )}
            onPress={() => {
              setFeedFilter({
                feed: 'featured',
                tagId: null,
              })
            }}
            activeOpacity={0.6}
            accessibilityRole="button"
            accessibilityLabel={t('character.filter.all')}
            accessibilityState={{ selected: feedFilter.feed === 'featured' && feedFilter.tagId === null }}
          >
            <MGText
              className={clsx(
                'leading-tight',
                feedFilter.feed === 'featured' && feedFilter.tagId === null && 'text-white',
              )}
            >
              {t('character.filter.all')}
            </MGText>
          </TouchableOpacity>
          <View className="h-full bg-neutral-100 w-0.5 mx-3" />
          <TouchableOpacity
            hitSlop={2}
            className={clsx(
              'bg-neutral-100 rounded-full py-3 mr-1 px-5 flex items-center justify-center',
              feedFilter.feed === 'ugc' && 'bg-primary-400 text-white',
            )}
            onPress={() => {
              setFeedFilter({
                feed: 'ugc',
                tagId: null,
              })
            }}
            activeOpacity={0.6}
            accessibilityRole="button"
            accessibilityLabel={t('character.filter.ugc')}
            accessibilityState={{ selected: feedFilter.feed === 'ugc' }}
          >
            <MGText className={clsx('leading-tight', feedFilter.feed === 'ugc' && 'text-white')}>
              {t('character.filter.ugc')}
            </MGText>
          </TouchableOpacity>
          {tags?.map((tag) => (
            <TouchableOpacity
              hitSlop={2}
              key={tag.id}
              className={clsx(
                'bg-neutral-100 rounded-full py-3 mr-1 px-5 flex items-center justify-center',
                feedFilter.feed === 'featured' && feedFilter.tagId === tag.id && 'bg-primary-400 text-white',
              )}
              onPress={() => {
                if (feedFilter.feed === 'featured' && feedFilter.tagId === tag.id) {
                  setFeedFilter({
                    feed: 'featured',
                    tagId: null,
                  })
                } else {
                  setFeedFilter({
                    feed: 'featured',
                    tagId: tag.id,
                  })
                  ampli.indexTagSelected({
                    tag_id: tag.id,
                  })
                }
              }}
              activeOpacity={0.6}
              accessibilityRole="button"
              accessibilityLabel={tag.name}
              accessibilityState={{ selected: feedFilter.feed === 'featured' && feedFilter.tagId === tag.id }}
            >
              <MGText
                className={clsx(
                  'leading-tight',
                  feedFilter.feed === 'featured' && feedFilter.tagId === tag.id && 'text-white',
                )}
              >
                {tag.name}
              </MGText>
            </TouchableOpacity>
          ))}
        </ScrollView>
      </View>
    )
  },
)

export default function HomeScreen() {
  const { t } = useTranslation()
  const [feedFilter, setFeedFilter] = useState<
    | {
        feed: 'featured'
        tagId: string | null
      }
    | {
        feed: 'ugc'
        tagId: null
      }
  >({
    feed: 'featured',
    tagId: null,
  })

  const isDesktop = useIsDesktop()

  const [currentShowingCharacter, setCurrentShowingCharacter] = useState<string | null>(null)

  const insets = useSafeAreaInsets()
  const {
    data: charactersRes,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isPending,
    isFetching,
    refetch: refetchCharacters,
  } = trpcReact.character.list.useInfiniteQuery(
    {
      feed: feedFilter.feed,
      limit: 20,
      filter: {
        tagId: feedFilter.tagId ?? undefined,
      },
    },
    {
      getNextPageParam: (lastPage) => lastPage.pagination.nextCursor,
    },
  )

  const onRefresh = () => {
    refetchCharacters()
    trpcUtils.banner.list.invalidate()
  }

  const loadMore = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }
  }
  const characters = useMemo(
    () => charactersRes?.pages?.flatMap((page) => page?.items) ?? [],
    [charactersRes?.pages, feedFilter],
  )

  const [numColumns, setNumColumns] = useState<number | null>(null)
  const dim = useWindowDimensions()
  useEffect(() => {
    setNumColumns(Math.max(Math.floor(dim.width / 200), 2))
  }, [dim.width])

  const resolvedListEmptyComponent = useMemo(() => {
    if (isPending) {
      return (
        <View className="flex-1 flex justify-start items-center h-96 pt-24">
          <Image source={require('../../../assets/images/status/loading.png')} style={{ width: 64, height: 64 }} />
        </View>
      )
    }

    return (
      <View className="flex justify-start items-start h-96 py-24">
        <NonIdealState
          title={t('error.empty-character-list')}
          description={t('error.empty-character-list.description')}
        />
      </View>
    )
  }, [isPending])

  return (
    <View className="bg-background h-full">
      <View
        style={{
          paddingTop: insets.top,
          paddingLeft: insets.left,
          paddingRight: insets.right,
        }}
        className="h-full relative"
      >
        <View>
          <MGHeader
            disableBackButton
            headerBody={<MGHeaderText>{t('tab.home')}</MGHeaderText>}
            trailingView={
              <Fragment>
                {isFetching && (
                  <Image
                    source={require('../../../assets/images/status/loading.png')}
                    style={{ width: 20, height: 20, marginRight: 10 }}
                  />
                )}
                <TouchableOpacity hitSlop={12} onPress={() => router.push('/character/search')}>
                  <MGIonicons name="search" size={24} />
                </TouchableOpacity>
              </Fragment>
            }
          />
        </View>
        <View className="flex-1 relative">
          {numColumns && (
            <FlatList
              key={numColumns}
              data={characters}
              numColumns={numColumns}
              horizontal={false}
              renderItem={({ item, index }) =>
                renderSquareItem({
                  item,
                  index,
                  numColumns: numColumns,
                  onPress: () => {
                    isDesktop ? setCurrentShowingCharacter(item.id) : router.push(`/character/${item.id}`)
                  },
                })
              }
              keyExtractor={(item) => item.id}
              style={{ flex: 1 }}
              onEndReached={loadMore}
              onEndReachedThreshold={0.5}
              ListHeaderComponent={
                <HomeScreenListHeaderComponent feedFilter={feedFilter} setFeedFilter={setFeedFilter} />
              }
              ListEmptyComponent={resolvedListEmptyComponent}
              ListFooterComponent={ListFooterComponent(isFetchingNextPage)}
              refreshControl={<RefreshControl refreshing={isFetching} onRefresh={onRefresh} />}
            />
          )}

          <CharacterCreateIconOnlyFAB />
        </View>
      </View>

      {isDesktop && !!currentShowingCharacter && (
        <MGCharacterModal characterId={currentShowingCharacter} onClose={() => setCurrentShowingCharacter(null)} />
      )}
    </View>
  )
}
