import type { FC } from 'react'
import { View } from 'react-native'
import Animated, {
  Easing,
  useAnimatedStyle,
  withDelay,
  withRepeat,
  withSequence,
  withTiming,
} from 'react-native-reanimated'

const LoadingDot: FC<{ delay: number }> = ({ delay }) => {
  const animatedStyle = useAnimatedStyle(() => {
    return {
      opacity: withDelay(
        delay,
        withRepeat(
          withSequence(
            withTiming(0.2, { duration: 0 }),
            withTiming(1, {
              duration: 600,
              easing: Easing.ease,
            }),
            withTiming(0.2, {
              duration: 600,
              easing: Easing.ease,
            }),
            withTiming(0.2, {
              duration: 800,
              easing: Easing.ease,
            }),
          ),
          -1,
          false,
        ),
      ),
    }
  })

  return <Animated.View style={[animatedStyle]} className="size-1.5 rounded-full bg-neutral-700" />
}

export const ChatMessageLoading: FC = () => {
  return (
    <View className="flex flex-row justify-start items-center gap-1 pl-2 pr-12 h-8">
      <LoadingDot delay={0} />
      <LoadingDot delay={200} />
      <LoadingDot delay={400} />
    </View>
  )
}
