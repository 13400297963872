import Ionicons from '@expo/vector-icons/Ionicons'
import clsx from 'clsx'
import { useSetAtom } from 'jotai'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Image, TouchableHighlight, View, type ViewProps } from 'react-native'
import { twMerge } from 'tailwind-merge'
import { useActiveSubscription } from '../../lib/hooks/useActiveSubscription'
import { purchaseSheetStateAtom } from '../../lib/purchaseSheet'
import { MGGradientBackground } from '../common/MGGradientBackground'
import MGText from '../common/MGText'

const ROUNDED_CLASSES = {
  sm: 'rounded-sm',
  md: 'rounded-md',
  lg: 'rounded-lg',
  xl: 'rounded-xl',
  '2xl': 'rounded-2xl',
  '3xl': 'rounded-3xl',
}

/**
 * MoguPassBanner will automatically hide if the user is already subscribed to MoguPass, so make sure to accommodate for different height this
 * banner will take up.
 */
export const MoguPassBanner: FC<
  ViewProps & {
    rounded?: keyof typeof ROUNDED_CLASSES
    onPress?: () => void
    contentClassName?: string
  }
> = ({ className, rounded = '3xl', onPress, contentClassName, ...props }) => {
  const { t } = useTranslation()
  const setPurchaseSheetState = useSetAtom(purchaseSheetStateAtom)

  const { data: viewerActiveSubscription } = useActiveSubscription()

  if (viewerActiveSubscription) {
    return null
  }

  return (
    <View className={clsx('w-full', className)}>
      <View
        className={clsx('w-full', ROUNDED_CLASSES[rounded])}
        style={{
          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: 1,
          },
          shadowOpacity: 0.22,
          shadowRadius: 2.22,

          elevation: 3,
        }}
        {...props}
      >
        <TouchableHighlight
          hitSlop={4}
          className={clsx('w-full overflow-hidden', ROUNDED_CLASSES[rounded])}
          onPress={() => {
            if (onPress) {
              onPress()
            } else {
              setPurchaseSheetState({ opened: true, selected: 'subscribe' })
            }
          }}
        >
          <MGGradientBackground className="w-full">
            <View
              className={twMerge(
                'flex-row items-center justify-between h-20 pl-3 pr-4 py-0.5 bg-background/25',
                contentClassName,
              )}
            >
              <Image
                source={require('../../assets/images/mogupass-mascot.webp')}
                style={{ width: 40, height: 40 }}
                resizeMode="cover"
              />

              <MGText numberOfLines={2} className="flex-1 opacity-80 text-black mx-1">
                {t('purchase.subscription.banner.content')}
              </MGText>

              <Ionicons name="chevron-forward" size={24} className="shrink-0 opacity-50" />
            </View>
          </MGGradientBackground>
        </TouchableHighlight>
      </View>
    </View>
  )
}
