import { queryOptions } from '@tanstack/react-query'
import { trpcReact, trpcUtils } from '../services/trpc'
import { useCurrentUser } from './useAuth'

export const queryInventoryKey = queryOptions({
  queryKey: ['inventory'],
  staleTime: 1 * 60_000,
})

interface AggregatedBalance {
  okome: number
  onigiri: number
}

export const useInventory = () => {
  const { data: user } = useCurrentUser()
  const { data } = trpcReact.inventory.list.useQuery(undefined, {
    enabled: !!user?.id,
  })

  const aggregatedBalance: AggregatedBalance = {
    okome:
      data?.reduce((acc, item) => acc + (item.entityId === 'ecoentity_currency_okome' ? item.usableBalance : 0), 0) ??
      0,
    onigiri:
      data?.reduce((acc, item) => acc + (item.entityId === 'ecoentity_currency_onigiri' ? item.usableBalance : 0), 0) ??
      0,
  }

  // aggregate and return a Record<entityId, usableBalance>
  const originalEntities =
    data?.reduce(
      (acc, item) => {
        acc[item.entityId] = (acc[item.entityId] ?? 0) + item.usableBalance
        return acc
      },
      {} as Record<string, number>,
    ) ?? {}

  return {
    aggregatedBalance,
    originalEntities,
  }
}

export const invalidateInventory = async () => {
  await trpcUtils.inventory.list.invalidate()
  await trpcUtils.inventory.list.refetch()
}
