import Ionicons from '@expo/vector-icons/Ionicons'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TouchableOpacity } from 'react-native'
import IconModal from './IconModal'

export const MGHelpDialog = ({ title, content }: { title: string; content: string }) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Fragment>
      <TouchableOpacity hitSlop={4} onPress={() => setIsOpen(true)}>
        <Ionicons name="help-circle-outline" size={20} color="#737373" />
      </TouchableOpacity>

      <IconModal
        visible={isOpen}
        severity="info"
        title={title}
        description={content}
        descriptionRequireScrollView
        primaryButtonContent={t('action.close')}
        hideCancelButton
        onCancel={() => setIsOpen(false)}
        onConfirm={() => setIsOpen(false)}
        textAlignment="left"
      />
    </Fragment>
  )
}
