import { usePathname } from 'expo-router'
import { useEffect, useRef } from 'react'
import { AppState } from 'react-native'
import type { AppStateStatus } from 'react-native'
import { ampli } from '../ampli'

export const useTrackPageView = () => {
  const pathname = usePathname()
  const enteredAtRef = useRef<number>(Date.now())
  const currentPathnameRef = useRef<string | null>(null)

  const handleScreenExit = (exitedPathname: string) => {
    if (!exitedPathname) return

    const durationSeconds = Math.floor((Date.now() - enteredAtRef.current) / 1000)
    ampli.pageView({
      pathname: exitedPathname,
      duration_seconds: durationSeconds,
    })
  }

  const handleScreenEnter = (enteredPathname: string) => {
    if (!enteredPathname) return

    enteredAtRef.current = Date.now()
    currentPathnameRef.current = enteredPathname
  }

  // Handle pathname changes (navigation)
  useEffect(() => {
    if (!pathname) return

    // Handle enter for the new pathname
    handleScreenEnter(pathname)

    // Cleanup function to handle the exit event
    return () => {
      if (currentPathnameRef.current) {
        handleScreenExit(currentPathnameRef.current)
      }
    }
  }, [pathname])

  // Handle app state changes
  useEffect(() => {
    const subscription = AppState.addEventListener('change', (nextAppState: AppStateStatus) => {
      // App going to background
      if (nextAppState === 'background' && currentPathnameRef.current) {
        handleScreenExit(currentPathnameRef.current)
      }
      // App coming to foreground
      else if (nextAppState === 'active' && currentPathnameRef.current) {
        handleScreenEnter(currentPathnameRef.current)
      }
    })

    return () => {
      subscription.remove()
    }
  }, []) // Removed pathname dependency
}
