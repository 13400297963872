{
  "errors.custom": "入力形式が間違っています。",
  "errors.invalid_arguments": "引数が間違っています。",
  "errors.invalid_date": "間違った日時データです。",
  "errors.invalid_enum_value": "'{{received}}'は無効な値です。{{- options}}で入力してください。",
  "errors.invalid_intersection_types": "交差型のマージができませんでした。",
  "errors.invalid_literal": "無効なリテラル値です。{{expected}}を入力してください。",
  "errors.invalid_return_type": "返値の型が間違っています。",
  "errors.invalid_string.cuid": "{{validation}}の形式で入力してください。",
  "errors.invalid_string.datetime": "{{validation}}の形式で入力してください。",
  "errors.invalid_string.email": "{{validation}}の形式で入力してください。",
  "errors.invalid_string.endsWith": "\"{{endsWith}}\"で終わる文字列である必要があります。",
  "errors.invalid_string.regex": "入力形式が間違っています。",
  "errors.invalid_string.startsWith": "\"{{startsWith}}\"で始まる文字列である必要があります。",
  "errors.invalid_string.url": "{{validation}}の形式で入力してください。",
  "errors.invalid_string.uuid": "{{validation}}の形式で入力してください。",
  "errors.invalid_type": "{{expected}}での入力を期待していますが、{{received}}が入力されました。",
  "errors.invalid_type_received_null": "必須",
  "errors.invalid_type_received_undefined": "必須",
  "errors.invalid_union": "入力形式が間違っています。",
  "errors.invalid_union_discriminator": "無効な識別子です。{{- options}}で入力してください。",
  "errors.not_finite": "有限数である必要があります。",
  "errors.not_multiple_of": "{{multipleOf}}の倍数である必要があります。",
  "errors.too_big.array.exact": "{{maximum}}個の要素である必要があります。",
  "errors.too_big.array.inclusive": "{{maximum}}個以下の要素である必要があります。",
  "errors.too_big.array.not_inclusive": "{{maximum}}個より少ない要素である必要があります。",
  "errors.too_big.date.exact": "{{- maximum, datetime}}の日時である必要があります。",
  "errors.too_big.date.inclusive": "{{- maximum, datetime}}以前の日時である必要があります。",
  "errors.too_big.date.not_inclusive": "{{- maximum, datetime}}よりも前の日時である必要があります。",
  "errors.too_big.number.exact": "{{maximum}}の数値である必要があります。",
  "errors.too_big.number.inclusive": "{{maximum}}以下の数値である必要があります。",
  "errors.too_big.number.not_inclusive": "{{maximum}}より小さな数値である必要があります。",
  "errors.too_big.set.exact": "入力形式が間違っています。",
  "errors.too_big.set.inclusive": "入力形式が間違っています。",
  "errors.too_big.set.not_inclusive": "入力形式が間違っています。",
  "errors.too_big.string.exact": "{{maximum}}文字の文字列である必要があります。",
  "errors.too_big.string.inclusive": "{{maximum}}文字以下の文字列である必要があります。",
  "errors.too_big.string.not_inclusive": "{{maximum}}文字より短い文字列である必要があります。",
  "errors.too_small.array.exact": "{{minimum}}個の要素が必要です。",
  "errors.too_small.array.inclusive": "{{minimum}}個以上の要素が必要です。",
  "errors.too_small.array.not_inclusive": "{{minimum}}個より多くの要素が必要です。",
  "errors.too_small.date.exact": "{{- minimum, datetime}}の日時である必要があります。",
  "errors.too_small.date.inclusive": "{{- minimum, datetime}}以降の日時である必要があります。",
  "errors.too_small.date.not_inclusive": "{{- minimum, datetime}}よりも後の日時である必要があります。",
  "errors.too_small.number.exact": "{{minimum}}の数値である必要があります。",
  "errors.too_small.number.inclusive": "{{minimum}}以上の数値である必要があります。",
  "errors.too_small.number.not_inclusive": "{{minimum}}より大きな数値である必要があります。",
  "errors.too_small.set.exact": "入力形式が間違っています。",
  "errors.too_small.set.inclusive": "入力形式が間違っています。",
  "errors.too_small.set.not_inclusive": "入力形式が間違っています。",
  "errors.too_small.string.exact": "{{minimum}}文字の文字列である必要があります。",
  "errors.too_small.string.inclusive": "{{minimum}}文字以上の文字列である必要があります。",
  "errors.too_small.string.not_inclusive": "{{minimum}}文字より長い文字列である必要があります。",
  "errors.unrecognized_keys": "オブジェクトのキー{{- keys}}が識別できません。",
  "types.array": "配列",
  "types.bigint": "Bigint",
  "types.boolean": "真偽値",
  "types.date": "日時",
  "types.float": "浮動小数点数",
  "types.function": "関数",
  "types.integer": "整数",
  "types.map": "マップ",
  "types.nan": "NaN",
  "types.never": "never",
  "types.null": "NULL",
  "types.number": "数値",
  "types.object": "オブジェクト",
  "types.promise": "Promise",
  "types.set": "セット",
  "types.string": "文字列",
  "types.symbol": "シンボル",
  "types.undefined": "undefined",
  "types.unknown": "unknown",
  "types.void": "void",
  "validations.cuid": "CUID",
  "validations.datetime": "日時",
  "validations.email": "メールアドレス",
  "validations.regex": "正規表現",
  "validations.url": "URL",
  "validations.uuid": "UUID"
}
