import i18next from 'i18next'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from './i18n'

const ZERO_DECIMAL_CURRENCIES = [
  'BIF',
  'CLP',
  'DJF',
  'GNF',
  'JPY',
  'KMF',
  'KRW',
  'MGA',
  'PYG',
  'RWF',
  'UGX',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF',
]

const getNormalizedPrice = (currency: string, unitAmount: number) => {
  if (ZERO_DECIMAL_CURRENCIES.includes(currency)) {
    return unitAmount
  }
  return unitAmount * 100
}

export const formatPrice = (currency: string, unitAmount: number) => {
  const normalizedPrice = getNormalizedPrice(currency.toUpperCase(), unitAmount)

  return normalizedPrice.toLocaleString(i18n.language, {
    style: 'currency',
    currency: currency.toUpperCase(),
  })
}
