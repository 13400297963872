import { Image } from 'expo-image'
import { type FC, useEffect } from 'react'
import { Animated, Dimensions, Easing, type ImageSourcePropType, View } from 'react-native'

interface AnimatedBackgroundImageProps {
  source: ImageSourcePropType
  xOffset?: number
  imageScaleFactor?: number
}

export const AnimatedBackgroundImage: FC<AnimatedBackgroundImageProps> = ({
  source,
  xOffset = 0,
  imageScaleFactor = 1,
}) => {
  const translateY = new Animated.Value(0)
  const translateX = new Animated.Value(xOffset)
  const opacity = new Animated.Value(0)
  const windowHeight = Dimensions.get('window').height
  const windowWidth = Dimensions.get('window').width
  const angle = (15 * Math.PI) / 180 // 15 degrees in radians
  const diagonalDistance = Math.sqrt(windowHeight * windowHeight + windowWidth * windowWidth)

  useEffect(() => {
    const startAnimation = () => {
      // Reset position
      translateY.setValue(0)
      translateX.setValue(xOffset)

      // Calculate diagonal movement
      const xDistance = Math.sin(angle) * diagonalDistance
      const yDistance = Math.cos(angle) * diagonalDistance

      Animated.loop(
        Animated.sequence([
          // Fade in
          Animated.timing(opacity, {
            toValue: 0.85,
            duration: 1000,
            useNativeDriver: true,
          }),
          // Diagonal movement
          Animated.parallel([
            Animated.timing(translateY, {
              toValue: -yDistance,
              duration: 150_000, // 150 seconds for slower movement
              easing: Easing.linear,
              useNativeDriver: true,
            }),
            Animated.timing(translateX, {
              toValue: xDistance,
              duration: 150_000,
              easing: Easing.linear,
              useNativeDriver: true,
            }),
          ]),
          // Fade out
          Animated.timing(opacity, {
            toValue: 0,
            duration: 1000,
            useNativeDriver: true,
          }),
          // Reset position instantly
          Animated.parallel([
            Animated.timing(translateY, {
              toValue: 0,
              duration: 0,
              useNativeDriver: true,
            }),
            Animated.timing(translateX, {
              toValue: xOffset,
              duration: 0,
              useNativeDriver: true,
            }),
          ]),
        ]),
      ).start()
    }

    startAnimation()
  }, [translateY, translateX, opacity, windowHeight, windowWidth, diagonalDistance, angle])

  return (
    <View className="overflow-hidden w-full h-full absolute inset-0">
      <Animated.View
        style={{
          opacity: opacity,
          transform: [{ translateY: translateY }, { translateX: translateX }, { rotate: '15deg' }, { scale: 1.25 }],
        }}
      >
        <Image
          source={source}
          blurRadius={10}
          style={{ width: 900 * imageScaleFactor, height: 1800 * imageScaleFactor }}
          contentFit="cover"
        />
      </Animated.View>
    </View>
  )
}
