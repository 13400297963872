// All of the requested modules are loaded behind enumerable getters.
const map = Object.defineProperties({}, {
  "./(app)/(tabs)/_layout.tsx": { enumerable: true, get() { return require("/home/runner/work/re-moguchat/re-moguchat/apps/frontend/app/(app)/(tabs)/_layout.tsx"); } },
  "./(app)/(tabs)/chats.tsx": { enumerable: true, get() { return require("/home/runner/work/re-moguchat/re-moguchat/apps/frontend/app/(app)/(tabs)/chats.tsx"); } },
  "./(app)/(tabs)/index.tsx": { enumerable: true, get() { return require("/home/runner/work/re-moguchat/re-moguchat/apps/frontend/app/(app)/(tabs)/index.tsx"); } },
  "./(app)/(tabs)/profile.tsx": { enumerable: true, get() { return require("/home/runner/work/re-moguchat/re-moguchat/apps/frontend/app/(app)/(tabs)/profile.tsx"); } },
  "./(app)/_layout.tsx": { enumerable: true, get() { return require("/home/runner/work/re-moguchat/re-moguchat/apps/frontend/app/(app)/_layout.tsx"); } },
  "./(app)/character/[characterId]/edit.tsx": { enumerable: true, get() { return require("/home/runner/work/re-moguchat/re-moguchat/apps/frontend/app/(app)/character/[characterId]/edit.tsx"); } },
  "./(app)/character/[characterId]/index.tsx": { enumerable: true, get() { return require("/home/runner/work/re-moguchat/re-moguchat/apps/frontend/app/(app)/character/[characterId]/index.tsx"); } },
  "./(app)/character/create.tsx": { enumerable: true, get() { return require("/home/runner/work/re-moguchat/re-moguchat/apps/frontend/app/(app)/character/create.tsx"); } },
  "./(app)/character/edit-complete.tsx": { enumerable: true, get() { return require("/home/runner/work/re-moguchat/re-moguchat/apps/frontend/app/(app)/character/edit-complete.tsx"); } },
  "./(app)/character/my.tsx": { enumerable: true, get() { return require("/home/runner/work/re-moguchat/re-moguchat/apps/frontend/app/(app)/character/my.tsx"); } },
  "./(app)/character/search.tsx": { enumerable: true, get() { return require("/home/runner/work/re-moguchat/re-moguchat/apps/frontend/app/(app)/character/search.tsx"); } },
  "./(app)/chat/[chatId]/index.tsx": { enumerable: true, get() { return require("/home/runner/work/re-moguchat/re-moguchat/apps/frontend/app/(app)/chat/[chatId]/index.tsx"); } },
  "./(app)/chat/create.tsx": { enumerable: true, get() { return require("/home/runner/work/re-moguchat/re-moguchat/apps/frontend/app/(app)/chat/create.tsx"); } },
  "./(app)/event/+not-found.tsx": { enumerable: true, get() { return require("/home/runner/work/re-moguchat/re-moguchat/apps/frontend/app/(app)/event/+not-found.tsx"); } },
  "./(app)/event/[eventId]/index.tsx": { enumerable: true, get() { return require("/home/runner/work/re-moguchat/re-moguchat/apps/frontend/app/(app)/event/[eventId]/index.tsx"); } },
  "./(app)/event/[eventId]/ranking.tsx": { enumerable: true, get() { return require("/home/runner/work/re-moguchat/re-moguchat/apps/frontend/app/(app)/event/[eventId]/ranking.tsx"); } },
  "./(app)/payment/callback.tsx": { enumerable: true, get() { return require("/home/runner/work/re-moguchat/re-moguchat/apps/frontend/app/(app)/payment/callback.tsx"); } },
  "./(app)/profile/edit.tsx": { enumerable: true, get() { return require("/home/runner/work/re-moguchat/re-moguchat/apps/frontend/app/(app)/profile/edit.tsx"); } },
  "./(app)/request-featured/index.tsx": { enumerable: true, get() { return require("/home/runner/work/re-moguchat/re-moguchat/apps/frontend/app/(app)/request-featured/index.tsx"); } },
  "./(app)/subscribe/index.tsx": { enumerable: true, get() { return require("/home/runner/work/re-moguchat/re-moguchat/apps/frontend/app/(app)/subscribe/index.tsx"); } },
  "./(app)/support/debug.tsx": { enumerable: true, get() { return require("/home/runner/work/re-moguchat/re-moguchat/apps/frontend/app/(app)/support/debug.tsx"); } },
  "./(app)/support/index.tsx": { enumerable: true, get() { return require("/home/runner/work/re-moguchat/re-moguchat/apps/frontend/app/(app)/support/index.tsx"); } },
  "./(app)/transaction/index.tsx": { enumerable: true, get() { return require("/home/runner/work/re-moguchat/re-moguchat/apps/frontend/app/(app)/transaction/index.tsx"); } },
  "./(app)/user-inbox/index.tsx": { enumerable: true, get() { return require("/home/runner/work/re-moguchat/re-moguchat/apps/frontend/app/(app)/user-inbox/index.tsx"); } },
  "./(legal)/privacy.tsx": { enumerable: true, get() { return require("/home/runner/work/re-moguchat/re-moguchat/apps/frontend/app/(legal)/privacy.tsx"); } },
  "./(legal)/terms.tsx": { enumerable: true, get() { return require("/home/runner/work/re-moguchat/re-moguchat/apps/frontend/app/(legal)/terms.tsx"); } },
  "./+not-found.tsx": { enumerable: true, get() { return require("/home/runner/work/re-moguchat/re-moguchat/apps/frontend/app/+not-found.tsx"); } },
  "./_layout.tsx": { enumerable: true, get() { return require("/home/runner/work/re-moguchat/re-moguchat/apps/frontend/app/_layout.tsx"); } },
  "./login.tsx": { enumerable: true, get() { return require("/home/runner/work/re-moguchat/re-moguchat/apps/frontend/app/login.tsx"); } },
  "./s/[shareId].tsx": { enumerable: true, get() { return require("/home/runner/work/re-moguchat/re-moguchat/apps/frontend/app/s/[shareId].tsx"); } },
});

function metroContext(request) {
    return map[request];
}

// Return the keys that can be resolved.
metroContext.keys = function metroContextKeys() {
  return Object.keys(map);
};

// Return the module identifier for a user request.
metroContext.resolve = function metroContextResolve(request) {
  throw new Error('Unimplemented Metro module context functionality');
}

module.exports = metroContext;