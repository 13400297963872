import { Image } from 'expo-image'
import { View } from 'react-native'
import { formatTimeLocalized } from '../../utils/time'
import MGText from '../common/MGText'

export type CharacterWithoutDetail = {
  id: string
  name: string
  createdAt: Date | string
  description: string
  tags: {
    id: string
    name: string
  }[]
  avatarUrl: string
}

export default function CharacterCard({
  character,
  rightContent,
}: {
  character: CharacterWithoutDetail
  rightContent?: 'last-updated'
}) {
  return (
    <View className="flex flex-row w-full items-center gap-2 rounded-lg px-2 py-3">
      <Image
        recyclingKey={character.id}
        source={character.avatarUrl}
        contentFit="cover"
        placeholderContentFit="cover"
        contentPosition="center"
        style={{
          width: 64,
          height: 64,
          alignSelf: 'center',
          borderRadius: 9999,
        }}
        placeholder={require('../../assets/images/character-avatar-placeholder.webp')}
      />
      <View className="flex flex-col flex-1">
        <View className="flex flex-row justify-between items-center">
          <MGText bold className="text-lg flex-1" lineBreakMode="tail" numberOfLines={1} ellipsizeMode="tail">
            {character.name}
          </MGText>
          {rightContent === 'last-updated' && (
            <View className="flex flex-row items-center gap-1">
              <MGText className="text-sm text-neutral-500">{formatTimeLocalized(character.createdAt)}</MGText>
            </View>
          )}
        </View>
        <MGText numberOfLines={1} className="text text-neutral-500">
          {character.description?.replaceAll('\n', ' ').replaceAll('\r', ' ')}
        </MGText>
        <MGText className="text-sm text-neutral-400 pt-1" numberOfLines={1}>
          {character.tags?.map((tag) => `#${tag.name}`).join(' ')}
        </MGText>
      </View>
    </View>
  )
}
