import { useEffect } from 'react'
import { Alert } from 'react-native'

// THIS SHOULD NEVER BE USED, AS WEB DOES NOT SUPPORT ADMOB

export const useInitAppLovin = (_: { showTrackingModal: () => Promise<void> }) => {
  useEffect(() => {}, [])
}

export const useAppLovinRewarded = (_: {
  onRewardEarned: () => void
  onClosed: () => void
  userId: string
  nonce: string
}) => {
  return {
    isLoaded: false,
    load: () => {
      throw new Error('admob is not supported on web')
    },
    show: () => {
      throw new Error('admob is not supported on web')
    },
  }
}

export const getAdID = () => {
  return '__no_adid_on_web__'
}

export const showAppLovinMAXDebugger = () => {
  Alert.alert('AppLovin MAX Debugger', 'AppLovin MAX Debugger is not available on web')
}
