import { Image } from 'expo-image'
import type { ReactNode } from 'react'
import { View } from 'react-native'
import { twMerge } from 'tailwind-merge'
import { useInventory } from '../../lib/hooks/useInventory'
import MGText from '../common/MGText'

export const Balance = () => {
  const { aggregatedBalance } = useInventory()

  return <BalanceRow onigiri={aggregatedBalance.onigiri} okome={aggregatedBalance.okome} />
}

export const BalanceRow = ({
  onigiri,
  okome,
  className,
}: { onigiri: ReactNode; okome: ReactNode; className?: string }) => {
  return (
    <View className={twMerge('flex flex-row items-center justify-center gap-4', className)}>
      <BalanceItem item="onigiri" value={onigiri} />
      <BalanceItem item="okome" value={okome} />
    </View>
  )
}

const ICON = {
  onigiri: require('../../assets/images/store/onigiri.webp'),
  okome: require('../../assets/images/store/okome.webp'),
} as const

export const BalanceItem = ({ item, value }: { item: 'onigiri' | 'okome'; value: ReactNode }) => {
  return (
    <View className="flex flex-row items-center justify-center gap-1">
      <Image source={ICON[item]} style={{ width: 32, height: 32 }} />
      <MGText bold className="text-primary-400 leading-snug text-xl">
        {value}
      </MGText>
    </View>
  )
}
