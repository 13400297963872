import Ionicons from '@expo/vector-icons/Ionicons'
import clsx from 'clsx'
import type React from 'react'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TouchableOpacity, View } from 'react-native'
import type { UseChatMessagesItem } from '../../app/(app)/chat/[chatId]'
import MGText from '../../components/common/MGText'
import { useCurrentUser } from '../../lib/hooks/useAuth'
import { useFormatRelativeTime } from '../../lib/hooks/useFormatRelativeTime'
import { trpcReact } from '../../lib/services/trpc'
import IconModal from '../common/IconModal'
import { ChatMessageLoading } from './ChatMessageLoading'
import { processMessageContent } from './messageUtils'

const ChatMessageRelativeTime = ({ createdAt, align = 'right' }: { createdAt: string; align?: 'left' | 'right' }) => {
  const formattedTime = useFormatRelativeTime(new Date(createdAt), true)
  return (
    <MGText
      className={clsx('text-xs text-neutral-500', align === 'left' && 'text-left', align === 'right' && 'text-right')}
    >
      {formattedTime}
    </MGText>
  )
}

export const ChatMessage = ({
  isInitialized,
  message,
  onPress,
  onLongPress,
  avatar,
  isMultiselecting,
  isSelected,
  disabled,
  showFirstMessageWarning = false,
}: {
  isInitialized?: boolean
  message: UseChatMessagesItem
  onPress?: () => void
  onLongPress?: () => void
  avatar?: React.ReactNode
  isMultiselecting?: boolean
  isSelected?: boolean
  disabled?: boolean
  showFirstMessageWarning?: boolean
}) => {
  const { t } = useTranslation()

  const contentParts = processMessageContent(message.content)

  const user = useCurrentUser()

  const [inspectMessageId, setInspectMessageId] = useState<string | null>(null)

  const handleInspectMessageModalDismiss = () => {
    setInspectMessageId(null)
  }

  const { data: inspectMessageData } = trpcReact.message.getAdminOnly.useQuery(
    {
      messageId: inspectMessageId || '',
      chatId: message.chatId,
    },
    { enabled: user.data?.isAdmin && !!inspectMessageId, throwOnError: false },
  )

  return (
    <Fragment>
      {user.data?.isAdmin && message.role === 'CHARACTER' && (
        <View className="flex flex-row gap-2 pb-1 pl-10 items-center cursor-default">
          <TouchableOpacity onPress={() => setInspectMessageId(message.id)}>
            <MGText className="text-neutral-300">Inspect Message</MGText>
          </TouchableOpacity>
        </View>
      )}
      <TouchableOpacity
        disabled={disabled || !isMultiselecting}
        onPress={onPress}
        className={clsx(
          'flex flex-row gap-2 py-1 items-center cursor-default select-text',
          isMultiselecting && isSelected && 'bg-neutral-200 -mx-4 px-4',
        )}
      >
        {isMultiselecting && (
          <Ionicons
            name={isSelected ? 'checkmark-circle' : 'ellipse-outline'}
            size={24}
            color={isSelected ? '#e81a89' : '#737373'}
          />
        )}
        <View
          className="flex flex-row gap-2 flex-1"
          style={{
            flexDirection: message.role === 'USER' ? 'row-reverse' : 'row',
            opacity: isInitialized ? 1 : 0,
          }}
        >
          {message.role === 'CHARACTER' && avatar}
          <View
            className="flex-1 flex flex-row gap-2"
            style={{
              flexDirection: message.role === 'USER' ? 'row-reverse' : 'row',
              opacity: isInitialized ? 1 : 0,
            }}
          >
            <TouchableOpacity
              disabled={disabled}
              onPress={onPress}
              onLongPress={onLongPress}
              activeOpacity={0.8}
              style={{
                maxWidth: '80%',
              }}
            >
              <View
                className={clsx(
                  'p-3 select-text cursor-text',
                  message.role === 'CHARACTER'
                    ? 'bg-neutral-100 self-start rounded-r-2xl rounded-bl-2xl'
                    : 'bg-primary-300 dark:bg-primary-600/40 self-end rounded-l-2xl rounded-br-2xl',
                )}
                accessibilityRole="text"
              >
                {message.clientAdded === 'loading' ? (
                  <ChatMessageLoading />
                ) : (
                  contentParts.map((part, index) => (
                    <MGText
                      key={`${part.type}-${part.content}-${index}`}
                      className={clsx('text-base', part.type === 'thought' && 'text-sm text-neutral-500 -skew-x-12')}
                      accessibilityRole="text"
                      accessibilityLabel={part.content}
                    >
                      {part.content}
                    </MGText>
                  ))
                )}
              </View>
            </TouchableOpacity>

            <View className="flex flex-1 py-1 self-end">
              <ChatMessageRelativeTime
                createdAt={message.createdAt}
                align={message.role === 'USER' ? 'right' : 'left'}
              />
            </View>
          </View>
        </View>
      </TouchableOpacity>

      {showFirstMessageWarning && (
        <View className="bg-primary-50 dark:bg-primary-600/5 border border-primary-200 dark:border-primary-600/20 w-full p-4 rounded-3xl text-center justify-center items-center my-4 flex gap-1">
          <View className="flex flex-row gap-0.5 items-center">
            <Ionicons name="alert-circle-outline" size={24} color="#fe68c4" />
            <MGText className="text-primary-400 text-center">{t('chat.ai-warning.title')}</MGText>
          </View>

          <MGText className="text-neutral-500 text-center text-xs">{t('chat.ai-warning')}</MGText>
        </View>
      )}

      {user.data?.isAdmin && (
        <IconModal
          visible={!!inspectMessageId}
          severity="info"
          icon="analytics"
          title={'Inspect Message'}
          description={JSON.stringify(inspectMessageData, null, 2) || 'Loading...'}
          descriptionType="plain"
          descriptionRequireScrollView
          primaryButtonContent={'Close'}
          hideCancelButton
          onCancel={() => {}}
          onConfirm={handleInspectMessageModalDismiss}
        />
      )}
    </Fragment>
  )
}
