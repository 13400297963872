import { Redirect, useLocalSearchParams } from 'expo-router'
import { useEffect } from 'react'
import { match, P } from 'ts-pattern'
import { TypeID } from 'typeid-js'
import { MGLoading } from '../../components/common/MGLoading'
import { ampli } from '../../lib/ampli'
import { trpcReact } from '../../lib/services/trpc'

export default function ShareLandingPage(): JSX.Element {
  const { shareId } = useLocalSearchParams<{ shareId: string }>()
  const { data: share, isLoading } = trpcReact.share.get.useQuery({ shareId })

  useEffect(() => {
    if (share?.shareId) {
      ampli.shareLinkClicked({
        share_id: share.shareId,
        subject_id: share.subjectId,
        author_id: share.authorId,
      })
    }
  }, [share?.shareId])

  return match<[typeof share, boolean], JSX.Element>([share, isLoading] as const)
    .with([P._, true], () => {
      return <MGLoading show />
    })
    .with([P.nullish, false], () => {
      return <Redirect href="/" />
    })
    .with([P.not(P.nullish), false], ([share]) => {
      const typeId = TypeID.fromString(share.subjectId) // TODO: use @troph-team/moguchat-registry. CI/CD of monorepo is really hard

      return match(typeId.getType())
        .with('character', () => {
          return <Redirect href={`/character/${share.subjectId}`} />
        })
        .otherwise(() => {
          return <Redirect href="/" />
        })
    })
    .exhaustive()
}
