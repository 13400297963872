import Ionicons from '@expo/vector-icons/Ionicons'
import { useMutation } from '@tanstack/react-query'
import * as Burnt from 'burnt'
import clsx from 'clsx'
import { Image } from 'expo-image'
import { Link, useLocalSearchParams } from 'expo-router'
import * as StoreReview from 'expo-store-review'
import { useSetAtom } from 'jotai'
import type React from 'react'
import { type FC, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FlatList,
  Platform,
  TextInput,
  TouchableHighlight,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native'
import Animated, {
  Easing,
  runOnJS,
  runOnUI,
  useDerivedValue,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated'
import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context'
import Svg, { Path } from 'react-native-svg'
import { AdsBanner } from '../../../../components/chat/AdsBanner'
import ChatList from '../../../../components/chat/ChatList'
import { ChatMessage } from '../../../../components/chat/ChatMessage'
import { ChatScreenshot } from '../../../../components/chat/ChatScreenshot'
import ChatSharingView from '../../../../components/chat/ChatSharingView'
import { MGCustomizedKeyboardAvoidingView } from '../../../../components/common/MGCustomizedKeyboardAvoidingView'
import MGHeader from '../../../../components/common/MGHeader'
import MGHeaderText from '../../../../components/common/MGHeaderText'
import { MGIonicons } from '../../../../components/common/MGIonicons'
import MGLoadingOverlay from '../../../../components/common/MGLoadingOverlay'
import { MGMessageDialog } from '../../../../components/common/MGMessageDialog'
import MGText from '../../../../components/common/MGText'
import { OutOfBalanceModal } from '../../../../components/monetization/OutOfBalanceModal'
import { MGActivityIndicator } from '../../../../components/ui/MGActivityIndicator'
import { regularFontFamily } from '../../../../constants/Font'
import { invalidateInventory } from '../../../../lib/hooks/useInventory'
import i18n from '../../../../lib/i18n'
import { registerAndSyncPushNotificationToken } from '../../../../lib/notification'
import { purchaseSheetStateAtom } from '../../../../lib/purchaseSheet'
import { type RouterError, type RouterOutputs, trpcClient, trpcReact } from '../../../../lib/services/trpc'
import {
  getIsNotFreeUser,
  getStoreReview20MessagesRequested,
  getStoreReview3MessagesPostPurchaseRequested,
  setStoreReview20MessagesRequested,
  setStoreReview3MessagesPostPurchaseRequested,
} from '../../../../lib/storageDeviceBound'
import { useTheme } from '../../../../lib/theme'

const generateNonce = () => Math.floor(Math.random() * 2147483647) // Max 32-bit signed integer

export type UseChatMessagesItem = RouterOutputs['message']['list']['items'][number] & {
  clientAdded?: 'optimistic' | 'loading'
}

// Add this near the top of the file, outside of any component
const KEYBOARD_ANIMATION_DURATION = Platform.select({
  ios: 250, // iOS keyboard animation is typically around 250ms
  android: 300, // Android is slightly slower
  default: 300,
})

const SMART_REPLY_CLOSE_DURATION = Platform.select({
  ios: 200,
  android: 200,
  default: 200,
})

// Add these constants at the top with the other animation constants
const IOS_EASING = Easing.bezier(0.17, 0.59, 0.4, 0.77)

function useChat(
  chatId: string,
  onBalanceInsufficient: () => void,
  updateMessageInput: React.Dispatch<React.SetStateAction<string>>,
) {
  const { use_new_generation_flow } = useLocalSearchParams<{ use_new_generation_flow: string }>()
  const useNewGenerationFlow = use_new_generation_flow === 'true'

  const { t } = useTranslation()
  const [successfulMessageCounter, setSuccessfulMessageCounter] = useState(0)

  const [optimisticMessage, setOptimisticMessage] = useState<UseChatMessagesItem | null>(null)

  const [loadingMessage, setLoadingMessage] = useState<UseChatMessagesItem | null>(null)

  const { data: chat, isPending: isChatPending } = trpcReact.chat.get.useQuery({ id: chatId })

  const {
    data: messagesData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = trpcReact.message.list.useInfiniteQuery(
    {
      chatId: chatId,
      limit: 20,
    },
    {
      getNextPageParam: (lastPage) => lastPage.pagination.nextCursor,
    },
  )
  const utils = trpcReact.useUtils()

  const { mutateAsync: sendMessageMutation, isPending: isSending } = useMutation<
    RouterOutputs['message']['create'],
    RouterError,
    { message: string; nonce: number }
  >({
    mutationFn: async ({
      message,
      nonce,
    }: {
      message: string
      nonce: number
    }) => {
      setLoadingMessage({
        id: `loading-${nonce}`,
        content: '...',
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        role: 'CHARACTER',
        chatId: chatId.toString(),
        editedAt: null,
        modelId: null,
        nonce: null,
        deletedAt: null,
        messageSmartReply: null,
      })
      return trpcClient.message.create.mutate({
        chatId: chatId,
        messageContent: message,
        nonce,
        locale: i18n.language,
        featureFlags: {
          useNewGenerationFlow,
        },
      })
    },
    onMutate: async ({ message, nonce }) => {
      // A temporary fix as the optimistic message is not being updated in the cache
      setOptimisticMessage({
        id: `temp-${nonce}`,
        content: message,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        role: 'USER',
        chatId: chatId.toString(),
        editedAt: null,
        modelId: null,
        nonce: nonce,
        deletedAt: null,
        messageSmartReply: null,
      })

      if (successfulMessageCounter > 3) {
        const isNotFreeUser = getIsNotFreeUser()
        const storeReview3MessagesPostPurchaseRequested = getStoreReview3MessagesPostPurchaseRequested()
        if (isNotFreeUser && !storeReview3MessagesPostPurchaseRequested) {
          if (await StoreReview.hasAction()) {
            setStoreReview3MessagesPostPurchaseRequested(true)
            StoreReview.requestReview()
          }
        }
      }

      if (successfulMessageCounter > 20) {
        const storeReview20MessagesRequested = getStoreReview20MessagesRequested()
        if (!storeReview20MessagesRequested) {
          if (await StoreReview.hasAction()) {
            setStoreReview20MessagesRequested(true)
            StoreReview.requestReview()
          }
        }
      }
    },
    onError: (err, variables, context) => {
      if (err.data?.code === 'INVENTORY::INSUFFICIENT_BALANCE') {
        onBalanceInsufficient()
      } else {
        Burnt.toast({
          message: err.message,
          title: t('chat.message.action.send.failed'),
          preset: 'error',
        })
      }
      updateMessageInput(variables.message)
      setOptimisticMessage(null)
    },
    onSettled: async () => {
      invalidateInventory()
      setSuccessfulMessageCounter((prev) => prev + 1)
      setLoadingMessage(null)

      // Instead of invalidating the entire list, fetch only the latest messages
      // and update the cache directly
      try {
        // Fetch the latest messages (first page without cursor)
        const latestMessages = await trpcClient.message.list.query({
          chatId: chatId,
          limit: 20,
        })
        // Update the message list cache using tRPC utils instead of directly manipulating the query cache
        utils.message.list.setInfiniteData({ chatId, limit: 20 }, (oldData) => {
          if (!oldData) return { pages: [latestMessages], pageParams: [null] }

          // Create a set of existing message IDs for quick lookup
          const existingMessageIds = new Set(oldData.pages.flatMap((page) => page.items.map((item) => item.id)))

          // Filter out any temporary optimistic messages and find new messages
          const newMessages = latestMessages.items.filter((item) => !existingMessageIds.has(item.id))

          if (newMessages.length === 0) {
            // No new messages, just return the existing data
            return oldData
          }

          // Clone the pages array to avoid mutating the original
          const newPages = [...oldData.pages]

          // Merge new messages with the first page
          if (newPages.length > 0) {
            // Create a new first page with the new messages added to the beginning
            newPages[0] = {
              ...newPages[0],
              items: [...newMessages, ...newPages[0].items],
            }
          } else {
            // If there are no pages yet, create a new one
            newPages.push(latestMessages)
          }

          return {
            ...oldData,
            pages: newPages,
          }
        })

        // Update chat data without full invalidation
        utils.chat.get.setData({ id: chatId }, (oldData) => {
          if (!oldData) return oldData
          return {
            ...oldData,
            updatedAt: new Date().toISOString(),
            lastMessage: latestMessages.items[0], // First item is the most recent
          }
        })

        // Update chat list without full invalidation - using setInfiniteData for infinite queries
        utils.chat.list.setInfiniteData({ limit: 20 }, (oldData) => {
          if (!oldData) return oldData

          // Clone the pages array to avoid mutating the original
          const newPages = [...oldData.pages]

          // Update the chat in all pages
          const updatedPages = newPages.map((page) => {
            // Find and update the chat in the current page
            const updatedItems = page.items.map((chat) => {
              if (chat.id === chatId) {
                return {
                  ...chat,
                  updatedAt: new Date().toISOString(),
                  lastMessage: latestMessages.items[0], // First item is the most recent
                }
              }
              return chat
            })

            return {
              ...page,
              items: updatedItems,
            }
          })

          return {
            ...oldData,
            pages: updatedPages,
          }
        })
      } catch (error) {
        // If the optimized approach fails, fall back to invalidation
        console.error('Failed to update message cache:', error)
        utils.message.list.invalidate({ chatId })
        utils.chat.get.invalidate({ id: chatId })
        utils.chat.list.invalidate()
      }

      // Clear optimistic message
      setOptimisticMessage(null)
    },
  })

  const messages = useMemo(() => {
    const items: UseChatMessagesItem[] = messagesData?.pages?.flatMap((page) => page.items) ?? []
    if (optimisticMessage && !items.find((m) => m.nonce === optimisticMessage.nonce)) {
      items.unshift({ ...optimisticMessage, clientAdded: 'optimistic' })
    }
    if (loadingMessage) {
      items.unshift({ ...loadingMessage, clientAdded: 'loading' })
    }
    return items
  }, [messagesData, optimisticMessage, loadingMessage])

  const sendMessage = useCallback(
    async ({ message, nonce }: { message: string; nonce: number }) => {
      const promise = sendMessageMutation({ message, nonce })
      await promise

      await registerAndSyncPushNotificationToken()

      return promise
    },
    [sendMessageMutation],
  )

  return {
    chat,
    isChatPending,
    messages,
    sendMessage,
    isSending,
    loadMore: () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage()
      }
    },
    hasNextPage,
    isFetchingNextPage,
    optimisticMessage,
  }
}

const ChatDetailScreen: FC = () => {
  const { chatId } = useLocalSearchParams()
  const { t } = useTranslation()

  const [messageInput, setMessageInput] = useState('')
  const [dialogSelectedMessage, setDialogSelectedMessage] = useState<UseChatMessagesItem | null>(null)
  const [isSelectedMessageDialogOpen, setIsSelectedMessageDialogOpen] = useState(false)
  const messageFlatListRef = useRef<FlatList>(null)
  const textInputRef = useRef<TextInput>(null)
  const [isMultiselecting, setIsMultiselecting] = useState(false)
  const [selectedMessages, setSelectedMessages] = useState<UseChatMessagesItem[]>([])
  const [isRenderShareView, setIsRenderShareView] = useState(false)
  const [isShowingAdModal, setIsShowingAdModal] = useState(false)
  const smartReplyOpenedWithHeight = useSharedValue(0)
  const [smartReplyOpenedWithHeightPrimitiveValue, setSmartReplyOpenedWithHeightPrimitiveValue] = useState(0)
  const smartReplyMeasuredHeight = useSharedValue(0)
  const [isSmartReplyVisible, setIsSmartReplyVisible] = useState(false)
  const setPurchaseSheetState = useSetAtom(purchaseSheetStateAtom)

  const inset = useSafeAreaInsets()

  useEffect(() => {
    if (smartReplyOpenedWithHeight.value > 0) {
      textInputRef.current?.blur()
    }
  }, [smartReplyOpenedWithHeight])

  // Create a derived value that updates the React state
  useDerivedValue(() => {
    const isOpen = smartReplyOpenedWithHeight.value > 0
    runOnJS(setIsSmartReplyVisible)(isOpen)
  }, [])

  const { chat, messages, sendMessage, loadMore, isChatPending, hasNextPage, isSending } = useChat(
    chatId.toString(),
    () => {
      if (Platform.OS === 'web') {
        setPurchaseSheetState({
          opened: true,
          selected: 'subscribe',
          reason: 'purchase.subscription.reason.insufficient-balance',
        })
      } else {
        setIsShowingAdModal(true)
      }
    },
    setMessageInput,
  )

  const character = chat?.character

  // Extract smart reply items from messages
  const smartReplyItems = useMemo(() => {
    return messages[0]?.messageSmartReply?.items ?? []
  }, [messages])

  // Scroll to top when messages change
  useEffect(() => {
    if (messages.length > 0) {
      messageFlatListRef.current?.scrollToOffset({ offset: 0, animated: true })
    }
  }, [messages])

  // Clear input when chat ID changes
  useEffect(() => {
    if (chatId) {
      setMessageInput('')
    }
  }, [chatId])

  const showMessageMenu = (message: UseChatMessagesItem) => {
    if (isMultiselecting) return
    setDialogSelectedMessage(message)
    setIsSelectedMessageDialogOpen(true)
  }

  const onMessagePress = (message: UseChatMessagesItem) => {
    if (!isMultiselecting) return

    if (selectedMessages.find((m) => m.id === message.id)) {
      setSelectedMessages(selectedMessages.filter((m) => m.id !== message.id))
    } else {
      setSelectedMessages([...selectedMessages, message])
    }
  }

  const handleSend = () => {
    if (!messageInput.trim()) return
    const nonce = generateNonce()
    sendMessage({ message: messageInput.trim(), nonce })
    setMessageInput('')
  }

  // Create a worklet function for handling smart reply toggle
  const toggleSmartReply = useCallback(() => {
    'worklet'
    if (smartReplyOpenedWithHeight.value === 0) {
      smartReplyOpenedWithHeight.value = withTiming(smartReplyMeasuredHeight.value, {
        duration: KEYBOARD_ANIMATION_DURATION,
        easing: IOS_EASING,
      })
      setSmartReplyOpenedWithHeightPrimitiveValue(smartReplyMeasuredHeight.value)
    } else {
      smartReplyOpenedWithHeight.value = withTiming(0, {
        duration: SMART_REPLY_CLOSE_DURATION,
        easing: IOS_EASING,
      })
      setSmartReplyOpenedWithHeightPrimitiveValue(0)
    }
  }, [])

  // Create a worklet function for closing smart reply
  const closeSmartReply = useCallback(() => {
    'worklet'
    if (smartReplyOpenedWithHeight.value > 0) {
      smartReplyOpenedWithHeight.value = withTiming(0, {
        duration: SMART_REPLY_CLOSE_DURATION,
        easing: IOS_EASING,
      })
      setSmartReplyOpenedWithHeightPrimitiveValue(0)
    }
  }, [])

  const handleSendSmartReply = useCallback(
    (message: string) => {
      const nonce = generateNonce()
      sendMessage({ message, nonce })
      closeSmartReply()
    },
    [sendMessage, closeSmartReply],
  )

  const avatar = character?.avatarUrl ? (
    <Image
      className="shrink-0"
      source={{ uri: character.avatarUrl }}
      style={{ width: 32, height: 32, borderRadius: 9999 }}
    />
  ) : (
    <Image
      className="shrink-0"
      source={require('../../../../assets/images/character-avatar-placeholder.webp')}
      style={{ width: 32, height: 32, borderRadius: 9999 }}
      onDisplay={() => {
        console.log('onDisplay placeholder')
      }}
    />
  )

  const SendButton: FC = () => (
    <TouchableOpacity onPress={handleSend} disabled={!messageInput.trim() || isSending} className="p-1" hitSlop={6}>
      {isSending ? (
        <MGActivityIndicator size={24} className="opacity-50" />
      ) : (
        <MGIonicons
          name="send"
          size={24}
          color={messageInput.trim() ? '#ffade1' : undefined}
          className={clsx(!messageInput.trim() && 'opacity-20')}
        />
      )}
    </TouchableOpacity>
  )
  SendButton.displayName = 'SendButton'

  // render on ipad
  const renderChatList = useWindowDimensions().width > 768

  return (
    <View className="flex-1 bg-background relative">
      <SafeAreaView className="flex-1 relative">
        <AdsBanner />

        <MGHeader
          headerBody={
            character ? (
              <Link href={`/character/${character.id}?from=chat`} className="flex items-center shrink">
                <View className="flex-row items-center gap-2">
                  {avatar}
                  <MGHeaderText numberOfLines={1} lineBreakMode="tail" className="shrink">
                    {character.name}
                  </MGHeaderText>
                </View>
              </Link>
            ) : isChatPending ? (
              <View>
                <Image
                  source={require('../../../../assets/images/status/loading.png')}
                  style={{ width: 32, height: 32 }}
                  autoplay={true}
                />
              </View>
            ) : (
              <View className="flex-row items-center gap-2">
                <Image
                  source={require('../../../../assets/images/character-avatar-placeholder.webp')}
                  style={{ width: 32, height: 32 }}
                  autoplay={true}
                />
                <MGHeaderText numberOfLines={1}>{t('character.deleted-name')}</MGHeaderText>
              </View>
            )
          }
        />

        <MGCustomizedKeyboardAvoidingView
          className="flex-1 flex-row"
          behavior="padding"
          smartReplyOpenedWithHeight={smartReplyOpenedWithHeight}
          smartReplyOpenedWithHeightPrimitiveValue={smartReplyOpenedWithHeightPrimitiveValue}
        >
          {renderChatList && (
            <View className="h-full max-w-96 shrink-0">
              <ChatList selectedChatId={chatId.toString()} />
            </View>
          )}
          <View className="flex flex-1 relative">
            <ChatScreenshot character={character}>
              <FlatList
                ref={messageFlatListRef}
                className="flex-1 px-4"
                data={messages}
                inverted
                renderItem={({ item }) => (
                  <ChatMessage
                    isInitialized
                    message={item}
                    onPress={isMultiselecting ? () => onMessagePress(item) : undefined}
                    onLongPress={() => showMessageMenu(item)}
                    isMultiselecting={isMultiselecting}
                    isSelected={selectedMessages.find((m) => m.id === item.id) !== undefined}
                    avatar={avatar}
                    showFirstMessageWarning={
                      !hasNextPage && messages.length > 0 && messages[messages.length - 1].id === item.id
                    }
                  />
                )}
                onStartReached={loadMore}
                onStartReachedThreshold={0.5}
                keyboardDismissMode="interactive"
                keyboardShouldPersistTaps="never"
                onScrollBeginDrag={closeSmartReply}
                onTouchStart={closeSmartReply}
              />
            </ChatScreenshot>
            {isMultiselecting ? (
              <View className="px-4 pt-2 flex flex-row gap-4 items-center bg-background">
                <TouchableHighlight
                  className="rounded-full flex-1"
                  disabled={selectedMessages.length === 0}
                  onPress={() => setIsRenderShareView(true)}
                  underlayColor="#000000"
                  activeOpacity={0.5}
                >
                  <View className="rounded-full px-4 py-3 flex flex-row items-center justify-center gap-1 bg-primary-500">
                    <Ionicons name="share-outline" size={24} color="#ffffff" />
                    <MGText className="text-white">{t('chat.message.action.capture')}</MGText>
                  </View>
                </TouchableHighlight>
              </View>
            ) : (
              // Hide when chat is loaded and character is undefined (i.e. deleted)
              (isChatPending || character) && (
                <>
                  <View className="px-4 flex flex-row gap-2 items-center bg-background pb-2 pt-2">
                    <TextInput
                      ref={textInputRef}
                      multiline
                      className="flex-1 text-base border border-neutral-200 rounded-2xl px-4 pt-2.5 pb-3 max-h-[80px] focus-within:outline-primary-400 text-foreground"
                      style={{
                        textAlignVertical: 'center',
                        fontFamily: regularFontFamily,
                      }}
                      value={messageInput}
                      onChangeText={setMessageInput}
                      onSubmitEditing={handleSend}
                      onFocus={closeSmartReply}
                    />
                    {smartReplyItems.length > 0 && (
                      <TouchableOpacity
                        onPress={() => {
                          if (smartReplyOpenedWithHeight.value === 0) {
                            // First start the animation, then blur the keyboard
                            toggleSmartReply()
                            textInputRef.current?.blur()
                          } else {
                            toggleSmartReply()
                          }
                        }}
                        className="p-1"
                        hitSlop={6}
                      >
                        <MGIonicons
                          name="sparkles-outline"
                          size={24}
                          color={isSmartReplyVisible ? '#ffade1' : undefined}
                        />
                      </TouchableOpacity>
                    )}
                    <SendButton />
                  </View>

                  {/* Animated container for showing the actual smart replies */}
                  <View className="w-full relative">
                    <Animated.View
                      className="px-4 flex flex-col gap-2 items-center overflow-hidden absolute top-0 left-0 right-0"
                      style={{
                        height:
                          Platform.OS === 'android'
                            ? smartReplyOpenedWithHeightPrimitiveValue
                            : smartReplyOpenedWithHeight,
                      }}
                    >
                      <View className="w-full flex flex-col gap-2 items-center pt-2">
                        {smartReplyItems.map((item) => (
                          <SmartReplyItem
                            key={item}
                            item={item}
                            onEditClick={() => {
                              setMessageInput(item)
                              smartReplyOpenedWithHeight.value = 0
                              textInputRef.current?.focus()
                            }}
                            onPress={() => {
                              handleSendSmartReply(item)
                            }}
                          />
                        ))}
                      </View>
                    </Animated.View>
                  </View>

                  <MeasureSmartReplyItems
                    items={smartReplyItems}
                    onMeasure={(height) => {
                      runOnUI(() => {
                        'worklet'
                        smartReplyMeasuredHeight.value = height
                      })()
                    }}
                  />
                </>
              )
            )}
          </View>
        </MGCustomizedKeyboardAvoidingView>
      </SafeAreaView>
      <View className="absolute bottom-0 left-0 right-0 top-0 pointer-events-none">
        <MGMessageDialog
          key={dialogSelectedMessage?.id}
          message={dialogSelectedMessage}
          avatar={avatar}
          isOpen={isSelectedMessageDialogOpen}
          onStartMultiselect={() => {
            if (!dialogSelectedMessage) return
            setIsMultiselecting(true)
            setSelectedMessages([dialogSelectedMessage])
          }}
          setIsOpen={setIsSelectedMessageDialogOpen}
          chatId={chatId.toString()}
          isFirstMessage={
            !hasNextPage && messages.length > 0 && messages[messages.length - 1].id === dialogSelectedMessage?.id
          }
        />
        <OutOfBalanceModal isOpen={isShowingAdModal} onClose={() => setIsShowingAdModal(false)} />
      </View>
      {isRenderShareView && (
        <Fragment>
          <View
            className="absolute bg-background"
            style={{
              top: inset.top,
              left: inset.left,
              right: inset.right,
              bottom: inset.bottom,
              zIndex: -1000,
            }}
          >
            <ChatSharingView
              avatar={avatar}
              isSharing={isRenderShareView}
              onSharingEnd={() => {
                setIsRenderShareView(false)
                setSelectedMessages([])
                setIsMultiselecting(false)
              }}
              messages={selectedMessages}
              name={character?.name ?? t('character.deleted-name')}
            />
          </View>
          <MGLoadingOverlay visible={isRenderShareView} />
        </Fragment>
      )}
    </View>
  )
}

ChatDetailScreen.displayName = 'ChatDetailScreen'

const MeasureSmartReplyItems: FC<{
  items: string[]
  onMeasure: (height: number) => void
}> = ({ items, onMeasure }) => {
  return (
    <View className="absolute top-0 left-0 right-0 -z-[1000] opacity-0 w-screen">
      <View
        className="w-full flex flex-col gap-2 items-center pt-2"
        onLayout={({ nativeEvent }) => {
          onMeasure(nativeEvent.layout.height)
        }}
      >
        {items.map((item) => (
          <SmartReplyItem key={item} item={item} />
        ))}
      </View>
    </View>
  )
}

const SmartReplyItem = ({
  item,
  onEditClick,
  onPress,
}: { item: string; onEditClick?: () => void; onPress?: () => void }) => {
  const theme = useTheme()
  return (
    <View className="w-full relative flex flex-col">
      <TouchableOpacity
        className="pl-4 pr-10 border border-primary-500/20 rounded-[1.125rem] w-full flex flex-row gap-2 items-center overflow-hidden"
        key={item}
        hitSlop={6}
        onPress={onPress}
      >
        <MGText className="text-primary-500 dark:text-primary-400 flex-1 w-full py-2" numberOfLines={5}>
          {item}
        </MGText>
      </TouchableOpacity>

      <TouchableHighlight
        onPress={onEditClick}
        hitSlop={8}
        className="w-9 flex items-stretch justify-stretch self-stretch absolute right-0 top-0 bottom-0"
        underlayColor={theme.background}
        activeOpacity={0.5}
      >
        {/* pr-0.5: visual compensation */}
        <Animated.View className="bg-primary-600/50 py-0.5 pr-0.5 w-full h-full flex items-center justify-center rounded-r-[1.125rem]">
          {/*
            Reason we are not using a Ionicons is that react-native-animated conflicts with the transform property and causes the icon not rotated occasionally.
            This is a modified version of the arrow-up icon from Ionicons.
          */}
          <Svg fill="none" viewBox="0 0 512 512" style={{ width: 20, height: 20 }}>
            <Path
              d="M145.692 349.338V145.691h203.646m-189.504 14.142 206.475 206.476"
              stroke="#ffffff"
              strokeWidth="48"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </Svg>
        </Animated.View>
      </TouchableHighlight>
    </View>
  )
}

export default ChatDetailScreen
