import { router, useLocalSearchParams } from 'expo-router'
import { type FC, Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { z } from 'zod'
import { MGIonicons } from '../../../components/common/MGIonicons'
import { MGModalButton } from '../../../components/common/MGModalButton'
import MGText from '../../../components/common/MGText'
import { MGActivityIndicator } from '../../../components/ui/MGActivityIndicator'
import { trpcClient } from '../../../lib/services/trpc'
import { formatZodError } from '../../../lib/zod'

type PollPaymentCallbackState =
  | {
      status: 'pending'
    }
  | {
      status: 'success'
    }
  | {
      status: 'error'
      error: string
    }

const paymentCallbackSearchParamsSchema = z.object({
  mode: z.enum(['one-time', 'subscription']),
  platform: z.enum(['STRIPE']),
  sessionId: z.string(),
})

const usePollOrder = () => {
  const searchParams = useLocalSearchParams()
  const [state, setState] = useState<PollPaymentCallbackState>({ status: 'pending' })

  const poll = async () => {
    const { success, error, data } = paymentCallbackSearchParamsSchema.safeParse(searchParams)
    if (!success) {
      setState({ status: 'error', error: `Invalid search params: ${formatZodError(error)}` })
      return
    }

    for (let i = 0; i < 10; i++) {
      try {
        if (data.mode === 'one-time') {
          const result = await trpcClient.store.order.get.query({
            platform: 'STRIPE',
            checkoutSessionId: data.sessionId,
          })

          if (result?.fulfilled) {
            setState({ status: 'success' })
            return
          }
        } else if (data.mode === 'subscription') {
          const result = await trpcClient.user.getViewerActiveSubscription.query()
          if (result?.privilegeId === 'privilege_mogupass') {
            setState({ status: 'success' })
            return
          }
        }

        console.info('Order not fulfilled, retrying...')
        await new Promise((resolve) => setTimeout(resolve, 1000))
      } catch (error) {
        console.info('Payment callback failed, retrying...')
        await new Promise((resolve) => setTimeout(resolve, 1000))
      }
    }

    setState({ status: 'error', error: 'Payment callback failed' })
  }

  useEffect(() => {
    poll()
  }, [JSON.stringify(searchParams)])

  return state
}

const PaymentCallback: FC = () => {
  const { t } = useTranslation()
  const state = usePollOrder()

  return (
    <SafeAreaView className="flex-1 bg-background max-w-3xl mx-auto w-full">
      <View className="flex-1 p-6 flex-col items-center justify-center">
        <View className="flex-row items-start gap-4">
          {state.status === 'pending' && (
            <Fragment>
              <MGActivityIndicator size={28} />
              <View>
                <MGText className="text-xl font-bold mb-2 leading-[28px]">
                  {t('purchase.payment-callback.pending.title')}
                </MGText>
                <MGText className="text-neutral-500">{t('purchase.payment-callback.pending.description')}</MGText>
              </View>
            </Fragment>
          )}

          {state.status === 'success' && (
            <Fragment>
              <MGIonicons name="checkmark-circle" size={28} color="#22c55e" />
              <View>
                <MGText className="text-xl font-bold mb-2 leading-[28px]">
                  {t('purchase.payment-callback.success.title')}
                </MGText>
                <MGText className="text-neutral-500 mb-6">{t('purchase.payment-callback.success.description')}</MGText>
                <MGModalButton
                  foregroundAppearance="light"
                  onPress={() => router.replace('/chats')}
                  className="bg-primary-500"
                >
                  {t('purchase.payment-callback.success.go-to-chat')}
                </MGModalButton>
              </View>
            </Fragment>
          )}

          {state.status === 'error' && (
            <Fragment>
              <MGIonicons name="close-circle" size={28} color="#ef4444" />
              <View className="flex-1">
                <MGText className="text-xl font-bold mb-2 leading-[28px]">
                  {t('purchase.payment-callback.failed-to-verify.title')}
                </MGText>
                <MGText className="text-neutral-500 mb-4">
                  {t('purchase.payment-callback.failed-to-verify.description', { error: state.error })}
                </MGText>
                <View className="bg-neutral-100 p-4 rounded-lg mb-6">
                  <View className="flex-row items-center gap-2 mb-2">
                    <MGIonicons name="help-circle" size={20} className="opacity-70" />
                    <MGText className="font-bold">
                      {t('purchase.payment-callback.failed-to-verify.already-completed-the-payment.title')}
                    </MGText>
                  </View>
                  <MGText className="text-neutral-600">
                    {t('purchase.payment-callback.failed-to-verify.already-completed-the-payment.description')}
                  </MGText>
                </View>
                <View className="flex-row gap-3">
                  <MGModalButton
                    onPress={() => router.replace('/support')}
                    className="flex-1 bg-primary-500"
                    foregroundAppearance="light"
                  >
                    {t('purchase.payment-callback.contact-support')}
                  </MGModalButton>
                  <MGModalButton
                    onPress={() => {
                      if (window !== undefined) {
                        window.location.reload()
                      }
                    }}
                    className="flex-1 bg-neutral-200"
                  >
                    {t('purchase.payment-callback.refresh')}
                  </MGModalButton>
                </View>
              </View>
            </Fragment>
          )}
        </View>
      </View>
    </SafeAreaView>
  )
}

export default PaymentCallback
