import { useController, type FieldValues } from 'react-hook-form'
import { View } from 'react-native'
import type { EditorFieldProps } from '../common/EditorProps'
import { MGHelpDialog } from '../common/MGHelpDialog'
import MGText from '../common/MGText'
import { MGTextInput } from '../common/MGTextInput'

interface CharacterDetailTextareaProps<T extends FieldValues> extends EditorFieldProps<T, string> {
  label: string
  help: {
    title: string
    content: string
  }
  className?: string
  placeholder: string
}

export const CharacterDetailTextarea = <T extends FieldValues>({
  label,
  help: { title, content },
  placeholder,
  control,
  name,
  className,
}: CharacterDetailTextareaProps<T>) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
  })

  return (
    <View>
      <View className="flex flex-row items-start gap-1">
        <MGText className="text-red-500 text-lg">*</MGText>
        <View className="flex flex-row items-center gap-1">
          <MGText bold className="text-foreground mb-1 text-lg">
            {label}
          </MGText>
          <MGHelpDialog title={title} content={content} />
        </View>
      </View>

      <MGTextInput
        className={className}
        value={value}
        onChangeText={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        multiline={true}
        style={{
          minHeight: 120,
          maxHeight: 120,
        }}
        ref={ref}
      />
      {error && <MGText className="text-sm text-red-500 mt-1">{error.message}</MGText>}
    </View>
  )
}
