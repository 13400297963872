import Ionicons from '@expo/vector-icons/Ionicons'
import { Image } from 'expo-image'
import { useSetAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { TouchableOpacity, View } from 'react-native'
import { useActiveSubscription } from '../../lib/hooks/useActiveSubscription'
import { useInventory } from '../../lib/hooks/useInventory'
import { purchaseSheetStateAtom } from '../../lib/purchaseSheet'
import { MGGradientBackground } from '../common/MGGradientBackground'
import { MGIonicons } from '../common/MGIonicons'
import MGText from '../common/MGText'
import { MoguPassSVG } from './MoguPassSVG'

export const PurchaseRows = () => {
  const { t } = useTranslation()
  const setPurchaseSheetState = useSetAtom(purchaseSheetStateAtom)
  const { aggregatedBalance } = useInventory()
  const { data: subscription } = useActiveSubscription()

  return (
    <View className="rounded-3xl overflow-hidden mx-4">
      {!subscription && (
        <TouchableOpacity onPress={() => setPurchaseSheetState({ opened: true, selected: 'subscribe' })}>
          <MGGradientBackground>
            <View className="flex flex-row items-center gap-2 py-6 px-4 justify-between bg-white/25">
              <View className="flex flex-row items-center justify-between gap-2 flex-1">
                <View className="flex flex-col items-start justify-center gap-2 w-full shrink">
                  <MoguPassSVG height={16} color="#000000" />
                  <MGText className="text-black/75 text-sm leading-normal">
                    {t('purchase.subscription.banner.content')}
                  </MGText>
                </View>

                <Ionicons name="chevron-forward-outline" className="shrink-0 opacity-50" size={24} color="#000000" />
              </View>
            </View>
          </MGGradientBackground>
        </TouchableOpacity>
      )}

      <TouchableOpacity
        onPress={() => setPurchaseSheetState({ opened: true, selected: 'store' })}
        className="bg-neutral-100"
      >
        <View className="flex flex-row items-center gap-2 py-4 px-4 justify-between">
          <View className="flex flex-row items-center gap-2 w-full">
            <MGIonicons name="cart-outline" size={18} />
            <MGText className="flex-1">{t('purchase.credit.store')}</MGText>
            <View className="flex flex-row items-center justify-center pl-2">
              <Image source={require('../../assets/images/store/onigiri.webp')} style={{ width: 20, height: 20 }} />
              <MGText className="text-primary-400 pl-1 pr-2 leading-snug" bold>
                {aggregatedBalance.onigiri}
              </MGText>
              <Image source={require('../../assets/images/store/okome.webp')} style={{ width: 20, height: 20 }} />
              <MGText className="text-primary-400 pl-1 pr-2 leading-snug" bold>
                {aggregatedBalance.okome}
              </MGText>
            </View>
            <Ionicons name="chevron-forward-outline" className="shrink-0" size={24} color="#dddddd" />
          </View>
        </View>
      </TouchableOpacity>
    </View>
  )
}
