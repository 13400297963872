import Markdown from 'marked-react'
import { Text, View } from 'react-native'

const PRIVACY_CONTENT = `# Privacy Policy

Last updated February 9, 2025

Hello and welcome! This Privacy Policy is an agreement formed between you and MoguChat. It covers the website available at MoguChat.AI (the "Website"), and the MoguChat mobile application (the "App"). In this Privacy Policy we'll sometimes refer to MoguChat as "Company", "we", or "us." We'll refer to our Website and App, together with any content, tools, features and functionality offered on or through them, as the "Services."

This privacy notice for Services ("we," "us," or "our"), describes how and why we might collect, store, use, and/or share (the "Process") your information when you use our services.

Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our services. If you still have any questions or concerns, please contact us at [inquiries@moguchat.ai](mailto:inquiries@moguchat.ai).

## Personal Data We Collect

### Personal Information You Disclose to Us

We collect information that you explicitly provide to us when you register on or use the Services. The personal information we collect may include your email address, and other personal data you choose to provide during the registration process.

We may also collect information that you explicitly provide to us when you use the Services.

### Personal Data We Obtain From Third Parties

We may receive personal data about you from third parties. We do not control how third parties use your personal data.

While using the Services, you may have the opportunity to connect your MoguChat account to third party services. The following third party services and their respective privacy policies are available:

**Sign in with Google**: You can sign up and log in to the Services using your Google account. When you sign up using Google, Google will ask your permission to share your profile information from your Google account with the Services. This includes your Google email address, avatar, and name. This information is collected by Google and is provided to us under the terms of [Google's Privacy Policy](https://policies.google.com/privacy). You can regulate the information that we receive from Google using your [Google Activity Controls](https://myaccount.google.com/?pli=1).

**Sign in with Apple**: You can sign up and log in to the Services using your Apple account. When you sign up using Apple, Apple will ask your permission to share your profile information from your Apple Account with the Services. You may choose to not share the name associated with your Apple Account on the consent screen. You may also choose to use an Apple-generated, privacy-safe email address for your Services account on the consent screen. The information collected by Apple is provided to us under the terms of [Apple's Privacy Policy](https://www.apple.com/legal/privacy/en-ww/).

## How We Use Your Personal Data

We use your personal data to operate and maintain your Services account, and to provide you with access to and use of the Services. We may use the profile information you provided to us to personalize your experience on the Services, such as enhancing the conversation experience by utilizing your name.

## How We Share Your Personal Data

We do not sell your personal data to external parties.

Your avatar and display name are accessible by other users of the Services, who may view your profile information. However, other personal and sensitive data you provided to us, including your email address, is not accessible by other users of the Services.

We may disclose your personal data to courts, law enforcement agencies, and governmental authorities (i) to comply with our legal obligations, (ii) to comply with legal process and to respond to claims asserted against the Services, (iii) to respond to verified requests relating to a criminal investigation or alleged or suspected illegal activity or any other activity that may expose us, you, or any other of our users to legal liability, (iv) to enforce and administer our Terms of Use and other agreements with users, or (v) to protect the rights, property or personal safety of the Services, its employees, and members of the public.

### Information Security

Our Services maintains appropriate technical, administrative, and physical safeguards that are designed to prevent unauthorized access, use or disclosure of personal data we collect or store and to ensure a level of security appropriate to the risk of varying likelihood and severity for the rights and freedoms of natural persons that may be involved with our processing operations. We regularly monitor our systems for possible vulnerabilities and attacks. However, it is not possible to guarantee the security of personal data transmitted over the Internet. You use the website and provide us with personal data at your own discretion and risk.

### Contact Information

If you have questions or complaints about this Privacy Policy or our practices, please contact us by email at [inquiries@moguchat.ai](mailto:inquiries@moguchat.ai).
`

export default function PrivacyPage() {
  return (
    <div className="w-full overflow-y-auto">
      <div className="flex flex-col px-8 pt-16 pb-32 overflow-y-auto [&_h1]:text-4xl [&_h1]:font-bold [&_h1]:mb-4 [&_h2]:text-2xl [&_h2]:font-semibold [&_h2]:mt-8 [&_h2]:mb-4 [&_h3]:text-xl [&_h3]:font-semibold [&_h3]:mt-8 [&_h3]:mb-4 [&_p]:mb-4 [&_p]:leading-7 [&_ul]:list-disc [&_ul]:ml-5 [&_ul]:mb-4 [&_ol]:list-decimal [&_ol]:ml-5 [&_ol]:mb-4 [&_a]:text-blue-600 [&_a]:underline max-w-3xl mx-auto text-foreground">
        <Markdown>{PRIVACY_CONTENT}</Markdown>
      </div>
    </div>
  )
}
