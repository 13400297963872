import { setStringAsync } from 'expo-clipboard'
import { useUpdates } from 'expo-updates'
import { useSetAtom } from 'jotai'
import { colorScheme } from 'nativewind'
import { type FC, Fragment, type ReactNode, useEffect, useMemo, useState } from 'react'
import { Alert, Platform, ScrollView, TouchableHighlight, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import MGHeader from '../../../components/common/MGHeader'
import MGHeaderText from '../../../components/common/MGHeaderText'
import MGText, { MGSelectableText } from '../../../components/common/MGText'
import { showAppLovinMAXDebugger } from '../../../lib/applovin'
import { config } from '../../../lib/config'
import { useCurrentUser } from '../../../lib/hooks/useAuth'
import { useInventory } from '../../../lib/hooks/useInventory'
import { inventoryChangeToastStateAtom } from '../../../lib/inventoryChangeToast'
import { queryClient } from '../../../lib/services/client'
import { getToken } from '../../../lib/storage'

const DebugViewButton: FC<{
  title: string
  onPress: () => void
}> = ({ title, onPress }) => {
  return (
    <TouchableHighlight
      onPress={onPress}
      className="bg-amber-100 rounded-md px-2 py-0.5"
      underlayColor="rgba(0, 0, 0, 0.1)"
    >
      <MGText className="text-neutral-500">{title}</MGText>
    </TouchableHighlight>
  )
}

interface DebugItem {
  title: string
  content: ReactNode
  copyContent?: string | null
}

const DebugItemView: FC<DebugItem> = ({ title, content, copyContent }) => {
  const children =
    typeof content === 'string' ? <MGSelectableText bold style={{ fontSize: 11 }} value={content} /> : content

  return (
    <Fragment>
      <View className="flex-col gap-1 p-4">
        {children}
        <View className="flex-row items-center gap-2">
          <MGText className="flex-1">{title}</MGText>

          <DebugViewButton
            title="Copy"
            onPress={() => {
              const contentToCopy = copyContent ?? content
              if (typeof contentToCopy === 'string') {
                setStringAsync(contentToCopy)
                Alert.alert('Copied', 'The content has been copied to the clipboard')
              } else {
                Alert.alert('Cannot copy', 'The content is not a string')
              }
            }}
          />
        </View>
      </View>
      <View className="bg-amber-200 w-full h-px" />
    </Fragment>
  )
}

export default function DebugView() {
  const token = getToken()
  const updates = useUpdates()
  const user = useCurrentUser()
  const inventory = useInventory()
  const [queryCacheDebugData, setQueryCacheDebugData] = useState<string | null>(null)
  const [iosReceipt, setIosReceipt] = useState<string | undefined | null>(null)
  const [, rerender] = useState(0)
  const setInventoryChangeToast = useSetAtom(inventoryChangeToastStateAtom)

  const clientEnvironment = useMemo(() => {
    return `Platform.OS=${Platform.OS}
Platform.Version=${Platform.Version}`
  }, [])

  useEffect(() => {
    const queryCache = queryClient.getQueryCache()
    const all = queryCache.getAll()
    const queryKeys = all
      .map((e) => {
        const key = JSON.stringify(e.queryKey)
        const state = JSON.stringify(e.state)
        const keepCharacters = 200
        return `${key}
${state.slice(0, keepCharacters)}${state.length > keepCharacters ? '...' : ''}`
      })
      .join('\n\n')
    setQueryCacheDebugData(queryKeys)
  }, [])

  useEffect(() => {
    const fetchIosReceipt = async () => {
      if (Platform.OS !== 'ios') return
      const { IapIos } = await import('react-native-iap')
      const receipt = await IapIos.getReceiptIOS({ forceRefresh: true })
      setIosReceipt(receipt)
    }
    fetchIosReceipt()
  }, [])

  const debugItems: (DebugItem | false)[] = [
    {
      title: 'Client environment',
      content: clientEnvironment,
    },
    {
      title: 'Color Scheme',
      content: (
        <MGText className="flex-1">
          {colorScheme.get()} @ {new Date().toISOString()}
        </MGText>
      ),
    },
    {
      title: 'Add Inventory Change Toast',
      content: (
        <DebugViewButton
          title="Add Inventory Change Toast"
          onPress={() => {
            setInventoryChangeToast({
              delta: {
                okome: 13,
                onigiri: 3,
              },
            })
          }}
        />
      ),
    },
    __DEV__ && {
      title: 'Show AppLovinMAX Mediation Debugger',
      content: (
        <DebugViewButton
          title="Show AppLovinMAX Mediation Debugger"
          onPress={() => {
            showAppLovinMAXDebugger()
          }}
        />
      ),
    },
    {
      title: 'Config',
      content: JSON.stringify(config, null, 2),
      copyContent: JSON.stringify(config),
    },
    {
      title: 'useCurrentUser().data',
      content: JSON.stringify(user.data, null, 2),
      copyContent: JSON.stringify(user.data),
    },
    {
      title: 'useInventory()',
      content: JSON.stringify(inventory, null, 2),
      copyContent: JSON.stringify(inventory),
    },
    {
      title: 'expo-updates',
      content: JSON.stringify(updates, null, 2),
      copyContent: JSON.stringify(updates),
    },
    {
      title: 'Auth Token',
      content: token,
    },
    {
      title: 'react-query query cache snapshot',
      content: (
        <MGText selectable style={{ fontSize: 10 }}>
          {queryCacheDebugData}
        </MGText>
      ),
      copyContent: queryCacheDebugData,
    },
    {
      title: 'Store Receipt (only on iOS)',
      content: (
        <MGText selectable style={{ fontSize: 10 }}>
          {iosReceipt ? `${iosReceipt.slice(0, 1000)}...` : 'No receipt found'}
        </MGText>
      ),
      copyContent: iosReceipt,
    },
    {
      title: 'React Query',
      content: (
        <DebugViewButton
          title="Reset React Query cache"
          onPress={() => {
            queryClient.clear()
            Alert.alert('React Query cache reset', 'The React Query cache has been reset')
          }}
        />
      ),
    },
  ]

  return (
    <View className="flex-1 bg-background">
      <SafeAreaView className="flex-1 bg-amber-50 dark:bg-amber-600/5">
        <MGHeader headerBody={<MGHeaderText>Debug</MGHeaderText>} />
        <ScrollView className="flex-col">
          {debugItems
            .filter((item) => item !== false)
            .map((item, i) => (
              // biome-ignore lint/suspicious/noArrayIndexKey: this index is stable
              <DebugItemView key={i} title={`D${i + 1}: ${item.title}`} content={item.content} />
            ))}
        </ScrollView>
      </SafeAreaView>
    </View>
  )
}
