import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SafeAreaView } from 'react-native-safe-area-context'
import MGHeader from '../common/MGHeader'
import NonIdealState from '../common/NonIdealState'

const CharacterNotFound: FC<{ characterId?: string }> = ({ characterId }) => {
  const { t } = useTranslation()
  return (
    <SafeAreaView className="flex-1 bg-white relative">
      <MGHeader />
      <NonIdealState title={t('error.character-not-found')} description={characterId} />
    </SafeAreaView>
  )
}

export default CharacterNotFound
