import clsx from 'clsx'
import { LinearGradient } from 'expo-linear-gradient'
import { useLocalSearchParams } from 'expo-router'
import { StatusBar } from 'expo-status-bar'
import { useEffect, useRef, useState } from 'react'
import { Animated, View } from 'react-native'
import { interpolateColor, useAnimatedStyle, withTiming } from 'react-native-reanimated'
import CharacterDetail from '../../../../components/character/CharacterDetail'
import CharacterNotFound from '../../../../components/character/CharacterNotFound'
import MGHeader, { MGShareHeaderItem } from '../../../../components/common/MGHeader'
import MGHeaderText from '../../../../components/common/MGHeaderText'
import MGLoadingOverlay from '../../../../components/common/MGLoadingOverlay'
import { useCurrentUser } from '../../../../lib/hooks/useAuth'
import { useIsDesktop } from '../../../../lib/hooks/useIsDesktop'
import { trpcReact } from '../../../../lib/services/trpc'

export default function CharacterDetailScreen() {
  const { characterId } = useLocalSearchParams()

  const { data: user, isLoading: isLoadingUser } = useCurrentUser()

  const isNotLoggedIn = !user && !isLoadingUser

  const { from } = useLocalSearchParams()

  const { data: character, isLoading } = trpcReact.character.get.useQuery(
    {
      id: characterId as string,
    },
    {
      enabled: characterId !== 'create',
    },
  )

  const [headerContentStyle, setHeaderContentStyle] = useState<'normal' | 'inverted'>('inverted')
  const headerBackgroundStyle = useAnimatedStyle(() => ({
    backgroundColor: withTiming(
      interpolateColor(
        headerContentStyle === 'inverted' ? 0 : 1,
        [0, 1],
        ['rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 1)'],
      ),
      { duration: 200 },
    ),
  }))

  const translateY = useRef(new Animated.Value(8)).current

  const isDesktop = useIsDesktop()

  useEffect(() => {
    if (headerContentStyle === 'normal') {
      Animated.timing(translateY, {
        toValue: 0,
        duration: 200,
        useNativeDriver: true,
      }).start()
    } else {
      Animated.timing(translateY, {
        toValue: 8,
        duration: 200,
        useNativeDriver: true,
      }).start()
    }
  }, [headerContentStyle])

  if (isLoading) {
    return (
      <View className="flex-1 bg-background relative">
        <MGLoadingOverlay visible={true} />
      </View>
    )
  }

  if (!character) {
    return <CharacterNotFound characterId={characterId.toString()} />
  }

  return (
    <View className="flex-1 bg-background relative">
      <StatusBar style={headerContentStyle === 'inverted' ? 'light' : 'dark'} />
      <View className={clsx(isDesktop ? 'size-auto m-auto' : 'size-full')}>
        <CharacterDetail
          character={character}
          setHeaderContentStyle={setHeaderContentStyle}
          from={from as string | undefined}
        />
      </View>
      <LinearGradient
        colors={['rgba(0,0,0,0.9)', 'transparent']}
        style={{
          opacity: isDesktop ? 0 : 1,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: 120,
        }}
      />
      <View
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
        }}
      >
        <MGHeader
          hideDesktopTabs={isNotLoggedIn}
          disableBackButton={isNotLoggedIn}
          topSafePadding
          headerContentStyle={headerContentStyle}
          headerBody={
            <Animated.View
              style={{
                transform: [{ translateY }],
                opacity: translateY.interpolate({
                  inputRange: [0, 8],
                  outputRange: [1, 0],
                }),
              }}
              className="shrink"
            >
              <MGHeaderText numberOfLines={1} lineBreakMode="tail" className="mr-4">
                {character.name}
              </MGHeaderText>
            </Animated.View>
          }
          trailingView={
            character.visibility === 'PUBLIC' && (
              <MGShareHeaderItem
                headerContentStyle={isDesktop ? 'normal' : headerContentStyle}
                shareSubject="CHARACTER"
                shareSubjectId={character.id}
                shareTitle={character.name}
              />
            )
          }
          backgroundStyle={headerBackgroundStyle}
        />
      </View>
    </View>
  )
}
