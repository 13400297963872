import { useAtomValue, useSetAtom } from 'jotai'
import { type FC, useCallback, useEffect, useMemo } from 'react'
import { Platform, View } from 'react-native'
import Animated, { runOnJS, useAnimatedStyle, useSharedValue, withSpring, withTiming } from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { inventoryChangeToastStateAtom } from '../lib/inventoryChangeToast'
import { BalanceItem } from './monetization/Balance'

const TOAST_DURATION = 5000 // 5 seconds
const ANIMATION_CONFIG = {
  duration: 300,
}

const SORT_ORDER = ['onigiri', 'okome']

export const InventoryChangeToast: FC<{ embedded?: boolean }> = ({ embedded = false }) => {
  const inventoryChangeToastState = useAtomValue(inventoryChangeToastStateAtom)
  const setInventoryChangeToastState = useSetAtom(inventoryChangeToastStateAtom)
  const translateY = useSharedValue(-100)
  const opacity = useSharedValue(0)

  const balanceItems = useMemo(() => {
    if (!inventoryChangeToastState) return []

    const items = Object.entries(inventoryChangeToastState.delta)
      .sort((a, b) => SORT_ORDER.indexOf(a[0]) - SORT_ORDER.indexOf(b[0]))
      .flatMap(([item, value]) => {
        if (value === 0) return []
        return <BalanceItem key={item} item={item as 'onigiri' | 'okome'} value={`+${value}`} />
      })

    // join items with a <View className="w-2" />
    return items.reduce((acc, item) => {
      if (acc.length === 0) return [item]
      // biome-ignore lint/performance/noAccumulatingSpread: the amount is absurdly small
      return [...acc, <View key={`${item.key}-separator`} className="w-px h-full bg-neutral-200" />, item]
    }, [] as React.ReactNode[])
  }, [inventoryChangeToastState])

  const insets = useSafeAreaInsets()

  const hideToast = useCallback(() => {
    setInventoryChangeToastState(null)
  }, [setInventoryChangeToastState])

  useEffect(() => {
    if (inventoryChangeToastState) {
      // Show animation
      translateY.value = withSpring(0, {
        damping: 15,
        stiffness: 100,
      })
      opacity.value = withTiming(1, ANIMATION_CONFIG)

      // Auto-hide after duration
      const timeout = setTimeout(() => {
        translateY.value = withSpring(-100, {
          damping: 15,
          stiffness: 100,
        })
        opacity.value = withTiming(0, ANIMATION_CONFIG, () => {
          runOnJS(hideToast)()
        })
      }, TOAST_DURATION)

      return () => clearTimeout(timeout)
    }
  }, [inventoryChangeToastState, translateY, opacity, hideToast])

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{ translateY: translateY.value }],
    opacity: opacity.value,
  }))

  if (!inventoryChangeToastState) return null

  return (
    <Animated.View
      className="flex flex-col items-center justify-start absolute"
      pointerEvents="none"
      style={[
        {
          top: embedded ? 12 : Math.max(insets.top, Platform.OS === 'web' ? 64 : 12),
          left: insets.left,
          right: insets.right,
        },
        animatedStyle,
      ]}
    >
      <View
        className="bg-background rounded-full px-4 py-2 flex flex-row gap-4"
        style={{
          shadowColor: '#000000',
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowOpacity: 0.25,
          shadowRadius: 3.84,
          elevation: 5,
        }}
      >
        {balanceItems}
      </View>
    </Animated.View>
  )
}
