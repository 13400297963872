// TODO: 404 page
import { Image } from 'expo-image'
import { usePathname } from 'expo-router'
import { Fragment } from 'react'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import MGHeader from '../../../components/common/MGHeader'
import MGHeaderText from '../../../components/common/MGHeaderText'
import MGText from '../../../components/common/MGText'

export default function NotFoundScreen() {
  const path = usePathname()
  return (
    <Fragment>
      <SafeAreaView className="flex-1 bg-background relative">
        <MGHeader headerBody={<MGHeaderText>Something went wrong</MGHeaderText>} />
        <View className="flex-1 items-center justify-center gap-5">
          <Image source={require('../../../assets/images/status/non-ideal.png')} style={{ width: 200, height: 200 }} />
          <MGText className="text-neutral-600 text-sm">{path}</MGText>
        </View>
      </SafeAreaView>
    </Fragment>
  )
}
