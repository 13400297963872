import * as WebBrowser from 'expo-web-browser'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import MGText from '../common/MGText'

const openUrl = async (url: string) => {
  try {
    await WebBrowser.openBrowserAsync(url, {
      dismissButtonStyle: 'close',
      toolbarColor: '#FFF',
      controlsColor: '#fe68c4',
    })
  } catch (error) {
    console.error(error)
  }
}

export const LegalRow = () => {
  const { t } = useTranslation()
  return (
    <View className="flex flex-row justify-center items-center gap-4">
      <MGText
        className="text-neutral-500"
        onPress={async () => {
          await openUrl('https://moguchat.ai/terms')
        }}
      >
        {t('purchase.footer.terms')}
      </MGText>
      <MGText
        className="text-neutral-500"
        onPress={async () => {
          await openUrl('https://moguchat.ai/privacy')
        }}
      >
        {t('purchase.footer.privacy')}
      </MGText>
    </View>
  )
}
