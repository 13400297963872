import { colorScheme } from 'nativewind'
import { useEffect } from 'react'
import { Appearance, Platform } from 'react-native'
import { useColorScheme as useStorageColorScheme } from './storage'
import { useTheme } from './theme'

const startupColorTheme = Appearance.getColorScheme()
export const useSyncColorScheme = () => {
  const [currentColorScheme] = useStorageColorScheme()

  useEffect(() => {
    const scheme = currentColorScheme ?? 'system'
    if (scheme === 'system' && startupColorTheme && Platform.OS !== 'web') {
      // Circumvent weird bug of buggy system color scheme with react-native
      colorScheme.set(startupColorTheme)
    } else {
      colorScheme.set(scheme)
    }
  }, [currentColorScheme])
}

export const useSyncWebBackgroundColor = () => {
  const theme = useTheme()

  useEffect(() => {
    if (Platform.OS === 'web') {
      document.documentElement.style.background = theme.background
    }
  }, [theme.background])
}
