import { Crisp } from 'crisp-sdk-web'
import { config } from '../lib/config'

const configure = () => {
  // metro output: 'static' fix
  if (typeof window !== 'undefined') {
    Crisp.configure(config.crisp.websiteId)
  }
}

const setSessionString = (key: string, value: string) => {
  Crisp.session.setData({
    [key]: value,
  })
}

const setUserAvatar = (url: string) => {
  Crisp.user.setAvatar(url)
}

const setUserEmail = (email: string) => {
  Crisp.user.setEmail(email)
}

const setUserNickname = (name: string) => {
  Crisp.user.setNickname(name)
}

const show = () => {
  Crisp.chat.open()
}

const setTokenId = (id?: string) => {
  Crisp.setTokenId(id)
}

/**
 * The web variant of resetSession also resets the crisp token id to match iOS & Android Crisp SDK behaviors.
 * @see https://docs.crisp.chat/guides/chatbox-sdks/web-sdk/session-continuity/
 */
const resetSession = () => {
  setTokenId() // reset crisp token id before reset session
  Crisp.session.reset()
}

export { configure, resetSession, setSessionString, setTokenId, setUserAvatar, setUserEmail, setUserNickname, show }
