{
  "action.back": "戻る",
  "action.cancel": "キャンセル",
  "action.close": "閉じる",
  "action.confirm": "確認",
  "action.report": "報告する",
  "action.report-and-block": "報告してブロックする",
  "action.submit": "作成",
  "app-settings.color-scheme.enum.dark": "ダーク",
  "app-settings.color-scheme.enum.light": "ライト",
  "app-settings.color-scheme.enum.system": "システムのデフォルト",
  "app-settings.color-scheme.title": "外観モード",
  "app-settings.language": "言語",
  "app-settings.language-selector.title": "言語を選択",
  "app-settings.title": "アプリ設定",
  "app.name": "MoguChat",
  "app.name.domain": "MoguChat.AI",
  "auth.legal-notice": "続行することで、MoguChatの<tos>利用規約</tos>およびMoguChatの<privacy>プライバシーポリシー</privacy>を読み、同意するものとします。",
  "auth.login.apple": "Appleでログイン",
  "auth.login.error": "ログインに失敗しました",
  "auth.login.google": "Googleでログイン",
  "auth.login.required.description": "ログインしてからキャラと会話しましょう！",
  "auth.login.required.title": "ログインしてください",
  "auth.login.title": "ログイン",
  "character.action": "操作",
  "character.action.chat": "チャットする",
  "character.action.delete": "削除",
  "character.action.delete.confirm.description": "削除されたキャラクターは戻せません！",
  "character.action.delete.confirm.title": "本当に削除しますか？",
  "character.action.delete.success": "キャラクターを削除しました",
  "character.action.edit": "編集",
  "character.action.edit.success": "編集成功",
  "character.author.report": "作成者を報告する",
  "character.author.report.description": "このキャラクターの作成者をMoguChat公式に報告する",
  "character.create": "キャラクターを作る",
  "character.create.complete": "キャラクター作成完了",
  "character.create.short-title": "作る",
  "character.deleted-name": "キャラが存在しない",
  "character.edit": "編集完了",
  "character.edit-complete.share": "シェアする",
  "character.edit-complete.start-chat": "チャットを始める",
  "character.edit-complete.submit-to-featured": "おすすめに申請する",
  "character.edit-complete.success-message": "キャラクターが保存されました",
  "character.edit-complete.title": "キャラクター作成完了",
  "character.edit.action.back": "戻る",
  "character.edit.action.continue": "次へ",
  "character.edit.complete": "キャラクター編集完了",
  "character.edit.confirm.description": "ここでやめると編集内容が失われる可能性があります。",
  "character.edit.confirm.title": "本当にやめますか？",
  "character.edit.error.title": "キャラクターを作れませんでした",
  "character.edit.submit.create": "作成",
  "character.edit.submit.update": "更新",
  "character.field.author": "作成者",
  "character.field.avatar": "アイコン",
  "character.field.background-setting": "背景ストーリー・世界観",
  "character.field.background-setting.help.content": "キャラクターに深みを持たせるために、キャラ設定に過去や世界観を設定しましょう。この部分がしっかりしていると、キャラクターにリアリティが生まれます。\n例：\n{{char}}は現代日本に生きている。{{char}}は大学時代に陸上部のエースとして活躍していたが、今は○○社で営業部に所属してる。○○社は{{user}}とはルームシェアでマンションに暮らしており、毎日その近くの公園でジョギングする。{{user}}と一緒に暮らしてることも恋愛関係がバレることに繋ぐことに恐れており、いつも時間をずらして登社するため、いつも出社時間はギリギリ。",
  "character.field.background-setting.help.title": "ここにキャラのいる世界観やキャラの背景ストーリーを入れましょう",
  "character.field.background-setting.placeholder": "このキャラはどんな世界でどんな生活を過ごしてますか？",
  "character.field.character-setting": "基本情報",
  "character.field.character-setting.help.content": "キャラ設定の中では、利用者のユーザーを{{user}}にし、AIキャラの名前を{{char}}にするとシステム的にはより理解しやすくなります。基礎設定でキャラの基本的な情報を入れましょう。キャラの名前や年齢、性別、特徴やユーザーとの関係を決めて入れる事をお勧めします。\n例1：\n- {{char}}の名前：佐々木智貴（ささき ともたか）\n- {{char}}の性別：男性\n- {{char}}の年齢：25歳\n- {{char}}の職業：○○社の平社員\n- {{char}}と{{user}}の関係：恋人同士で、会社にバレないように秘密裏に付き合ってる。\n\n例2：オレは佐々木智貴（ささき ともたか）、25歳で○○社に勤めてる会社員だ。22歳で大学を卒業したらずっとこの会社で働いていて、初めは同僚に「ともき」と間違って呼ばれることもあって、今はもうあだ名みたいになってる。{{user}}とは付き合って二年目で、未だ周囲に関係を隠している。うちは社内恋愛にちょっと厳しくてさ、バレたら多分辞めさせられるかも。\n\nより詳しい作り方を知りたい場合、MoguChatの[公式Discord](https://discord.com/invite/grjhdf4SNb)に参加するか、こちらの[ページ](https://help.moguchat.ai/ja/article/44kt44oj44op44kv44k44o844gu5l2c5oiq-12476mc/?bust=1743498256790)をご参照ください。",
  "character.field.character-setting.help.title": "キャラの基本情報となる設定を入れましょう",
  "character.field.character-setting.placeholder": "ここにキャラの基本情報を入れましょう。名前や年齢、ユーザーとの関係性を述べてください。直接リストアップか、自己紹介風の内容がオススメです。",
  "character.field.chat-background": "チャット背景画像",
  "character.field.chat-background.description": "このキャラとチャットする時の背景画像を指定できます",
  "character.field.description": "キャラ紹介",
  "character.field.description.description": "この内容はユーザーに公開されます。キャラを紹介してみましょう。",
  "character.field.example-conversation": "会話例",
  "character.field.example-conversation.help.content": "例：\nセリフ例\n「おっ、今日も元気そうだね！俺も嬉しいな！」\n「疲れてるの？俺に何でも話していいよ！」\n「{{user}}さんの笑顔、俺の元気の源だから！」\n「えっ、それ面白いね！もっと詳しく聞かせて！」\n「落ち込むなって、俺がそばにいるんだからさ！」\n会話例\n{{user}}: 今日疲れた\n{{char}}: お疲れ様、無理しすぎちゃった？今日はゆっくり休もう、先にお風呂入れる？\n{{user}}: 最近仕事が忙しくてね…疲れすぎて動けないー\n{{char}}: 確かに、今日も残業しちゃったな。動けないなら風呂まで運ぶ？\n{{user}}: 運んで！\n{{char}}: はいはい、ちゃんと頑張って偉いね。明日からオレが夕飯用意しようか？",
  "character.field.example-conversation.help.title": "ここにキャラのセリフや会話のサンプルを入れましょう",
  "character.field.example-conversation.placeholder": "このキャラが言いそうなセリフや会話はどんな感じですか？具体的な例を書きましょう",
  "character.field.intro-message": "挨拶・始まりの一言",
  "character.field.intro-message.description": "キャラクターが会話の初めに最初にする挨拶の言葉を入力してください",
  "character.field.intro-message.help.content": "キャラがこの会話で最初に送るメッセージです。基本的挨拶やシナリオに沿う言葉をお勧めします。利用者と初めて会う設定のキャラであれば「初めまして」とか、すでに知り合い、もしくは結構馴染みがある設定ならば「今日どうだった」とか、会話が続けるような内容をお願いします。",
  "character.field.intro-message.help.title": "会話の最初にキャラクターが発する言葉",
  "character.field.intro-message.placeholder": "例：おはようございます",
  "character.field.name": "名前",
  "character.field.name.description": "このキャラの名前は？",
  "character.field.persona": "詳細設定",
  "character.field.persona.help.content": "キャラクターの性格は口調、会話のトーンや反応に影響します。ここで細かい部分を設定しておきましょう。キャラの性格と好き嫌いや特技、口癖などを入れましょう。\n例：\n{{char}}は明るい性格で、甘いものが好き。軽快で親しみやすい口調で話し、周囲から親しみを持たれている。人に同意する時「うんうん、だよな！」が口癖。\n\nより詳しい作り方を知りたい場合、MoguChatの[公式Discord](https://discord.com/invite/grjhdf4SNb)に参加してください。",
  "character.field.persona.help.title": "キャラの設定内容",
  "character.field.persona.placeholder": "ここにキャラの性格特徴や好み、口癖など詳細な設定を入れましょう。",
  "character.field.personality.callout-style": "呼び方",
  "character.field.personality.callout-style.description": "キャラクターの他人への呼び方は？",
  "character.field.personality.description": "口調や一人称、二人称などを設定することができます",
  "character.field.personality.first-person": "第一人称",
  "character.field.personality.first-person.description": "キャラクターが使う第一人称は？例：ぼく",
  "character.field.personality.second-person": "第二人称",
  "character.field.personality.second-person.description": "キャラクターが使う第二人称は？",
  "character.field.personality.speaking-tone": "口調・話し方",
  "character.field.personality.speaking-tone.description": "キャラクターはどんな口調、もしくは話し方をしますか？",
  "character.field.personality.title": "その他設定",
  "character.field.tag": "タグ",
  "character.field.tag.description": "キャラのタグを選んでください（最大5個）",
  "character.field.tag.max-length": "最大5つまでタグを選べます",
  "character.field.visibility": "公開状態",
  "character.field.visibility.description": "キャラクターの公開状態を選んでください",
  "character.field.visibility.enum.private": "非公開",
  "character.field.visibility.enum.private.description": "私だけが利用できます",
  "character.field.visibility.enum.public": "公開",
  "character.field.visibility.enum.public.description": "すべての人が利用できます",
  "character.field.visibility.enum.unlisted": "限定公開",
  "character.field.visibility.enum.unlisted.description": "自分とシェアURLからアクセスした人のみが利用できます",
  "character.filter.all": "おすすめ",
  "character.filter.ugc": "ユーザー作成",
  "character.form.auto-save": "一時保存しました",
  "character.meta.title": "{{authorName}}作の{{characterName}}",
  "character.my": "自作キャラクター",
  "character.my.view-more": "もっと見る",
  "character.no-owned-character": "作成したキャラクターがまだいません",
  "character.not-found": "キャラクターが見つかりません",
  "character.popularity": "人気",
  "character.report": "キャラクターを報告する",
  "character.report.description": "このキャラクターをMoguChat公式に報告する",
  "character.report.success": "報告を送りました",
  "character.request-featured.action.request": "申請",
  "character.request-featured.hero-description": "「自分の自作キャラをより多くの人に見せてもらいたい！」「自信作をみんなに使ってもらいたい！」と希望するクリエイター様は、こちらを通じて自分のキャラをホームページの「おすすめ」に追加するように申請しましょう。\n「おすすめ」に載せたいキャラを選び、申請を提出すると、審査が通り次第キャラはオススメに追加されます。",
  "character.request-featured.hero-title": "自作キャラをおすすめに乗せましょう！",
  "character.request-featured.no-characters.description": "おすすめキャラクターを申請するには、キャラクターを公開する必要がありますのでご注意ください。",
  "character.request-featured.no-characters.title": "申請可能なキャラクターがありません",
  "character.request-featured.select-character": "申請するキャラクターを選ぶ",
  "character.request-featured.state.featured": "おすすめに追加しました！",
  "character.request-featured.state.pending": "申請済み",
  "character.request-featured.title": "おすすめキャラクターの申請",
  "character.search.input.placeholder": "｜・ω・)ノ 今日は誰と話したい気分？",
  "character.search.title": "話し相手を探す",
  "character.share.copied": "リンクをクリップボードにコピーしました",
  "character.share.error-message": "後でもう一度お試しください",
  "character.share.error-title": "シェアに失敗しました",
  "character.share.success": "シェアしました",
  "character.share.title": "{{characterName}} | MoguChat.AI",
  "character.stats.conversations-created": "会話",
  "character.stats.messages-sent": "送信されたメッセージ",
  "character.submit-to-featured.error-title": "申請に失敗しました",
  "character.submit-to-featured.success-message": "審査後、おすすめに表示されます",
  "character.submit-to-featured.success-title": "申請が完了しました",
  "character.temp-save": "一時保存",
  "chat.action": "アクション",
  "chat.action.delete": "リストから削除",
  "chat.action.delete.cancel": "キャンセル",
  "chat.action.delete.confirm": "チャットを削除",
  "chat.action.delete.description": "削除したチャットの内容は取り戻せません。",
  "chat.action.delete.title": "本当にこのチャットを削除しますか？",
  "chat.action.description": "このチャットをどうしますか？",
  "chat.action.restart": "やり直し",
  "chat.action.restart.confirm": "やり直す",
  "chat.action.restart.description": "やり直し機能ではキャラとのすべての会話記憶とデータがなくなります。データを復元することはできません。",
  "chat.action.restart.title": "本当このトークをやり直しますか？",
  "chat.action.title": "このチャットを",
  "chat.ads-banner-description": "広告なしでMoguChatをもっと楽しみましょう！<underline>MoguPassを購読する</underline>",
  "chat.ai-warning": "キャラ回答やその他コンテンツ内容はAIによって生成されています。\n内容にエラーや不適切な情報を含む場合があり、MoguChat公式の見解と立場を表すものではありません。また、実在の人物や団体などとは関係ありません。\n回答内容に不適切な情報や問題があると思われる場合、該当メッセージを長押しして不具合報告をしてくださると幸いです。",
  "chat.ai-warning.title": "ご注意",
  "chat.input.placeholder": "メッセージを入力してください...",
  "chat.list": "会話",
  "chat.list.empty": "会話がありません",
  "chat.list.empty.action.find-character": "キャラクターを探す",
  "chat.message.action.capture": "スクショシェア",
  "chat.message.action.copy": "メッセージをコピー",
  "chat.message.action.delete": "メッセージを削除",
  "chat.message.action.delete.failed": "メッセージを削除できませんでした",
  "chat.message.action.edit": "メッセージを編集",
  "chat.message.action.regenerate": "メッセージを再生成",
  "chat.message.action.report": "回答に不満がある",
  "chat.message.action.report.description": "より良い回答をご提供いただけるよう不満を感じる理由をお教えください",
  "chat.message.action.report.failed": "メッセージを報告できませんでした",
  "chat.message.action.report.reason.CHAT_CONTEXT_LOST": "以前会話した内容が記憶にない",
  "chat.message.action.report.reason.EMPTY_RESPONSE": "回答内容が表示されていない",
  "chat.message.action.report.reason.MIXED_LANGUAGE": "回答に変な外国語が混ざっている",
  "chat.message.action.report.reason.OTHERS": "その他",
  "chat.message.action.report.reason.REPETITIVE_RESPONSE": "似たセリフばかり繰り返す",
  "chat.message.action.report.reason.SETTINGS_CONTEXT_LOST": "背景や設定内容とずれている",
  "chat.message.action.report.success": "フィードバックを送りました",
  "chat.message.action.select": "選ぶ",
  "chat.message.action.send.failed": "メッセージを送信できませんでした",
  "chat.message.no-more": "メッセージはありません",
  "chat.recreate": "チャットを再開",
  "chat.recreate.description": "このキャラクターと新しい会話を始めませんか?",
  "chat.screenshot-share.more": "シェア",
  "chat.screenshot-share.save-to-album.button": "保存",
  "chat.screenshot-share.save-to-album.success": "アルバムに保存しました",
  "chat.screenshot-share.text-content": "MoguChatで素敵な会話を楽しんでいます！",
  "common.confirm.nonreversible": "この操作は元に戻せません！",
  "common.confirm.title": "確認しますか？",
  "common.countdown-button": "{{action}} ({{seconds}}秒後に操作可能)",
  "common.go-back": "戻る",
  "common.input.custom-value.option-label": "カスタム...",
  "common.input.custom-value.placeholder": "カスタムオプションを入力してください",
  "common.input.custom-value.title": "カスタムオプションを入力",
  "common.loading": "読み込み中",
  "common.select.unselect": "選択解除",
  "common.slogan": "Moguで推しとつながる、会話の魔法をいつどこでも",
  "common.tracking-modal.description": "次の画面でトラッキングを許可すると選んでいただければ無関係な広告が表示されなくなります。次の画面でトラッキングを許可するを選ぶようお願い申し上げます(´;ω;｀)。",
  "common.tracking-modal.primary-button": "次へ",
  "common.tracking-modal.title": "MoguChatからのお願い",
  "common.upload-image.error": "画像のアップロードに失敗しました。もう一度お試しください。",
  "common.upload-image.uploading": "アップロード中",
  "components.remote-image-input.permission-required.description": "画像を選択するために、写真ライブラリへのアクセス許可が必要です。設定から、写真ライブラリへのアクセスをMoguChatに許可してください。\n\n選択されていない写真ライブラリ内のアイテムは、アップロードされることはありません。",
  "components.remote-image-input.permission-required.open-settings": "設定を開く",
  "components.remote-image-input.permission-required.title": "写真ライブラリへのアクセス許可",
  "download-app.description": "モバイルでMoguChatを体験できます",
  "download-app.title": "アプリをゲット",
  "error.auth-error": "ログイン情報に問題が起きた可能性があります。このエラーが数度起きた場合、ログアウトして再度ログインしてみてください。",
  "error.avatar-required": "アイコンが必要です",
  "error.character-not-found": "キャラクターが見つかりません",
  "error.chat-not-found": "チャットが見つかりません",
  "error.empty-character-list": "キャラが見つかりません",
  "error.empty-character-list.description": "まだ探しているキャラがありません(´;ω;｀)\n自分で作ってみましょう(｀･ω･´)",
  "error.general-error": "操作が完成できませんでした",
  "error.internal-error": "サーバーが、消えた？Σ(･ω･ﾉ)ﾉ！",
  "error.invalid-input": "無効な入力",
  "error.message-not-found": "メッセージが見つかりません",
  "error.moderation-flagged": "入力内容が全年齢向けではないと判断されました",
  "error.name-required": "名前が必要です",
  "error.no-chat": "会話がありません",
  "error.no-chat.description": "まだチャットがないよ(;´･ω･)",
  "error.no-owned-character": "まだ自作のキャラクターがありません ｡ﾟ(ﾟ´ω`ﾟ)ﾟ｡\n好きなキャラクターを作りましょう！",
  "error.not-authorized": "権限がありません、不具合を報告してください",
  "error.page.back": "ホームページに戻る",
  "error.page.refresh": "リロード",
  "error.page.title": "問題が発生しました",
  "error.permission-denied": "許可が拒否されました",
  "error.save-character-failed": "キャラを作れませんでした",
  "error.tags-limit": "これ以上タグをつけられません",
  "error.token-expired": "接続時間が切れています。ログアウトして再度ログインしてみてください。",
  "error.unknown-error": "未知のエラーに遭遇した",
  "error.update-profile-failed": "不明なエラー",
  "error.upload-failed": "アップロードできませんでした",
  "error.upload-failed.require-permission": "権限が足りない為アップロードできませんでした",
  "event.nightclub.action.go-ranking-page": "ランキング",
  "event.nightclub.action.view-character": "指名する",
  "event.nightclub.action.vote": "シャンパンを卸す",
  "event.nightclub.action.vote.ranking-page": "卸す",
  "event.nightclub.characters.title": "キャスト",
  "event.nightclub.introduction.content": "忙しさに追われ続けて、どこか身体が重い毎日。その足で辿り着いたのは、夜の静寂の中で佇む一つの扉だった。ここはナイトクラブ「MG」――もちろんまだ物語のプロローグ。扉の向こうであなたを待っていたのは、優しい言葉と温かい笑顔であなたを癒す「キャスト」。ここは嫌なことを全て忘れ、そっと目を閉じて心を解き放てる場所。そう、あなたをここ「MG」でずっと待っていた、特別な「彼」の傍で――",
  "event.nightclub.introduction.title": "ようこそ、夜の楽園へ――\nあなたを優しく包み込む特別なひととき",
  "event.nightclub.ranking.reward-description": "ランキングのTOP3ホストはイベント終了後、\n「彼氏バージョン」の彼になり、\n新キャラとして追加します！",
  "event.nightclub.ranking.title": "ランキング",
  "event.voting.no-ticket": "投票アイテムがありません",
  "event.voting.send-button": "送る",
  "event.voting.success": "投票しました",
  "event.voting.title": "シャンパンを卸す",
  "open-in-app.action.open": "開く",
  "open-in-app.download-dialog.title": "MoguChatアプリを入手",
  "open-in-app.title": "MoguChatアプリで開く",
  "profile.action.save": "保存",
  "profile.edit": "プロフィールを編集",
  "profile.edit.success": "プロフィールを更新しました",
  "profile.field.biography": "自分について",
  "profile.field.biography.description": "ここであなたの設定を入れましょう。この内容は他の人には見えません。",
  "profile.field.biography.placeholder": "会話する時のあなたの設定をここに入れましょう",
  "profile.field.gender-interest": "興味のあるカテゴリーを選んでください",
  "profile.field.gender-interest.description": "あなたの好みを教えてください",
  "profile.field.gender-interest.enum.all": "どちらでも",
  "profile.field.gender-interest.enum.for-female": "女性向け",
  "profile.field.gender-interest.enum.for-male": "男性向け",
  "profile.field.nickname": "ニックネーム",
  "profile.field.nickname.description": "何と呼ばれたいですか？",
  "profile.field.nickname.hint": "ニックネームの長さは2〜20文字である必要があります。",
  "profile.field.nickname.placeholder": "ニックネームを入力",
  "profile.field.username": "ユーザー名",
  "profile.field.username.placeholder": "ユーザー名を入力してください",
  "purchase.credit.ad-modal.description": "キャンセルするとチャットが終わります",
  "purchase.credit.ad-modal.error.rewarded.message": "不具合によりお米が追加できませんでした。この問題が起きた場合、プロフィールページのお問い合わせにて公式スタッフにご連絡をお願いします。",
  "purchase.credit.ad-modal.error.rewarded.title": "お米を追加できませんでした",
  "purchase.credit.ad-modal.success.rewarded.message": "お米を追加しました。チャットの続きを楽しんでください",
  "purchase.credit.ad-modal.success.rewarded.title": "お米を追加しました",
  "purchase.credit.ad-modal.title": "チャットを続けるには\nCM視聴をする必要があります",
  "purchase.credit.help.content": "※回答メッセージ1つにつきお米1つが消費されます。\n※お米がない場合、代わりにおにぎりを消費します。\n※お米は無料で獲得できます。\n※おにぎりに有効期限はありません。\n※毎日の4時（日本時間）で、それ以前に獲得したお米はリセットされます。",
  "purchase.credit.help.title": "お米とおにぎりについて",
  "purchase.credit.no-packs-available": "購入できるアイテムがありません。",
  "purchase.credit.only-available-on-mobile": "アプリ版でのみ購入できます",
  "purchase.credit.pack.marketing.first-time-bonus": "初回購入2倍！",
  "purchase.credit.pack.marketing.first-time-only": "一度のみ購入可能",
  "purchase.credit.store": "ストア",
  "purchase.credit.watch-ads": "CM視聴",
  "purchase.credit.watch-ads.download-app-to-watch": "CMを見るためにアプリをダウンロード",
  "purchase.failed": "購入が失敗しました",
  "purchase.footer.privacy": "プライバシー",
  "purchase.footer.restore-purchase": "購入を復元する",
  "purchase.footer.terms": "利用規約",
  "purchase.payment-callback.contact-support": "お問い合わせ",
  "purchase.payment-callback.failed-to-verify.already-completed-the-payment.description": "ご安心ください！すでにお支払いを完了されている場合、ネットワークの問題やその他の要因により、通信情報が遅れることがあります。現在、支払いプロバイダーと確認中ですので、少し後でページを更新してお支払いが完了しているかご確認ください。ページを更新しても解決しない場合は、お問い合わせページにお越しください。",
  "purchase.payment-callback.failed-to-verify.already-completed-the-payment.title": "すでにお支払いを完了しましたか？",
  "purchase.payment-callback.failed-to-verify.description": "お支払いの確認ができませんでした。{{error}}",
  "purchase.payment-callback.failed-to-verify.title": "支払いの確認ができませんでした",
  "purchase.payment-callback.pending.description": "お支払いの確認をお待ちください…",
  "purchase.payment-callback.pending.title": "処理中",
  "purchase.payment-callback.refresh": "更新",
  "purchase.payment-callback.success.description": "特典がアカウントに追加されました。",
  "purchase.payment-callback.success.go-to-chat": "続く",
  "purchase.payment-callback.success.title": "支払いが完了しました",
  "purchase.price.monthly": "毎月{{value}}",
  "purchase.price.weekly": "毎週{{value}}",
  "purchase.price.yearly": "毎年{{value}}",
  "purchase.privilege.no-ads": "広告なし",
  "purchase.privilege.unlimited-characters": "無制限のキャラクター",
  "purchase.privilege.unlimited-messages": "メッセージ数制限解除",
  "purchase.subscription.banner.content": "購読すると広告が削除され、会話し放題に！",
  "purchase.subscription.benefits.ads-free": "すべての<strong>広告を削除</strong>して<strong>会話し放題</strong>",
  "purchase.subscription.benefits.bonus-credits": "毎日<strong>おにぎり*3(賞味期限なし)</strong>をゲット",
  "purchase.subscription.benefits.quicker-responses": "<strong>素早い</strong>返信速度",
  "purchase.subscription.benefits.remove-all-ads": "すべての<strong>広告を削除</strong>",
  "purchase.subscription.benefits.unlimited-chats": "<strong>会話し放題</strong>",
  "purchase.subscription.benefits.unlimited-private-characters": "非公開キャラクターを<strong>作り放題</strong>",
  "purchase.subscription.cta.already-subscribed": "登録済み",
  "purchase.subscription.cta.free-trial": "無料トライアル",
  "purchase.subscription.cta.manage-subscription": "サブスクリプションを管理する",
  "purchase.subscription.cta.subscribe": "登録する",
  "purchase.subscription.interval.monthly": "月間プラン",
  "purchase.subscription.interval.yearly": "年間プラン",
  "purchase.subscription.price-info.begin-free-trial": "{{days}}日間無料",
  "purchase.subscription.product.mogupass.monthly.free-trial.description": "{{days}}日間の無料体験後、{{price}}が請求されます",
  "purchase.subscription.product.mogupass.monthly.subscription-info": "毎月にプラン額が請求されます。プランはいつでも変更またはキャンセル可能です。",
  "purchase.subscription.product.mogupass.yearly.free-trial.description": "{{days}}日間の無料体験後、{{price}}が請求されます",
  "purchase.subscription.product.mogupass.yearly.subscription-info": "毎年にプラン額が請求されます、プランはいつでも変更またはキャンセル可能です。",
  "purchase.subscription.reason.insufficient-balance": "残高が不足しています - 無制限で広告なしのチャットを楽しむためにMoguPassに登録することをお勧めします！",
  "purchase.subscription.reason.private-character-limit-reached": "作成できる非公開キャラクター数の上限に達しました。無制限のキャラクターを作成するには、もぐもぐパスに登録してください。",
  "purchase.subscription.success": "もぐもぐパズはゲットしました！",
  "purchase.subscription.title": "もぐもぐパス",
  "purchase.unavailable.provider-mismatch.description": "別のOSで登録なされた可能性がございます",
  "purchase.unavailable.provider-mismatch.title": "登録できません",
  "reward.daily-bonus": "ログインボーナス",
  "reward.daily-bonus.claim": "受け取る",
  "reward.daily-bonus.claim-error": "ボーナスが受け取れませんでした、もう一度お試しください。\n何度もこの問題が起きた場合、お問い合わせください。",
  "reward.daily-bonus.claimed": "お米13個を受け取りました",
  "reward.daily-bonus.description": "ログインボーナスの受け取りは毎日4時（日本時間）にてリセットされます。",
  "settings": "設定",
  "settings.account.delete": "アカウントを削除",
  "settings.account.delete.confirm.button-countdown": "({{seconds}}秒後に削除可能になります)",
  "settings.account.delete.confirm.description": "アカウントを削除すればデータは元に戻せませんので、すべての会話内容も削除されます。",
  "settings.account.delete.confirm.title": "本当にアカウントを削除しますか？",
  "settings.account.delete.error.active-subscription.description": "プラン登録中のため、アカウントを削除できません。プランが期限を過ぎた後でしかアカウントを削除できます。",
  "settings.account.delete.error.active-subscription.title": "アカウントを削除できません",
  "settings.account.sign-out": "ログアウト",
  "settings.account.sign-out.confirm.description": "ログアウトすると、現在のデータにアクセスするために同じ方法でログインする必要があります。",
  "settings.account.sign-out.confirm.title": "本当にログアウトしますか？",
  "settings.account.title": "アカウント",
  "settings.account.user-id": "ユーザーID",
  "settings.account.user-id.description": "問題を報告する際に上記にあるユーザーIDを添付してください、より問題を特定しやすくなります。",
  "socials.discord.link": "https://discord.gg/grjhdf4SNb",
  "socials.discord.name": "公式Discord",
  "socials.instagram.link": "https://www.instagram.com/moguchat_/",
  "socials.instagram.name": "Instagram",
  "socials.tiktok.link": "https://www.tiktok.com/@moguchatai",
  "socials.tiktok.name": "TikTok",
  "socials.twitter.link": "https://x.com/MoguChatJP",
  "socials.twitter.name": "公式X（旧Twitter）",
  "socials.youtube.link": "https://www.youtube.com/@MoguChatJP",
  "socials.youtube.name": "YouTube",
  "support.chat": "チャットで問い合わせ",
  "support.title": "お問い合わせ",
  "tab.chat": "トーク",
  "tab.home": "ホーム",
  "tab.profile": "プロフィール",
  "tag.all": "おすすめ",
  "transaction.list.empty": "何もない",
  "transaction.list.title": "アイテム変更履歴",
  "transaction.type.CONSOLE": "運営変更",
  "transaction.type.EVENT_VOTE": "イベント投票",
  "transaction.type.PURCHASE": "パック購入",
  "transaction.type.REGULAR_MESSAGE_COST": "回答メッセージ",
  "transaction.type.REWARD_AD": "CM視聴ボーナス",
  "transaction.type.REWARD_DAILY_BONUS": "ログインボーナス",
  "transaction.type.UNKNOWN": "不明",
  "update.available": "新しいアップデートがあります",
  "update.available.message": "次回アプリを開く時に更新します",
  "user-inbox.empty": "メールがありません",
  "user-inbox.title": "メールボックス"
}
