import { forwardRef } from 'react'
import { Platform, Text, TextInput, type TextInputProps, type TextProps } from 'react-native'
import { twMerge } from 'tailwind-merge'
import { boldFontFamily, regularFontFamily } from '../../constants/Font'

const MGText = forwardRef<Text, TextProps & { children?: React.ReactNode; bold?: boolean }>(
  ({ bold, style, children, className, ...props }, ref) => {
    return (
      <Text
        {...props}
        ref={ref}
        style={[
          {
            fontFamily: bold ? boldFontFamily : regularFontFamily,
          },
          style,
        ]}
        className={twMerge('text-foreground', className)}
      >
        {children}
      </Text>
    )
  },
)

export default MGText

/**
 * MGSelectableText implements a MGText that behaves correctly on iOS.
 * Please note that you shall only pass `string` into the `value` prop.
 */
export const MGSelectableText = forwardRef<
  TextInput,
  Omit<TextInputProps, 'value' | 'children'> & { bold?: boolean; value: string }
>((props, ref) => {
  if (Platform.OS !== 'ios')
    return (
      <MGText {...props} selectable ref={ref}>
        {props.value}
      </MGText>
    )

  return (
    <TextInput
      {...props}
      ref={ref}
      multiline
      editable={false}
      style={[
        {
          fontFamily: props.bold ? boldFontFamily : regularFontFamily,
          userSelect: 'text',
        },
        props.style,
      ]}
      className={twMerge('text-foreground', props.className)}
    />
  )
})
