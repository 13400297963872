import Ionicons from '@expo/vector-icons/Ionicons'
import { captureException } from '@sentry/react-native'
import * as Burnt from 'burnt'
import clsx from 'clsx'
import * as AppleAuthentication from 'expo-apple-authentication'
import { makeRedirectUri, useAuthRequest as useGenericAuthRequest } from 'expo-auth-session'
import { useAuthRequest as useGoogleAuthRequest } from 'expo-auth-session/providers/google'
import { Image } from 'expo-image'
import { type Href, router, useLocalSearchParams } from 'expo-router'
import * as WebBrowser from 'expo-web-browser'
import { atom, useAtom, useAtomValue } from 'jotai'
import { jwtDecode } from 'jwt-decode'
import { useColorScheme } from 'nativewind'
import { type FC, type PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  Alert,
  Platform,
  TextInput,
  TouchableHighlight,
  type TouchableHighlightProps,
  TouchableOpacity,
  View,
} from 'react-native'
import { KeyboardAvoidingView } from 'react-native-keyboard-controller'
import { SafeAreaView } from 'react-native-safe-area-context'
import Svg, { Path } from 'react-native-svg'
import { AnimatedBackgroundImage } from '../components/common/AnimatedBackgroundImage'
import { MGIonicons } from '../components/common/MGIonicons'
import MGText from '../components/common/MGText'
import { DownloadAppButtons } from '../components/login/DownloadButtons'
import { MGActivityIndicator } from '../components/ui/MGActivityIndicator'
import { ampli } from '../lib/ampli'
import { config } from '../lib/config'
import { invalidateCurrentUser, mutateCurrentUser } from '../lib/hooks/useAuth'
import { trpcClient } from '../lib/services/trpc'
import { getLoginRedirect, setLoginRedirect, setToken } from '../lib/storage'
import { randomSafeString } from '../utils/string'

WebBrowser.maybeCompleteAuthSession()

const afterLoginRedirect = () => {
  const url = getLoginRedirect()
  if (url) {
    setLoginRedirect('')
    router.replace(url as Href)
  } else {
    router.replace('/(app)/(tabs)')
  }
}

const redirect =
  Platform.OS === 'web'
    ? makeRedirectUri({
        path: '/login',
      })
    : makeRedirectUri({
        scheme: 'ai.moguchat.app',
      })

const formatAppleOAuthUri = (state: string) => {
  const u = new URL('https://appleid.apple.com/auth/authorize')
  u.searchParams.set('client_id', 'ai.moguchat.backend')
  u.searchParams.set('redirect_uri', `${config.authApiBaseUrl}/api/v1/oauth2/apple/${Platform.OS}`)
  u.searchParams.set('response_mode', 'form_post')
  u.searchParams.set('response_type', 'code')
  u.searchParams.set('scope', 'email name')
  u.searchParams.set('state', state)
  return u.toString()
}

type OAuthProvider = 'google' | 'apple' | 'token' // token is for dev only
const pendingProviderAtom = atom<OAuthProvider | null>(null)

const OAuthButton: FC<
  { provider: OAuthProvider; icon: React.ReactNode } & Omit<TouchableHighlightProps, 'disabled' | 'underlayColor'>
> = ({ provider, icon, ...props }) => {
  const { t } = useTranslation()
  const pendingProvider = useAtomValue(pendingProviderAtom)
  const isSelfPending = pendingProvider === provider
  const isOthersPending = pendingProvider !== provider && pendingProvider !== null
  const isAnyPending = pendingProvider !== null
  const { colorScheme } = useColorScheme()

  return (
    <TouchableHighlight
      disabled={isAnyPending}
      className={clsx(
        'flex flex-row items-center gap-2.5 py-4 px-5 rounded-2xl',
        isOthersPending ? 'bg-neutral-400' : 'bg-neutral-100',
        props.className,
      )}
      underlayColor={isSelfPending ? '#000' : colorScheme === 'dark' ? '#000' : '#a3a3a3'}
      accessibilityRole="button"
      accessibilityLabel={t(`auth.login.${provider}`)}
      accessibilityState={{ disabled: isAnyPending, busy: isSelfPending }}
      {...props}
    >
      <View className="flex flex-row items-center gap-2.5 w-full">
        <View className={clsx('size-8 flex items-center justify-center', isOthersPending && 'opacity-50')}>{icon}</View>
        <MGText bold className={clsx('flex-1 text-lg', isOthersPending ? 'text-neutral-500' : 'text-neutral-900')}>
          {t(`auth.login.${provider}`)}
        </MGText>
        {!isSelfPending ? (
          !isAnyPending && <Ionicons name="chevron-forward-outline" size={24} color="#999" />
        ) : (
          <MGActivityIndicator size={24} />
        )}
      </View>
    </TouchableHighlight>
  )
}

const GoogleOAuthButton: FC = () => {
  const { t } = useTranslation()
  const [, setPendingProvider] = useAtom(pendingProviderAtom)
  const state = useMemo(() => randomSafeString(24), [])

  const [request, , promptAsync] = useGoogleAuthRequest({
    iosClientId: config.auth.google.iosClientId,
    androidClientId: config.auth.google.androidClientId,
    webClientId: config.auth.google.webClientId,
    scopes: ['email', 'profile'],
    redirectUri: redirect,
    state,
    shouldAutoExchangeCode: false,
    responseType: 'code',
    usePKCE: false,
  })

  const handleGoogleLogin = async () => {
    if (!request) return

    setToken()
    setPendingProvider('google')
    ampli.login({ provider: 'google' })

    try {
      console.log('Prompting OAuth login at URL:', request.url)
      const result = await promptAsync()

      if ('params' in result) {
        const { code, error } = result.params
        if (error) throw error

        console.debug('oauth result', JSON.stringify(result, null, 2))

        if (!code) throw new Error('Failed to Sign in with Google: no code returned')

        const { token } = await trpcClient.user.authorize.mutate({
          provider: 'google',
          code,
          platform: Platform.OS as 'ios' | 'android' | 'web',
          redirectUri: redirect,
        })

        setToken(token)
        await invalidateCurrentUser()
        afterLoginRedirect()
      }
    } catch (error) {
      Alert.alert(t('auth.login.error'), error instanceof Error ? error.message : String(error))
      captureException(error)
    } finally {
      setPendingProvider(null)
    }
  }

  return <OAuthButton provider="google" icon={<GoogleIcon />} onPress={handleGoogleLogin} />
}

const AppleOAuthButton: FC = () => {
  const { t } = useTranslation()
  const [, setPendingProvider] = useAtom(pendingProviderAtom)
  const state = useMemo(() => randomSafeString(24), [])

  // useGenericAuthRequest is for the web/android platform
  const [, , promptAsync] = useGenericAuthRequest(
    {
      // yes, this client id is the backend client_id (not to be confused with the iOS native client_id which is just the bundle id of the app)
      clientId: 'ai.moguchat.backend',
      redirectUri: Platform.OS === 'web' ? redirect : 'ai.moguchat.app://', // ai.moguchat.app://: faked redirect uri so expo will start Linking.addEventListener
      extraParams: {
        response_mode: 'form_post',
      },
      state,
      usePKCE: false,
    },
    {
      authorizationEndpoint: 'https://appleid.apple.com/auth/authorize',
    },
  )

  const handleAppleLogin = async () => {
    setToken()
    setPendingProvider('apple')
    ampli.login({ provider: 'apple' })

    try {
      if (Platform.OS === 'ios') {
        // ios-specific native login
        const credential = await AppleAuthentication.signInAsync({
          requestedScopes: [
            AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
            AppleAuthentication.AppleAuthenticationScope.EMAIL,
          ],
        })

        if (!credential.identityToken) throw new Error('Failed to Sign in with Apple: no identity token returned')

        const { token } = await trpcClient.user.authorize.mutate({
          provider: 'apple',
          identityToken: credential.identityToken,
          platform: Platform.OS,
        })

        setToken(token)
        // ios native login only returns the full name via the built-in authentication library instead of passing
        // that information to the redirect_uri. So we need to manually retrieve that info and set it on the user for them.
        const displayName = credential.fullName?.givenName
        if (displayName) {
          await mutateCurrentUser({
            displayName,
          })
        } else {
          await invalidateCurrentUser()
        }
        afterLoginRedirect()
      } else {
        // web/android login via the generic auth request (opening up oauth page)
        // this method will form_post the response to the redirect_uri, so we are going to handle that on the backend
        // and then redirect back to the app
        const result = await promptAsync({
          url: formatAppleOAuthUri(state),
        })
        console.log('oauth result', result)
        if (result.type === 'success') {
          const { token } = result.params
          if (!token) throw new Error('Failed to Sign in with Apple: no token returned')

          setToken(token)
          await invalidateCurrentUser()
          afterLoginRedirect()
        }
      }
    } catch (error: unknown) {
      if (error && typeof error === 'object' && 'code' in error && error.code === 'ERR_REQUEST_CANCELED') {
        return
      }
      Alert.alert(t('auth.login.error'), error instanceof Error ? error.message : String(error))
      console.error(error, JSON.stringify(error, null, 2))
      captureException(error)
    } finally {
      setPendingProvider(null)
    }
  }

  return <OAuthButton provider="apple" icon={<AppleIcon />} onPress={handleAppleLogin} />
}

const TokenLogin: FC = () => {
  const [tokenInput, setTokenInput] = useState<string>('')
  const [pendingProvider, setPendingProvider] = useAtom(pendingProviderAtom)
  const isSelfPending = pendingProvider === 'token'
  const isOthersPending = pendingProvider !== 'token' && pendingProvider !== null
  const isAnyPending = pendingProvider !== null
  const { t } = useTranslation()
  const { impersonate_token: impersonateToken } = useLocalSearchParams()
  const { colorScheme } = useColorScheme()

  const handleTokenLogin = async (token: string) => {
    if (isAnyPending) return
    setToken()
    setPendingProvider('token')
    try {
      setToken(token)
      const user = await invalidateCurrentUser()
      if (!user) {
        throw new Error('Invalid token: Received empty user data')
      }
      afterLoginRedirect()
      return user
    } catch (error) {
      setToken()
      Alert.alert(t('auth.login.error'), error instanceof Error ? error.message : String(error))
      captureException(error)
    } finally {
      setPendingProvider(null)
    }
  }

  useEffect(() => {
    ;(async () => {
      if (!Array.isArray(impersonateToken) && impersonateToken) {
        const decoded = jwtDecode(impersonateToken)
        const payload = decoded as { mogu_typ: string } | undefined
        if (!payload || payload.mogu_typ !== 'impersonation') {
          throw new Error('Invalid token: not an impersonation token')
        }

        setToken() // remove the token
        await invalidateCurrentUser() // invalidate user

        const user = await handleTokenLogin(impersonateToken)
        if (user) {
          Burnt.alert({
            preset: 'done',
            title: 'Logged in as an impersonated user',
            message: `You can now use MoguChat as "${user.displayName}" (${user.id}).`,
          })
        }
      }
    })()
  }, [impersonateToken])

  return (
    __DEV__ && (
      <View className="flex flex-row items-center w-full h-12">
        <TextInput
          className={clsx(
            'bg-neutral-100 rounded-2xl rounded-r-none p-4 flex-1 h-full',
            isOthersPending && 'opacity-50',
          )}
          value={tokenInput}
          onChangeText={setTokenInput}
          placeholder="Token (Dev Only)"
          editable={!isAnyPending}
          placeholderTextColor={colorScheme === 'dark' ? '#777777' : '#666666'}
          accessibilityLabel={t('auth.login.token-input')}
          accessibilityHint={t('auth.login.token-input.hint')}
        />
        <TouchableHighlight
          className="rounded-2xl rounded-l-none h-full flex justify-center overflow-hidden"
          onPress={() => handleTokenLogin(tokenInput)}
          activeOpacity={0.6}
          disabled={isAnyPending}
          accessibilityRole="button"
          accessibilityLabel={t('auth.login.token-submit')}
          accessibilityState={{ disabled: isAnyPending }}
        >
          <View
            className={clsx(
              'bg-primary-300 dark:bg-primary-700 px-4 flex-1 flex items-center justify-center flex-row',
              isOthersPending && 'opacity-50',
            )}
          >
            <MGText bold className="text-black dark:text-white">
              Login via Token
            </MGText>
            {isSelfPending && <MGActivityIndicator size={24} />}
          </View>
        </TouchableHighlight>
      </View>
    )
  )
}

const GoogleIcon: FC = () => (
  <Svg width={24} height={24} viewBox="0 0 24 24">
    <Path
      d="M23.745 12.27c0-.79-.07-1.54-.19-2.27h-11.3v4.51h6.47c-.29 1.48-1.14 2.73-2.4 3.58v3h3.86c2.26-2.09 3.56-5.17 3.56-8.82Z"
      fill="#4285F4"
    />
    <Path
      d="M12.255 24c3.24 0 5.95-1.08 7.93-2.91l-3.86-3c-1.08.72-2.45 1.16-4.07 1.16-3.13 0-5.78-2.11-6.73-4.96h-3.98v3.09C3.515 21.3 7.565 24 12.255 24Z"
      fill="#34A853"
    />
    <Path
      d="M5.525 14.29c-.25-.72-.38-1.49-.38-2.29s.14-1.57.38-2.29V6.62h-3.98a11.86 11.86 0 0 0 0 10.76l3.98-3.09Z"
      fill="#FBBC05"
    />
    <Path
      d="M12.255 4.75c1.77 0 3.35.61 4.6 1.8l3.42-3.42C18.205 1.19 15.495 0 12.255 0c-4.69 0-8.74 2.7-10.71 6.62l3.98 3.09c.95-2.85 3.6-4.96 6.73-4.96Z"
      fill="#EA4335"
    />
  </Svg>
)

const AppleIcon: FC = () => <MGIonicons name="logo-apple" size={26} />

const PROVIDERS = Platform.OS === 'ios' ? (['apple', 'google'] as const) : (['google', 'apple'] as const)

const LegalLink: FC<
  PropsWithChildren<{
    href: string
  }>
> = ({ children, href }) => {
  return (
    <TouchableOpacity
      className={clsx(
        'font-medium border-b border-white/20',
        Platform.OS === 'android' ? 'translate-y-[0.35rem]' : Platform.OS === 'ios' ? 'translate-y-[0.6rem]' : '',
      )}
      onPress={async () => {
        try {
          await WebBrowser.openBrowserAsync(href, {
            dismissButtonStyle: 'close', // "cancel" | "close" | "done" (default to "close")
            toolbarColor: '#FFF', // in-app browser UI background color
            controlsColor: '#fe68c4', // in-app browser buttons color
          })
        } catch (error) {
          console.error(error)
        }
      }}
    >
      <MGText className="text-white/50 text-xs">{children}</MGText>
    </TouchableOpacity>
  )
}

const LoginScreenContent: FC = () => {
  const { t } = useTranslation()

  return (
    <KeyboardAvoidingView className="flex-1 justify-center items-start p-8 lg:p-16 xl:p-24 2xl:p-64">
      <View className="flex-1 lg:flex-none" />

      <Image
        source={require('../assets/images/icon.png')}
        style={{
          width: 96,
          height: 96,
          borderRadius: 20,
          marginBottom: 20,
        }}
        contentFit="contain"
        className="shadow-sm-light"
      />

      <MGText bold className="text-2xl mb-2 text-white">
        {t('app.name')}
      </MGText>
      <MGText className="text-white/50 mb-8 text-center">{t('auth.login.required.description')}</MGText>

      <View className="flex flex-col gap-3 w-full lg:w-auto">
        {PROVIDERS.map((provider) =>
          provider === 'google' ? <GoogleOAuthButton key={provider} /> : <AppleOAuthButton key={provider} />,
        )}
        <TokenLogin />
      </View>
      <View className="mt-4 pl-1">
        <MGText className="text-white/50 text-xs">
          <Trans
            t={t}
            i18nKey="auth.legal-notice"
            components={{
              privacy: <LegalLink href="https://moguchat.ai/privacy" />,
              tos: <LegalLink href="https://moguchat.ai/terms" />,
            }}
          />
        </MGText>
      </View>

      {Platform.OS === 'web' && <DownloadAppButtons />}
    </KeyboardAvoidingView>
  )
}

const LoginScreen = () => {
  return (
    <View className="flex-1 bg-black">
      <AnimatedBackgroundImage
        source={require('../assets/images/pix-masonry.webp')}
        xOffset={Platform.OS === 'web' ? -1000 : -200}
        imageScaleFactor={Platform.OS === 'web' ? 3.5 : 1}
      />
      <View className="w-full h-full bg-black/60 absolute top-0 left-0" />
      <SafeAreaView className="flex-1 flex flex-row">
        <LoginScreenContent />
      </SafeAreaView>
    </View>
  )
}

export default LoginScreen
