import { Image } from 'expo-image'
import { View } from 'react-native'
import type { Tag } from '../../../backend/src/app/trpc/tag/schema'
import MGText from '../common/MGText'

export type CharacterSquareCardProps = {
  id: string
  name: string
  createdAt: Date | string
  description: string
  tags: Omit<Tag, 'createdAt' | 'updatedAt'>[]
  avatarUrl: string
}

export default function CharacterSquareCard({
  character,
}: {
  character: CharacterSquareCardProps
}) {
  return (
    <View className="p-2 w-full">
      <View className="flex flex-col w-full items-center gap-2 rounded-3xl bg-neutral-100 overflow-hidden">
        <Image
          recyclingKey={character.id}
          source={character.avatarUrl}
          contentFit="cover"
          placeholderContentFit="cover"
          contentPosition="center"
          style={{
            width: '100%',
            aspectRatio: 1,
          }}
        />
        <View className="flex flex-col flex-1 px-4 pb-4 w-full">
          <MGText bold className="text-lg flex-1" numberOfLines={1}>
            {character.name}
          </MGText>
          <MGText numberOfLines={1} className="text-neutral-500">
            {character.description?.replaceAll('\n', ' ').replaceAll('\r', ' ')}
          </MGText>
          <MGText className="text-sm text-neutral-400 pt-1 min-h-5" numberOfLines={1}>
            {character.tags?.map((tag) => `#${tag.name}`).join(' ')}
          </MGText>
        </View>
      </View>
    </View>
  )
}
