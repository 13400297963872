import { Image } from 'expo-image'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TouchableOpacity } from 'react-native'
import IconModal from './IconModal'

export const BadgeDisplay = ({
  imageUrl,
  description,
  name,
}: { imageUrl: string; description: string; name: string }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { t } = useTranslation()

  return (
    <Fragment>
      <TouchableOpacity hitSlop={4} onPress={() => setIsModalVisible(true)}>
        <Image
          source={imageUrl}
          contentFit="cover"
          placeholderContentFit="cover"
          style={{
            width: 18,
            height: 18,
            borderRadius: 9999,
          }}
        />
      </TouchableOpacity>

      <IconModal
        visible={isModalVisible}
        severity="neutral"
        title={name}
        description={description}
        icon="checkmark"
        overrideIcon={
          <Image
            source={imageUrl}
            contentFit="cover"
            placeholderContentFit="cover"
            style={{ width: 48, height: 48, borderRadius: 9999 }}
          />
        }
        hideCancelButton
        onCancel={() => setIsModalVisible(false)}
        onConfirm={() => setIsModalVisible(false)}
        primaryButtonContent={t('action.close')}
      />
    </Fragment>
  )
}
