import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { FlatList, RefreshControl, View } from 'react-native'
import Markdown from 'react-native-markdown-display'
import { SafeAreaView } from 'react-native-safe-area-context'
import MGHeader from '../../../components/common/MGHeader'
import MGHeaderText from '../../../components/common/MGHeaderText'
import { MGLoading } from '../../../components/common/MGLoading'
import MGText from '../../../components/common/MGText'
import { regularFontFamily } from '../../../constants/Font'
import { dayjs } from '../../../lib/dayjs'
import { trpcReact, trpcUtils } from '../../../lib/services/trpc'

export default function UserInboxView() {
  const { t } = useTranslation()

  const { data: inboxMessages, isPending, refetch, isLoading } = trpcReact.mailbox.items.list.useQuery()

  const markAsRead = trpcReact.mailbox.items.markAsRead.useMutation({
    onSuccess: () => {
      trpcUtils.mailbox.items.list.invalidate()
    },
  })

  return (
    <SafeAreaView edges={['top', 'left', 'right']} className="flex-1 bg-background">
      <MGLoading show={isPending} />
      <MGHeader headerBody={<MGHeaderText>{t('user-inbox.title')}</MGHeaderText>} />

      <FlatList
        contentInsetAdjustmentBehavior="automatic"
        onViewableItemsChanged={({ viewableItems }) => {
          const unreadIds = viewableItems.filter((item) => !item.item.readAt).map((item) => item.item.id)

          if (unreadIds.length > 0) {
            markAsRead.mutate({ ids: unreadIds })
          }
        }}
        refreshControl={<RefreshControl refreshing={isLoading} onRefresh={refetch} />}
        className="flex-1 px-6 mx-auto w-full"
        data={inboxMessages}
        renderItem={({ item }) => (
          <UserInboxItem
            key={item.id}
            title={item.title}
            body={item.body}
            updatedAt={dayjs(item.updatedAt).format('YYYY-MM-DD')}
            readAt={item.readAt}
          />
        )}
        ListEmptyComponent={<MGText className="text-center text-neutral-500 py-64">{t('user-inbox.empty')}</MGText>}
        ItemSeparatorComponent={Separator}
        contentContainerStyle={{ paddingVertical: 8 }}
      />
    </SafeAreaView>
  )
}

const UserInboxItem = ({
  title,
  body,
  updatedAt,
  readAt,
}: {
  title: string
  body: string
  updatedAt: string
  readAt: string | null
}) => {
  return (
    <View className="w-full py-2 relative">
      <View className="flex-row items-start justify-between gap-2">
        <MGText bold className={clsx('text-3xl font-bold shrink', !readAt ? 'text-primary-400' : 'text-neutral-500')}>
          {title}
        </MGText>
        <MGText className="text-sm text-neutral-500 shrink-0">{updatedAt}</MGText>
      </View>
      <Markdown
        style={{
          body: {
            width: '100%',
            lineHeight: 24,
            fontSize: 16,
            fontFamily: regularFontFamily,
          },
        }}
      >
        {body}
      </Markdown>
    </View>
  )
}

const Separator = () => {
  return <View className="w-full h-[1px] bg-neutral-200 my-4" />
}
