import clsx from 'clsx'
import { Image } from 'expo-image'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlatList, Keyboard, RefreshControl, TextInput, TouchableOpacity, View } from 'react-native'
import { KeyboardAvoidingView } from 'react-native-keyboard-controller'
import Animated, { SlideInDown } from 'react-native-reanimated'
import { SafeAreaView } from 'react-native-safe-area-context'
import { CharacterCreateFAB } from '../../../components/character/CharacterCreateFAB'
import { ListEmptyComponent, ListFooterComponent, renderItem } from '../../../components/character/CharacterList'
import MGHeader from '../../../components/common/MGHeader'
import { MGIonicons } from '../../../components/common/MGIonicons'
import MGText from '../../../components/common/MGText'
import { ampli } from '../../../lib/ampli'
import { trpcReact } from '../../../lib/services/trpc'

export default function CharacterSearch() {
  const [searchTextInput, setSearchTextInput] = useState('')
  const [searchText, setSearchText] = useState('')
  const [showCreateFAB, setShowCreateFAB] = useState(false)

  const { t } = useTranslation()

  const {
    data: charactersRes,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isPending,
    isFetching,
    refetch: refetchCharacters,
  } = trpcReact.character.search.useInfiniteQuery(
    {
      limit: 20,
      filter: {
        keyword: searchText,
      },
    },
    {
      getNextPageParam: (lastPage) => lastPage.pagination.nextCursor,
      enabled: !!searchText,
    },
  )

  const onRefresh = () => {
    refetchCharacters()
  }

  const loadMore = () => {
    setShowCreateFAB(true)
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }
  }
  const characters = useMemo(() => charactersRes?.pages?.flatMap((page) => page?.items) ?? [], [charactersRes?.pages])

  useEffect(() => {
    if (searchText && !isPending && characters.length === 0) {
      setShowCreateFAB(true)
    }
  }, [characters, isPending, searchText])

  const onSearch = () => {
    setSearchText(searchTextInput)
    ampli.searchCharacter({
      keyword: searchTextInput,
    })
  }

  return (
    <View className="flex-1 bg-background relative">
      <SafeAreaView className="flex-1">
        <MGHeader
          fullSizeBody
          headerBody={
            <View className="flex-1 w-full flex flex-row items-center gap-2">
              <TextInput
                defaultValue={searchTextInput}
                onChangeText={setSearchTextInput}
                autoFocus
                className="flex-1 border border-neutral-300 rounded-full px-4 py-3 mx-1 text-foreground"
                style={{
                  textAlignVertical: 'center',
                }}
                onSubmitEditing={onSearch}
              />
              <TouchableOpacity
                hitSlop={12}
                onPress={() => {
                  onSearch()
                  // dismiss keyboard
                  Keyboard.dismiss()
                }}
                disabled={!searchTextInput}
                className={clsx(!searchTextInput && 'opacity-10')}
              >
                <MGIonicons name="search" size={24} />
              </TouchableOpacity>
            </View>
          }
        />
        <KeyboardAvoidingView
          behavior="padding"
          className="flex-1 relative flex flex-col h-full transition-all"
          enabled
        >
          {!searchText ? (
            <View className="flex-1 relative flex flex-col w-full h-full items-center justify-center px-2">
              <MGText style={{ fontSize: 20, color: '#999' }}>{t('character.search.input.placeholder')}</MGText>
            </View>
          ) : isPending ? (
            <View className="flex-1 relative flex flex-col w-full h-full items-center justify-center">
              <Image
                source={require('../../../assets/images/status/loading.png')}
                style={{ width: 50, height: 50 }}
                autoplay={true}
              />
            </View>
          ) : (
            <FlatList
              data={characters}
              renderItem={renderItem}
              keyExtractor={(item) => item.id}
              style={{ flex: 1 }}
              onEndReached={loadMore}
              onEndReachedThreshold={0.5}
              ListEmptyComponent={ListEmptyComponent}
              ListFooterComponent={ListFooterComponent(isFetchingNextPage)}
              refreshControl={<RefreshControl refreshing={isFetching} onRefresh={onRefresh} />}
            />
          )}
          {showCreateFAB && (
            <Animated.View
              entering={SlideInDown.damping(40).stiffness(450).mass(1.2)}
              className="absolute bottom-8 left-0 right-0"
            >
              <CharacterCreateFAB size="md" justify="center" />
            </Animated.View>
          )}
        </KeyboardAvoidingView>
      </SafeAreaView>
    </View>
  )
}
