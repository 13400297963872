import { LinearGradient, type LinearGradientProps } from 'expo-linear-gradient'
import type { FC } from 'react'

export const MGGradientBackground: FC<Omit<LinearGradientProps, 'colors' | 'start' | 'end'>> = (props) => {
  return (
    <LinearGradient
      colors={['#ffade1', '#f88a8c']}
      start={{ x: 0.0549318364, y: 0.1423416199 }}
      end={{ x: 0.8476343224, y: 0.7333600642 }}
      {...props}
    />
  )
}
