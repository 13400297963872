export const config = {
  appVersion: process.env.EXPO_PUBLIC_APP_VERSION || '0.0.0',
  commitHash: process.env.EXPO_PUBLIC_COMMIT_HASH || '0000000',
  apiBaseUrl: process.env.EXPO_PUBLIC_API_BASE_URL || 'https://api.moguchat.ai',
  // authApiBaseUrl:
  // you might want to set this to https://moguchat-api.pnpublic.mewtant.co during development,
  // if you are using the shared development database (moguchat_server_dev).
  // this should be left as the same as apiBaseUrl on production.
  authApiBaseUrl: process.env.EXPO_PUBLIC_AUTH_API_BASE_URL || 'https://api.moguchat.ai',
  amplitudeApiUrl: process.env.EXPO_PUBLIC_AMPLITUDE_API_URL || 'https://t.moguchat.ai/',
  sentryDsn:
    process.env.EXPO_PUBLIC_SENTRY_DSN ||
    'https://c42d47c33b63d691fc48b6c81359edd9@o374408.ingest.us.sentry.io/4508698307330048',

  crisp: {
    websiteId: '4eddb201-18b6-4947-bdf4-8512c47c561c',
  },

  auth: {
    google: {
      iosClientId: '159818266415-sodatq66qk1rr1p9jkg3c0adtuf4e635.apps.googleusercontent.com',
      androidClientId: '159818266415-74i271dolv0v0n1ga0k0rgs4pvlt7ter.apps.googleusercontent.com',
      webClientId: '159818266415-ctq3ulvfkr38q9mdgkmcg7poccfp9tea.apps.googleusercontent.com',
    },
  },

  appDownloadUrls: {
    _default: 'https://getmogu.chat',
    ios: 'https://apple.co/3ZRlt2a',
    android: 'https://play.google.com/store/apps/details?id=ai.moguchat.app',
  },
} as const
