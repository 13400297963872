import { z } from '../../lib/zod'

// TODO: isolate this schema into a separate package within the monorepo, then reuse them across admin-frontend and frontend
export const characterFormSchema = z
  .object({
    name: z.string().min(1),
    avatarUrl: z.string(),
    visibility: z.enum(['PUBLIC', 'PRIVATE']).default('PRIVATE'),
    description: z.string().optional(),
    tagIds: z.array(z.string()).min(0).max(5).default([]),

    details: z.object({
      /** 第一人称；キャラクターが使う第一人称は？例：ぼく */
      firstPersonPronoun: z.string().max(20).optional(),
      /** 第二人称；キャラクターが使う第二人称は？ */
      secondPersonPronoun: z.string().max(20).optional(),
      /** 呼び方；キャラクターの他人への呼び方は？ */
      calloutStyle: z.string().max(20).optional(),
      /** 口調・話し方；キャラクターはどんな口調、もしくは話し方をしますか？ */
      speakingTone: z.string().max(20).optional(),

      /** 基本情報 */
      characterSetting: z.string().min(1).max(5000),
      /** 詳細設定；キャラクターの性格は口調、会話のトーンや反応に影響します。ここで細かい部分を設定しておきましょう。キャラの性格と好き嫌いや特技、口癖などを入れましょう。 */
      persona: z.string().min(1).max(5000),
      /** 背景ストーリー・世界観 */
      backgroundSetting: z.string().min(1).max(5000),
      /** 会話例 */
      exampleConversation: z.string().min(1).max(5000),

      /** 挨拶・始まりの一言 */
      introMessage: z.string().min(1).max(2000),
    }),
  })
  .refine(
    (data) => {
      if (data.visibility === 'PRIVATE') return true
      return !!data.description
    },
    {
      // FIXME: i18n this error message
      message: 'Description is required for public characters',
      path: ['description'],
    },
  )
export type CharacterForm = z.infer<typeof characterFormSchema>

export const generateCharacterFormSchema = z.object({
  prompt: z.string().min(4).max(2000),
})

export type GenerateCharacterForm = z.infer<typeof generateCharacterFormSchema>
