import Ionicons from '@expo/vector-icons/Ionicons'
import { Image } from 'expo-image'
import { Link } from 'expo-router'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, View } from 'react-native'
import { config } from '../../lib/config'
import { MGIonicons } from '../common/MGIonicons'
import MGText from '../common/MGText'
import { DownloadAppGooglePlayIcon, DownloadAppStoreIcon } from '../common/StoreDownloadIcon'

const DownloadAppButtonMobile: FC = () => {
  const { t } = useTranslation()

  const url = (() => {
    if (Platform.OS === 'web') {
      // https://stackoverflow.com/a/9039885/8829241
      if (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
          navigator.platform,
        ) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
      ) {
        return config.appDownloadUrls.ios
      }
      return config.appDownloadUrls.android
    }
    return config.appDownloadUrls._default
  })()

  return (
    <Link
      href={url}
      className="flex lg:hidden flex-row items-center gap-2.5 py-3 px-4 rounded-2xl bg-black/50 border-2 border-black/70 mt-4 w-full"
    >
      <Image
        source={require('../../assets/images/icon.png')}
        style={{
          width: 24,
          height: 24,
          borderRadius: 6,
        }}
        contentFit="contain"
        className="shadow-sm-light"
      />

      <MGText className="text-neutral-300 text-md flex-1" bold>
        {t('download-app.title')}
      </MGText>

      <Ionicons name="download-outline" size={24} color="#fff" className="opacity-70" />
    </Link>
  )
}

const DownloadAppButtonDesktop: FC = () => {
  const { t } = useTranslation()

  return (
    <View className="hidden lg:flex items-center gap-4 absolute right-8 lg:right-16 xl:right-24 2xl:right-64">
      <Image
        source={require('../../assets/images/mobile-app-lineup.webp')}
        style={{
          width: 1253 / 2,
          height: 1145 / 2,
        }}
        className="w-full"
      />

      <View className="flex flex-row items-center gap-2">
        <MGIonicons name="download-outline" size={24} color="#fff" className="opacity-70" />

        <MGText className="text-white text-2xl" bold>
          {t('download-app.title')}
        </MGText>
      </View>

      <View className="flex flex-row gap-4 mt-4">
        <Link href={config.appDownloadUrls.ios}>
          <DownloadAppStoreIcon className="h-12 hover:brightness-125 active:brightness-75" />
        </Link>
        <Link href={config.appDownloadUrls.android}>
          <DownloadAppGooglePlayIcon className="h-12 hover:brightness-125 active:brightness-75" />
        </Link>
      </View>
    </View>
  )
}

export const DownloadAppButtons: FC = () => {
  return (
    <>
      <DownloadAppButtonDesktop />
      <DownloadAppButtonMobile />
    </>
  )
}
