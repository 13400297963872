{
  "action.back": "Back",
  "action.cancel": "Cancel",
  "action.close": "Close",
  "action.confirm": "Confirm",
  "action.report": "Report",
  "action.report-and-block": "Report and Block",
  "action.submit": "Submit",
  "app-settings.color-scheme.enum.dark": "Dark",
  "app-settings.color-scheme.enum.light": "Light",
  "app-settings.color-scheme.enum.system": "Auto",
  "app-settings.color-scheme.title": "Appearance",
  "app-settings.language": "Language",
  "app-settings.language-selector.title": "Select Language",
  "app-settings.title": "App Settings",
  "app.name": "MoguChat",
  "app.name.domain": "MoguChat.AI",
  "auth.legal-notice": "By continuing, you agree to have read and accepted the MoguChat <tos>Terms of Service</tos> and <privacy>Privacy Policy</privacy>.",
  "auth.login.apple": "Log in with Apple",
  "auth.login.error": "Login failed",
  "auth.login.google": "Log in with Google",
  "auth.login.required.description": "Log in and chat with characters!",
  "auth.login.required.title": "Please log in",
  "auth.login.title": "Login",
  "character.action": "Action",
  "character.action.chat": "Chat",
  "character.action.delete": "Delete",
  "character.action.delete.confirm.description": "Deleted characters cannot be restored!",
  "character.action.delete.confirm.title": "Are you sure you want to delete?",
  "character.action.delete.success": "Character deleted",
  "character.action.edit": "Edit",
  "character.action.edit.success": "Edit has been saved.",
  "character.author.report": "Report creator",
  "character.author.report.description": "Report the creator of this character to MoguChat official",
  "character.create": "Create character",
  "character.create.complete": "Character creation complete",
  "character.create.short-title": "Create",
  "character.deleted-name": "The character does not exist",
  "character.edit": "Edit complete",
  "character.edit-complete.share": "Share",
  "character.edit-complete.start-chat": "Start chatting",
  "character.edit-complete.submit-to-featured": "Apply for recommendation",
  "character.edit-complete.success-message": "Character has been saved",
  "character.edit-complete.title": "Character creation complete",
  "character.edit.action.back": "Back",
  "character.edit.action.continue": "Next",
  "character.edit.complete": "Character editing complete",
  "character.edit.confirm.description": "If you stop here, you may lose your edits.",
  "character.edit.confirm.title": "Are you really going to stop?",
  "character.edit.error.title": "Could not create character",
  "character.edit.submit.create": "Create",
  "character.edit.submit.update": "Update",
  "character.field.author": "Author",
  "character.field.avatar": "Icon",
  "character.field.background-setting": "Background Story and Worldview",
  "character.field.background-setting.help.content": "To give depth to a character, set a past and world view in the character's background. If this part is well-established, the character gains realism.  \nExample:  \n{{char}} lives in modern-day Japan. {{char}} was a star of the athletics club during university days but now belongs to the sales department at ○○ Company. ○○ Company shares a flat in a mansion with {{user}}, and jogs every day in the nearby park. They fear that living together with {{user}} might reveal their romantic relationship, so they always stagger their departure times and cut it close when arriving at work.",
  "character.field.background-setting.help.title": "Let's include the worldview where the characters exist and their background story here.",
  "character.field.background-setting.placeholder": "What kind of world does this character live in and what kind of life do they lead?",
  "character.field.character-setting": "Basic Information",
  "character.field.character-setting.help.content": "In character settings, setting the user's username to {{user}} and the AI character's name to {{char}} makes it more understandable system-wise. It is recommended to input the basic information of the character in the basic settings. Decide and input the character's name, age, gender, traits, and relationship with the user.\n\nExample 1:\n- Name of {{char}}: Tomotaka Sasaki\n- Gender of {{char}}: Male\n- Age of {{char}}: 25\n- Occupation of {{char}}: Employee at XX company\n- Relationship between {{char}} and {{user}}: They are lovers, secretly dating so as not to be discovered by the company.\n\nExample 2: I am Tomotaka Sasaki, 25 years old and working as an employee at XX company. I've been working at this company since graduating from university at 22, and at first, my colleagues mistakenly called me \"Tomoki,\" which has now become a sort of nickname. I have been in a relationship with {{user}} for two years, and we are still hiding our relationship from those around us. Our company is quite strict about office romances, and if found out, they might possibly make us resign.\n\nIf you want to know more detailed instructions, please join the [official Discord](https://discord.com/invite/grjhdf4SNb) of MoguChat or refer to this [page](https://help.moguchat.ai/en-us/article/create-your-character-rt0b61/?bust=1743498807578).",
  "character.field.character-setting.help.title": "Let's input the settings that will be the basic information of the character",
  "character.field.character-setting.placeholder": "Let's put the basic information about the character here. Please state their name, age, and relationship with the user. A direct listing or a self-introduction style is recommended.",
  "character.field.chat-background": "Chat background image",
  "character.field.chat-background.description": "You can specify the background image when chatting with this character.",
  "character.field.description": "Character Introduction",
  "character.field.description.description": "This content will be made public to users. Let's introduce the character.",
  "character.field.example-conversation": "Conversation example",
  "character.field.example-conversation.help.content": "Example dialogue:\n\n\"Oh, you look lively today! I'm happy too!\"  \n\"Are you tired? You can talk to me about anything!\"  \n\"{{user}}'s smile is my source of energy!\"  \n\"Wow, that's interesting! Tell me more!\"  \n\"Don't be down, I'm here with you!\"  \n\nExample conversation:\n\n{{user}}: I'm tired today  \n{{char}}: Good job, did you overdo it? Let's take it easy today, shall I run a bath for you first?  \n{{user}}: Work has been busy lately... I'm too tired to move  \n{{char}}: Indeed, I ended up working overtime today too. Shall I carry you to the bath if you can't move?  \n{{user}}: Carry me!  \n{{char}}: Alright, alright, you've done well. Shall I prepare dinner from tomorrow?",
  "character.field.example-conversation.help.title": "Let's insert sample character lines and dialogues here.",
  "character.field.example-conversation.placeholder": "What kind of phrases or conversations would this character likely say? Let's write specific examples.",
  "character.field.intro-message": "Greetings・Opening Remarks",
  "character.field.intro-message.description": "Please enter the greeting words that the character first says at the beginning of the conversation",
  "character.field.intro-message.help.content": "The character sends the first message in this conversation. We recommend basic greetings or words that align with the scenario. If the character is meeting the user for the first time, \"Nice to meet you\" is suggested, or if they already know each other or are quite familiar, something like \"How was your day today?\" would encourage the continuation of the conversation.",
  "character.field.intro-message.help.title": "The words spoken by the character at the beginning of the conversation",
  "character.field.intro-message.placeholder": "Example: Good morning",
  "character.field.name": "Name",
  "character.field.name.description": "What is this character's name?",
  "character.field.persona": "Detailed settings",
  "character.field.persona.help.content": "The character's personality influences their tone, conversation style, and reactions. Let's set up the finer details here. Include the character's personality, likes and dislikes, special skills, and catchphrases. \n\nExample: \n{{char}} has a bright personality and likes sweet things. They speak in a lively and approachable manner and are well-liked by those around them. Their catchphrase when agreeing with others is \"Yes, yes, that's right!\"\n\nIf you want to know more about how to create this in detail, please join the official MoguChat Discord or refer to this page.",
  "character.field.persona.help.title": "Character Settings",
  "character.field.persona.placeholder": "Let's include detailed settings here such as the character's personality traits, preferences, and catchphrases.",
  "character.field.personality.callout-style": "Callout Style",
  "character.field.personality.callout-style.description": "How do characters refer to others?",
  "character.field.personality.description": "You can set the tone, first person, second person, and so on.",
  "character.field.personality.first-person": "First person",
  "character.field.personality.first-person.description": "What first-person pronoun does the character use? Example: boku",
  "character.field.personality.second-person": "Second person",
  "character.field.personality.second-person.description": "What second person pronoun does the character use?",
  "character.field.personality.speaking-tone": "Tone and manner of speaking",
  "character.field.personality.speaking-tone.description": "What kind of tone or manner of speaking does the character have?",
  "character.field.personality.title": "Other Settings",
  "character.field.tag": "Tag",
  "character.field.tag.description": "Please select the character tags (up to 5)",
  "character.field.tag.max-length": "You can select up to 5 tags",
  "character.field.visibility": "Public status",
  "character.field.visibility.description": "Please select the character's public status",
  "character.field.visibility.enum.private": "Private",
  "character.field.visibility.enum.private.description": "Only I can use it",
  "character.field.visibility.enum.public": "Public",
  "character.field.visibility.enum.public.description": "Available to everyone",
  "character.field.visibility.enum.unlisted": "Limited release",
  "character.field.visibility.enum.unlisted.description": "Only people who access via the shared URL with you can use it.",
  "character.filter.all": "For You",
  "character.filter.ugc": "Community",
  "character.form.auto-save": "Automatically Saved",
  "character.meta.title": "By {{authorName}}, {{characterName}}",
  "character.my": "My characters",
  "character.my.view-more": "See more",
  "character.no-owned-character": "No characters created yet",
  "character.not-found": "Character not found",
  "character.popularity": "Popularity",
  "character.report": "Report character",
  "character.report.description": "Report this character to moderators",
  "character.report.success": "Report sent",
  "character.request-featured.action.request": "Apply",
  "character.request-featured.hero-description": "Creators who wish to show their original characters to more people and have everyone use their masterpieces should apply here to add their characters to the \"Recommendations\" on the homepage. Choose the character you want to feature in \"Recommendations\" and submit your application.\nOnce approved, the character will be added to the recommendations.",
  "character.request-featured.hero-title": "Recommend your character to everyone!",
  "character.request-featured.no-characters.description": "Please note that you need to set the character to public in order to apply for recommended characters.",
  "character.request-featured.no-characters.title": "No eligible characters",
  "character.request-featured.select-character": "Select the character to apply",
  "character.request-featured.state.featured": "Added to recommendations!",
  "character.request-featured.state.pending": "Applied",
  "character.request-featured.title": "Recommended character application",
  "character.search.input.placeholder": "(・ω・)ノ Who do you feel like talking to today?",
  "character.search.title": "Find someone to talk to",
  "character.share.copied": "The link has been copied to the clipboard",
  "character.share.error-message": "Please try again later",
  "character.share.error-title": "Failed to share",
  "character.share.success": "Shared",
  "character.share.title": "{{characterName}} | MoguChat.AI",
  "character.stats.conversations-created": "Conversations created",
  "character.stats.messages-sent": "Messages sent",
  "character.submit-to-featured.error-title": "Failed to submit application",
  "character.submit-to-featured.success-message": "After review, it will be displayed in recommendations",
  "character.submit-to-featured.success-title": "Application submitted",
  "character.temp-save": "Auto-saved",
  "chat.action": "Action",
  "chat.action.delete": "Remove from list",
  "chat.action.delete.cancel": "Cancel",
  "chat.action.delete.confirm": "Delete chat",
  "chat.action.delete.description": "The content of deleted chats cannot be recovered.",
  "chat.action.delete.title": "Are you sure you want to delete this chat?",
  "chat.action.description": "What do you want to do with this chat?",
  "chat.action.restart": "Restart Chat",
  "chat.action.restart.confirm": "Restart",
  "chat.action.restart.description": "After resetting this chat, all conversation memories and data with the character will be lost. Data cannot be restored.",
  "chat.action.restart.title": "Do you really want to reset this chat?",
  "chat.action.title": "This chat",
  "chat.ads-banner-description": "Enjoy MoguChat better without ads! <underline>Subscribe MoguPass</underline>",
  "chat.ai-warning": "The character responses and other content are generated by AI. They may contain errors or inappropriate information and do not represent the official views or positions of MoguChat. They are also not related to any real persons or organisations. If you believe there is inappropriate information or issues with the response content, it would be appreciated if you could long-press the relevant message and report the issue.",
  "chat.ai-warning.title": "Caution",
  "chat.input.placeholder": "Please enter a message...",
  "chat.list": "Chat",
  "chat.list.empty": "No chats available",
  "chat.list.empty.action.find-character": "Discover",
  "chat.message.action.capture": "Screenshot Share",
  "chat.message.action.copy": "Copy",
  "chat.message.action.delete": "Delete",
  "chat.message.action.delete.failed": "Could not delete the message",
  "chat.message.action.edit": "Edit",
  "chat.message.action.regenerate": "Regenerate",
  "chat.message.action.report": "Report dissatisfied response",
  "chat.message.action.report.description": "Please tell us the reasons for your dissatisfaction so that we can provide better responses.",
  "chat.message.action.report.failed": "Could not report the message",
  "chat.message.action.report.reason.CHAT_CONTEXT_LOST": "I don't remember the content of the previous conversation",
  "chat.message.action.report.reason.EMPTY_RESPONSE": "The response content is not displayed",
  "chat.message.action.report.reason.MIXED_LANGUAGE": "There are unexpected foreign languages mixed in the responses",
  "chat.message.action.report.reason.OTHERS": "Others",
  "chat.message.action.report.reason.REPETITIVE_RESPONSE": "Repeating similar lines over and over",
  "chat.message.action.report.reason.SETTINGS_CONTEXT_LOST": "It does not align with the background or settings",
  "chat.message.action.report.success": "I have sent feedback",
  "chat.message.action.select": "Select...",
  "chat.message.action.send.failed": "Could not send message",
  "chat.message.no-more": "There are no messages",
  "chat.recreate": "Resume chat",
  "chat.recreate.description": "Would you like to start a new conversation with this character?",
  "chat.screenshot-share.more": "Share",
  "chat.screenshot-share.save-to-album.button": "Save",
  "chat.screenshot-share.save-to-album.success": "Saved to album",
  "chat.screenshot-share.text-content": "Enjoying a wonderful conversation on MoguChat!",
  "common.confirm.nonreversible": "This action cannot be undone!",
  "common.confirm.title": "Are you sure?",
  "common.countdown-button": "{{action}} (clickable after {{seconds}} seconds)",
  "common.go-back": "Back",
  "common.input.custom-value.option-label": "Custom...",
  "common.input.custom-value.placeholder": "Please enter custom options",
  "common.input.custom-value.title": "Enter custom options",
  "common.loading": "Loading",
  "common.select.unselect": "Deselect",
  "common.slogan": "Connect with your favourite on Mogu, the magic of conversation anytime, anywhere",
  "common.tracking-modal.description": "If you choose to allow tracking on the next screen, unrelated advertisements will no longer be displayed. We kindly ask you to select allow tracking on the next screen (´;ω;｀).",
  "common.tracking-modal.primary-button": "Next",
  "common.tracking-modal.title": "A kind request from MoguChat",
  "common.upload-image.error": "Failed to upload image. Please try again.",
  "common.upload-image.uploading": "Uploading",
  "components.remote-image-input.permission-required.description": "We need your photo library permission to let you pick images. Please head to the system settings and allow MoguChat to access your photo library.\n\nWe will not upload any of your photo library items unless you choose to do so.",
  "components.remote-image-input.permission-required.open-settings": "Open Settings",
  "components.remote-image-input.permission-required.title": "Photo Library Permission",
  "download-app.description": "Experience MoguChat on your mobile device",
  "download-app.title": "Download App",
  "error.auth-error": "There may be a problem with your login information. If this error occurs several times, please log out and try logging in again.",
  "error.avatar-required": "An avatar image of the character is needed",
  "error.character-not-found": "Character not found",
  "error.chat-not-found": "Chat not found",
  "error.empty-character-list": "Character not found",
  "error.empty-character-list.description": "There isn't a character I'm looking for yet (´;ω;｀) Let's try making one ourselves (｀･ω･´)",
  "error.general-error": "The operation could not be completed",
  "error.internal-error": "Encountered server error Σ(･ω･ﾉ)ﾉ！",
  "error.invalid-input": "Invalid input",
  "error.message-not-found": "Message not found",
  "error.moderation-flagged": "The input content was deemed not suitable for all ages.",
  "error.name-required": "A name is required",
  "error.no-chat": "No conversation available",
  "error.no-chat.description": "There's no chat yet (;´･ω･)",
  "error.no-owned-character": "You've not created any characters yet. Create one of your like!",
  "error.not-authorized": "You do not have permission, please report the issue",
  "error.page.back": "Return to homepage",
  "error.page.refresh": "Reload",
  "error.page.title": "A problem has occurred",
  "error.permission-denied": "Permission denied",
  "error.save-character-failed": "Unable to create your character",
  "error.tags-limit": "No more tags can be attached",
  "error.token-expired": "Session expired. Please log out and try logging in again.",
  "error.unknown-error": "Encountered an unknown error",
  "error.update-profile-failed": "Unknown error",
  "error.upload-failed": "Upload failed",
  "error.upload-failed.require-permission": "Photo gallery permission is not granted.",
  "event.nightclub.action.go-ranking-page": "Leaderboard",
  "event.nightclub.action.view-character": "Nominate",
  "event.nightclub.action.vote": "Supply champagne",
  "event.nightclub.action.vote.ranking-page": "Gift",
  "event.nightclub.characters.title": "Cast",
  "event.nightclub.introduction.content": "Constantly chased by busyness, feeling somewhat heavy every day. The place I arrived at with those weary feet was a door standing silently in the night. This is the nightclub \"MG\" - of course, still just the prologue of the story. Waiting for you beyond the door were the \"cast\" who soothe you with kind words and warm smiles. This is a place where you can forget all the unpleasant things, gently close your eyes, and free your mind. Yes, next to that special \"someone\" who has been waiting for you here at \"MG\" all along.",
  "event.nightclub.introduction.title": "Welcome to the paradise of the night――\nA special moment that gently embraces you",
  "event.nightclub.ranking.reward-description": "The top 3 hosts in the ranking will become the \"boyfriend version\" of themselves after the event ends and will be added as new characters!",
  "event.nightclub.ranking.title": "Leaderboard",
  "event.voting.no-ticket": "There are no voting items",
  "event.voting.send-button": "Gift",
  "event.voting.success": "Voted",
  "event.voting.title": "Gift champagne",
  "open-in-app.action.open": "Open",
  "open-in-app.download-dialog.title": "Get the MoguChat app",
  "open-in-app.title": "Open in MoguChat app",
  "profile.action.save": "Save",
  "profile.edit": "Edit Profile",
  "profile.edit.success": "Profile updated",
  "profile.field.biography": "Biography",
  "profile.field.biography.description": "Let's enter your settings here. This content is not visible to others.",
  "profile.field.biography.placeholder": "Let's enter your settings for when you have conversations",
  "profile.field.gender-interest": "Please select a category of interest",
  "profile.field.gender-interest.description": "Please tell me your preferences",
  "profile.field.gender-interest.enum.all": "Either way",
  "profile.field.gender-interest.enum.for-female": "For women",
  "profile.field.gender-interest.enum.for-male": "For men",
  "profile.field.nickname": "Nickname",
  "profile.field.nickname.description": "What would you like to be called?",
  "profile.field.nickname.hint": "The nickname must be between 2 and 20 characters in length.",
  "profile.field.nickname.placeholder": "Enter nickname",
  "profile.field.username": "Username",
  "profile.field.username.placeholder": "Please enter your username",
  "purchase.credit.ad-modal.description": "Cancelling will end the chat",
  "purchase.credit.ad-modal.error.rewarded.message": "We are sorry for the inconvenience caused, but okome could not be added. If this problem occurs, please contact us via the Support located on the profile page.",
  "purchase.credit.ad-modal.error.rewarded.title": "Failed to add okome",
  "purchase.credit.ad-modal.success.rewarded.message": "Okome added, enjoy.",
  "purchase.credit.ad-modal.success.rewarded.title": "Okome added",
  "purchase.credit.ad-modal.title": "Watch an AD to\ncontinue your chat.",
  "purchase.credit.help.content": "- 1 okome is consumed for each response message.  \n- If there is no okome, 1 onigiri will be consumed instead.  \n- Okome can be obtained for free.  \n- Onigiri do not have an expiration date.  \n- At 4 a.m. (Japan time) every day, okome obtained before then will be reset.  ",
  "purchase.credit.help.title": "About Okome and Onigiri",
  "purchase.credit.no-packs-available": "There are no items available for purchase.",
  "purchase.credit.only-available-on-mobile": "Purchase is only available on the app version",
  "purchase.credit.pack.marketing.first-time-bonus": "First-time: 2× Bonus!",
  "purchase.credit.pack.marketing.first-time-only": "First-time Limited!",
  "purchase.credit.store": "Store",
  "purchase.credit.watch-ads": "CM viewing",
  "purchase.credit.watch-ads.download-app-to-watch": "Download App to Watch Ads",
  "purchase.failed": "Purchase failed",
  "purchase.footer.privacy": "Privacy",
  "purchase.footer.restore-purchase": "Restore Purchase",
  "purchase.footer.terms": "Terms of Use",
  "purchase.payment-callback.contact-support": "Contact Support",
  "purchase.payment-callback.failed-to-verify.already-completed-the-payment.description": "If you have already completed the payment, don't worry! Due to network complications and other things, your payment might've been delayed. We're checking with the payment provider right now, and you may refresh the page a bit later to see if the payment went through. If refreshing the page still doesn't help, please kindly head to our support page, where our team will help you out.",
  "purchase.payment-callback.failed-to-verify.already-completed-the-payment.title": "Already completed the payment?",
  "purchase.payment-callback.failed-to-verify.description": "We couldn't verify your payment. {{error}}",
  "purchase.payment-callback.failed-to-verify.title": "Payment Verification Failed",
  "purchase.payment-callback.pending.description": "Please wait while we confirm your payment...",
  "purchase.payment-callback.pending.title": "Processing",
  "purchase.payment-callback.refresh": "Refresh",
  "purchase.payment-callback.success.description": "Your perks have now been added to your account.",
  "purchase.payment-callback.success.go-to-chat": "Continue",
  "purchase.payment-callback.success.title": "Payment Completed",
  "purchase.price.monthly": "{{value}}/month",
  "purchase.price.weekly": "{{value}}/week",
  "purchase.price.yearly": "{{value}}/year",
  "purchase.privilege.no-ads": "No adverts",
  "purchase.privilege.unlimited-characters": "Unlimited characters",
  "purchase.privilege.unlimited-messages": "Remove message limit",
  "purchase.subscription.banner.content": "Subscribe to remove ads and enjoy unlimited chats!",
  "purchase.subscription.benefits.ads-free": "Remove all <strong>ads</strong> and enjoy <strong>unlimited talks</strong>",
  "purchase.subscription.benefits.bonus-credits": "Get <strong>bonus credits</strong> everyday",
  "purchase.subscription.benefits.quicker-responses": "<strong>Quicker responses</strong>",
  "purchase.subscription.benefits.remove-all-ads": "Remove all <strong>ads</strong>",
  "purchase.subscription.benefits.unlimited-chats": "<strong>Unlimited messages</strong>",
  "purchase.subscription.benefits.unlimited-private-characters": "Unlimited <strong>private characters</strong>",
  "purchase.subscription.cta.already-subscribed": "Subscribed",
  "purchase.subscription.cta.free-trial": "Free Trial",
  "purchase.subscription.cta.manage-subscription": "Manage Subscription",
  "purchase.subscription.cta.subscribe": "Subscribe",
  "purchase.subscription.interval.monthly": "Monthly",
  "purchase.subscription.interval.yearly": "Yearly",
  "purchase.subscription.price-info.begin-free-trial": "{{days}} Days Free Trial",
  "purchase.subscription.product.mogupass.monthly.free-trial.description": "After a {{days}} day free trial, {{price}} will be charged.",
  "purchase.subscription.product.mogupass.monthly.subscription-info": "Charged each month. You may change or cancel the plan at any time.",
  "purchase.subscription.product.mogupass.yearly.free-trial.description": "After a {{days}} day free trial, {{price}} will be charged.",
  "purchase.subscription.product.mogupass.yearly.subscription-info": "Charged each year. You may change or cancel the plan at any time.",
  "purchase.subscription.reason.insufficient-balance": "Insufficient balance - you might choose to subscribe to MoguPass to enjoy unlimited ads-free chat!",
  "purchase.subscription.reason.private-character-limit-reached": "You have reached the maximum limit of how many private characters you may create. Subscribe MoguPass for unlimited characters.",
  "purchase.subscription.success": "You are now a MoguPass holder!",
  "purchase.subscription.title": "MoguPass",
  "purchase.unavailable.provider-mismatch.description": "You may have been registered on a different OS",
  "purchase.unavailable.provider-mismatch.title": "Cannot register",
  "reward.daily-bonus": "Login bonus",
  "reward.daily-bonus.claim": "Claim",
  "reward.daily-bonus.claim-error": "The bonus could not be received, please try again. If this problem occurs repeatedly, please contact us.",
  "reward.daily-bonus.claimed": "Received your daily claim",
  "reward.daily-bonus.description": "The login bonus is reset daily at 4 a.m. (Japan time).",
  "settings": "Settings",
  "settings.account.delete": "Delete account",
  "settings.account.delete.confirm.button-countdown": "It will be deletable after {{seconds}} seconds",
  "settings.account.delete.confirm.description": "If you delete your account, the data cannot be restored, and all conversation content will also be deleted.",
  "settings.account.delete.confirm.title": "Are you sure you want to delete the account?",
  "settings.account.delete.error.active-subscription.description": "You cannot delete your account because you are registered with a plan. You can only delete the account after the plan has expired.",
  "settings.account.delete.error.active-subscription.title": "Cannot delete account",
  "settings.account.sign-out": "Log out",
  "settings.account.sign-out.confirm.description": "When you log out, you will need to log in the same way to access the current data.",
  "settings.account.sign-out.confirm.title": "Are you sure you want to log out?",
  "settings.account.title": "Account",
  "settings.account.user-id": "User ID",
  "settings.account.user-id.description": "When reporting an issue, please attach the user ID above, as it will help to identify the problem more easily.",
  "socials.discord.link": "https://discord.gg/grjhdf4SNb",
  "socials.discord.name": "Official Discord",
  "socials.instagram.link": "https://www.instagram.com/moguchat_/",
  "socials.instagram.name": "Instagram",
  "socials.tiktok.link": "https://www.tiktok.com/@moguchatai",
  "socials.tiktok.name": "TikTok",
  "socials.twitter.link": "https://x.com/MoguChatJP",
  "socials.twitter.name": "Official X (formerly Twitter)",
  "socials.youtube.link": "https://www.youtube.com/@MoguChatJP",
  "socials.youtube.name": "YouTube",
  "support.chat": "Chat with Support",
  "support.title": "Support",
  "tab.chat": "Talk",
  "tab.home": "Home",
  "tab.profile": "Profile",
  "tag.all": "For You",
  "transaction.list.empty": "Nothing there",
  "transaction.list.title": "Transaction History",
  "transaction.type.CONSOLE": "Operational change",
  "transaction.type.EVENT_VOTE": "Event voting",
  "transaction.type.PURCHASE": "Purchase pack",
  "transaction.type.REGULAR_MESSAGE_COST": "Response message",
  "transaction.type.REWARD_AD": "CM viewing bonus",
  "transaction.type.REWARD_DAILY_BONUS": "Login bonus",
  "transaction.type.UNKNOWN": "Unknown",
  "update.available": "There is a new update",
  "update.available.message": "The app will be updated at the next time",
  "user-inbox.empty": "No mails yet.",
  "user-inbox.title": "Mailbox"
}
