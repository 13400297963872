import { Adsense } from '@ctrl/react-adsense'
import clsx from 'clsx'
import { useSetAtom } from 'jotai'
import { useColorScheme } from 'nativewind'
import type { FC } from 'react'
import { Trans } from 'react-i18next'
import { type TextProps, TouchableHighlight, View } from 'react-native'
import { purchaseSheetStateAtom } from '../../lib/purchaseSheet'
import { MGGradientBackground } from '../common/MGGradientBackground'
import MGText from '../common/MGText'
import { MoguPassSVG } from '../monetization/MoguPassSVG'

export const AdsBanner: FC<{ className?: string }> = ({ className }) => {
  const setPurchaseSheetState = useSetAtom(purchaseSheetStateAtom)
  const { colorScheme } = useColorScheme()

  return (
    <View className={clsx('w-full h-20 overflow-hidden', className)}>
      <View className={clsx('size-full relative')}>
        <TouchableHighlight
          className="w-full h-full absolute inset-0"
          onPress={() => setPurchaseSheetState({ opened: true, selected: 'subscribe' })}
          underlayColor={colorScheme === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'}
          activeOpacity={0.8}
        >
          <MGGradientBackground>
            <View className="size-full flex flex-col items-center justify-center gap-1 dark:bg-black/50 py-2">
              <MoguPassSVG height={40} color="#ffffff" />

              <Trans
                i18nKey="chat.ads-banner-description"
                parent={(props: TextProps) => <MGText className="text-white text-xs" {...props} />}
                components={{
                  underline: <MGText className="text-white underline" />,
                }}
              />
            </View>
          </MGGradientBackground>
        </TouchableHighlight>
        <Adsense
          client="ca-pub-4476343950956926"
          slot="7877982835"
          style={{
            width: '100%',
            height: 40,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'block',
            zIndex: 10,
          }}
          format="auto"
          responsive="true"
        />
      </View>
    </View>
  )
}
