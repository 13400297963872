import { Image } from 'expo-image'
import * as Linking from 'expo-linking'
import { router, type Href } from 'expo-router'
import { useSetAtom } from 'jotai'
import { useColorScheme } from 'nativewind'
import { useMemo, useRef, type FC } from 'react'
import { Alert, useWindowDimensions, View } from 'react-native'
import { TouchableHighlight } from 'react-native-gesture-handler'
import { useSharedValue } from 'react-native-reanimated'
import Carousel, { Pagination, type ICarouselInstance } from 'react-native-reanimated-carousel'
import { useActiveSubscription } from '../lib/hooks/useActiveSubscription'
import { purchaseSheetStateAtom } from '../lib/purchaseSheet'
import { trpcReact, type RouterOutputs } from '../lib/services/trpc'

const HOME_CAROUSEL_ASPECT_RATIO = 3 / 1

const useHomeCarouselDimensions = () => {
  const { width: widthDesktop } = useWindowDimensions()
  const width = Math.min(widthDesktop, 768)
  const height = (width + 16) / HOME_CAROUSEL_ASPECT_RATIO
  return { width, height }
}

type Banner = RouterOutputs['banner']['list'][number]

interface CarouselItem {
  onPress: () => void
  bannerUrl: string
}

const HomeCarousel: FC = () => {
  const ref = useRef<ICarouselInstance>(null)
  const progress = useSharedValue<number>(0)
  const { colorScheme } = useColorScheme()
  const { data: subscription } = useActiveSubscription()
  const setPurchaseSheetStateAtom = useSetAtom(purchaseSheetStateAtom)

  const onPressPagination = (index: number) => {
    ref.current?.scrollTo({
      /**
       * Calculate the difference between the current index and the target index
       * to ensure that the carousel scrolls to the nearest index
       */
      count: index - progress.value,
      animated: true,
    })
  }

  const { width, height } = useHomeCarouselDimensions()

  const { data: banners } = trpcReact.banner.list.useQuery()

  const handleBannerPress = (banner: Banner) => {
    if (banner.action.type === 'href' && banner.action.href) {
      if (banner.action.href.startsWith('/event/')) {
        const eventId = banner.action.href.split('/')[2]
        router.push({
          pathname: '/(app)/event/[eventId]' as const,
          params: { eventId },
        })
      } else if (banner.action.href.startsWith('/')) {
        router.push(banner.action.href as Href)
      } else {
        // open url
        Linking.openURL(banner.action.href)
      }
    } else if (banner.action.type === 'open_purchase_sheet') {
      setPurchaseSheetStateAtom({
        opened: true,
        selected: banner.action.withSelection ?? 'subscribe',
      })
    } else {
      Alert.alert('Unsupported', 'Please upgrade your app to the latest version to use this feature.')
    }
  }

  const data = useMemo<CarouselItem[]>(
    () =>
      banners
        ?.filter((banner) => !banner.predicate || (banner.predicate === 'not_subscribed' && !subscription))
        .map((banner) => ({
          onPress: () => handleBannerPress(banner),
          bannerUrl: banner.imageUrl,
        })) ?? [],
    [banners, subscription],
  )

  if (data.length === 0) {
    return null
  }

  return (
    <View className="w-full mx-auto flex flex-col items-center">
      <Carousel
        ref={ref}
        loop={data.length > 1}
        width={width}
        height={height}
        data={data}
        scrollAnimationDuration={500}
        autoPlay={data.length > 1}
        autoPlayInterval={5000}
        renderItem={({ item }) => (
          <View className="relative size-full p-2">
            <View className="relative size-full rounded overflow-hidden">
              <TouchableHighlight
                onPress={item.onPress}
                hitSlop={16}
                style={{ height: '100%', width: '100%' }}
                containerStyle={{ height: '100%', width: '100%' }}
              >
                <Image source={item.bannerUrl} style={{ height: '100%', width: '100%' }} />
              </TouchableHighlight>
            </View>
          </View>
        )}
        onProgressChange={progress}
        autoFillData
      />

      {data.length > 1 && (
        <Pagination.Basic
          progress={progress}
          data={data}
          dotStyle={{
            width: 20,
            height: 5,
            backgroundColor: colorScheme === 'dark' ? '#262626' : '#f1f1f1',
          }}
          activeDotStyle={{
            overflow: 'hidden',
            backgroundColor: colorScheme === 'dark' ? '#f1f1f1' : '#262626',
          }}
          containerStyle={{
            gap: 5,
            marginTop: 4,
            marginBottom: 12,
            borderRadius: 4,
            overflow: 'hidden',
          }}
          horizontal
          onPress={onPressPagination}
        />
      )}
    </View>
  )
}

export default HomeCarousel
