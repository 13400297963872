{
  "errors.custom": "입력 형식이 잘못되었습니다。",
  "errors.invalid_arguments": "인수가 잘못되었습니다。",
  "errors.invalid_date": "잘못된 날짜 및 시간 데이터입니다。",
  "errors.invalid_enum_value": "'{{received}}'은(는) 유효하지 않은 값입니다. {{- options}}에서 입력하세요.",
  "errors.invalid_intersection_types": "교차형 병합을 할 수 없습니다.",
  "errors.invalid_literal": "유효하지 않은 리터럴 값입니다. {{expected}}을 입력하세요.",
  "errors.invalid_return_type": "반환 값의 유형이 잘못되었습니다。",
  "errors.invalid_string.cuid": "{{validation}} 형식으로 입력해주세요.",
  "errors.invalid_string.datetime": "{{validation}} 형식으로 입력해주세요.",
  "errors.invalid_string.email": "{{validation}} 형식으로 입력해주세요.",
  "errors.invalid_string.endsWith": "\"{{endsWith}}\"로 끝나는 문자열이어야 합니다.",
  "errors.invalid_string.regex": "입력 형식이 잘못되었습니다。",
  "errors.invalid_string.startsWith": "\"{{startsWith}}\"로 시작하는 문자열이어야 합니다.",
  "errors.invalid_string.url": "{{validation}} 형식으로 입력해주세요.",
  "errors.invalid_string.uuid": "{{validation}} 형식으로 입력해주세요.",
  "errors.invalid_type": "{{expected}}에서의 입력을 기대하고 있지만, {{received}}가 입력되었습니다.",
  "errors.invalid_type_received_null": "필수",
  "errors.invalid_type_received_undefined": "필수",
  "errors.invalid_union": "입력 형식이 잘못되었습니다。",
  "errors.invalid_union_discriminator": "잘못된 식별자입니다. {{- options}}에서 입력하세요.",
  "errors.not_finite": "유한수여야 합니다.",
  "errors.not_multiple_of": "{{multipleOf}}의 배수여야 합니다.",
  "errors.too_big.array.exact": "{{maximum}}개의 요소여야 합니다.",
  "errors.too_big.array.inclusive": "{{maximum}}개 이하의 요소여야 합니다。",
  "errors.too_big.array.not_inclusive": "{{maximum}}개보다 적은 요소여야 합니다.",
  "errors.too_big.date.exact": "{{- maximum, datetime}}의 날짜여야 합니다.",
  "errors.too_big.date.inclusive": "{{- maximum, datetime}}이전의 날짜여야 합니다.",
  "errors.too_big.date.not_inclusive": "{{- maximum, datetime}}보다 이전의 날짜여야 합니다.",
  "errors.too_big.number.exact": "{{maximum}}의 수치여야 합니다。",
  "errors.too_big.number.inclusive": "{{maximum}} 이하의 수치여야 합니다。",
  "errors.too_big.number.not_inclusive": "{{maximum}}보다 작은 값이어야 합니다.",
  "errors.too_big.set.exact": "입력 형식이 잘못되었습니다。",
  "errors.too_big.set.inclusive": "입력 형식이 잘못되었습니다。",
  "errors.too_big.set.not_inclusive": "입력 형식이 잘못되었습니다。",
  "errors.too_big.string.exact": "{{maximum}}자의 문자열이어야 합니다.",
  "errors.too_big.string.inclusive": "{{maximum}}자 이하의 문자열이어야 합니다。",
  "errors.too_big.string.not_inclusive": "{{maximum}}자보다 짧은 문자열이어야 합니다.",
  "errors.too_small.array.exact": "{{minimum}}개의 요소가 필요합니다.",
  "errors.too_small.array.inclusive": "{{minimum}}개 이상의 요소가 필요합니다。",
  "errors.too_small.array.not_inclusive": "{{minimum}}개 이상의 요소가 필요합니다。",
  "errors.too_small.date.exact": "{{- minimum, datetime}}의 날짜여야 합니다.",
  "errors.too_small.date.inclusive": "최소 {{- minimum, datetime}} 이후의 날짜여야 합니다.",
  "errors.too_small.date.not_inclusive": "{{- minimum, datetime}}보다 이후 날짜여야 합니다.",
  "errors.too_small.number.exact": "{{minimum}}의 수치여야 합니다.",
  "errors.too_small.number.inclusive": "{{minimum}} 이상의 수치여야 합니다。",
  "errors.too_small.number.not_inclusive": "{{minimum}}보다 큰 숫자여야 합니다.",
  "errors.too_small.set.exact": "입력 형식이 잘못되었습니다。",
  "errors.too_small.set.inclusive": "입력 형식이 잘못되었습니다。",
  "errors.too_small.set.not_inclusive": "입력 형식이 잘못되었습니다。",
  "errors.too_small.string.exact": "{{minimum}}문자 이상의 문자열이어야 합니다.",
  "errors.too_small.string.inclusive": "{{minimum}}자 이상의 문자열이어야 합니다。",
  "errors.too_small.string.not_inclusive": "{{minimum}}자보다 긴 문자열이어야 합니다.",
  "errors.unrecognized_keys": "오브젝트의 키 {{- keys}}를 식별할 수 없습니다。",
  "types.array": "배열",
  "types.bigint": "큰 정수",
  "types.boolean": "참거짓값",
  "types.date": "일시",
  "types.float": "부동소수점수",
  "types.function": "함수",
  "types.integer": "정수",
  "types.map": "지도",
  "types.nan": "NaN",
  "types.never": "결코",
  "types.null": "NULL",
  "types.number": "수치",
  "types.object": "객체",
  "types.promise": "약속",
  "types.set": "세트",
  "types.string": "문자열",
  "types.symbol": "심볼",
  "types.undefined": "정의되지 않음",
  "types.unknown": "알 수 없음",
  "types.void": "무효",
  "validations.cuid": "CUID",
  "validations.datetime": "일시",
  "validations.email": "이메일 주소",
  "validations.regex": "정규 표현식",
  "validations.url": "URL",
  "validations.uuid": "UUID"
}
