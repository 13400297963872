import * as NavigationBar from 'expo-navigation-bar'
import * as SystemUI from 'expo-system-ui'
import { useEffect } from 'react'
import { Platform } from 'react-native'
import { useTheme } from '../theme'

export function useAndroidNavigationBar() {
  const theme = useTheme()

  useEffect(() => {
    if (Platform.OS === 'android') {
      ;(async () => {
        await NavigationBar.setPositionAsync('absolute')
        await NavigationBar.setBackgroundColorAsync(theme.background)
        await SystemUI.setBackgroundColorAsync(theme.background)
      })()
    }
    if (Platform.OS === 'web') {
      SystemUI.setBackgroundColorAsync(theme.background)
    }
  }, [theme])
}
