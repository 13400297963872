// WebFontsLoader.tsx

import { useFonts } from 'expo-font'
import { useEffect, useState } from 'react'
import MGLoadingOverlay from './MGLoadingOverlay'

const MIN_FALLBACK_DURATION = 500

/**
 * For web only: include this to load fonts.
 * For native platforms, otf files are bundled via app.json.
 */
export function WebFontsLoader({
  children,
}: {
  children?: React.ReactNode
}) {
  const [forceFallback, setForceFallback] = useState(true)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setForceFallback(false)
    }, MIN_FALLBACK_DURATION)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  const [loaded, error] = useFonts({
    'ZenMaruGothic-400Regular': require('../../assets/fonts/ZenMaruGothic_400Regular.ttf'),
    'ZenMaruGothic-700Bold': require('../../assets/fonts/ZenMaruGothic_700Bold.ttf'),
    'NotoSerifJP-400Regular': require('../../assets/fonts/NotoSerifJP_400Regular.ttf'),
    'NotoSerifJP-700Bold': require('../../assets/fonts/NotoSerifJP_700Bold.ttf'),
  })

  const fallback = <MGLoadingOverlay visible={true} />

  return (loaded || error) && forceFallback ? fallback : children
}
