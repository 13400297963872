import { type Dispatch, type SetStateAction, useEffect, useState } from 'react'
import { MMKV, useMMKVString } from 'react-native-mmkv'

export const storage = new MMKV()

const TOKEN_KEY = 'moguchat-auth-token'
const HAS_CHAT_KEY = 'moguchat-has-chat'
const LOGIN_REDIRECT_KEY = 'moguchat-login-redirect'
const COLOR_SCHEME_KEY = 'moguchat-color-scheme'

export const getHasChat = () => {
  return storage.getBoolean(HAS_CHAT_KEY)
}

export const setHasChat = (hasChat: boolean) => {
  return storage.set(HAS_CHAT_KEY, hasChat)
}

export const getToken = () => {
  return storage.getString(TOKEN_KEY)
}

export const setToken = (token?: string) => {
  if (!token) {
    storage.delete(TOKEN_KEY)
    storage.delete(HAS_CHAT_KEY)
    return
  }
  return storage.set(TOKEN_KEY, token)
}

export const useIsTokenSet = () => {
  const [isTokenSet, setIsTokenSet] = useState(!!getToken())
  useEffect(() => {
    const ln = storage.addOnValueChangedListener((key) => {
      if (key === TOKEN_KEY) {
        setIsTokenSet(!!getToken())
      }
    })

    return () => {
      ln.remove()
    }
  }, [])
  return isTokenSet
}

export const getLoginRedirect = () => {
  return storage.getString(LOGIN_REDIRECT_KEY)
}

export const setLoginRedirect = (redirect: string) => {
  return storage.set(LOGIN_REDIRECT_KEY, redirect)
}

type ColorSchemeType = 'light' | 'dark' | 'system'

export const useColorScheme = () => {
  const [colorScheme, setColorScheme] = useMMKVString(COLOR_SCHEME_KEY)
  return [
    colorScheme as ColorSchemeType | undefined,
    setColorScheme as Dispatch<SetStateAction<ColorSchemeType>>,
  ] as const
}
