import { useCallback, useEffect, useState } from 'react'

const useCountdown = (seconds: number) => {
  const [remaining, setRemaining] = useState(0)
  const [isRunning, setIsRunning] = useState(false)

  const start = useCallback(() => {
    setRemaining(seconds)
    setIsRunning(true)
  }, [seconds])

  useEffect(() => {
    let timer: number | null = null

    if (isRunning && remaining > 0) {
      timer = window.setInterval(() => {
        setRemaining((prev) => prev - 1)
      }, 1000)
    } else if (remaining === 0) {
      setIsRunning(false)
    }

    return () => {
      if (timer) {
        clearInterval(timer)
      }
    }
  }, [remaining, isRunning])

  return [remaining, start] as const
}

export default useCountdown
