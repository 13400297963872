import clsx from 'clsx'
import { Image } from 'expo-image'
import { Link, useNavigation, usePathname } from 'expo-router'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TouchableHighlight, TouchableOpacity, View } from 'react-native'
import AppDownloadModal from './AppDownloadModal'
import { MGIonicons } from './MGIonicons'
import MGText from './MGText'

const LogoLink = () => {
  const pathname = usePathname()
  return (
    <Link href="/" className={clsx('flex items-center', pathname === '/' && 'cursor-default')}>
      <Image
        key="LogoLink_Logo"
        recyclingKey="LogoLink_Logo"
        source={require('../../assets/images/icon.png')}
        style={{ width: 40, height: 40, borderRadius: 8 }}
      />
    </Link>
  )
}

export default function DesktopHeader({
  trailingView,
  hideTabs = false,
  disableBackButton = false,
}: {
  trailingView?: React.ReactNode
  hideTabs?: boolean
  disableBackButton?: boolean
}) {
  const { t } = useTranslation()
  const pathname = usePathname()
  const navigation = useNavigation()
  const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false)

  const tabs = [
    { name: t('tab.home'), href: '/' },
    { name: t('tab.chat'), href: '/chats' },
    { name: t('tab.profile'), href: '/profile' },
  ] as const

  return (
    <View className="flex flex-row items-center px-4 h-16 bg-background border-b border-neutral-100 relative justify-between">
      {/* Navigation Tabs */}
      <View className="absolute left-0 right-0 flex flex-row justify-center items-center">
        {!disableBackButton && (
          <TouchableOpacity className="flex items-center" onPress={() => navigation.goBack()}>
            <View className="flex flex-row items-center px-6 h-16 border-l border-r border-neutral-100 gap-2 text-neutral-500 group-hover:text-neutral-800 group-active:text-neutral-300">
              <MGIonicons name="chevron-back" size={24} className="opacity-50" />
            </View>
          </TouchableOpacity>
        )}

        {!hideTabs &&
          tabs.map((tab) => (
            <Link key={tab.href} href={tab.href} asChild>
              <View className="flex items-center justify-center px-4 h-16 transition-all group">
                <MGText
                  bold={pathname === tab.href}
                  className={clsx(
                    'text-lg transition-all',
                    pathname === tab.href
                      ? 'text-primary-400 font-bold'
                      : 'text-neutral-500 group-hover:text-neutral-800 group-active:text-neutral-300 mt-1',
                  )}
                  style={{
                    textShadowColor: 'rgba(0, 0, 0, 0.1)',
                    textShadowOffset: { width: 0, height: 1 },
                    textShadowRadius: 1,
                  }}
                >
                  {tab.name}
                </MGText>

                <View
                  className={clsx(
                    'h-0.5 w-full mt-1 rounded-full transition',
                    pathname === tab.href ? 'bg-primary-400' : 'bg-transparent',
                  )}
                />
              </View>
            </Link>
          ))}
      </View>

      <View className="flex-shrink-0 flex flex-row items-center gap-3">
        <LogoLink />

        <TouchableHighlight
          onPress={() => setIsDownloadModalVisible(true)}
          className="px-3 py-1.5 rounded-lg bg-primary-50 hover:bg-primary-100 active:bg-primary-200 border border-primary-600/20 shadow-md-light hover:shadow-md-hovered-light transition-all"
          underlayColor="#e81a89/10"
          activeOpacity={0.8}
        >
          <View className="flex flex-row items-center gap-2">
            <MGIonicons name="cloud-download-outline" size={16} color="#e81a89" />
            <MGText className="text-sm text-primary-500" bold>
              {t('download-app.title')}
            </MGText>
          </View>
        </TouchableHighlight>
      </View>

      {/* Right section */}
      <View className="flex justify-end flex-row items-center gap-2">{trailingView}</View>

      <AppDownloadModal visible={isDownloadModalVisible} onClose={() => setIsDownloadModalVisible(false)} />
    </View>
  )
}
