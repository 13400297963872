import { Image } from 'expo-image'
import { router, usePathname } from 'expo-router'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import MGHeader from '../components/common/MGHeader'
import MGHeaderText from '../components/common/MGHeaderText'
import { MGModalButton } from '../components/common/MGModalButton'
import MGText from '../components/common/MGText'

export default function NotFoundScreen() {
  const path = usePathname()
  return (
    <SafeAreaView className="flex-1 bg-background relative">
      <MGHeader headerBody={<MGHeaderText>Something went wrong</MGHeaderText>} />
      <View className="flex-1 items-center justify-center gap-5">
        <Image source={require('../assets/images/status/non-ideal.png')} style={{ width: 128, height: 128 }} />
        <MGText className="text-lg" bold>
          Not Found
        </MGText>
        <MGText className="text-neutral-600 text-sm bg-neutral-100 p-2">{path}</MGText>
        <MGModalButton
          foregroundAppearance="light"
          className="bg-primary-400 dark:bg-primary-500 text-white rounded-full px-8 py-4 mt-8"
          onPress={() => router.replace('/')}
        >
          Back to Home
        </MGModalButton>
      </View>
    </SafeAreaView>
  )
}
