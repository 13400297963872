import clsx from 'clsx'
import { Image } from 'expo-image'
import * as Sharing from 'expo-sharing'
import { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, View } from 'react-native'
import ViewShot from 'react-native-view-shot'
import type { UseChatMessagesItem } from '../../app/(app)/chat/[chatId]'
import { dayjs } from '../../lib/dayjs'
import { useFormatRelativeTime } from '../../lib/hooks/useFormatRelativeTime'
import MGHeaderText from '../common/MGHeaderText'
import MGText from '../common/MGText'

export default function ChatSharingView({
  messages,
  isSharing,
  onSharingEnd,
  avatar,
  name,
}: {
  messages: UseChatMessagesItem[]
  isSharing: boolean
  onSharingEnd: () => void
  avatar?: React.ReactNode
  name?: string
}) {
  const { t } = useTranslation()
  const viewRef = useRef<ViewShot>(null)

  const onShare = () => {
    if (!viewRef.current?.capture) return

    viewRef.current
      .capture()
      .then((uri) => {
        if (Platform.OS === 'web') {
          const link = document.createElement('a')
          link.href = uri
          link.download = `${name}-MoguChat.png`
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        } else {
          Sharing.shareAsync(`file://${uri}`, {
            mimeType: 'image/png',
          })
        }
      })
      .catch((error) => {
        console.log('error', error)
      })
      .finally(onSharingEnd)
  }

  useEffect(() => {
    if (isSharing && messages.length > 0) {
      setTimeout(() => {
        onShare()
      }, 500)
    }
  }, [isSharing, messages])

  const sortedMessages = useMemo(() => {
    const processedMessages = messages.slice()
    processedMessages.sort((a, b) => dayjs(a.createdAt).diff(dayjs(b.createdAt)))
    return processedMessages
  }, [messages])

  return (
    <ViewShot
      ref={viewRef}
      style={{
        maxWidth: 520,
      }}
    >
      <View className="bg-background p-4">
        <View className="flex flex-row items-center justify-between mb-4 border-b border-neutral-100 pb-4">
          <View className="flex-row items-center gap-2">
            {avatar}
            <MGHeaderText>{name}</MGHeaderText>
          </View>
          <View className="flex flex-row items-center gap-2">
            <MGText className="text-sm text-neutral-500">{t('app.name.domain')}</MGText>
            <Image
              source={require('../../assets/images/icon.png')}
              style={{
                width: 32,
                height: 32,
                borderRadius: 8,
              }}
            />
          </View>
        </View>
        {sortedMessages.map((item) => (
          <ChatMessage key={item.id} message={item} avatar={avatar} />
        ))}
      </View>
    </ViewShot>
  )
}

const ChatMessage = ({
  message,
  avatar,
}: {
  message: UseChatMessagesItem
  avatar: React.ReactNode
}) => {
  const formattedTime = useFormatRelativeTime(new Date(message.createdAt), true)

  return (
    <View className="flex flex-row gap-2 py-1 items-center">
      <View
        className="flex flex-row gap-2 flex-1"
        style={{
          flexDirection: message.role === 'USER' ? 'row-reverse' : 'row',
        }}
      >
        {message.role === 'CHARACTER' && avatar}
        <View
          className="flex-1 flex flex-row gap-2"
          style={{
            flexDirection: message.role === 'USER' ? 'row-reverse' : 'row',
          }}
        >
          <View
            style={{
              maxWidth: '80%',
            }}
          >
            <View
              className={clsx(
                'p-3',
                message.role === 'CHARACTER'
                  ? 'bg-neutral-100 self-start rounded-r-2xl rounded-bl-2xl'
                  : 'bg-primary-300 dark:bg-primary-900 self-end rounded-l-2xl rounded-br-2xl',
              )}
            >
              <MGText className="text-base">{message.content}</MGText>
            </View>
          </View>

          <View
            className={clsx(
              'flex flex-col gap-2 justify-between py-2 flex-shrink-0',
              message.role === 'USER' && 'order-first',
            )}
          >
            <View />
            <MGText className="text-xs text-neutral-500">{formattedTime}</MGText>
          </View>
        </View>
      </View>
    </View>
  )
}
