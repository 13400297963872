import { Image } from 'expo-image'
import { View } from 'react-native'
import { BadgeDisplay } from '../common/BadgeDisplay'
import MGText from '../common/MGText'

export default function UserChip({
  user,
}: {
  user: {
    avatarUrl: string | null
    displayName: string
    badges?: {
      id: string
      imageUrl: string
      description: string
      name: string
    }[]
  }
}) {
  return (
    <View className="flex flex-row items-center gap-1">
      <Image
        source={user.avatarUrl}
        style={{
          width: 34,
          height: 34,
          borderRadius: 9999,
        }}
        contentFit="cover"
        placeholderContentFit="cover"
        placeholder={require('../../assets/images/user-avatar-placeholder.png')}
        transition={100}
      />
      <MGText className="ml-1">{user.displayName}</MGText>
      {user.badges && user.badges.length > 0 && (
        <View className="flex flex-row items-center gap-1">
          {user.badges.map((badge) => (
            <BadgeDisplay key={badge.id} imageUrl={badge.imageUrl} description={badge.description} name={badge.name} />
          ))}
        </View>
      )}
    </View>
  )
}
