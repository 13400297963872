import * as Burnt from 'burnt'
import { useRouter } from 'expo-router'
import { type FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, View } from 'react-native'
import { isAdRewarded } from '../../lib/ads'
import { ampli } from '../../lib/ampli'
import { useAppLovinRewarded } from '../../lib/applovin'
import { useCurrentUser } from '../../lib/hooks/useAuth'
import { invalidateInventory } from '../../lib/hooks/useInventory'
import MGBaseModal from '../common/MGBaseModal'
import { MGModalButton } from '../common/MGModalButton'
import MGText from '../common/MGText'
import { MoguPassBanner } from './MoguPassBanner'

export const OutOfBalanceModal: FC<{
  isOpen: boolean
  onClose: () => void
}> = ({ isOpen, onClose }) => {
  const { t } = useTranslation()
  const { data: user } = useCurrentUser()
  const [nonce] = useState(Math.random().toString(36).substring(2, 15))

  const [isBackendRewarded, setIsBackendRewarded] = useState(false)
  const [isNativeAdClosed, setIsNativeAdClosed] = useState(false)
  const [isWaitingForReward, setIsWaitingForReward] = useState(false)

  const router = useRouter()

  const onRewardEarned = () => {
    ampli.adRewardedEarned()

    isAdRewarded(user?.id!, nonce)
      .then((isRewarded) => {
        if (isRewarded) {
          invalidateInventory()
          setIsBackendRewarded(true)
        } else {
          Burnt.toast({
            title: t('purchase.credit.ad-modal.error.rewarded.title'),
            message: t('purchase.credit.ad-modal.error.rewarded.message'),
            preset: 'error',
          })
        }
      })
      .finally(() => {
        setIsWaitingForReward(false)
      })
  }

  const appLovinRewarded = useAppLovinRewarded({
    onRewardEarned,
    userId: user?.id!,
    nonce,
    onClosed: () => setIsNativeAdClosed(true),
  })

  const onShowAd = () => {
    if (Platform.OS === 'web') {
      window.open('https://getmogu.chat/', '_blank')
      return
    }
    appLovinRewarded.show()
    ampli.adRewardedShown()
    setIsWaitingForReward(true)
  }

  useEffect(() => {
    if (isNativeAdClosed && isBackendRewarded) {
      Burnt.toast({
        title: t('purchase.credit.ad-modal.success.rewarded.title'),
        message: t('purchase.credit.ad-modal.success.rewarded.message'),
        preset: 'done',
      })
      onClose()
    }
  }, [isNativeAdClosed, isBackendRewarded])

  return (
    <MGBaseModal
      icon="information"
      visible={isOpen}
      onClose={onClose}
      title={t('purchase.credit.ad-modal.title')}
      textAlignment="left"
      contentRequireScrollView={false}
    >
      <View className="flex-col gap-2">
        <MGText className="text-neutral-500">{t('purchase.credit.ad-modal.description')}</MGText>

        <MoguPassBanner
          rounded="xl"
          className="mt-2 mb-4"
          contentClassName="py-6 h-auto"
          onPress={() => {
            onClose()
            router.push('/subscribe')
          }}
        />

        <MGModalButton
          onPress={onShowAd}
          className="bg-blue-500"
          foregroundAppearance="light"
          loading={isWaitingForReward}
        >
          {t('purchase.credit.watch-ads')}
        </MGModalButton>

        <MGModalButton onPress={onClose} className="bg-neutral-100">
          {t('action.cancel')}
        </MGModalButton>
      </View>
    </MGBaseModal>
  )
}
