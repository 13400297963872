import { TRPCClientError } from '@trpc/client'
import { trpcClient } from './services/trpc'
import pRetry from 'p-retry'

interface PollConfig {
  pollingInterval?: number
  timeout?: number
  maxRetries?: number
}

export const isAdRewarded = async (
  userId: string,
  nonce: string,
  { pollingInterval = 3000, timeout = 30000, maxRetries = 10 }: PollConfig = {},
): Promise<boolean> => {
  const startTime = Date.now()

  while (Date.now() - startTime < timeout) {
    try {
      const result = await pRetry(
        async () => {
          const data = await trpcClient.store.adsOrder.get.query({ userId, nonce })
          if (!data?.id) {
            throw new Error('Ad reward not found')
          }
          return true
        },
        {
          retries: maxRetries,
          onFailedAttempt: (error) => {
            if (error.retriesLeft === 0) {
              console.error('All retries exhausted for ad reward polling:', error)
              return
            }
            if (error.name === 'AbortError' || error.cause instanceof TRPCClientError) {
              console.warn(
                `Network error while polling ad reward (${error.retriesLeft} retries left):`,
                error.cause || error.message,
              )
            } else {
              console.error(
                `Critical error while polling ad reward (${error.retriesLeft} retries left):`,
                error.cause || error.message,
              )
            }
          },
          minTimeout: pollingInterval,
          maxTimeout: pollingInterval + maxRetries * 1000,
        },
      )

      if (result) return true
    } catch (error) {
      console.error('Failed to poll ad reward after all retries:', error)
    }

    await new Promise((resolve) => setTimeout(resolve, pollingInterval))
  }

  return false
}
