import { type UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query'
import { useEffect, useMemo, useState } from 'react'
import { Platform } from 'react-native'
import {
  type SubscriptionIosPeriod,
  SubscriptionPlatform,
  getSubscriptions,
  requestSubscription,
  IapIosSk2,
} from 'react-native-iap'
import { TypeID } from 'typeid-js'
import { trpcClient, trpcUtils } from '../services/trpc'
import * as Sentry from '@sentry/react-native'
import { queryClient } from '../services/client'
import { useActiveSubscription } from '../hooks/useActiveSubscription'
import { initiateStripeCheckout } from '../services/stripe'
import { formatPrice } from '../formatPrice'

/**
 * There's no way to get this via react-native-iap so we have to hardcode it.
 * Yeah I know, this sucks.
 * But before we write our own native module, react-native-iap is our best bet...
 *
 * Fortunately it is only used once - to check eligibility of the intro offer.
 */
const IOS_MOGUPASS_GROUP_ID = '21645920'

export const MOGUPASS_SKUS = {
  MONTHLY: {
    privilegeId: 'privilege_mogupass',
    sku: Platform.select({
      ios: 'mogupass_monthly',
      android: 'mogupass', // we use this SKU just for query purposes.
      web: 'subscription_mogupass_monthly',
    }),
    // we specify this separately from SKU because android treat same subscription (despite different interval/base plan ID) as same SKU but different base plan IDs.
    androidBasePlanId: 'mogupass-monthly',
  },
  YEARLY: {
    privilegeId: 'privilege_mogupass',
    sku: Platform.select({
      ios: 'mogupass_yearly',
      android: 'mogupass', // we use this SKU just for query purposes.
      web: 'subscription_mogupass_yearly',
    }),
    // we specify this separately from SKU because android treat same subscription (despite different interval/base plan ID) as same SKU but different base plan IDs.
    androidBasePlanId: 'mogupass-yearly',
  },
} as const

const getIOSIntroductoryPricePeriodDays = ({
  numberOfPeriods,
  subscriptionPeriod,
}: {
  numberOfPeriods?: string
  subscriptionPeriod?: SubscriptionIosPeriod
}) => {
  if (!numberOfPeriods || !subscriptionPeriod) {
    console.warn('[IAP] getIOSIntroductoryPricePeriodDays: numberOfPeriods or subscriptionPeriod is undefined')
    return null
  }

  const numericalPeriods = Number.parseInt(numberOfPeriods, 10)
  if (Number.isNaN(numericalPeriods) || numericalPeriods <= 0) {
    console.warn('[IAP] getIOSIntroductoryPricePeriodDays: numericalPeriods is NaN or <= 0')
    return null
  }

  switch (subscriptionPeriod) {
    case 'DAY':
      return numericalPeriods
    case 'WEEK':
      return numericalPeriods * 7
    case 'MONTH':
      return numericalPeriods * 30
    case 'YEAR':
      return numericalPeriods * 365
    default:
      console.warn('[IAP] getIOSIntroductoryPricePeriodDays: subscriptionPeriod is not DAY, WEEK, MONTH, or YEAR')
      return null
  }
}

// billingPeriod is in the ISO 8601 duration format, e.g. P1D, P1W, P1M, P1Y
const getAndroidIntroductoryPricePeriodDays = (billingPeriod: string) => {
  if (!billingPeriod) {
    console.warn('[IAP] getAndroidIntroductoryPricePeriodDays: billingPeriod is undefined')
    return null
  }

  // Parse ISO 8601 duration format
  const match = RegExp(/^P(\d+)([DWMY])$/).exec(billingPeriod)
  if (!match) {
    console.warn('[IAP] getAndroidIntroductoryPricePeriodDays: invalid billingPeriod format', billingPeriod)
    return null
  }

  const [, amount, unit] = match
  const numericAmount = Number.parseInt(amount, 10)

  if (Number.isNaN(numericAmount) || numericAmount <= 0) {
    console.warn('[IAP] getAndroidIntroductoryPricePeriodDays: invalid amount', amount)
    return null
  }

  switch (unit) {
    case 'D':
      return numericAmount
    case 'W':
      return numericAmount * 7
    case 'M':
      return numericAmount * 30
    case 'Y':
      return numericAmount * 365
    default:
      console.warn('[IAP] getAndroidIntroductoryPricePeriodDays: invalid unit', unit)
      return null
  }
}

const getNormalizedSubscriptions = async () => {
  if (Platform.OS === 'web') {
    const prices = await trpcClient.store.prices.subscriptions.list.query({
      platform: 'STRIPE',
      subscriptionId: 'mogupass',
    })

    const monthlyPrice = prices.find((p) => p.interval === 'MONTHLY')?.currencyOptions.at(0)
    const yearlyPrice = prices.find((p) => p.interval === 'YEARLY')?.currencyOptions.at(0)

    if (!monthlyPrice || !yearlyPrice) {
      return []
    }

    const yearlyDiscount = Math.round((1 - yearlyPrice.unitAmount! / (monthlyPrice.unitAmount! * 12)) * 100)

    return prices.map((p) => ({
      interval: p.interval,
      platform: 'STRIPE',
      localizedPrice: formatPrice(p.currencyOptions.at(0)!.currency, p.currencyOptions.at(0)!.unitAmount ?? 0),
      eligibleForFreeTrialWithDays: p.freeTrialDays,
      subscribeParams: { sku: p.sku.stripePriceLookupKey },
      discountPercentage: p.interval === 'YEARLY' ? yearlyDiscount : null,
    }))
  }

  const storeSubscriptions = await getSubscriptions({
    skus: [
      ...new Set(
        Object.values(MOGUPASS_SKUS)
          .map((sku) => sku.sku)
          .filter((sku) => sku !== undefined),
      ),
    ],
  })

  if (Platform.OS === 'ios') {
    const mogupassEligibleForIntroOffer = (await IapIosSk2.isEligibleForIntroOffer(
      IOS_MOGUPASS_GROUP_ID,
    )) as unknown as boolean

    const subscriptions = storeSubscriptions.filter((s) => s.platform === SubscriptionPlatform.ios)
    const monthlySubscription = subscriptions.find((s) => s.productId === MOGUPASS_SKUS.MONTHLY.sku)
    const yearlySubscription = subscriptions.find((s) => s.productId === MOGUPASS_SKUS.YEARLY.sku)

    // Calculate yearly discount if both prices are available
    const yearlyDiscount =
      monthlySubscription?.price && yearlySubscription?.price
        ? Math.round((1 - Number(yearlySubscription.price) / (Number(monthlySubscription.price) * 12)) * 100)
        : null

    return subscriptions.map((subscription) => ({
      interval: subscription.productId === MOGUPASS_SKUS.MONTHLY.sku ? 'MONTHLY' : 'YEARLY',
      platform: 'APP_STORE',
      localizedPrice: subscription.localizedPrice,
      eligibleForFreeTrialWithDays:
        subscription.introductoryPricePaymentModeIOS === 'FREETRIAL' && mogupassEligibleForIntroOffer
          ? getIOSIntroductoryPricePeriodDays({
              numberOfPeriods: subscription.introductoryPriceNumberOfPeriodsIOS,
              subscriptionPeriod: subscription.introductoryPriceSubscriptionPeriodIOS,
            })
          : null,
      subscribeParams: {
        sku: subscription.productId,
      } as SubscribeParams,
      discountPercentage: subscription.productId === MOGUPASS_SKUS.YEARLY.sku ? yearlyDiscount : null,
    }))
  }

  // stupid android
  if (Platform.OS === 'android') {
    // first we assume there's only one subscription. In iOS we call this subscription group.
    const subscription = storeSubscriptions.at(0)
    if (!subscription || subscription.platform !== SubscriptionPlatform.android) {
      return []
    }

    // then we manually, painfully, match monthly and yearly subscriptionOfferDetails somewhat manually.
    const sortedSubscriptionOfferDetails = subscription.subscriptionOfferDetails.slice().sort((a, b) => {
      if (a.offerId && !b.offerId) return -1
      if (!a.offerId && b.offerId) return 1
      return 0
    })

    console.log('[IAP] sortedSubscriptionOfferDetails', sortedSubscriptionOfferDetails)

    // Find monthly and yearly base plans
    const monthlyOffer = sortedSubscriptionOfferDetails.find(
      (s) => s.basePlanId === MOGUPASS_SKUS.MONTHLY.androidBasePlanId,
    )
    const yearlyOffer = sortedSubscriptionOfferDetails.find(
      (s) => s.basePlanId === MOGUPASS_SKUS.YEARLY.androidBasePlanId,
    )

    // Calculate yearly discount if both prices are available
    const monthlyPrice = monthlyOffer?.pricingPhases.pricingPhaseList.find((p) => p.priceAmountMicros !== '0')
    const yearlyPrice = yearlyOffer?.pricingPhases.pricingPhaseList.find((p) => p.priceAmountMicros !== '0')

    const yearlyDiscount =
      monthlyPrice && yearlyPrice
        ? Math.round((1 - Number(yearlyPrice.priceAmountMicros) / (Number(monthlyPrice.priceAmountMicros) * 12)) * 100)
        : null

    return Object.entries(MOGUPASS_SKUS).flatMap(([key, sku]) => {
      const offer = sortedSubscriptionOfferDetails.find((s) => s.basePlanId === sku.androidBasePlanId)
      if (!offer) {
        return []
      }

      const freeTrialPricingPhase = offer.pricingPhases.pricingPhaseList.find((p) => p.priceAmountMicros === '0')
      const firstPricingPhaseThatIsNotFreeTrialAnymore = offer.pricingPhases.pricingPhaseList.find(
        (p) => p.priceAmountMicros !== '0',
      )
      if (!firstPricingPhaseThatIsNotFreeTrialAnymore) {
        return []
      }

      return {
        interval: key as 'MONTHLY' | 'YEARLY',
        platform: 'GOOGLE_PLAY',
        localizedPrice: firstPricingPhaseThatIsNotFreeTrialAnymore.formattedPrice,
        eligibleForFreeTrialWithDays: freeTrialPricingPhase
          ? getAndroidIntroductoryPricePeriodDays(freeTrialPricingPhase.billingPeriod)
          : null,
        subscribeParams: {
          sku: subscription.productId,
          subscriptionOfferTokenAndroid: offer.offerToken,
        } as SubscribeParams,
        discountPercentage: key === 'YEARLY' ? yearlyDiscount : null,
      }
    })
  }

  return []
}

export const storeSubscriptionsQueryOptions = () =>
  ({
    queryKey: [
      'monetization.getNormalizedSubscriptions',
      Platform.OS === 'web'
        ? trpcUtils.store.prices.subscriptions.list.queryOptions({
            platform: 'STRIPE',
            subscriptionId: 'mogupass',
          }).queryKey
        : undefined,
    ],
    queryFn: getNormalizedSubscriptions,
  }) satisfies UndefinedInitialDataOptions

export type SubscribeParams = {
  sku: string
  subscriptionOfferTokenAndroid?: string
}

export const useAvailableSubscriptions = () => {
  const { data: subscription } = useActiveSubscription()
  const currentUserPrivilegeId = subscription?.privilegeId ?? ('free' as const)

  const subscriptionsQuery = useQuery(storeSubscriptionsQueryOptions())

  return { currentUserPrivilegeId, storePlans: subscriptionsQuery.data }
}

export const subscribe = async ({
  userId,
  subscribeParams,
}: {
  userId: string
  subscribeParams: SubscribeParams
}) => {
  if (Platform.OS === 'web') {
    await initiateStripeCheckout({
      platform: 'STRIPE',
      type: 'SUBSCRIPTION',
      subscriptionId: 'mogupass',
      priceLookupKey: subscribeParams.sku,
    })
    throw new Error('StripePageOpenedError')
  }

  const subscription = await requestSubscription({
    sku: subscribeParams.sku,
    appAccountToken: TypeID.fromString(userId).toUUID(),
    obfuscatedAccountIdAndroid: userId,
    andDangerouslyFinishTransactionAutomaticallyIOS: true, // This is fine since we are handling messages server-side.
    subscriptionOffers: subscribeParams.subscriptionOfferTokenAndroid
      ? [{ sku: subscribeParams.sku, offerToken: subscribeParams.subscriptionOfferTokenAndroid }]
      : [],
  })

  queryClient.invalidateQueries(storeSubscriptionsQueryOptions())
  trpcUtils.user.getViewerActiveSubscription.invalidate()
  trpcUtils.user.getViewerPrivileges.invalidate()

  if (!subscription) {
    console.warn('[IAP] subscribe: no subscription returned', subscribeParams)
    throw new Error('No subscription returned')
  }
  if (Array.isArray(subscription) && subscription.length > 1) {
    // this should never happen: the actual source code of react-native-iap
    // does NOT return an array in ANY of its code paths but this array
    // return value is still in its return type. For god's sake.
    console.warn('[IAP] subscribe: multiple subscriptions returned', subscription)
    throw new Error('Multiple subscriptions returned, this should never happen')
  }
  const subscribeResult = Array.isArray(subscription) ? subscription.at(0) : subscription

  console.log('[IAP] subscribe result', subscribeResult)

  if (!subscribeResult) {
    Sentry.captureEvent({
      level: 'warning',
      message: '[IAP] subscribe failed: empty response',
      extra: {
        subscribeParams,
        subscribeResult,
      },
    })
    console.warn('[IAP] subscribe failed: empty response', subscribeParams, subscribeResult)
    throw new Error('Empty response, this should never happen')
  }

  return subscribeResult
}
