import Ionicons from '@expo/vector-icons/Ionicons'
import clsx from 'clsx'
import type { FC } from 'react'
import { TouchableOpacity, View } from 'react-native'

import MGText from './MGText'

const MGRadioCard: FC<{
  label: string
  description?: string
  selected: boolean
  onPress: () => void
  disabled?: boolean
}> = ({ label, description, selected, onPress, disabled }) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      className={clsx(
        'p-4 rounded-lg border',
        selected ? 'border-primary-400 bg-primary-50 dark:bg-primary-600/5' : 'border-neutral-200',
        disabled && 'opacity-50',
      )}
      disabled={disabled}
    >
      <View className="flex-row items-center justify-between">
        <View>
          <MGText>{label}</MGText>
          {description && <MGText className="text-sm text-neutral-500">{description}</MGText>}
        </View>
        <Ionicons
          name={selected ? 'radio-button-on' : 'radio-button-off'}
          size={20}
          color={selected ? '#ffade1' : '#D4D4D4'}
        />
      </View>
    </TouchableOpacity>
  )
}

export default MGRadioCard
