import { type ReactNode, cloneElement, isValidElement } from 'react'

function TabBarIcon({
  children,
  focused,
  color,
  size,
}: {
  children: ReactNode
  focused: boolean
  color: string
  size: number
}) {
  return isValidElement(children)
    ? cloneElement(children, {
        ...children.props,
        size: 28,
        color,
      })
    : children
}

export default TabBarIcon
