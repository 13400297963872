import { Image } from 'expo-image'
import type { FC, ReactNode } from 'react'
import { View } from 'react-native'
import MGText from './MGText'

interface NonIdealStateProps {
  title: string
  description?: string
  children?: ReactNode
}

const NonIdealState: FC<NonIdealStateProps> = ({ title, description, children }) => {
  return (
    <View className="items-center justify-center w-full flex gap-2 px-8">
      <Image source={require('../../assets/images/status/non-ideal.png')} style={{ width: 64, height: 64 }} />
      <MGText className="mt-4">{title}</MGText>
      {description && <MGText className="text-center text-neutral-300">{description}</MGText>}
      {children}
    </View>
  )
}

export default NonIdealState
