import { getToken } from '../lib/storage'
import { fetch } from 'expo/fetch'

const PIXNEXT_MEDIA_INGEST = 'https://media-ingest.moguchat.ai/v1'

interface UploadPixNEXTMediaPayload {
  category: 'moguchat/avatar' | 'moguchat/chat/background'
  visibility: 'PUBLIC' | 'PRIVATE'
}

interface UploadPixNEXTMediaOptions {
  signal?: AbortSignal
}

export type PixNEXTMediaUploadResult = Awaited<ReturnType<typeof uploadPixNEXTMedia>>

export const uploadPixNEXTMedia = async (
  blob: Blob,
  payload: UploadPixNEXTMediaPayload,
  { signal }: UploadPixNEXTMediaOptions = {},
) => {
  const start = performance.now()

  const formData = new FormData()
  formData.append(
    'payload',
    JSON.stringify({
      content_length: blob.size,
      ...payload,
    }),
  )
  formData.append('file', blob)

  // Always use `expo/fetch` for uploading blob - React Native's fetch does not support uploading Blob and would crash on iOS when trying to do so.
  // The exception was something like `*** -[__NSPlaceholderDictionary initWithObjects:forKeys:count:]: attempt to insert nil object from objects[0]`.
  // See https://troph.sentry.io/issues/6463342758/ and react-native/Libraries/Blob/RCTBlobManager.mm:122
  const res = await fetch(PIXNEXT_MEDIA_INGEST, {
    method: 'POST',
    body: formData,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
    signal,
  })
  if (!res.ok) {
    const body = await res.text()
    throw new Error(`Upload failed with status ${res.status}: ${body}`)
  }

  const data = (await res.json()) as {
    media_id: string
    delivery_url: string
    variants: {
      variant: string
      size_bytes: number
      height: number
      width: number
      delivery_url: string
    }[]
  }

  console.log(
    `[PixNEXT Media] Upload successfully completed in ${Math.floor(performance.now() - start)}ms with response`,
    data,
  )
  return data
}
