import { useMemo } from 'react'
import { trpcReact } from '../services/trpc'
import { useCurrentUser } from './useAuth'

export const useMyCharacters = () => {
  const { data: user } = useCurrentUser()

  const {
    data: charactersRes,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = trpcReact.character.list.useInfiniteQuery(
    {
      limit: 20,
      filter: {
        authorId: user?.id,
      },
    },
    {
      getNextPageParam: (lastPage) => lastPage.pagination.nextCursor,
    },
  )

  const characters = useMemo(() => charactersRes?.pages.flatMap((page) => page.items), [charactersRes?.pages])

  return {
    characters,
    refetch,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  }
}
