import type Ionicons from '@expo/vector-icons/Ionicons'
import clsx from 'clsx'
import { router, useNavigation } from 'expo-router'
import { useColorScheme } from 'nativewind'
import { type FC, Fragment, type ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Platform,
  Share,
  type StyleProp,
  TouchableOpacity,
  type TouchableOpacityProps,
  View,
  type ViewStyle,
} from 'react-native'
import Animated from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { ampli } from '../../lib/ampli'
import { useIsDesktop } from '../../lib/hooks/useIsDesktop'
import { trpcReact } from '../../lib/services/trpc'
import DesktopHeader from './DesktopHeader'
import { MGIonicons } from './MGIonicons'

interface MGHeaderItemProps extends TouchableOpacityProps {
  iconName: keyof typeof Ionicons.glyphMap
  size?: number
  headerContentStyle: 'normal' | 'inverted'
  onPress?: () => void
}

export const MGHeaderItem: FC<MGHeaderItemProps> = ({ iconName, size = 28, headerContentStyle, onPress, ...props }) => {
  const { colorScheme } = useColorScheme()
  const isDark = colorScheme === 'dark'

  return (
    <TouchableOpacity onPress={onPress} hitSlop={6} {...props}>
      <View className="flex items-center justify-center">
        <MGIonicons
          name={iconName}
          size={size}
          color={isDark ? 'white' : headerContentStyle === 'inverted' ? 'white' : 'black'}
        />
      </View>
    </TouchableOpacity>
  )
}

interface MGShareHeaderItemProps {
  headerContentStyle: 'normal' | 'inverted'
  shareSubject: string
  shareSubjectId: string
  shareTitle: string
}

export const MGShareHeaderItem: FC<MGShareHeaderItemProps> = ({
  headerContentStyle,
  shareSubject,
  shareSubjectId,
  shareTitle,
}) => {
  const { mutateAsync: createShareLink } = trpcReact.share.create.useMutation()

  const onShare = async () => {
    ampli.shareButtonClicked({
      share_subject: shareSubject,
      share_subject_id: shareSubjectId,
    })
    try {
      const { shareId } = await createShareLink({
        subjectId: shareSubjectId,
      })

      const url = `https://moguchat.ai/s/${shareId}`

      await Share.share({
        message: `${shareTitle} | ${url}`,
        url,
        title: shareTitle,
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <MGHeaderItem
      className="shrink-0"
      iconName="share-outline"
      size={28}
      headerContentStyle={headerContentStyle}
      onPress={onShare}
    />
  )
}

interface MGHeaderProps {
  headerContentStyle?: 'normal' | 'inverted'
  headerBody?: ReactNode
  backgroundStyle?: StyleProp<ViewStyle>
  topSafePadding?: boolean
  trailingView?: ReactNode
  desktopExtraTrailingView?: ReactNode
  disableBackButton?: boolean
  fullSizeBody?: boolean
  hideDesktopTabs?: boolean
  className?: string
}

export const MGHeader: FC<MGHeaderProps> = ({
  headerContentStyle = 'normal',
  headerBody,
  backgroundStyle,
  topSafePadding = false,
  trailingView,
  desktopExtraTrailingView,
  disableBackButton = false,
  fullSizeBody = false,
  hideDesktopTabs = false,
  className,
}) => {
  const insets = useSafeAreaInsets()
  const navigation = useNavigation()
  const { t } = useTranslation()
  const isDesktop = useIsDesktop()

  return isDesktop ? (
    <DesktopHeader
      hideTabs={hideDesktopTabs}
      trailingView={
        <Fragment>
          {headerBody}
          {desktopExtraTrailingView}
          {trailingView}
        </Fragment>
      }
      disableBackButton={disableBackButton}
    />
  ) : (
    <Animated.View
      className={clsx('flex flex-row justify-between items-center', className)}
      style={[
        backgroundStyle,
        {
          paddingTop: topSafePadding ? insets.top : Platform.OS === 'web' ? 12 : 0,
        },
      ]}
      accessibilityRole="header"
    >
      <View
        className={clsx(
          'flex flex-row items-center justify-between w-full pr-4 pb-4',
          disableBackButton ? 'pl-4' : 'pl-2',
          className,
        )}
      >
        {!disableBackButton && (
          <MGHeaderItem
            iconName="chevron-back"
            size={32}
            headerContentStyle={headerContentStyle}
            onPress={() => {
              if (navigation.canGoBack()) {
                navigation.goBack()
              } else {
                router.replace('/')
              }
            }}
            accessibilityRole="button"
            accessibilityLabel={t('action.back')}
            accessibilityState={{ disabled: !navigation.canGoBack() }}
          />
        )}
        {headerBody}
        {!fullSizeBody && (
          <Fragment>
            <View className="flex-1" />
            {trailingView}
          </Fragment>
        )}
      </View>
    </Animated.View>
  )
}

export default MGHeader
