import { captureException } from '@sentry/react-native'
import { toast } from 'burnt'
import clsx from 'clsx'
import { atom, useAtom } from 'jotai'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Image, TouchableOpacity, View } from 'react-native'
import { ampli } from '../../lib/ampli'
import { useCurrentUser } from '../../lib/hooks/useAuth'
import { invalidateInventory } from '../../lib/hooks/useInventory'
import {
  type PurchaseParams,
  purchaseProduct,
  useAvailableProducts,
  type UseAvailableProductsReturn,
} from '../../lib/monetization/purchase'
import { trpcUtils } from '../../lib/services/trpc'
import MGText from '../common/MGText'
import { MGActivityIndicator } from '../ui/MGActivityIndicator'
import { LegalRow } from './LegalRow'

const purchasingStoreProductIdAtom = atom<string | null>(null)

const PRODUCT_ID_TO_IMAGE = {
  komebukuro_200_one_time_purchase_bonus: require('../../assets/images/store/komebukuro_200_one_time_purchase_bonus.webp'),
  komebukuro_300: require('../../assets/images/store/komebukuro_300.webp'),
  komebukuro_500: require('../../assets/images/store/komebukuro_500.webp'),
  komebukuro_1200: require('../../assets/images/store/komebukuro_1200.webp'),
  komebukuro_2500: require('../../assets/images/store/komebukuro_2500.webp'),
  komebukuro_4000: require('../../assets/images/store/komebukuro_4000.webp'),
  komebukuro_7000: require('../../assets/images/store/komebukuro_7000.webp'),
  komebukuro_10000: require('../../assets/images/store/komebukuro_10000.webp'),
}

const productIdToImage = (id: string) => {
  return (
    PRODUCT_ID_TO_IMAGE[id as keyof typeof PRODUCT_ID_TO_IMAGE] ?? require('../../assets/images/store/fallback.webp')
  )
}

const StoreProduct: FC<{
  id: string
  reward: UseAvailableProductsReturn['data'][number]['reward']
  localizedPrice: string
  purchaseParams: PurchaseParams
}> = ({ id, reward, localizedPrice, purchaseParams }) => {
  const { t } = useTranslation()
  const { data: user } = useCurrentUser()
  const [purchasingStoreProductId, setPurchasingStoreProductId] = useAtom(purchasingStoreProductIdAtom)
  const isPurchasingAnyProduct = purchasingStoreProductId !== null
  const isPurchasingThisProduct = purchasingStoreProductId === id

  const onPurchase = async () => {
    ampli.purchaseCreditPackClicked({
      store_item_id: id,
      is_first_time: reward.isFirstTimeOnly || reward.bonus?.type === 'first_time_bonus',
    })
    setPurchasingStoreProductId(id)

    try {
      if (!user?.id) {
        toast({
          preset: 'error',
          title: t('error.auth-error'),
        })
        return
      }

      await purchaseProduct(user.id, purchaseParams)

      try {
        await Promise.allSettled([invalidateInventory(), trpcUtils.store.items.list.invalidate()])
      } catch (e) {
        console.error('failed to invalidate inventory', e)
        captureException(e, {
          level: 'error',
          extra: {
            id,
          },
        })
      }
    } catch (e) {
      toast({
        preset: 'error',
        title: t('purchase.failed'),
        message: e instanceof Error ? e.message : 'Unknown error',
      })
      console.error('purchase error', e)
      captureException(e)
    } finally {
      setPurchasingStoreProductId(null)
    }
  }

  const marketingText = (() => {
    if (reward.isFirstTimeOnly) {
      return t('purchase.credit.pack.marketing.first-time-only')
    }
    if (reward.bonus?.type === 'first_time_bonus') {
      return t('purchase.credit.pack.marketing.first-time-bonus')
    }
  })()

  return (
    <View className="w-[50%] p-1 relative">
      <TouchableOpacity
        className={clsx(
          'w-full rounded-xl border h-[7.5rem] relative',
          isPurchasingAnyProduct ? 'opacity-30' : 'opacity-100',
          isPurchasingThisProduct
            ? 'border-primary-500'
            : isPurchasingAnyProduct
              ? 'border-neutral-500'
              : 'border-primary-500',
          marketingText ? 'bg-primary-100 dark:bg-primary-600/10' : 'bg-background',
        )}
        onPress={onPurchase}
        disabled={isPurchasingAnyProduct}
      >
        <View className="flex-1 items-center justify-between flex-row w-full p-4 rounded-[0.6875rem] bg-background">
          <Image source={productIdToImage(id)} style={{ width: 50, height: 50 }} resizeMode="contain" />

          <View className="flex flex-col items-end justify-center">
            <View className="flex flex-row items-end">
              <MGText bold className="text-xl">
                {reward.baseAmount}
              </MGText>
              {reward.bonus?.amount && (
                <MGText bold className="text-primary-400 text-xl">
                  +{reward.bonus.amount}
                </MGText>
              )}
            </View>
            <MGText bold className="text-base">
              {localizedPrice}
            </MGText>
          </View>
        </View>

        {marketingText && (
          <View className="text-center items-center justify-center py-1 rounded-t-[0.625rem]">
            <MGText className="text-primary-600" bold>
              {marketingText}
            </MGText>
          </View>
        )}

        {isPurchasingThisProduct && (
          <View className="absolute -inset-[1px] flex items-center justify-center bg-neutral-500/50 rounded-xl">
            <MGActivityIndicator size="small" />
          </View>
        )}
      </TouchableOpacity>
    </View>
  )
}

export default function PurchaseSheetStore() {
  const { t } = useTranslation()

  const { data: products, isLoading } = useAvailableProducts()

  return (
    <View className="w-full flex gap-2">
      <View className="flex-row flex-wrap">
        {isLoading && (
          <View className="flex items-center justify-center py-16 w-full">
            <MGActivityIndicator size="large" />
          </View>
        )}
        {products.length === 0 && !isLoading && (
          <MGText className="text-neutral-500 w-full text-center my-16">
            {t('purchase.credit.no-packs-available')}
          </MGText>
        )}
        {products.map((pack) => (
          <StoreProduct
            key={pack.id}
            id={pack.id}
            localizedPrice={pack.localizedPrice}
            reward={pack.reward}
            purchaseParams={pack.purchaseParams}
          />
        ))}
      </View>

      <LegalRow />
    </View>
  )
}
