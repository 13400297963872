import Ionicons from '@expo/vector-icons/Ionicons'
import clsx from 'clsx'
import { Image } from 'expo-image'
import { type Href, Link, router } from 'expo-router'
import { type FC, Fragment, type ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, TouchableOpacity, View } from 'react-native'
import { useMyCharacters } from '../../lib/hooks/useMyCharacters'
import { MGIonicons } from '../common/MGIonicons'
import MGText from '../common/MGText'
import { SettingsItem } from '../common/SettingsSection'

interface CircleItemProps {
  name: string
  href?: Href
  onPress?: () => void
  isFirst: boolean
  image: ReactNode
}

const CircleItem: FC<CircleItemProps> = ({ name, href, onPress, isFirst, image }) => {
  const content = (
    <View className="flex flex-col items-center">
      {image}
      <MGText
        className="text-sm text-center"
        style={{ maxWidth: 72 }}
        lineBreakMode="tail"
        numberOfLines={1}
        ellipsizeMode="tail"
      >
        {name}
      </MGText>
    </View>
  )

  return (
    <View className={clsx('flex flex-col items-center mr-4 mb-4', isFirst && 'ml-4')}>
      {href ? (
        <Link href={href} asChild>
          <TouchableOpacity>{content}</TouchableOpacity>
        </Link>
      ) : (
        <TouchableOpacity onPress={onPress}>{content}</TouchableOpacity>
      )}
    </View>
  )
}

interface CharacterItemProps {
  id: string
  name: string
  avatarUrl: string
  isFirst: boolean
}

const CharacterItem: FC<Omit<CharacterItemProps, 'isFirst'> & { isFirst: boolean }> = ({
  id,
  name,
  avatarUrl,
  isFirst,
}) => (
  <CircleItem
    name={name}
    href={`/character/${id}`}
    isFirst={isFirst}
    image={
      <Image source={{ uri: avatarUrl }} style={{ width: 72, height: 72, borderRadius: 100 }} contentFit="cover" />
    }
  />
)

const CreateCharacterItem: FC = () => {
  const { t } = useTranslation()
  return (
    <CircleItem
      name={t('character.create.short-title')}
      onPress={() => router.push('/character/create')}
      isFirst={true}
      image={
        <View
          className="bg-neutral-200 border-2 border-black/10 rounded-full flex items-center justify-center"
          style={{ width: 72, height: 72, borderRadius: 100 }}
        >
          <MGIonicons name="add" className="shrink-0" size={32} />
        </View>
      }
    />
  )
}

export default function MyCharactersRow() {
  const { t } = useTranslation()
  const { characters, hasNextPage } = useMyCharacters()

  return (
    <View className="px-4">
      <View className="rounded-3xl overflow-hidden">
        <Link href="/character/my" asChild>
          <TouchableOpacity className="bg-neutral-100">
            <View className="flex flex-row items-center justify-between p-4 gap-2">
              <MGIonicons name="person-outline" size={18} />
              <MGText className="flex-1 text-base">{t('character.my')}</MGText>
              <MGIonicons name="chevron-forward-outline" className="shrink-0" size={24} />
            </View>
          </TouchableOpacity>
        </Link>

        <ScrollView
          automaticallyAdjustContentInsets={false}
          directionalLockEnabled
          horizontal
          showsHorizontalScrollIndicator={false}
          className="bg-neutral-100"
        >
          {!characters || characters.length === 0 ? (
            <CreateCharacterItem />
          ) : (
            <Fragment>
              {characters.map((character, idx) => (
                <CharacterItem
                  key={character.id}
                  id={character.id}
                  name={character.name}
                  avatarUrl={character.avatarUrl}
                  isFirst={idx === 0}
                />
              ))}
              {hasNextPage && (
                <View className="flex flex-col items-center mr-4 mb-4">
                  <Link href="/character/my">
                    <View className="flex flex-col items-center">
                      <View
                        className="bg-primary-600/20 rounded-full flex items-center justify-center"
                        style={{ width: 72, height: 72, borderRadius: 100 }}
                      >
                        <Ionicons name="chevron-forward-outline" className="shrink-0" size={24} color="#ffffff" />
                      </View>
                      <MGText className="text-sm text-center" style={{ maxWidth: 72 }}>
                        {t('character.my.view-more')}
                      </MGText>
                    </View>
                  </Link>
                </View>
              )}
            </Fragment>
          )}
        </ScrollView>

        <View className="h-px bg-neutral-200" />

        <SettingsItem title={t('character.request-featured.title')} icon="star-outline" href="/request-featured" />
      </View>
    </View>
  )
}
