import { type RouterInputs, trpcClient, trpcReact, trpcUtils } from '../services/trpc'

export const useCurrentUser = trpcReact.user.getViewer.useQuery

export const invalidateCurrentUser = async () => {
  await trpcUtils.user.getViewer.invalidate(undefined, {
    refetchType: 'active',
  })
  trpcUtils.user.getViewerActiveSubscription.prefetch()
  return trpcUtils.user.getViewer.fetch()
}

export const mutateCurrentUser = async (input: RouterInputs['user']['updateProfile']) => {
  await trpcClient.user.updateProfile.mutate(input)
  await invalidateCurrentUser()
}
