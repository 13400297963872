import { MMKV } from 'react-native-mmkv'

// This should NEVER be cleared
const storage = new MMKV({
  // 2025.4.2: added new ID
  id: 'moguchat-storage-device-bound',
})

const IS_NOT_FREE_USER_KEY = 'moguchat-is-not-free-user'
const STORE_REVIEW_FIRST_LOGIN_KEY = 'moguchat-store-review-first-login'
const STORE_REVIEW_20_MESSAGES_REQUESTED_KEY = 'moguchat-store-review-20-messages-requested'
const STORE_REVIEW_3_MESSAGES_POST_PURCHASE_REQUESTED_KEY = 'moguchat-store-review-3-messages-post-purchase-requested'

export const getIsNotFreeUser = () => {
  return storage.getBoolean(IS_NOT_FREE_USER_KEY)
}

export const setIsNotFreeUser = (isNotFreeUser: boolean) => {
  return storage.set(IS_NOT_FREE_USER_KEY, isNotFreeUser)
}

export const getStoreReview20MessagesRequested = () => {
  return storage.getBoolean(STORE_REVIEW_20_MESSAGES_REQUESTED_KEY)
}

export const setStoreReview20MessagesRequested = (requested: boolean) => {
  return storage.set(STORE_REVIEW_20_MESSAGES_REQUESTED_KEY, requested)
}

export const getStoreReview3MessagesPostPurchaseRequested = () => {
  return storage.getBoolean(STORE_REVIEW_3_MESSAGES_POST_PURCHASE_REQUESTED_KEY)
}

export const setStoreReview3MessagesPostPurchaseRequested = (requested: boolean) => {
  return storage.set(STORE_REVIEW_3_MESSAGES_POST_PURCHASE_REQUESTED_KEY, requested)
}

export const getStoreReviewFirstLogin = () => {
  return storage.getBoolean(STORE_REVIEW_FIRST_LOGIN_KEY)
}

export const setStoreReviewFirstLogin = (firstLogin: boolean) => {
  return storage.set(STORE_REVIEW_FIRST_LOGIN_KEY, firstLogin)
}
