import clsx from 'clsx'
import { type FC, useCallback, useState } from 'react'
import { TouchableOpacity, type TouchableOpacityProps, View } from 'react-native'
import { match } from 'ts-pattern'
import MGText from '../../components/common/MGText'
import { MGActivityIndicator } from '../ui/MGActivityIndicator'

interface MGModalButtonProps extends Omit<TouchableOpacityProps, 'onPress' | 'disabled' | 'className' | 'children'> {
  onPress: () => void | Promise<void>
  disabled?: boolean
  foregroundAppearance?: 'light' | 'dark'
  className?: string
  textClassName?: string
  loading?: boolean
  children: React.ReactNode

  leftAdornment?: React.ReactNode
  rightAdornment?: React.ReactNode
}

export const MGModalButton: FC<MGModalButtonProps> = ({
  onPress,
  disabled,
  foregroundAppearance,
  className,
  textClassName,
  loading,
  children,
  leftAdornment,
  rightAdornment,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const handlePress = useCallback(async () => {
    const result = onPress()
    if (result instanceof Promise) {
      setIsLoading(true)
      try {
        await result
      } finally {
        setIsLoading(false)
      }
    }
  }, [onPress])

  const isDisabled = !!(disabled || isLoading || loading)

  const resolvedTextColorClassname = match([foregroundAppearance, isDisabled])
    .with(['light', false], () => 'text-white/95')
    .with(['light', true], () => 'text-white/50')
    .with(['dark', false], () => 'text-black/95')
    .with(['dark', true], () => 'text-black/50')
    .with([undefined, false], () => 'text-neutral-900')
    .with([undefined, true], () => 'text-neutral-900')
    .exhaustive()

  return (
    <TouchableOpacity
      onPress={handlePress}
      disabled={isDisabled}
      className={clsx('rounded-xl p-4 flex items-center justify-center', isDisabled && 'opacity-50', className)}
      accessibilityRole="button"
      accessibilityState={{ disabled: isDisabled, busy: isLoading || loading }}
      {...props}
    >
      <View className="flex-row justify-center items-center gap-2">
        {leftAdornment}
        {(isLoading || loading) && <MGActivityIndicator size="small" />}
        <MGText bold className={clsx('text-center', resolvedTextColorClassname, textClassName)}>
          {children}
        </MGText>
        {rightAdornment}
      </View>
    </TouchableOpacity>
  )
}
