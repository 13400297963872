import { useLocalSearchParams, useRouter } from 'expo-router'
import { useEffect } from 'react'
import { trpcClient, trpcUtils } from '../../../lib/services/trpc'

import * as Burnt from 'burnt'

export default function ChatCreateScreen() {
  const { characterId } = useLocalSearchParams()

  const router = useRouter()

  const createChat = async (characterId: string) => {
    try {
      const chat = await trpcClient.chat.create.mutate({ characterId: characterId })
      trpcUtils.chat.list.invalidate()
      router.push(`/chat/${chat.id}`)
    } catch (error) {
      console.error(error)
      Burnt.toast({
        title: 'Failed to create chat',
        preset: 'error',
      })
      router.push(`/character/${characterId}`)
    }
  }

  useEffect(() => {
    if (!characterId) {
      router.push('/')
    }
    createChat(characterId as string)
  }, [characterId])

  // adding loading here will cause rerender error on web :(
  return null
}
