import { Image } from 'expo-image'
import { Modal, View } from 'react-native'

export const MGLoading = ({ show }: { show: boolean }) => {
  return (
    <Modal visible={show} transparent>
      <View className="flex-1 justify-center items-center bg-black/50 p-4">
        {/* <View className="bg-white rounded-lg p-8"> */}
        <Image
          source={require('../../assets/images/status/loading.png')}
          style={{ width: 50, height: 50 }}
          autoplay={true}
        />
        {/* </View> */}
      </View>
    </Modal>
  )
}
