import { type UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query'
import { Alert, Platform } from 'react-native'
import { ErrorCode, type PurchaseError, finishTransaction, getProducts, requestPurchase } from 'react-native-iap'
import { TypeID } from 'typeid-js'
import { trpcClient, trpcUtils } from '../services/trpc'
import { initiateStripeCheckout } from '../services/stripe'
import { queryClient } from '../services/client'

export type PurchaseParams = {
  sku: string
}

export type ProductReward = {
  /**
   * For now it should only be ecoentity_currency_onigiri
   */
  itemId: string
  baseAmount: number
  /**
   * isFirstTimeOnly will be true if the product is only available for purchase once.
   */
  isFirstTimeOnly: boolean
  /**
   * bonus will be undefined if the user is not eligible for a bonus on this product.
   */
  bonus?: {
    /**
     * first_time_bonus: This product is available for purchase multiple times, and the user is eligible for a first-time bonus.
     * subsequent_bonus: This product is available for purchase multiple times, and the user is eligible for bonuses that are not first-time.
     */
    type: 'first_time_bonus' | 'subsequent_bonus'
    /**
     * amount is guaranteed to be a non-zero number.
     */
    amount: number
  }
}

export type UseAvailableProductsReturn = {
  data: {
    localizedPrice: string

    id: string
    reward: ProductReward

    /**
     * purchaseParams shall NOT be used by the UI at all.
     * Its only purpose is to be passed to purchaseProduct as-is.
     * If you need to access values from purchaseParams, please add a new field to UseIAPProductsReturn.
     */
    purchaseParams: PurchaseParams
  }[]
  isLoading: boolean
}

export const storeProductsQueryOptions = () =>
  ({
    queryKey: ['monetization.getNormalizedProducts'],
    queryFn: async () => {
      const serverItems = (await trpcClient.store.items.list.query()).flatMap(({ rewardItems, ...serverItem }) => {
        // we consider the first reward item as the "primary" reward item; as of now, all products only have one reward item
        const firstRewardItem = rewardItems.at(0)
        if (!firstRewardItem) {
          console.warn(`[StoreScreen] no reward item found for item ${serverItem.id}`)
          return []
        }

        const isFirstTimeOnly = serverItem.predicate === 'first_time_only'
        const bonus = (() => {
          if (serverItem.isFirstTime && firstRewardItem.firstTimeBonusAmount) {
            return {
              type: 'first_time_bonus',
              amount: firstRewardItem.firstTimeBonusAmount,
            } as const
          }
          if (firstRewardItem.subsequentBonusAmount) {
            return {
              type: 'subsequent_bonus',
              amount: firstRewardItem.subsequentBonusAmount,
            } as const
          }
        })()

        return [
          {
            ...serverItem,
            reward: {
              itemId: firstRewardItem.entityId,
              baseAmount: firstRewardItem.baseAmount,
              isFirstTimeOnly,
              bonus,
            },
          },
        ]
      })

      if (Platform.OS === 'web') {
        const prices = await trpcClient.store.prices.products.list.query({ platform: 'STRIPE' })
        return serverItems.map((item) => {
          const price = prices.find((p) => p.sku === item.sku.stripeProductId)
          return {
            ...item,
            localizedPrice: `JPY¥${price?.price.unitAmount.toLocaleString() ?? '?'}`,
            purchaseParams: { sku: item.sku.stripeProductId },
          }
        })
      }

      const products = await getProducts({
        skus: serverItems.map(
          (i) =>
            Platform.select({
              ios: i.sku.appStore,
              android: i.sku.googlePlay,
            })!,
        ),
      })

      return serverItems.flatMap((serverItem) => {
        const productSKU = Platform.select({
          ios: serverItem.sku.appStore,
          android: serverItem.sku.googlePlay,
        })
        if (!productSKU) {
          console.warn(
            `[StoreScreen] no corresponding sku found for the current platform ${Platform.OS} for item ${serverItem.id}`,
          )
          return []
        }
        const localizedPrice = products?.find((p) => p.productId === productSKU)?.localizedPrice ?? '?'

        return [
          {
            id: serverItem.id,
            localizedPrice,
            reward: serverItem.reward,
            purchaseParams: { sku: productSKU },
          } satisfies UseAvailableProductsReturn['data'][number],
        ]
      })
    },
  }) satisfies UndefinedInitialDataOptions

const useAvailableProducts = (): UseAvailableProductsReturn => {
  const { data, isLoading } = useQuery(storeProductsQueryOptions())
  return { data: data ?? [], isLoading }
}

const purchaseProductNative = async (userId: string, purchaseParams: PurchaseParams) => {
  const resolvedPurchaseParams = Platform.select({
    ios: {
      sku: purchaseParams.sku,
      andDangerouslyFinishTransactionAutomaticallyIOS: true,
      appAccountToken: TypeID.fromString(userId).toUUID(),
    },
    android: { skus: [purchaseParams.sku], obfuscatedAccountIdAndroid: userId },
  })
  if (!resolvedPurchaseParams) {
    throw new Error('Invalid purchase parameters')
  }

  const purchaseResults = await requestPurchase(resolvedPurchaseParams)
  console.log('[IAP] raw return from requestPurchase', purchaseResults)

  if (!purchaseResults) {
    throw new Error('Unexpected empty purchase result')
  }

  if (Array.isArray(purchaseResults) && purchaseResults.length !== 1) {
    throw new Error(`Unexpected purchase quantity (${purchaseResults.length})`)
  }

  const purchaseResult = Array.isArray(purchaseResults) ? purchaseResults[0] : purchaseResults

  // always finish the transaction first
  await finishTransaction({ purchase: purchaseResult, isConsumable: true })

  // we use useIAP to handle the purchase callback, as it is the recommended way to listen to purchase events
}

const purchaseProductWeb = async (purchaseParams: PurchaseParams) => {
  await initiateStripeCheckout({
    platform: 'STRIPE',
    type: 'ONE_TIME',
    productId: purchaseParams.sku,
  })
}

const purchaseProduct = async (userId: string, purchaseParams: PurchaseParams) => {
  try {
    if (Platform.OS === 'web') {
      await purchaseProductWeb(purchaseParams)
    }

    await purchaseProductNative(userId, purchaseParams)
  } catch (e) {
    const err = e as PurchaseError

    if (err.code === ErrorCode.E_USER_CANCELLED) {
      // ignore user cancelled error
      return
    }

    console.error('[IAP] purchase error', err, JSON.stringify(err))

    if (err.code === ErrorCode.E_DEFERRED_PAYMENT) {
      Alert.alert(
        'Deferred payment method',
        'After your payment is confirmed, you will be receiving your credits automatically. Please restart MoguChat after you complete your payment.',
      )
      return
    }

    throw e
  } finally {
    await trpcUtils.store.items.list.invalidate()
    await queryClient.invalidateQueries(storeProductsQueryOptions())
  }
}

export { useAvailableProducts, purchaseProduct }
