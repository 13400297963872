import { Image } from 'expo-image'
import { Link } from 'expo-router'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { config } from '../../lib/config'
import MGBaseModal from './MGBaseModal'
import { MGModalButton } from './MGModalButton'
import MGText from './MGText'
import { DownloadAppGooglePlayIcon, DownloadAppStoreIcon } from './StoreDownloadIcon'

interface AppDownloadModalProps {
  visible: boolean
  onClose: () => void
}

const AppDownloadModal: FC<AppDownloadModalProps> = ({ visible, onClose }) => {
  const { t } = useTranslation()

  return (
    <MGBaseModal
      visible={visible}
      onClose={onClose}
      title={t('download-app.title')}
      icon="cloud-download-outline"
      iconColor="#3b82f6"
      iconBgColor="bg-blue-100"
      contentMaxHeightPercentage={0.8}
    >
      <View className="flex flex-col items-center gap-4">
        <MGText className="text-center text-base text-neutral-500">{t('download-app.description')}</MGText>

        <Image
          source={require('../../assets/images/mobile-app-lineup.webp')}
          style={{
            width: 1253 / 3,
            height: 1145 / 3,
          }}
          contentFit="contain"
          className="-my-2"
        />

        <View className="flex flex-row gap-4">
          <Link href={config.appDownloadUrls.ios}>
            <DownloadAppStoreIcon className="h-12 hover:brightness-125 active:brightness-75" />
          </Link>
          <Link href={config.appDownloadUrls.android}>
            <DownloadAppGooglePlayIcon className="h-12 hover:brightness-125 active:brightness-75" />
          </Link>
        </View>

        <MGModalButton onPress={onClose} className="bg-neutral-200 w-full">
          {t('action.close')}
        </MGModalButton>
      </View>
    </MGBaseModal>
  )
}

export default AppDownloadModal
