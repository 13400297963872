import { router } from 'expo-router'
import { Image, TouchableOpacity, View } from 'react-native'
import { MGIonicons } from '../../../components/common/MGIonicons'
import { MoguPassSVG } from '../../../components/monetization/MoguPassSVG'
import PurchaseSheetSubscription from '../../../components/monetization/PurchaseSheetSubscription'

export default function SubscribeFullScreenModal() {
  return (
    <View className="flex-1 bg-background p-safe lg:max-w-3xl lg:mx-auto">
      <View className="flex-row items-center px-4 py-2 gap-4">
        <TouchableOpacity
          hitSlop={6}
          onPress={() => {
            if (router.canGoBack()) {
              router.back()
            } else {
              router.push('/')
            }
          }}
          className="flex-row items-center justify-start gap-2"
        >
          <MGIonicons name="close" size={24} className="opacity-50" />
        </TouchableOpacity>

        <MoguPassSVG height={16} />
      </View>

      <View className="flex-1 px-4 pb-4">
        <View className="items-center justify-center py-8">
          <Image
            source={require('../../../assets/images/mogupass-mascot.webp')}
            style={{ width: 120, height: 120 }}
            resizeMode="contain"
          />

          <MoguPassSVG height={24} />
        </View>
        <PurchaseSheetSubscription className="flex-1 justify-between" />
      </View>
    </View>
  )
}
