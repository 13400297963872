import Ionicons from '@expo/vector-icons/Ionicons'
import clsx from 'clsx'
import { Link } from 'expo-router'
import { useTranslation } from 'react-i18next'
import { TouchableHighlight, View, type ViewStyle } from 'react-native'
import MGText from '../../components/common/MGText'
import { MGIonicons } from '../common/MGIonicons'

const sizeToFontSize = {
  md: 20,
  lg: 30,
}

export const CharacterCreateFAB = ({
  style,
  size = 'md',
  justify = 'end',
}: { style?: ViewStyle; size?: 'md' | 'lg'; justify?: 'end' | 'center' }) => {
  const { t } = useTranslation()

  return (
    <View
      className={clsx('flex flex-row', {
        'justify-end': justify === 'end',
        'justify-center': justify === 'center',
      })}
    >
      <Link href="/character/create" asChild>
        <TouchableHighlight
          underlayColor="#000000"
          activeOpacity={0.8}
          className="rounded-full overflow-hidden"
          accessibilityRole="button"
          accessibilityLabel={t('character.create')}
          style={style}
        >
          <View
            className={clsx('bg-primary-500 dark:bg-primary-600 flex items-center justify-center flex-row', {
              'p-4': size === 'lg',
              'py-2 px-4': size === 'md',
            })}
          >
            <Ionicons name="add-outline" size={sizeToFontSize[size]} color="#ffffff" />
            <MGText bold className="text-white mr-2">
              {t('character.create')}
            </MGText>
          </View>
        </TouchableHighlight>
      </Link>
    </View>
  )
}

export const CharacterCreateIconOnlyFAB = () => {
  const { t } = useTranslation()
  return (
    <View className="absolute bottom-4 right-4">
      <View className="flex flex-row justify-center">
        <Link href="/character/create" asChild>
          <TouchableHighlight
            underlayColor="#000000"
            activeOpacity={0.8}
            className="rounded-full overflow-hidden"
            style={{
              shadowColor: '#000',
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.25,
              shadowRadius: 3.84,

              elevation: 5,
            }}
            accessibilityRole="button"
            accessibilityLabel={t('character.create')}
          >
            <View className="bg-primary-500 dark:bg-primary-600 flex items-center justify-center flex-row p-4">
              <MGIonicons name="add-outline" size={36} color="#ffffff" />
            </View>
          </TouchableHighlight>
        </Link>
      </View>
    </View>
  )
}
