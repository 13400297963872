import type { Resource } from 'i18next'

import enDefault from './en/translation.json'
import enZod from './en/zod.json'
import jaDefault from './ja/translation.json'
import jaZod from './ja/zod.json'
import koDefault from './ko/translation.json'
import koZod from './ko/zod.json'
import zhTWDefault from './zh_TW/translation.json'
import zhTWZod from './zh_TW/zod.json'

export const resources: Resource = {
  ja: {
    translation: jaDefault,
    zod: jaZod,
  },
  en: {
    translation: enDefault,
    zod: enZod,
  },
  zh_TW: {
    translation: zhTWDefault,
    zod: zhTWZod,
  },
  ko: {
    translation: koDefault,
    zod: koZod,
  },
}

export const supportedLngs = ['ja', 'en', 'zh_TW', 'ko'] as const
