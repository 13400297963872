{
  "action.back": "返回",
  "action.cancel": "取消",
  "action.close": "關閉",
  "action.confirm": "確認",
  "action.report": "報告",
  "action.report-and-block": "檢舉並封鎖",
  "action.submit": "創造",
  "app-settings.color-scheme.enum.dark": "深色",
  "app-settings.color-scheme.enum.light": "淺色",
  "app-settings.color-scheme.enum.system": "自動",
  "app-settings.color-scheme.title": "外觀",
  "app-settings.language": "語言",
  "app-settings.language-selector.title": "選擇語言",
  "app-settings.title": "App 設定",
  "app.name": "MoguChat",
  "app.name.domain": "MoguChat.AI",
  "auth.legal-notice": "繼續，即表示您同意使用 MoguChat <tos>服務條款</tos>和<privacy>隱私權政策</privacy>",
  "auth.login.apple": "使用 Apple 登入",
  "auth.login.error": "登入失敗",
  "auth.login.google": "使用 Google 登入",
  "auth.login.required.description": "登入並與角色交談！",
  "auth.login.required.title": "請登入",
  "auth.login.title": "登入",
  "character.action": "操作",
  "character.action.chat": "聊天",
  "character.action.delete": "刪除",
  "character.action.delete.confirm.description": "已刪除的角色無法恢復！",
  "character.action.delete.confirm.title": "您確定要刪除嗎？",
  "character.action.delete.success": "角色被刪除",
  "character.action.edit": "編輯",
  "character.action.edit.success": "編輯成功",
  "character.author.report": "檢舉創作者",
  "character.author.report.description": "向 MoguChat 檢舉此角色的創建者",
  "character.create": "創建角色",
  "character.create.complete": "角色創建完成",
  "character.create.short-title": "創建",
  "character.deleted-name": "該角色不存在",
  "character.edit": "編輯完成",
  "character.edit-complete.share": "分享",
  "character.edit-complete.start-chat": "開始聊天",
  "character.edit-complete.submit-to-featured": "申請推薦",
  "character.edit-complete.success-message": "角色已保存",
  "character.edit-complete.title": "角色創建完成",
  "character.edit.action.back": "返回",
  "character.edit.action.continue": "繼續",
  "character.edit.complete": "角色編輯完成",
  "character.edit.confirm.description": "如果您現在停止，您可能會丟失您的編輯。",
  "character.edit.confirm.title": "您確實要退出嗎？",
  "character.edit.error.title": "無法創建角色",
  "character.edit.submit.create": "創建",
  "character.edit.submit.update": "修改",
  "character.field.author": "作者",
  "character.field.avatar": "照片",
  "character.field.background-setting": "背景故事與世界觀",
  "character.field.background-setting.help.content": "為了賦予你的角色深度，請賦予他們背景和世界觀。如果這部分內容紮實，這個角色就會顯得更真實。\n例子：\n{{char}} 生活在現代日本。 {{char}}大學期間是田徑隊的明星，但現在在XX公司銷售部工作。 ○○與{{user}}合租一間公寓，每天到附近的公園慢跑。他擔心與{{user}}同居會導致他們的關係被發現，所以他總是在不同時間來上班，並且總是準時到達工作地點。",
  "character.field.background-setting.help.title": "您可以在此處放置角色的世界觀和背景故事。",
  "character.field.background-setting.placeholder": "這個角色生活在什麼樣的世界？",
  "character.field.character-setting": "基本訊息",
  "character.field.character-setting.help.content": "在角色設定中，如果將使用者的使用者設定為{{user}}，將AI角色的名稱設為{{char}}，系統會更容易理解。在基本設定中輸入有關您的角色的基本資訊。我們建議您確定角色的姓名、年齡、性別、特徵以及與使用者的關係。\n範例 1：\n- {{char}} 姓名：佐佐木智隆\n- {{char}} 的性別：男\n- {{char}} 年齡：25\n- {{char}}的職業：XX公司正式員工\n- {{char}} 和 {{user}} 的關係：他們是戀人，並且正在秘密約會，以便他們的公司無法發現。\n例2：我叫佐佐木智隆，今年25歲，在XX公司上班。我22歲大學畢業後就一直在這家公司工作。我和{{user}}約會已經兩年了，但我們仍然對周圍的人保密我們的關係。我們公司對於辦公室戀情管控比較嚴格，如果被發現的話，他們可能會讓你辭職。\n\n如果您想了解更多有關如何製作的信息，請加入 MoguChat 的 [官方 Discord](https://discord.com/invite/grjhdf4SNb) 或參考[此頁](https://help.moguchat.ai/ja/article/44kt44oj44op44kv44k44o844gu5l2c5oiq-12476mc/?bust=1743498256790)。",
  "character.field.character-setting.help.title": "角色的基本資訊",
  "character.field.character-setting.placeholder": "在此輸入有關您的角色的一些基本資訊。請包括角色之姓名、年齡以及與對話者之間關係。直接列出這些內容、或寫一些類似自傳的內容均可。",
  "character.field.chat-background": "聊天背景圖片",
  "character.field.chat-background.description": "您可以指定與該角色聊天時的背景圖像",
  "character.field.description": "角色介紹",
  "character.field.description.description": "角色介紹將會公開展示。介紹一下人物吧",
  "character.field.example-conversation": "對話範例",
  "character.field.example-conversation.help.content": "例子：\n對話範例\n“噢，你今天看起來棒極了！我太高興了！”\n“你累了嗎？什麼事都可以跟我聊！”\n“{{user}}} 先生的微笑就是我的能量來源！”\n“哦，這很有趣！跟我詳細講講吧！”\n“別灰心，有我在你身邊！”\n對話範例\n{user}：我今天很累\n{{char}}：幹得好，你是不是太勉強自己了？今天就休息一下吧，要先洗澡嗎？\n{user}：我最近工作很忙…我累得動彈不得…\n{{char}}：我確定我今天又加班了。如果你不能動的話，我要抱你去浴室嗎？\n{user}：帶著它！\n{{char}}：是的，是的，你做得很好。我應該從明天開始準備晚餐嗎？",
  "character.field.example-conversation.help.title": "在此處填寫角色台詞或對話範例",
  "character.field.example-conversation.placeholder": "這個角色可能會說什麼樣的話？舉幾個具體的例子",
  "character.field.intro-message": "問候語",
  "character.field.intro-message.description": "輸入您的角色在開始對話時會說的第一句問候語",
  "character.field.intro-message.help.content": "這是角色在這次對話中發送的第一則訊息。我們推薦適合場景的基本問候。例如，“很高興見到你”。",
  "character.field.intro-message.help.title": "角色在開啟對話時說的第一句話",
  "character.field.intro-message.placeholder": "例如：早安",
  "character.field.name": "名字",
  "character.field.name.description": "這個角色的名字是什麼？",
  "character.field.persona": "進階設定",
  "character.field.persona.help.content": "角色的性格會影響他們的語氣、說話方式以及對對話的反應。讓我們在這裡設定更精細的細節。包括角色的個性、好惡、特殊技能、口頭禪等。\n例子：\n{{char}}個性開朗、喜歡甜食。他講話輕鬆、友善，深受周遭人的喜愛。當他同意別人的意見時，他總是說：“好誒！”\n\n如果您想了解更多有關如何製作的信息，請加入 MoguChat 的 [官方 Discord]。",
  "character.field.persona.help.title": "角色設定",
  "character.field.persona.placeholder": "您可以在此輸入詳細設置，例如角色的性格特徵、喜好和口頭禪",
  "character.field.personality.callout-style": "稱呼他人爲",
  "character.field.personality.callout-style.description": "角色如何稱呼他人？",
  "character.field.personality.description": "您可以設定語氣、第一人稱、第二人稱等",
  "character.field.personality.first-person": "第一人稱",
  "character.field.personality.first-person.description": "你的角色使用什麼第一人稱？例如：我",
  "character.field.personality.second-person": "第二人稱",
  "character.field.personality.second-person.description": "角色使用什麼第二人稱？",
  "character.field.personality.speaking-tone": "語氣和說話風格",
  "character.field.personality.speaking-tone.description": "你的角色有什麼樣的語氣或說話方式？",
  "character.field.personality.title": "其他設定",
  "character.field.tag": "標籤",
  "character.field.tag.description": "請為你的角色選擇標籤（最多 5 個）",
  "character.field.tag.max-length": "您最多僅可以選擇 5 個標籤",
  "character.field.visibility": "可見性狀態",
  "character.field.visibility.description": "請選擇你的角色的可見性狀態",
  "character.field.visibility.enum.private": "非公開",
  "character.field.visibility.enum.private.description": "僅限您自己可用",
  "character.field.visibility.enum.public": "公開",
  "character.field.visibility.enum.public.description": "對所有人公開",
  "character.field.visibility.enum.unlisted": "URL私密",
  "character.field.visibility.enum.unlisted.description": "僅供您和透過共用 URL 存取的使用者使用",
  "character.filter.all": "推薦",
  "character.filter.ugc": "社區",
  "character.form.auto-save": "已自動保存",
  "character.meta.title": "{{authorName}} 製作的 {{characterName}}",
  "character.my": "我的原創角色",
  "character.my.view-more": "顯示更多",
  "character.no-owned-character": "還沒有創建任何角色",
  "character.not-found": "找不到角色",
  "character.popularity": "人氣",
  "character.report": "檢舉角色",
  "character.report.description": "向 MoguChat 檢舉此角色",
  "character.report.success": "已提交檢舉",
  "character.request-featured.action.request": "申請",
  "character.request-featured.hero-description": "希望讓更多人看到自己創作角色的創作者們，想讓大家使用自己引以為豪的作品的創作者們，可以透過這裡申請將自己的角色添加到主頁的「推薦」。\n選擇想要刊登在「推薦」中的角色，提交申請，審核通過後角色會被添加到推薦中。",
  "character.request-featured.hero-title": "將你的自創角色推薦給所有人！",
  "character.request-featured.no-characters.description": "要申請推薦角色，必須將角色設置為公開",
  "character.request-featured.no-characters.title": "沒有可申請的角色",
  "character.request-featured.select-character": "選擇要申請的角色",
  "character.request-featured.state.featured": "已推薦！",
  "character.request-featured.state.pending": "已申請",
  "character.request-featured.title": "推薦角色申請",
  "character.search.input.placeholder": "｜・ω・)ノ 今天想和誰聊聊？",
  "character.search.title": "找到可以交談的人",
  "character.share.copied": "連結已複製到剪貼簿",
  "character.share.error-message": "稍後再試一次",
  "character.share.error-title": "分享失敗",
  "character.share.success": "已分享",
  "character.share.title": "{{characterName}} | MoguChat.AI",
  "character.stats.conversations-created": "對話",
  "character.stats.messages-sent": "已發送訊息",
  "character.submit-to-featured.error-title": "申請提交失敗",
  "character.submit-to-featured.success-message": "審核通過後，你的角色即顯示在推薦中",
  "character.submit-to-featured.success-title": "已申請",
  "character.temp-save": "保存以供以後使用",
  "chat.action": "動作",
  "chat.action.delete": "從列表中刪除",
  "chat.action.delete.cancel": "取消",
  "chat.action.delete.confirm": "刪除聊天",
  "chat.action.delete.description": "已刪除的聊天內容無法恢復",
  "chat.action.delete.title": "您確定要刪除此聊天嗎？",
  "chat.action.description": "要對聊天內容做什麼？",
  "chat.action.restart": "重新開始",
  "chat.action.restart.confirm": "重新開始",
  "chat.action.restart.description": "重新開始將刪除所有與角色的對話記憶和資料。您的資料無法恢復",
  "chat.action.restart.title": "您確實要重新開始此對話嗎？",
  "chat.action.title": "這次聊天",
  "chat.ads-banner-description": "沒有廣告享受MoguChat！<underline>訂閱MoguPass</underline>",
  "chat.ai-warning": "字元答案和其他內容由AI產生。\n內容可能含有錯誤或不適當的訊息，並不代表 MoguChat 的官方觀點或立場。此外，它與任何真實的人或組織都沒有任何關聯。\n如果您覺得答案包含不適當的資訊或有問題，請長按訊息並檢舉。",
  "chat.ai-warning.title": "注意",
  "chat.input.placeholder": "請輸入您的訊息...",
  "chat.list": "對話",
  "chat.list.empty": "沒有對話",
  "chat.list.empty.action.find-character": "尋找角色",
  "chat.message.action.capture": "截圖分享",
  "chat.message.action.copy": "複製訊息",
  "chat.message.action.delete": "刪除訊息",
  "chat.message.action.delete.failed": "無法刪除訊息",
  "chat.message.action.edit": "編輯訊息",
  "chat.message.action.regenerate": "重新產生訊息",
  "chat.message.action.report": "對答案不滿意",
  "chat.message.action.report.description": "請告訴我們您不滿意的原因，以便我們提升回答質素",
  "chat.message.action.report.failed": "無法報告訊息",
  "chat.message.action.report.reason.CHAT_CONTEXT_LOST": "不記得我們之前談了什麼",
  "chat.message.action.report.reason.EMPTY_RESPONSE": "答案未顯示",
  "chat.message.action.report.reason.MIXED_LANGUAGE": "答案裡有奇怪的外來詞",
  "chat.message.action.report.reason.OTHERS": "其他",
  "chat.message.action.report.reason.REPETITIVE_RESPONSE": "重複同樣的話",
  "chat.message.action.report.reason.SETTINGS_CONTEXT_LOST": "回答與背景、設定不同步",
  "chat.message.action.report.success": "已發送反饋，多謝",
  "chat.message.action.select": "選擇",
  "chat.message.action.send.failed": "無法發送訊息",
  "chat.message.no-more": "沒有消息",
  "chat.recreate": "恢復聊天",
  "chat.recreate.description": "您想與這個角色開始新的對話嗎？",
  "chat.screenshot-share.more": "分享",
  "chat.screenshot-share.save-to-album.button": "保存",
  "chat.screenshot-share.save-to-album.success": "已保存到相簿",
  "chat.screenshot-share.text-content": "在MoguChat享受美好的對話！",
  "common.confirm.nonreversible": "此操作無法撤銷！",
  "common.confirm.title": "確定嗎？",
  "common.countdown-button": "{{action}}（在 {{seconds}} 後才可進行操作）",
  "common.go-back": "返回",
  "common.input.custom-value.option-label": "自訂...",
  "common.input.custom-value.placeholder": "輸入您的自訂選項",
  "common.input.custom-value.title": "輸入自訂選項",
  "common.loading": "載入中",
  "common.select.unselect": "取消選擇",
  "common.slogan": "透過 MoguChat 與你最喜歡的偶像聯繫，隨時隨地體驗對話的魔力",
  "common.tracking-modal.description": "如果您選擇在下一個畫面上允許跟踪，您將不再看到不相關的廣告。請在下一個畫面上選擇允許追蹤（´;ω;｀）。",
  "common.tracking-modal.primary-button": "繼續",
  "common.tracking-modal.title": "來自 MoguChat 的請求",
  "common.upload-image.error": "圖片上傳失敗，請重試",
  "common.upload-image.uploading": "上傳",
  "components.remote-image-input.permission-required.description": "我們需要您的相簿權限，以便您選擇圖片。請前往系統設置並允許 MoguChat 存取您的相簿。\n\n除非您選擇了特定的照片，否則我們不會上傳您照片圖庫中的任何項目。",
  "components.remote-image-input.permission-required.open-settings": "前往系統設定",
  "components.remote-image-input.permission-required.title": "照片庫權限",
  "download-app.description": "在行動電話上體驗MoguChat",
  "download-app.title": "獲取App",
  "error.auth-error": "您的登入詳細資訊可能有問題。如果此錯誤多次出現，請嘗試登出並重新登入。",
  "error.avatar-required": "圖片為必填項",
  "error.character-not-found": "未找到角色",
  "error.chat-not-found": "未找到聊天",
  "error.empty-character-list": "未找到字符",
  "error.empty-character-list.description": "未找到角色\n我們自己做吧(｀･ω･´)",
  "error.general-error": "操作無法完成",
  "error.internal-error": "伺服器消失了？ Σ(･ω･ﾉ)ﾉ！",
  "error.invalid-input": "無效輸入",
  "error.message-not-found": "未找到訊息",
  "error.moderation-flagged": "輸入不適合所有年齡層",
  "error.name-required": "姓名為必填項",
  "error.no-chat": "沒有對話",
  "error.no-chat.description": "尚未有任何聊天",
  "error.no-owned-character": "還沒有角色\n創造你最喜歡的角色！",
  "error.not-authorized": "沒有權限，請反饋此問題",
  "error.page.back": "返回首頁",
  "error.page.refresh": "重新載入",
  "error.page.title": "出了點問題",
  "error.permission-denied": "沒有權限",
  "error.save-character-failed": "無法創建角色",
  "error.tags-limit": "不允許再添加標籤",
  "error.token-expired": "連線時間已到期，請嘗試登出並重新登入",
  "error.unknown-error": "遇到未知錯誤",
  "error.update-profile-failed": "未知錯誤",
  "error.upload-failed": "上傳失敗",
  "error.upload-failed.require-permission": "由於權限不足，無法上傳",
  "event.nightclub.action.go-ranking-page": "排行",
  "event.nightclub.action.view-character": "提名",
  "event.nightclub.action.vote": "投擲香檳",
  "event.nightclub.action.vote.ranking-page": "投擲",
  "event.nightclub.characters.title": "投擲",
  "event.nightclub.introduction.content": "我不斷被忙碌的日程追趕，每天都覺得身體很沉重。接下來他看到的是一扇矗立在寂靜夜空的門。這就是夜總會「MG」——當然，這依然只是故事的序幕。在門的另一邊等待您的將是一位“演員”，他會用親切的話語和溫暖的微笑安慰您。在這裡，您可以忘記一切不愉快的事情，閉上眼睛，讓思緒飛揚。是的，就在MG這裡，在那個一直在等待你的特別的「他」的旁邊...",
  "event.nightclub.introduction.title": "歡迎來到夜晚的天堂…\n溫柔擁抱你的特別時刻",
  "event.nightclub.ranking.reward-description": "活動結束後將公佈前三名角色。\n成為他的“男朋友版”，\n新增角色！",
  "event.nightclub.ranking.title": "排行",
  "event.voting.no-ticket": "暫無投票項目",
  "event.voting.send-button": "投擲",
  "event.voting.success": "已投擲",
  "event.voting.title": "投擲香檳",
  "open-in-app.action.open": "打開",
  "open-in-app.download-dialog.title": "取得 MoguChat 應用",
  "open-in-app.title": "在 MoguChat 應用中打開",
  "profile.action.save": "存儲",
  "profile.edit": "編輯資料",
  "profile.edit.success": "更新了個人資料",
  "profile.field.biography": "關於我",
  "profile.field.biography.description": "在此輸入您的配置；其他人看不到該內容",
  "profile.field.biography.placeholder": "在此輸入您的對話偏好",
  "profile.field.gender-interest": "選擇您感興趣的類別",
  "profile.field.gender-interest.description": "告訴我們您的偏好",
  "profile.field.gender-interest.enum.all": "任意",
  "profile.field.gender-interest.enum.for-female": "女性向",
  "profile.field.gender-interest.enum.for-male": "男性向",
  "profile.field.nickname": "暱稱",
  "profile.field.nickname.description": "您希望別人怎樣稱呼您？",
  "profile.field.nickname.hint": "暱稱長度必須為2-20個字元。",
  "profile.field.nickname.placeholder": "輸入你的暱稱",
  "profile.field.username": "使用者名稱",
  "profile.field.username.placeholder": "輸入您的用戶名",
  "purchase.credit.ad-modal.description": "取消將結束聊天",
  "purchase.credit.ad-modal.error.rewarded.message": "添加米粒時遇到問題。如果您反復遇到此問題，請透過「我」頁面上的「支援」與我們聯繫",
  "purchase.credit.ad-modal.error.rewarded.title": "無法添加米飯",
  "purchase.credit.ad-modal.success.rewarded.message": "米粒已添加，享受接下來的聊天吧",
  "purchase.credit.ad-modal.success.rewarded.title": "米粒已添加",
  "purchase.credit.ad-modal.title": "您需要觀看廣告\n才能繼續聊天",
  "purchase.credit.help.content": "- 每回覆一則訊息將消耗一顆米粒。\n- 如果沒有可用的米粒，將自動消耗一顆飯糰。\n- 米粒可被免費獲得。\n- 飯糰沒有保存期限。\n- 每天凌晨4點（日本時間）之前獲取的米粒將會消失。",
  "purchase.credit.help.title": "關於米粒和飯糰",
  "purchase.credit.no-packs-available": "沒有可供購買的商品",
  "purchase.credit.only-available-on-mobile": "僅可在App內購買",
  "purchase.credit.pack.marketing.first-time-bonus": "首次購買翻倍！",
  "purchase.credit.pack.marketing.first-time-only": "僅可購買一次",
  "purchase.credit.store": "店鋪",
  "purchase.credit.watch-ads": "觀看廣告",
  "purchase.credit.watch-ads.download-app-to-watch": "下載應用程式以查看廣告",
  "purchase.failed": "購買失敗",
  "purchase.footer.privacy": "隱私",
  "purchase.footer.restore-purchase": "恢復購買",
  "purchase.footer.terms": "服務條款",
  "purchase.payment-callback.contact-support": "聯絡支援",
  "purchase.payment-callback.failed-to-verify.already-completed-the-payment.description": "如果您已經完成支付，請不用擔心！由於網絡問題或其他因素，支付可能會延遲。我們正在與支付提供商確認，請稍後重新整理頁面，查看支付是否已完成。如果重新整理頁面仍無法解決，請訪問支援頁面。我們的團隊將為您提供支持。",
  "purchase.payment-callback.failed-to-verify.already-completed-the-payment.title": "您已經完成付款了嗎？",
  "purchase.payment-callback.failed-to-verify.description": "無法確認您的付款。{{error}}",
  "purchase.payment-callback.failed-to-verify.title": "無法確認付款",
  "purchase.payment-callback.pending.description": "請等待付款確認…",
  "purchase.payment-callback.pending.title": "處理中",
  "purchase.payment-callback.refresh": "重新載入",
  "purchase.payment-callback.success.description": "獎勵已新增至帳戶。",
  "purchase.payment-callback.success.go-to-chat": "繼續",
  "purchase.payment-callback.success.title": "付款已完成",
  "purchase.price.monthly": "每月{{value}}",
  "purchase.price.weekly": "每週{{value}}",
  "purchase.price.yearly": "每年",
  "purchase.privilege.no-ads": "無廣告",
  "purchase.privilege.unlimited-characters": "無限地創建角色",
  "purchase.privilege.unlimited-messages": "取消訊息數量限制",
  "purchase.subscription.banner.content": "訂閱即可移除廣告，無限暢聊！",
  "purchase.subscription.benefits.ads-free": "刪除所有<strong>廣告</strong>，<strong>暢所欲言</strong>",
  "purchase.subscription.benefits.bonus-credits": "每天獲得<strong>飯糰*3(無保存期限)</strong>",
  "purchase.subscription.benefits.quicker-responses": "<strong>快速</strong>回覆速度",
  "purchase.subscription.benefits.remove-all-ads": "刪除所有<strong>廣告</strong>",
  "purchase.subscription.benefits.unlimited-chats": "<strong>無限對話</strong>",
  "purchase.subscription.benefits.unlimited-private-characters": "創建無限個<strong>非公開角色</strong>",
  "purchase.subscription.cta.already-subscribed": "已訂閱",
  "purchase.subscription.cta.free-trial": "免費試用",
  "purchase.subscription.cta.manage-subscription": "管理訂閱",
  "purchase.subscription.cta.subscribe": "訂閱",
  "purchase.subscription.interval.monthly": "月度計劃",
  "purchase.subscription.interval.yearly": "年度計劃",
  "purchase.subscription.price-info.begin-free-trial": "{{days}} 天免費",
  "purchase.subscription.product.mogupass.monthly.free-trial.description": "在{{days}}天的免費試用期後，將收取{{price}}",
  "purchase.subscription.product.mogupass.monthly.subscription-info": "按月收取費用。您可以隨時更改或取消方案。",
  "purchase.subscription.product.mogupass.yearly.free-trial.description": "在{{days}}天的免費試用期後，將收取{{price}}",
  "purchase.subscription.product.mogupass.yearly.subscription-info": "按年收取費用。您可以隨時更改或取消方案。",
  "purchase.subscription.reason.insufficient-balance": "餘額不足 - 為了無限制享受無廣告的聊天，建議您訂閱MoguPass！",
  "purchase.subscription.reason.private-character-limit-reached": "已達到可創建的非公開角色數量上限。訂閱MoguPass來創建無限個非公開角色！",
  "purchase.subscription.success": "獲得了 MoguPass！",
  "purchase.subscription.title": "MoguPass",
  "purchase.unavailable.provider-mismatch.description": "它可能已使用不同的作業系統進行註冊。",
  "purchase.unavailable.provider-mismatch.title": "無法註冊",
  "reward.daily-bonus": "登入獎勵",
  "reward.daily-bonus.claim": "收到",
  "reward.daily-bonus.claim-error": "我沒有收到獎金，請再試一次。\n如果該問題反覆出現，請聯絡我們。",
  "reward.daily-bonus.claimed": "收到了13袋米",
  "reward.daily-bonus.description": "登入獎勵每天凌晨 4:00（日本時間）重設。",
  "settings": "環境",
  "settings.account.delete": "刪除帳戶",
  "settings.account.delete.confirm.button-countdown": "（{{seconds}}後才可刪除）",
  "settings.account.delete.confirm.description": "如果您刪除您的帳戶，您的所有資料將被刪除並且無法恢復。",
  "settings.account.delete.confirm.title": "您確定要刪除您的帳戶嗎？",
  "settings.account.delete.error.active-subscription.description": "您無法刪除您的帳戶，因為您目前正在註冊計劃。您只能在套餐到期後刪除您的帳戶。",
  "settings.account.delete.error.active-subscription.title": "我無法刪除我的帳戶",
  "settings.account.sign-out": "登出",
  "settings.account.sign-out.confirm.description": "如果您退出，您將需要以相同的方式登入才能存取您目前的資料。",
  "settings.account.sign-out.confirm.title": "您確定要退出嗎？",
  "settings.account.title": "帳戶",
  "settings.account.user-id": "使用者 ID",
  "settings.account.user-id.description": "報告問題時請包含上面列出的使用者ID，這將有助於我們更好地識別問題。",
  "socials.discord.link": "https://discord.gg/grjhdf4SNb",
  "socials.discord.name": "官方 Discord",
  "socials.instagram.link": "https://www.instagram.com/moguchat_/",
  "socials.instagram.name": "Instagram",
  "socials.tiktok.link": "https://www.tiktok.com/@moguchatai",
  "socials.tiktok.name": "抖音",
  "socials.twitter.link": "https://x.com/MoguChatJP",
  "socials.twitter.name": "官方 X (原 Twitter)",
  "socials.youtube.link": "https://www.youtube.com/@MoguChatJP",
  "socials.youtube.name": "Youtube",
  "support.chat": "透過線上對談與我們聯繫",
  "support.title": "支援",
  "tab.chat": "交談",
  "tab.home": "首頁",
  "tab.profile": "我",
  "tag.all": "推薦",
  "transaction.list.empty": "什麼都沒有",
  "transaction.list.title": "交易歷史",
  "transaction.type.CONSOLE": "運營變更",
  "transaction.type.EVENT_VOTE": "活動投票",
  "transaction.type.PURCHASE": "購買附加包",
  "transaction.type.REGULAR_MESSAGE_COST": "回覆訊息",
  "transaction.type.REWARD_AD": "商業觀看獎金",
  "transaction.type.REWARD_DAILY_BONUS": "登入獎勵",
  "transaction.type.UNKNOWN": "未知",
  "update.available": "有新更新",
  "update.available.message": "下次打開應用程式時將自動更新",
  "user-inbox.empty": "沒有郵件",
  "user-inbox.title": "郵件"
}
