import { atom } from 'jotai'

export type PurchaseSheetState = {
  opened: boolean
  selected: 'subscribe' | 'store' | null
  reason?:
    | 'purchase.subscription.reason.private-character-limit-reached'
    | 'purchase.subscription.reason.insufficient-balance'
}

/**
 * purchaseSheetStateAtom controls the state of the purchase sheet
 * opened: false - sheet is closed
 * opened: true, selected: null - sheet is open with selection screen
 * opened: true, selected: 'subscribe' | 'store' - sheet is open with corresponding screen
 */
export const purchaseSheetStateAtom = atom<PurchaseSheetState>({
  opened: false,
  selected: null,
})
