import { useLocalSearchParams } from 'expo-router'
import { useEffect, useState } from 'react'
import CharacterNotFound from '../../../../components/character/CharacterNotFound'
import { EditOrCreateCharacter } from '../../../../components/characterManagement/EditOrCreateCharacter'
import MGLoadingOverlay from '../../../../components/common/MGLoadingOverlay'
import { trpcClient } from '../../../../lib/services/trpc'

export default function CharacterDetailScreen() {
  const { characterId } = useLocalSearchParams()
  const [character, setCharacter] = useState<Character | null>(null)
  const [valueUpdatedAt, setValueUpdatedAt] = useState<Date | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (characterId === 'create') {
      setCharacter(null)
      setIsLoading(false)
      setValueUpdatedAt(new Date())
    } else {
      setIsLoading(true)
      trpcClient.character.get
        .query({ id: characterId as string })
        .then((data) => {
          setCharacter(data)
          setValueUpdatedAt(new Date())
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [characterId])

  if (isLoading) {
    return <MGLoadingOverlay visible />
  }
  if (!character) {
    return <CharacterNotFound characterId={characterId.toString()} />
  }

  return (
    <EditOrCreateCharacter
      key={valueUpdatedAt?.toISOString()}
      characterId={characterId.toString()}
      defaultValues={{
        avatarUrl: character.avatarUrl ?? undefined,
        name: character.name,
        description: character.description ?? undefined,
        visibility: character.visibility,

        tagIds: character.tags?.map((tag: { id: string }) => tag.id) ?? [],
        details: {
          introMessage: character.details?.introMessage!,
          persona: character.details?.persona!,
          characterSetting: character.details?.characterSetting!,
          backgroundSetting: character.details?.backgroundSetting!,
          exampleConversation: character.details?.exampleConversation!,
          firstPersonPronoun: character.details?.firstPersonPronoun ?? undefined,
          secondPersonPronoun: character.details?.secondPersonPronoun ?? undefined,
          calloutStyle: character.details?.calloutStyle ?? undefined,
          speakingTone: character.details?.speakingTone ?? undefined,
        },
      }}
    />
  )
}
