/* tslint:disable */
/* eslint-disable eslint-comments/no-unlimited-disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull moguchat-frontend'
 *
 * Required dependencies: @amplitude/analytics-react-native@^0.4.0, @react-native-async-storage/async-storage@^1.17.9
 * Tracking Plan Version: 2
 * Build: 1.0.0
 * Runtime: react-native:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/mewtant/re-MoguChat/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/mewtant/re-MoguChat/implementation/moguchat-frontend)
 */

import * as amplitude from '@amplitude/analytics-react-native';

export type ReactNativeClient = amplitude.Types.ReactNativeClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
export type ReactNativeOptions = amplitude.Types.ReactNativeOptions;

export type Environment = 'remoguchat';

export const ApiKey: Record<Environment, string> = {
  remoguchat: '89625194dd70e70874674aaa91a3b2f1'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: ReactNativeOptions = {
  plan: {
    version: '2',
    branch: 'main',
    source: 'moguchat-frontend',
    versionId: '37fa79c0-4ff1-4695-a797-287b8fc3ef88'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'react-native-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: ReactNativeOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: ReactNativeOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: ReactNativeClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  display_name?: string;
  email?: string;
  is_admin?: boolean;
  referrer?: string;
  referring_domain?: string;
  user_agent?: string;
  username?: string;
}

export interface BannerAdErrorProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | display_failed, load_failed |
   */
  banner_ad_error_type: "display_failed" | "load_failed";
}

export interface CharacterCreatedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total_details_length: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | PUBLIC, PRIVATE |
   */
  visibility: "PUBLIC" | "PRIVATE";
}

export interface EventVotingDialogOpenedProperties {
  character_id: string;
}

export interface EventVotingSendButtonClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amount: number;
  character_id: string;
}

export interface IndexTagSelectedProperties {
  tag_id: string;
}

export interface LoginProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | google, apple |
   */
  provider: "google" | "apple";
}

export interface MessageDialogActionProperties {
  action: string;
}

export interface PageViewProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  duration_seconds?: number;
  pathname?: string;
}

export interface PurchaseCreditPackClickedProperties {
  is_first_time: boolean;
  /**
   * Deprecated: Due to ambiguity across platforms (different platforms may have different SKUs for a single product), please use `store_item_id` instead.
   */
  sku?: string;
  store_item_id: string;
}

export interface SearchCharacterProperties {
  keyword: string;
}

export interface ShareButtonClickedProperties {
  share_subject: string;
  share_subject_id: string;
}

export interface ShareLinkClickedProperties {
  author_id: string;
  share_id: string;
  subject_id: string;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AdRewardedEarned implements BaseEvent {
  event_type = 'ad_rewarded_earned';
}

export class AdRewardedShown implements BaseEvent {
  event_type = 'ad_rewarded_shown';
}

export class AddCreditBottomSheetOpened implements BaseEvent {
  event_type = 'add_credit_bottom_sheet_opened';
}

export class BannerAdError implements BaseEvent {
  event_type = 'banner_ad_error';

  constructor(
    public event_properties: BannerAdErrorProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CharacterCreated implements BaseEvent {
  event_type = 'character_created';

  constructor(
    public event_properties: CharacterCreatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EventVotingDialogOpened implements BaseEvent {
  event_type = 'event_voting_dialog_opened';

  constructor(
    public event_properties: EventVotingDialogOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EventVotingSendButtonClicked implements BaseEvent {
  event_type = 'event_voting_send_button_clicked';

  constructor(
    public event_properties: EventVotingSendButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class IndexTagSelected implements BaseEvent {
  event_type = 'index_tag_selected';

  constructor(
    public event_properties: IndexTagSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class Login implements BaseEvent {
  event_type = 'login';

  constructor(
    public event_properties: LoginProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MessageDialogAction implements BaseEvent {
  event_type = 'message_dialog_action';

  constructor(
    public event_properties: MessageDialogActionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PageView implements BaseEvent {
  event_type = 'page_view';

  constructor(
    public event_properties?: PageViewProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PurchaseCreditPackClicked implements BaseEvent {
  event_type = 'purchase_credit_pack_clicked';

  constructor(
    public event_properties: PurchaseCreditPackClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchCharacter implements BaseEvent {
  event_type = 'search_character';

  constructor(
    public event_properties: SearchCharacterProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ShareButtonClicked implements BaseEvent {
  event_type = 'share_button_clicked';

  constructor(
    public event_properties: ShareButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ShareLinkClicked implements BaseEvent {
  event_type = 'share_link_clicked';

  constructor(
    public event_properties: ShareLinkClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: ReactNativeClient;

  get client(): ReactNativeClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }

    return this.amplitude!.identify(amplitudeIdentify, options);
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Flush pending events.
   */
  flush(): PromiseResult<void> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * ad_rewarded_earned
   *
   * [View in Tracking Plan](https://data.amplitude.com/mewtant/re-MoguChat/events/main/latest/ad_rewarded_earned)
   *
   * Event to track when a user earns a reward after interacting with an advertisement.
   *
   * @param options Amplitude event options.
   */
  adRewardedEarned(
    options?: EventOptions,
  ) {
    return this.track(new AdRewardedEarned(), options);
  }

  /**
   * ad_rewarded_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/mewtant/re-MoguChat/events/main/latest/ad_rewarded_shown)
   *
   * Event to track when a rewarded ad is displayed to the user.
   *
   * @param options Amplitude event options.
   */
  adRewardedShown(
    options?: EventOptions,
  ) {
    return this.track(new AdRewardedShown(), options);
  }

  /**
   * add_credit_bottom_sheet_opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/mewtant/re-MoguChat/events/main/latest/add_credit_bottom_sheet_opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  addCreditBottomSheetOpened(
    options?: EventOptions,
  ) {
    return this.track(new AddCreditBottomSheetOpened(), options);
  }

  /**
   * banner_ad_error
   *
   * [View in Tracking Plan](https://data.amplitude.com/mewtant/re-MoguChat/events/main/latest/banner_ad_error)
   *
   * Event to track errors related to displaying banner ads on the platform.
   *
   * @param properties The event's properties (e.g. banner_ad_error_type)
   * @param options Amplitude event options.
   */
  bannerAdError(
    properties: BannerAdErrorProperties,
    options?: EventOptions,
  ) {
    return this.track(new BannerAdError(properties), options);
  }

  /**
   * character_created
   *
   * [View in Tracking Plan](https://data.amplitude.com/mewtant/re-MoguChat/events/main/latest/character_created)
   *
   * Event to track when a new character is created in the system.
   *
   * @param properties The event's properties (e.g. total_details_length)
   * @param options Amplitude event options.
   */
  characterCreated(
    properties: CharacterCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CharacterCreated(properties), options);
  }

  /**
   * event_voting_dialog_opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/mewtant/re-MoguChat/events/main/latest/event_voting_dialog_opened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. character_id)
   * @param options Amplitude event options.
   */
  eventVotingDialogOpened(
    properties: EventVotingDialogOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventVotingDialogOpened(properties), options);
  }

  /**
   * event_voting_send_button_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/mewtant/re-MoguChat/events/main/latest/event_voting_send_button_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. amount)
   * @param options Amplitude event options.
   */
  eventVotingSendButtonClicked(
    properties: EventVotingSendButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventVotingSendButtonClicked(properties), options);
  }

  /**
   * index_tag_selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/mewtant/re-MoguChat/events/main/latest/index_tag_selected)
   *
   * Event to track when a user selects a tag from the index for further exploration or filtering.
   *
   * @param properties The event's properties (e.g. tag_id)
   * @param options Amplitude event options.
   */
  indexTagSelected(
    properties: IndexTagSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new IndexTagSelected(properties), options);
  }

  /**
   * login
   *
   * [View in Tracking Plan](https://data.amplitude.com/mewtant/re-MoguChat/events/main/latest/login)
   *
   * Event to track user login activity, capturing when users successfully log into the platform.
   *
   * @param properties The event's properties (e.g. provider)
   * @param options Amplitude event options.
   */
  login(
    properties: LoginProperties,
    options?: EventOptions,
  ) {
    return this.track(new Login(properties), options);
  }

  /**
   * message_dialog_action
   *
   * [View in Tracking Plan](https://data.amplitude.com/mewtant/re-MoguChat/events/main/latest/message_dialog_action)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  messageDialogAction(
    properties: MessageDialogActionProperties,
    options?: EventOptions,
  ) {
    return this.track(new MessageDialogAction(properties), options);
  }

  /**
   * page_view
   *
   * [View in Tracking Plan](https://data.amplitude.com/mewtant/re-MoguChat/events/main/latest/page_view)
   *
   * A pathname has been visit**ed** for an optional duration of time.
   *
   * @param properties The event's properties (e.g. duration_seconds)
   * @param options Amplitude event options.
   */
  pageView(
    properties?: PageViewProperties,
    options?: EventOptions,
  ) {
    return this.track(new PageView(properties), options);
  }

  /**
   * purchase_credit_pack_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/mewtant/re-MoguChat/events/main/latest/purchase_credit_pack_clicked)
   *
   * Event triggered when a user clicks on the option to purchase a credit pack.
   *
   * @param properties The event's properties (e.g. is_first_time)
   * @param options Amplitude event options.
   */
  purchaseCreditPackClicked(
    properties: PurchaseCreditPackClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PurchaseCreditPackClicked(properties), options);
  }

  /**
   * search_character
   *
   * [View in Tracking Plan](https://data.amplitude.com/mewtant/re-MoguChat/events/main/latest/search_character)
   *
   * Event to track when a user searches for a character within the application.
   *
   * @param properties The event's properties (e.g. keyword)
   * @param options Amplitude event options.
   */
  searchCharacter(
    properties: SearchCharacterProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchCharacter(properties), options);
  }

  /**
   * share_button_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/mewtant/re-MoguChat/events/main/latest/share_button_clicked)
   *
   * Event to track when a user clicks on the share button within the MoguChat application.
   *
   * @param properties The event's properties (e.g. share_subject)
   * @param options Amplitude event options.
   */
  shareButtonClicked(
    properties: ShareButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShareButtonClicked(properties), options);
  }

  /**
   * share_link_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/mewtant/re-MoguChat/events/main/latest/share_link_clicked)
   *
   * A share link (/s/\[shareId\]) has been visited by other users.
   *
   * @param properties The event's properties (e.g. author_id)
   * @param options Amplitude event options.
   */
  shareLinkClicked(
    properties: ShareLinkClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShareLinkClicked(properties), options);
  }
}

export const ampli = new Ampli();
