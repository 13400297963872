import type Ionicons from '@expo/vector-icons/Ionicons'
import clsx from 'clsx'
import { type ComponentProps, type FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Markdown from 'react-native-markdown-display'
import Animated from 'react-native-reanimated'
import { match } from 'ts-pattern'
import { regularFontFamily } from '../../constants/Font'
import useCountdown from '../../lib/hooks/useCountdown'
import MGBaseModal from './MGBaseModal'
import { MGModalButton } from './MGModalButton'
import MGText from './MGText'

const DIALOG_CONFIG = {
  neutral: {
    name: 'information' as const,
    color: '#e5e5e5',
    iconBgClassName: 'bg-neutral-100 dark:bg-neutral-900',
    buttonColor: 'bg-neutral-500',
  },
  info: {
    name: 'information' as const,
    color: '#3b82f6',
    iconBgClassName: 'bg-blue-100 dark:bg-blue-600/20',
    buttonColor: 'bg-blue-500',
  },
  warning: {
    name: 'warning' as const,
    color: '#f59e0b',
    iconBgClassName: 'bg-amber-100 dark:bg-amber-600/20',
    buttonColor: 'bg-amber-500',
  },
  danger: {
    name: 'alert' as const,
    color: '#ef4444',
    iconBgClassName: 'bg-red-100 dark:bg-red-600/20',
    buttonColor: 'bg-red-500',
  },
  destructive: {
    name: 'alert' as const,
    color: '#ef4444',
    iconBgClassName: 'bg-red-100 dark:bg-red-600/20',
    buttonColor: 'bg-red-100 dark:bg-red-600/20',
  },
} as const

type IconModalProps = {
  visible: boolean
  severity: keyof typeof DIALOG_CONFIG
  icon?: ComponentProps<typeof Ionicons>['name']
  overrideIcon?: React.ReactNode
  title: string
  /**
   * Markdown is supported
   */
  description: string
  textAlignment?: 'left' | 'center' | 'right'
  descriptionRequireScrollView?: boolean
  descriptionType?: 'markdown' | 'plain'
  primaryButtonLoading?: boolean
  onCancel: () => void
  hideCancelButton?: boolean
} & (
  | {
      onConfirm: () => void | Promise<void>
      primaryButtonContent: string
    }
  | {
      onConfirm: (() => void | Promise<void>)[]
      primaryButtonContent: string[]
    }
)

const IconModal: FC<IconModalProps> = ({
  visible,
  severity,
  icon,
  overrideIcon,
  title,
  description,
  descriptionRequireScrollView = false,
  descriptionType = 'markdown',
  textAlignment = 'center',
  primaryButtonContent,
  primaryButtonLoading,
  onCancel,
  onConfirm,
  hideCancelButton,
}) => {
  const { t } = useTranslation()
  const [countdown, startCountdown] = useCountdown(severity === 'destructive' ? 10 : severity === 'danger' ? 5 : 0)
  const [isProcessing, setIsProcessing] = useState(false)

  useEffect(() => {
    if (visible) {
      startCountdown()
    }
  }, [visible, startCountdown])

  const handleConfirm = useCallback(
    (index?: number) => {
      if (countdown === 0) {
        let result: void | Promise<void>
        if (typeof onConfirm === 'function') {
          result = onConfirm()
        } else {
          if (index === undefined) {
            throw new Error('Index is required')
          }
          result = onConfirm[index]()
        }
        if (result instanceof Promise) {
          setIsProcessing(true)
          result.finally(() => setIsProcessing(false))
        }
      }
    },
    [countdown, onConfirm],
  )

  const config = DIALOG_CONFIG[severity]
  const resolvedIcon = icon ?? config.name

  const resolvedMarkdownParagraphJustifyContent = match(textAlignment)
    .with('left', () => 'flex-start' as const)
    .with('center', () => 'center' as const)
    .with('right', () => 'flex-end' as const)
    .exhaustive()

  // the outer Animated.View is required to prevent the modal from display incorrectly on Android
  // unsure if this is related but it seems to be: https://github.com/software-mansion/react-native-reanimated/issues/6659
  return (
    <Animated.View>
      <MGBaseModal
        visible={visible}
        onClose={onCancel}
        title={title}
        textAlignment={textAlignment}
        icon={resolvedIcon}
        iconColor={config.color}
        iconBgColor={config.iconBgClassName}
        overrideIcon={overrideIcon}
        contentRequireScrollView={descriptionRequireScrollView}
        contentMaxHeightPercentage={0.7}
      >
        {descriptionType === 'markdown' ? (
          <Markdown
            style={{
              body: {
                width: '100%',
                lineHeight: 24,
                fontSize: 16,
                fontFamily: regularFontFamily,
                color: '#525252',
                textAlign: textAlignment,
              },
              paragraph: {
                marginTop: 0,
                justifyContent: resolvedMarkdownParagraphJustifyContent,
              },
            }}
          >
            {description}
          </Markdown>
        ) : (
          <MGText>{description}</MGText>
        )}
        {typeof primaryButtonContent === 'string' ? (
          <MGModalButton
            onPress={handleConfirm}
            disabled={countdown > 0}
            foregroundAppearance={severity === 'destructive' ? 'dark' : 'light'}
            className={clsx(
              countdown > 0 ? 'bg-neutral-100' : config.buttonColor,
              severity === 'destructive' ? 'mt-4 mb-16 py-1 self-center' : 'mt-4',
            )}
            textClassName={clsx(
              countdown > 0 ? 'text-neutral-400' : severity === 'destructive' ? 'text-red-600' : undefined,
            )}
            loading={isProcessing || primaryButtonLoading}
          >
            {countdown > 0
              ? t('common.countdown-button', {
                  seconds: countdown,
                  action: primaryButtonContent,
                })
              : primaryButtonContent}
          </MGModalButton>
        ) : (
          primaryButtonContent.map((content, index) => (
            <MGModalButton
              key={content}
              onPress={() => handleConfirm(index)}
              disabled={countdown > 0}
              foregroundAppearance={severity === 'destructive' ? 'dark' : 'light'}
              className={clsx(
                countdown > 0 ? 'bg-neutral-100' : config.buttonColor,
                severity === 'destructive' ? 'mt-2 mb-16 py-1 self-center' : 'mt-2',
              )}
              textClassName={clsx(
                countdown > 0 ? 'text-neutral-400' : severity === 'destructive' ? 'text-red-600' : 'text-white',
              )}
              loading={isProcessing || primaryButtonLoading}
            >
              {content}
            </MGModalButton>
          ))
        )}

        {!hideCancelButton && (
          <MGModalButton
            onPress={onCancel}
            disabled={isProcessing}
            className={clsx(
              'mt-2',
              severity === 'destructive'
                ? 'bg-neutral-800 dark:bg-white py-28 border border-neutral-900'
                : 'bg-neutral-100',
            )}
            textClassName={clsx(severity === 'destructive' && 'text-2xl text-white dark:text-black')}
            foregroundAppearance={severity === 'destructive' ? 'light' : undefined}
          >
            {t('action.cancel')}
          </MGModalButton>
        )}
      </MGBaseModal>
    </Animated.View>
  )
}

export default IconModal
