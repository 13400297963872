import clsx from 'clsx'
import { type FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { TouchableOpacity } from 'react-native'
import { useColorScheme } from '../../lib/storage'
import MGBaseModal from './MGBaseModal'
import { MGIonicons } from './MGIonicons'
import { MGModalButton } from './MGModalButton'
import MGText from './MGText'

// Color scheme display names and icons mapping
const COLOR_SCHEME_CONFIG = {
  system: {
    icon: 'cog' as const,
    color: '#10b981',
  },
  light: {
    icon: 'sunny' as const,
    color: '#f59e0b',
  },
  dark: {
    icon: 'moon' as const,
    color: '#3b82f6',
  },
} as const

type ColorSchemeType = 'light' | 'dark' | 'system'

interface ColorSchemeSelectorModalProps {
  visible: boolean
  onClose: () => void
}

const ColorSchemeSelectorModal: FC<ColorSchemeSelectorModalProps> = ({ visible, onClose }) => {
  const { t } = useTranslation()
  const [selectedScheme, setSelectedScheme] = useColorScheme()
  const resolvedSelectedScheme = selectedScheme ?? 'system'

  const handleSchemeSelect = useCallback(
    (scheme: ColorSchemeType) => {
      if (scheme === selectedScheme) {
        return
      }

      setSelectedScheme(scheme)
      // no need to call colorScheme.set(scheme) because useSyncColorScheme will manage it
    },
    [selectedScheme, onClose],
  )

  return (
    <MGBaseModal
      visible={visible}
      onClose={onClose}
      title={t('app-settings.color-scheme.title')}
      icon="color-palette"
      iconColor="#3b82f6"
      iconBgColor="bg-neutral-100"
      contentRequireScrollView={false}
    >
      {(['system', 'dark', 'light'] as const).map((scheme) => (
        <TouchableOpacity
          key={scheme}
          className={clsx(
            'flex-row items-center justify-between p-4 my-1 rounded-xl gap-2',
            scheme === resolvedSelectedScheme ? 'bg-neutral-300' : 'bg-neutral-100',
          )}
          onPress={() => handleSchemeSelect(scheme)}
        >
          <MGIonicons name={COLOR_SCHEME_CONFIG[scheme].icon} size={18} className="opacity-60" />
          <MGText
            className={clsx(
              'text-base flex-row items-center gap-2 flex-1',
              scheme === resolvedSelectedScheme && 'text-foreground',
            )}
          >
            {t(`app-settings.color-scheme.enum.${scheme}`)}
          </MGText>
          {scheme === resolvedSelectedScheme && <MGIonicons name="checkmark" size={18} />}
        </TouchableOpacity>
      ))}

      <MGModalButton onPress={onClose} className="bg-neutral-100 mt-4">
        {t('action.close')}
      </MGModalButton>
    </MGBaseModal>
  )
}

export default ColorSchemeSelectorModal
