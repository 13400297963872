import { Image } from 'expo-image'
import { View } from 'react-native'
import { useFormatRelativeTime } from '../../lib/hooks/useFormatRelativeTime'
import MGText from '../common/MGText'

// FIXME: Now I know why GraphQL sucks. Yikes! Should we try using proper Fragments to avoid this?
interface ChatCardProps {
  lastActiveAt: string
  avatarUrl?: string
  name: string
  lastMessage?: {
    content: string
  } | null
}

export default function ChatCard({ lastActiveAt, avatarUrl, name, lastMessage }: ChatCardProps) {
  const formattedTime = useFormatRelativeTime(new Date(lastActiveAt))

  return (
    <View className="flex flex-row w-full items-center gap-2 p-2">
      <Image
        source={avatarUrl ?? require('../../assets/images/character-avatar-placeholder.webp')}
        contentFit="cover"
        placeholderContentFit="cover"
        contentPosition="center"
        style={{
          width: 56,
          height: 56,
          alignSelf: 'center',
          borderRadius: 9999,
          objectFit: 'cover',
        }}
        placeholder={require('../../assets/images/character-avatar-placeholder.webp')}
      />
      <View className="flex flex-col flex-1">
        <View className="flex flex-row justify-between items-center">
          <MGText bold className="text-lg flex-1" numberOfLines={1}>
            {name}
          </MGText>
          <MGText className="text-sm text-foreground opacity-50 ml-4">{formattedTime}</MGText>
        </View>
        <MGText numberOfLines={2} className="text-foreground opacity-50">
          {lastMessage?.content.replace(/\n/g, ' ') ?? ''}
        </MGText>
      </View>
    </View>
  )
}
