import { QueryClientProvider } from '@tanstack/react-query'
import type { FC, PropsWithChildren } from 'react'
import { queryClient } from '../../lib/services/client'
import { trpcClient, trpcReact } from '../services/trpc'

const QueryProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <trpcReact.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpcReact.Provider>
  )
}

export default QueryProvider
