{
  "errors.custom": "格式錯誤",
  "errors.invalid_arguments": "參數錯誤",
  "errors.invalid_date": "錯誤的日期",
  "errors.invalid_enum_value": "無效的 '{{received}}' 值，請輸入 {{- options}}",
  "errors.invalid_intersection_types": "交集類型無法合併",
  "errors.invalid_literal": "無效的輸入，請輸入 {{expected}}",
  "errors.invalid_return_type": "錯誤的回傳值類型",
  "errors.invalid_string.cuid": "{{validation}} 格式錯誤",
  "errors.invalid_string.datetime": "{{validation}} 格式錯誤",
  "errors.invalid_string.email": "{{validation}}格式錯誤",
  "errors.invalid_string.endsWith": "必須以 \"{{endsWith}}\" 結尾",
  "errors.invalid_string.regex": "格式錯誤",
  "errors.invalid_string.startsWith": "必須以 \"{{startsWith}}\" 開始",
  "errors.invalid_string.url": "{{validation}}格式錯誤",
  "errors.invalid_string.uuid": "{{validation}} 格式錯誤",
  "errors.invalid_type": "期望輸入的是{{expected}}，而輸入的是{{received}}",
  "errors.invalid_type_received_null": "必填的欄位",
  "errors.invalid_type_received_undefined": "必填的欄位",
  "errors.invalid_union": "輸入格式錯誤",
  "errors.invalid_union_discriminator": "無效的識別符，請輸入 {{- options}}",
  "errors.not_finite": "不能為無限值",
  "errors.not_multiple_of": "必須是 {{multipleOf}} 的倍數",
  "errors.too_big.array.exact": "必須是{{maximum}}個元素。",
  "errors.too_big.array.inclusive": "最多只能包含 {{maximum}} 個元素",
  "errors.too_big.array.not_inclusive": "必須少於 {{maximum}} 個元素",
  "errors.too_big.date.exact": "必須是 {{- maximum, datetime}} 內的日期時間。",
  "errors.too_big.date.inclusive": "日期必須早於或等於 {{- maximum, datetime}}",
  "errors.too_big.date.not_inclusive": "日期必須早於 {{- maximum, datetime}}",
  "errors.too_big.number.exact": "{{maximum}} 中必須是數字。",
  "errors.too_big.number.inclusive": "必須小於或等於 {{maximum}}",
  "errors.too_big.number.not_inclusive": "必須小於 {{maximum}}",
  "errors.too_big.set.exact": "無效的輸入",
  "errors.too_big.set.inclusive": "無效的輸入",
  "errors.too_big.set.not_inclusive": "無效的輸入",
  "errors.too_big.string.exact": "必須是 {{maximum}} 個字元的字串。",
  "errors.too_big.string.inclusive": "最多只能包含 {{maximum}} 個字元",
  "errors.too_big.string.not_inclusive": "必須少於 {{maximum}} 個字元",
  "errors.too_small.array.exact": "需要 {{minimum}} 個元素。",
  "errors.too_small.array.inclusive": "至少需要包含 {{minimum}} 個元素",
  "errors.too_small.array.not_inclusive": "必須包含多於 {{minimum}} 個元素",
  "errors.too_small.date.exact": "必須是 {{- minimum, datetime}} 內的日期時間。",
  "errors.too_small.date.inclusive": "日期必須晚於或等於 {{- minimum, datetime}}",
  "errors.too_small.date.not_inclusive": "日期必須晚於 {{- minimum, datetime}}",
  "errors.too_small.number.exact": "{{minimum}} 中必須是數字。",
  "errors.too_small.number.inclusive": "必須大於或等於 {{minimum}}",
  "errors.too_small.number.not_inclusive": "必須大於 {{minimum}}",
  "errors.too_small.set.exact": "無效的輸入",
  "errors.too_small.set.inclusive": "無效的輸入",
  "errors.too_small.set.not_inclusive": "無效的輸入",
  "errors.too_small.string.exact": "必須是 {{minimum}} 個字元的字串。",
  "errors.too_small.string.inclusive": "至少需要包含 {{minimum}} 個字元",
  "errors.too_small.string.not_inclusive": "必須包含多於 {{minimum}} 個字元",
  "errors.unrecognized_keys": "無法識別物件的鍵值：{{- keys}}",
  "types.array": "陣列",
  "types.bigint": "Bigint",
  "types.boolean": "布林值",
  "types.date": "日期",
  "types.float": "浮點數",
  "types.function": "函數",
  "types.integer": "整數",
  "types.map": "Map",
  "types.nan": "NaN",
  "types.never": "never",
  "types.null": "null",
  "types.number": "數字",
  "types.object": "物件",
  "types.promise": "Promise",
  "types.set": "Set",
  "types.string": "字串",
  "types.symbol": "Symbol",
  "types.undefined": "未定義",
  "types.unknown": "unknown",
  "types.void": "void",
  "validations.cuid": "cuid",
  "validations.datetime": "datetime",
  "validations.email": "電子郵件",
  "validations.regex": "正則表達式",
  "validations.url": "連結",
  "validations.uuid": "uuid"
}
