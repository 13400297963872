import { reset } from '@amplitude/analytics-react-native'
import { setUser } from '@sentry/react-native'
import { invalidateCurrentUser } from '../lib/hooks/useAuth'
import { setToken } from '../lib/storage'
import { resetSession } from '../utils/crisp'
import { queryClient } from './services/client'
import { router } from 'expo-router'
import { dissociatePushTokenFromServer } from './notification'

export const signOut = async () => {
  await dissociatePushTokenFromServer()
  resetSession()
  setToken()
  await invalidateCurrentUser()
  queryClient.clear()
  reset()
  setUser(null)
  router.replace('/login')
}
