import * as Burnt from 'burnt'
import { Image } from 'expo-image'
import { Link, useLocalSearchParams, useRouter } from 'expo-router'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Share, TouchableOpacity, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import MGHeader from '../../../components/common/MGHeader'
import MGHeaderText from '../../../components/common/MGHeaderText'
import { MGIonicons } from '../../../components/common/MGIonicons'
import MGText from '../../../components/common/MGText'
import { MGActivityIndicator } from '../../../components/ui/MGActivityIndicator'
import { trpcClient, trpcReact, trpcUtils } from '../../../lib/services/trpc'

export default function EditCompletePage() {
  const { t } = useTranslation()
  const insets = useSafeAreaInsets()
  const router = useRouter()
  const { type, characterId } = useLocalSearchParams<{ type: 'edit' | 'create'; characterId: string }>()

  // Fetch character data
  const { data: character, isPending } = trpcReact.character.get.useQuery(
    { id: characterId },
    {
      enabled: !!characterId,
    },
  )

  const handleShare = async () => {
    if (!character) return

    const content = `${character.name} | https://moguchat.ai/character/${character.id}`
    const url = `https://moguchat.ai/character/${character.id}`
    try {
      const result = await Share.share({
        message: content,
        url,
      })
      if (result.action === Share.dismissedAction) {
        Burnt.toast({
          title: t('character.share.error-title'),
          message: t('character.share.error-message'),
          preset: 'error',
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const { mutateAsync: featuringRequest } = trpcReact.character.featuring.request.create.useMutation()

  const handleSubmitToFeatured = async () => {
    if (character?.id) {
      setIsOperationPending(true)
      try {
        setIsOperationPending(true)
        await featuringRequest({ characterId: character.id })
        Burnt.toast({
          title: t('character.submit-to-featured.success-title'),
          message: t('character.submit-to-featured.success-message'),
          preset: 'done',
        })
      } catch (error) {
        Burnt.toast({
          title: t('character.submit-to-featured.error-title'),
          message: error instanceof Error ? error.message : String(error),
          preset: 'error',
        })
      } finally {
        setIsOperationPending(false)
      }
    }
  }

  const [isOperationPending, setIsOperationPending] = useState(false)

  const handleStartChat = async () => {
    setIsOperationPending(true)
    try {
      if (!character?.id) {
        return
      }
      const chat = await trpcClient.chat.create.mutate({ characterId: character.id })
      trpcUtils.chat.list.invalidate()
      router.push(`/chat/${chat.id}`)
    } catch (error) {
      console.error(error)
    } finally {
      setIsOperationPending(false)
    }
  }
  const isPublic = character?.visibility === 'PUBLIC'

  return (
    <View
      className="flex-1 bg-background"
      style={{
        paddingTop: insets.top,
        paddingBottom: insets.bottom,
        paddingLeft: insets.left,
        paddingRight: insets.right,
      }}
    >
      <MGHeader headerBody={<MGHeaderText>{t('character.edit-complete.title')}</MGHeaderText>} />

      <View className="flex-1 items-center justify-center px-4">
        {isPending ? (
          <MGActivityIndicator size="large" color="#ffade1" />
        ) : character ? (
          <Fragment>
            <View className="items-center mb-8">
              {character.avatarUrl ? (
                <Image
                  source={{ uri: character.avatarUrl }}
                  style={{ width: 120, height: 120, borderRadius: 60 }}
                  className="mb-4"
                />
              ) : (
                <Image
                  source={require('../../../assets/images/character-avatar-placeholder.webp')}
                  style={{ width: 120, height: 120, borderRadius: 60 }}
                  className="mb-4"
                />
              )}
              <MGText className="text-2xl font-bold text-center">{character.name}</MGText>
              <MGText className="text-neutral-500 text-center mt-2">
                {t('character.edit-complete.success-message')}
              </MGText>
            </View>

            {/* Primary Action */}
            <TouchableOpacity
              className="bg-primary-400 rounded-full py-4 px-8 w-64 items-center mb-6 flex flex-row gap-2 justify-center"
              onPress={handleStartChat}
              disabled={isOperationPending}
            >
              {isOperationPending ? (
                <MGActivityIndicator size="small" />
              ) : (
                <Fragment>
                  <MGIonicons name="chatbubbles-outline" size={24} color="white" />
                  <MGText className="text-white font-semibold">{t('character.edit-complete.start-chat')}</MGText>
                </Fragment>
              )}
            </TouchableOpacity>

            {/* Secondary Actions */}
            <View className="flex-row justify-center gap-4">
              {isPublic && (
                <Fragment>
                  <TouchableOpacity
                    className="bg-neutral-100 rounded-full py-3 px-6 items-center flex flex-row gap-2 justify-center"
                    onPress={handleSubmitToFeatured}
                    disabled={isOperationPending}
                  >
                    <MGIonicons name="star-outline" size={24} />
                    {isOperationPending ? (
                      <MGActivityIndicator size="small" />
                    ) : (
                      <MGText className="text-neutral-800">{t('character.edit-complete.submit-to-featured')}</MGText>
                    )}
                  </TouchableOpacity>

                  <TouchableOpacity
                    disabled={isOperationPending}
                    className="bg-neutral-100 rounded-full py-3 px-6 items-center flex flex-row gap-2 justify-center"
                    onPress={handleShare}
                  >
                    <MGIonicons name="share-outline" size={24} />
                    <MGText className="text-neutral-800">{t('character.edit-complete.share')}</MGText>
                  </TouchableOpacity>
                </Fragment>
              )}
            </View>
          </Fragment>
        ) : (
          <View className="items-center">
            <MGText className="text-red-500">{t('character.not-found')}</MGText>
            <Link href="/character/my" asChild>
              <TouchableOpacity className="mt-4">
                <MGText className="text-primary-400">{t('common.go-back')}</MGText>
              </TouchableOpacity>
            </Link>
          </View>
        )}
      </View>
    </View>
  )
}
