import clsx from 'clsx'
import { forwardRef, useState } from 'react'
import { TextInput as RNTextInput, type TextInputProps } from 'react-native'
import Animated, { interpolateColor, useAnimatedStyle, withTiming } from 'react-native-reanimated'
import { useTheme } from '../../lib/theme'

export const MGTextInput = forwardRef<RNTextInput, TextInputProps>(({ className, ...props }, ref) => {
  const [isFocused, setIsFocused] = useState(false)
  const theme = useTheme()

  const animatedStyles = useAnimatedStyle(() => ({
    borderWidth: 1,
    borderColor: withTiming(interpolateColor(isFocused ? 1 : 0, [0, 1], ['transparent', '#f83caa']), {
      duration: 200,
    }),
  }))

  return (
    <Animated.View className={clsx('bg-neutral-100 rounded-lg', className)} style={animatedStyles}>
      <RNTextInput
        ref={ref}
        className="px-4 py-3 text-foreground"
        placeholderTextColor={theme['neutral-300']}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onEndEditing={() => setIsFocused(false)}
        {...props}
      />
    </Animated.View>
  )
})

MGTextInput.displayName = 'MGTextInput'
