import { Ionicons } from '@expo/vector-icons'
import { toast } from 'burnt'
import clsx from 'clsx'
import { LinearGradient } from 'expo-linear-gradient'
import { Link } from 'expo-router'
import { Fragment, memo, useMemo, type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { FlatList, Image, RefreshControl, TouchableHighlight, TouchableOpacity, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { match } from 'ts-pattern'
import { MGHeader } from '../../../components/common/MGHeader'
import MGHeaderText from '../../../components/common/MGHeaderText'
import MGText from '../../../components/common/MGText'
import NonIdealState from '../../../components/common/NonIdealState'
import { MGActivityIndicator } from '../../../components/ui/MGActivityIndicator'
import { trpcReact, trpcUtils } from '../../../lib/services/trpc'

interface Character {
  id: string
  name: string
  description: string
  avatarUrl: string
  featuringRequestStatus: 'FEATURED' | 'PENDING' | 'NONE'
}

interface CharacterRowProps {
  character: Character
}

const CharacterRow: FC<CharacterRowProps> = ({ character }) => {
  const { t } = useTranslation()
  const { featuringRequestStatus } = character

  const { mutate: requestFeature, isPending } = trpcReact.character.featuring.request.create.useMutation({
    onSuccess: async () => {
      toast({
        title: t('character.submit-to-featured.success-title'),
        message: t('character.submit-to-featured.success-message'),
        preset: 'done',
      })
      await trpcUtils.character.featuring.listEligible.invalidate()
    },
    onError: (error) => {
      toast({
        title: t('character.submit-to-featured.error-title'),
        message: error instanceof Error ? error.message : t('error.general-error'),
        preset: 'error',
      })
    },
  })

  const buttonStyles = match(featuringRequestStatus)
    .with('FEATURED', () => 'bg-neutral-100')
    .with('PENDING', () => 'bg-neutral-100')
    .with('NONE', () => 'bg-primary-600')
    .exhaustive()

  const textStyles = match(featuringRequestStatus)
    .with('FEATURED', () => 'text-green-600')
    .with('PENDING', () => 'text-blue-800 dark:text-blue-200')
    .with('NONE', () => 'text-white px-4')
    .exhaustive()

  const buttonText = match(featuringRequestStatus)
    .with('FEATURED', () => t('character.request-featured.state.featured'))
    .with('PENDING', () => t('character.request-featured.state.pending'))
    .with('NONE', () => t('character.request-featured.action.request'))
    .exhaustive()

  return (
    <View className="flex-row items-center p-4">
      <Image
        source={{ uri: character.avatarUrl }}
        style={{ width: 60, height: 60, borderRadius: 30 }}
        className="mr-4"
      />
      <View className="flex-1">
        <MGText className="font-semibold text-lg mb-2">{character.name}</MGText>
        <MGText className="text-neutral-500 dark:text-neutral-400" numberOfLines={2}>
          {character.description}
        </MGText>
      </View>
      <TouchableHighlight
        onPress={() => requestFeature({ characterId: character.id })}
        disabled={featuringRequestStatus !== 'NONE'}
        hitSlop={6}
        className="rounded-full overflow-hidden"
        activeOpacity={0.6}
      >
        {isPending ? (
          <MGActivityIndicator size="small" color="#ffffff" />
        ) : (
          <View className={clsx('px-3 py-2.5', buttonStyles)}>
            <MGText className={clsx('text-sm', textStyles)}>{buttonText}</MGText>
          </View>
        )}
      </TouchableHighlight>
    </View>
  )
}

const HeroComponent = memo(() => {
  const { t } = useTranslation()
  return (
    <LinearGradient
      colors={['#ffade1', '#f88a8c']}
      start={{ x: 0.0549318364, y: 0.1423416199 }}
      end={{ x: 0.8476343224, y: 0.7333600642 }}
    >
      <View className="items-center py-16 px-6 bg-white/50 dark:bg-black/40">
        <MGText className="text-2xl font-bold text-center mb-2 text-primary-400">
          {t('character.request-featured.hero-title')}
        </MGText>
        <MGText className="opacity-80 text-center px-4">{t('character.request-featured.hero-description')}</MGText>
      </View>
    </LinearGradient>
  )
})

const RequestFeaturedPage: FC = () => {
  const { t } = useTranslation()

  const {
    data: charactersData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isRefetching,
    refetch,
  } = trpcReact.character.featuring.listEligible.useInfiniteQuery(
    {
      limit: 20,
    },
    {
      getNextPageParam: (lastPage) => lastPage.pagination.nextCursor,
    },
  )

  const characters = useMemo(() => charactersData?.pages.flatMap((page) => page.items) ?? [], [charactersData])

  const renderItem = ({ item }: { item: Character }) => <CharacterRow character={item} />

  const ListEmptyComponent = () => (
    <View className="items-center justify-center py-16">
      {isLoading ? (
        <MGActivityIndicator size="large" color="#ffade1" />
      ) : (
        <Fragment>
          <NonIdealState
            title={t('character.request-featured.no-characters.title')}
            description={t('character.request-featured.no-characters.description')}
          />
          <Link href="/character/create" asChild>
            <TouchableOpacity className="bg-primary-500 rounded-full py-3 px-6 flex-row items-center mt-8">
              <Ionicons name="add" size={24} color="#ffffff" style={{ marginRight: 8 }} />
              <MGText className="text-white font-semibold">{t('character.create')}</MGText>
            </TouchableOpacity>
          </Link>
        </Fragment>
      )}
    </View>
  )

  const ListFooterComponent = () =>
    isFetchingNextPage ? (
      <View className="py-4 items-center">
        <MGActivityIndicator size="large" color="#ffade1" />
      </View>
    ) : null

  return (
    <SafeAreaView edges={['top', 'left', 'right']} className="flex-1 bg-background">
      <MGHeader headerBody={<MGHeaderText>{t('character.request-featured.title')}</MGHeaderText>} />

      <View className="flex-1 relative mx-auto size-full max-w-3xl">
        <FlatList
          data={characters}
          horizontal={false}
          renderItem={renderItem}
          style={{ flex: 1 }}
          automaticallyAdjustContentInsets
          contentContainerClassName="pb-20"
          onEndReached={() => {
            if (hasNextPage && !isFetchingNextPage) {
              fetchNextPage()
            }
          }}
          onEndReachedThreshold={0.5}
          ListHeaderComponent={HeroComponent}
          ItemSeparatorComponent={() => <View className="h-[1px] bg-neutral-200" />}
          ListEmptyComponent={ListEmptyComponent}
          ListFooterComponent={ListFooterComponent}
          refreshControl={<RefreshControl refreshing={isRefetching} onRefresh={refetch} />}
        />
      </View>
    </SafeAreaView>
  )
}

export default RequestFeaturedPage
