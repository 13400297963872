import { getLocales } from 'expo-localization'
import i18n from 'i18next'
import ChainedBackend, { type ChainedBackendOptions } from 'i18next-chained-backend'
import HttpBackend, { type HttpBackendOptions } from 'i18next-http-backend'
import resourcesToBackend from 'i18next-resources-to-backend'
import { initReactI18next } from 'react-i18next'
import { resources, supportedLngs } from '../locales'
import { storage } from './storage'
import { dayjs } from './dayjs'

const currentEnvironmentLocale = (() => {
  const localeThatHaveALanguageCode = getLocales().find((locale) => locale.languageCode)
  if (localeThatHaveALanguageCode) {
    if (localeThatHaveALanguageCode.languageCode?.startsWith('zh')) {
      // dirty hack for `zh`s
      return 'zh_TW'
    }
    return localeThatHaveALanguageCode.languageCode!
  }
})()

const currentLocale = (() => {
  if (typeof window === 'undefined') {
    return 'ja'
  }

  return storage.getString('language') ?? currentEnvironmentLocale ?? 'ja'
})()

const loadPath: HttpBackendOptions['loadPath'] = (languages: string[], namespaces: string[]) => {
  const language = languages.length > 0 ? languages[0] : 'ja' // should always contain 1 and only 1 language but just to be safe
  const namespace = namespaces.length > 0 ? namespaces[0] : 'translation' // should always contain 1 and only 1 namespace but just to be safe

  // we don't append a namespace to the path if we are loading the default `translation` namespace
  const namespacePart = namespace === 'translation' ? '' : `/${namespace}`

  return `https://cdn.simplelocalize.io/8bb2a44e7e974004ab7eb24dff575864/_latest/${language}${namespacePart}`
}

i18n
  .use(initReactI18next)
  .use(ChainedBackend)
  .init<ChainedBackendOptions>({
    defaultNS: 'translation',
    ns: ['translation'],
    load: 'all',
    backend: {
      backends: [HttpBackend, resourcesToBackend(resources)],
      backendOptions: [
        {
          loadPath,
        },
      ],
    },
    compatibilityJSON: 'v4',
    lng: currentLocale,
    fallbackLng: 'ja',
    supportedLngs: supportedLngs,
    interpolation: {
      escapeValue: false,
    },
  })

i18n.on('languageChanged', (lng) => {
  storage.set('language', lng)

  const dayjsLocaleMapping = {
    ja: 'ja',
    en: 'en',
    zh_TW: 'zh-tw',
    ko: 'ko',
  } as const
  dayjs.locale(dayjsLocaleMapping[lng as keyof typeof dayjsLocaleMapping] ?? 'ja')
})

export default i18n
