import Ionicons from '@expo/vector-icons/Ionicons'
import * as Application from 'expo-application'
import Constants from 'expo-constants'
import { Image } from 'expo-image'
import * as Linking from 'expo-linking'
import { router } from 'expo-router'
import { useUpdates } from 'expo-updates'
import { useColorScheme } from 'nativewind'
import { type FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Platform, ScrollView, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import IconModal from '../../../components/common/IconModal'
import { DiscordIcon, TwitterIcon } from '../../../components/common/Icons'
import MGHeader from '../../../components/common/MGHeader'
import MGHeaderText from '../../../components/common/MGHeaderText'
import MGText from '../../../components/common/MGText'
import SettingsSection from '../../../components/common/SettingsSection'
import { signOut } from '../../../lib/auth'
import { useCurrentUser } from '../../../lib/hooks/useAuth'
import { trpcClient } from '../../../lib/services/trpc'
import {
  configure as configureCrisp,
  setSessionString,
  setUserAvatar,
  setUserEmail,
  setUserNickname,
  show,
} from '../../../utils/crisp'

configureCrisp()

const SUPPORT_LINKS = [
  {
    key: 'discord',
    icon: DiscordIcon,
    variant: 'normal' as const,
    color: '#5865F2',
  },
] as const

const SupportScreen: FC = () => {
  const { t } = useTranslation()
  const { data: user } = useCurrentUser()
  const version = Application.nativeApplicationVersion ?? Constants.expoConfig?.version ?? 'N/A'
  const buildNumber = Platform.select({
    ios: Constants.platform?.ios?.buildNumber ?? 'N/A',
    android: Application.nativeBuildVersion ?? 'N/A',
    default: 'N/A',
  })
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false)
  const { currentlyRunning } = useUpdates()
  const { colorScheme } = useColorScheme()

  const SOCIAL_LINKS = [
    {
      key: 'tiktok',
      icon: 'logo-tiktok',
      color: colorScheme === 'dark' ? '#ffffff' : '#000000',
    },
    {
      key: 'youtube',
      icon: 'logo-youtube',
      color: '#FF0000',
    },
    {
      key: 'instagram',
      icon: 'logo-instagram',
      color: '#E4405F',
    },
    {
      key: 'twitter',
      icon: TwitterIcon,
      color: colorScheme === 'dark' ? '#ffffff' : '#000000',
    },
  ] as const

  useEffect(() => {
    if (user) {
      setUserEmail(user.email)
      setUserNickname(user.displayName)
      if (user.avatarUrl) {
        setUserAvatar(user.avatarUrl)
      }
      setSessionString('user_id', user.id)
    }
  }, [user])

  const handleDeleteAccount = useCallback(async () => {
    return new Promise<void>((resolve) => {
      Alert.alert(
        t('settings.account.delete.confirm.title'),
        t('settings.account.delete.confirm.description'),
        [
          { text: t('action.cancel'), style: 'cancel', onPress: () => resolve() },
          {
            text: t('settings.account.delete'),
            style: 'destructive',
            onPress: async () => {
              await trpcClient.user.deleteAccount.mutate()
              await signOut()
              resolve()
              setShowDeleteConfirmation(false)
            },
          },
        ],
        {
          cancelable: true,
          onDismiss: () => {
            resolve()
            setShowDeleteConfirmation(false)
          },
        },
      )
    })
  }, [])

  const handleLogout = useCallback(async () => {
    await signOut()
  }, [])

  const [lastTap, setLastTap] = useState<number | null>(null)
  const [tapCount, setTapCount] = useState(0)

  const onVersionNumberPress = () => {
    const now = Date.now()
    if (!lastTap) {
      setLastTap(now)
      setTapCount(1)
      return
    }

    if (now - lastTap < 10000) {
      setTapCount(tapCount + 1)
      if (tapCount >= 10) {
        router.push('/support/debug')
        setTapCount(0)
      }
    } else {
      setTapCount(1)
    }
    setLastTap(now)
  }

  return (
    <SafeAreaView className="flex-1 bg-background">
      <MGHeader headerBody={<MGHeaderText>{t('support.title')}</MGHeaderText>} />
      <View className="mx-auto w-full flex-1" style={{ maxWidth: 728 }}>
        {/* App Icon and Version */}
        <TouchableWithoutFeedback onPress={onVersionNumberPress}>
          <View className="items-center my-4 px-8 flex flex-row gap-4 justify-center">
            <Image
              source={require('../../../assets/images/icon.png')}
              style={{ width: 64, height: 64, borderRadius: 16 }}
              contentFit="cover"
            />
            <View className="flex-col mr-4">
              <MGText bold className="text-xl mb-1">
                {t('app.name')}
              </MGText>
              <MGText className="text-neutral-500">
                v{version} ({buildNumber})
              </MGText>
              <MGText className="text-neutral-300">
                {currentlyRunning.channel || 'cold'}-{currentlyRunning.updateId?.slice(0, 8) || 'native'}
              </MGText>
            </View>

            <View className="flex-1" />

            {/* Social Links */}
            <View className="flex flex-row items-center justify-center flex-wrap gap-4">
              {SOCIAL_LINKS.map((link) => (
                <TouchableOpacity
                  key={link.key}
                  accessibilityLabel={t(`socials.${link.key}.name`)}
                  onPress={() => Linking.openURL(t(`socials.${link.key}.link`))}
                  className="items-center justify-center size-6"
                  hitSlop={6}
                >
                  {typeof link.icon === 'string' ? (
                    <Ionicons name={link.icon} size={20} color={link.color} />
                  ) : (
                    <link.icon height={20} width={20} color={link.color} />
                  )}
                </TouchableOpacity>
              ))}
            </View>
          </View>
        </TouchableWithoutFeedback>

        {/* Support Social Links */}
        <View className="flex-row justify-center flex-wrap gap-2 mb-4 px-8">
          <TouchableOpacity
            accessibilityLabel={t('support.chat')}
            onPress={() => show()}
            className="flex flex-row gap-2 items-center justify-center rounded-2xl text-white px-8 py-4 self-stretch flex-1 bg-primary-500 border border-solid border-primary-600"
          >
            <Ionicons name="chatbox-outline" size={24} color="#ffffff" />

            <MGText className="text-white leading-tight" bold>
              {t('support.chat')}
            </MGText>
          </TouchableOpacity>

          {SUPPORT_LINKS.map((link) => (
            <TouchableOpacity
              key={link.key}
              accessibilityLabel={t(`socials.${link.key}.name`)}
              onPress={() => Linking.openURL(t(`socials.${link.key}.link`))}
              className="flex flex-row gap-2 items-center justify-center rounded-2xl border border-solid p-4 self-stretch"
              style={{ borderColor: link.color }}
            >
              <link.icon height={24} width={24} color={link.color} />
            </TouchableOpacity>
          ))}
        </View>

        <ScrollView>
          <View className="px-4">
            <View className="bg-neutral-100 rounded-3xl p-4 flex-col gap-1 mb-4">
              <MGText bold selectable>
                {user?.id}
              </MGText>
              <MGText className="text-neutral-500">{t('settings.account.user-id')}</MGText>
              <MGText className="text-neutral-400 text-sm">{t('settings.account.user-id.description')}</MGText>
            </View>

            {__DEV__ && (
              <TouchableOpacity onPress={() => router.push('/support/debug')} className="mb-4">
                <View className="bg-amber-100 dark:bg-amber-900 rounded-3xl p-4 flex-row gap-1 justify-between items-center">
                  <MGText bold className="text-amber-800 dark:text-amber-200">
                    Debug Page
                  </MGText>

                  <Ionicons name="chevron-forward" size={24} />
                </View>
              </TouchableOpacity>
            )}
          </View>
          <SettingsSection
            items={[
              {
                title: t('settings.account.sign-out'),
                icon: 'log-out-outline',
                onPress: () => {
                  setShowLogoutConfirmation(true)
                },
                isDestructive: true,
              },
            ]}
          />

          {Platform.OS !== 'web' && (
            <TouchableOpacity
              className="flex flex-row gap-2 items-center justify-center rounded-2xl p-2 self-start mx-4 mt-32"
              onPress={() => setShowDeleteConfirmation(true)}
            >
              <Ionicons name="trash-outline" size={18} color="#737373" />
              <MGText className="text-neutral-500">{t('settings.account.delete')}</MGText>
            </TouchableOpacity>
          )}
        </ScrollView>
      </View>

      <IconModal
        visible={showDeleteConfirmation}
        severity="destructive"
        title={t('settings.account.delete.confirm.title')}
        icon="trash"
        description={t('settings.account.delete.confirm.description')}
        primaryButtonContent={t('settings.account.delete')}
        onConfirm={handleDeleteAccount}
        onCancel={() => setShowDeleteConfirmation(false)}
      />

      <IconModal
        visible={showLogoutConfirmation}
        severity="warning"
        title={t('settings.account.sign-out.confirm.title')}
        icon="log-out"
        description={t('settings.account.sign-out.confirm.description')}
        primaryButtonContent={t('settings.account.sign-out')}
        onConfirm={handleLogout}
        onCancel={() => setShowLogoutConfirmation(false)}
      />
    </SafeAreaView>
  )
}

export default SupportScreen
