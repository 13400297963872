import Markdown from 'marked-react'

const TERMS_CONTENT = `# Terms of Service

Last Updated Date: October 31, 2024

Hello and welcome! These Terms of Service are an agreement formed between you and MoguChat. They cover the website available at MoguChat.AI (the “Website”), and the MoguChat mobile application (the “App”). In these Terms we’ll sometimes refer to MoguChat as “Company”, “we”, or “us.” We’ll refer to our Website and App, together with any content, tools, features and functionality offered on or through them, as the “Services.”

These Terms govern your access to and use of the Services. Please read them carefully, as they include important information about your legal rights. By accessing or using the Services, you’re agreeing to these Terms. If you don’t understand or agree to these Terms, please don’t use the Services.

In these Terms, “you” and “your” means you as the user of the Services. If you use the Services on behalf of a company or other entity then “you” includes you and that entity, and you represent and warrant that (a) you are an authorized representative of the entity with the authority to bind the entity to these Terms, and (b) you agree to these Terms on the entity’s behalf.

NOTE: THESE TERMS CONTAIN AN ARBITRATION CLAUSE AND CLASS ACTION WAIVER. By agreeing to these Terms, you agree to resolve all disputes with us through binding individual arbitration. That means you also waive any right to have those disputes decided by a judge or jury, and you waive your right to participate in class actions, class arbitrations, or representative actions. You have the right to opt out of arbitration as explained below.

**Use of the Services**

**Your Registration Obligations.** When you register to use the Services, you agree to provide true, accurate, complete and current information about yourself. If you provide any information that is untrue, inaccurate, incomplete, or not current, or we have reasonable grounds to suspect that any information you provide is untrue, inaccurate, incomplete, or not current, we have the right to suspend or terminate your account and refuse any and all current or future use of the Services. We reserve the right to remove or reclaim any usernames at any time and for any reason, including but not limited to, claims by a third party that a username violates the third party’s rights.

**Minimum Age.** If you are under 13 years old OR other minimum age required by applicable laws in your country to use the Services, do not sign up for the Services – you are not authorized to use them.

**What you can do.** Subject to your compliance with these Terms, you may access and use the Services. In using the Services, you must comply with all applicable laws as well as our policies we make available to you.

**Member Account, Password and Security.** You are responsible for maintaining the confidentiality of your password and account, and for all activities that occur under your password or account. You agree to immediately notify MoguChat of any unauthorized use of your password or account or any other breach of security, and to ensure that you exit from your account at the end of each session when accessing the Services. MoguChat will not be liable for any loss or damage arising from your failure to comply with this paragraph.

**General Practices Regarding Use and Storage.** You acknowledge that MoguChat may establish general practices and limits concerning use of the Services. These may include, without limitation, the maximum period of time that data or other content will be retained by the Services and the maximum storage space that will be allotted on MoguChat’s servers on your behalf. You agree that MoguChat has no responsibility or liability for the deletion or failure to store any data or other content maintained or uploaded to the Services. You acknowledge that MoguChat reserves the right to terminate accounts that are inactive for an extended period of time. You further acknowledge that MoguChat reserves the right to change these general practices and limits at any time, in its sole discretion, with or without notice.

**Email Notifications.** You consent to receive notifications from us electronically to the e-mail address you provide to us. These notifications may be about your account, changes to our Services, or other updates or marketing relating to our platform.

**Conditions of Use**

**User Conduct.** In addition to agreeing to comply with our policies, you are solely responsible for all Content you submit to the Services. (When we say “Content you submit” and similar terms, we mean anything you seek to post, transmit, or share, including but not limited to text, images, sounds, video, graphics, information, or other data.).

You may not use our Services for any illegal, harmful, or abusive activity. For example, you may not:

- Give your account and password to minors for use, otherwise, you’ll accept full responsibility for any unauthorized use of the Services by minors;
- Use our Services in a way that infringes, misappropriates or violates anyone’s rights;
- Modify, copy, lease, sell or distribute any of our Services;
- Attempt to or assist anyone to reverse engineer, decompile or discover the source code or underlying components of our Services, including our models, algorithms, or systems (except to the extent this restriction is prohibited by applicable law);
- Automatically or programmatically extract data or output from the Services based on Content you submit (the “Output”);
- Represent that Output was human-generated when it was not;
- Interfere with or disrupt our Services, including circumvent any rate limits or restrictions or bypass any protective measures or safety mitigations we put on our Services; and
- Use Output to develop models that compete with MoguChat.

We reserve the right to investigate and take appropriate action against anyone who we conclude, in our sole discretion, has violated our policies. That action may include, without limitation, removing Content from the Services, suspending or terminating your account, and reporting you to law enforcement.

**Content You Submit.** When you submit Content to the Services, you represent and warrant that you own all right, title and interest in and to that Content (including, without limitation, all copyrights and rights of publicity), or that you have received all necessary permissions, clearances, and authorizations in order to submit it to the Services for the uses contemplated in these Terms.

When you submit such Content, you retain whatever ownership rights in that Content you had to begin with. You grant MoguChat, to the fullest extent permitted under the law, a nonexclusive, worldwide, royalty-free, fully paid up, transferable, sublicensable, perpetual, irrevocable license to copy, display, upload, perform, distribute, transmit, make available, store, modify, exploit, commercialize and otherwise use the Content for any MoguChat-related purpose in any form, medium or technology now known or later developed, including without limitation to operate, improve and provide the Services. You agree that these rights and licenses include a right for MoguChat to make the Content available to, and pass these rights along to, others with whom we have contractual relationships, and to otherwise permit access to or disclose the Content to third parties if we determine such access is or may be necessary or appropriate.

While we’re not required to do so, we may access, review, screen, edit, modify and delete your Content at any time and for any reason, including to provide and develop the Services or if we think the Content violates these Terms or any applicable laws.

MoguChat shall not be obligated to provide any attribution or obtain consent for the aforementioned rights and license.

**Characters & Generations.** When you create an automated AI character ("Character") using the Services in accordance with these Terms, then as between you and MoguChat, you own all rights in that Character. As between you and MoguChat, you also own any text, images, audio, or video the Character generates ("Generations") that are elicited by you. You grant MoguChat, to the fullest extent permitted under the law, a nonexclusive, worldwide, royalty-free, fully paid up, transferable, sublicensable, perpetual, irrevocable license to copy, display, upload, perform, distribute, transmit, make available, store, access, modify, exploit, commercialize and otherwise use the Character and all Generations elicited by you for any purpose in any form, medium or technology now known or later developed, including but not limited to (i) facilitating other users’ ability to interact with the Character and elicit Generations, and (ii) promoting the Services on- or off-platform.

When you interact with a Character created by MoguChat or created by another user who utilized the Services in accordance with the then-applicable Terms, then you own Generations that are elicited by you from such Character (but not the Character itself or other Generations or other Content, all of which will remain owned by MoguChat or the other third-party owner(s) thereof, as applicable). You grant MoguChat, to the fullest extent permitted under the law, a nonexclusive, worldwide, royalty-free, fully paid up, transferable, sublicensable, perpetual, irrevocable license to copy, display, upload, perform, distribute, transmit, make available, store, access, modify, exploit, commercialize and otherwise use the Generations elicited by you for any purpose in any form, medium or technology now known or later developed, including but not limited to (i) facilitating other users’ ability to interact with the Character and elicit Generations, and (ii) promoting the Services on- or off-platform.

MoguChat shall not be obligated to provide any attribution or obtain consent for the aforementioned rights and license.

**Services Content, Software and Trademarks.** You acknowledge and agree that the Services may contain content or features protected by copyright, patent, trademark, trade secret or other proprietary rights and laws. In connection with your use of the Services you will not engage in or use any data mining, robots, scraping or similar automated data gathering or extraction methods. If you are blocked by us from accessing the Services (including by blocking your IP address), you agree not to implement any measures to circumvent such blocking (e.g., by masking your IP address or using a proxy IP address). Any use of the Services or content on the Services other than as specifically authorized herein is strictly prohibited. Any rights not expressly granted herein are reserved by us.

**Third Party Websites, Services and Output**

Our Services may provide links or other access to other sites and resources on the Internet, as well as include third-party software, applications, products, websites or services (“Third Party Websites and Services”) and some parts of our Services may include output from those websites and services (“Third Party Output”). Third Party Websites and Services and Third Party Output are subject to their own terms, MoguChat has no control over such Third Party Websites and Services and Third Party Output and MoguChat is not responsible for and does not endorse them. You acknowledge and agree that MoguChat will not be responsible or liable, directly or indirectly, for any damage or loss caused by reliance on any content, events, goods or services available on or through any such Third Party Websites and Services and Third Party Output. Any dealings you have with third parties found while using the Services are between you and the third party, and you agree that MoguChat is not liable for any loss or claim that you may have against any such third party.

**Indemnity and Release**

You agree to release, indemnify and hold MoguChat and its affiliates and their officers, employees, directors and agents harmless from any and all losses, damages, and expenses of any kind arising out of or relating to your use of the Services. Without limiting the foregoing, the release and indemnification described above includes reasonable attorneys’ fees, rights, claims, actions of any kind and injury (including death) arising out of or relating to your use of the Services.

If you are a California resident, you waive California Civil Code Section 1542, which says:

A general release does not extend to claims that the creditor or releasing party does not know or suspect to exist in his or her favor at the time of executing the release and that, if known by him or her, would have materially affected his or her settlement with the debtor or released party.

If you are a resident of another jurisdiction, you waive any comparable statute or doctrine.

**Disclaimer of Warranty**

Your use of the Services is at your sole risk. The site is provided on an “AS IS” and “AS AVAILABLE” basis. MoguChat and its affiliates or licensors expressly disclaims all warranties of any kind, whether express, implied or statutory, including, but not limited to the implied warranties of merchantability, fitness for a particular purpose, title and non-infringement. MoguChat makes no warranty that (i) the Services will meet your requirements, (ii) the Services will be uninterrupted, timely, secure, or error-free, or (iii) the results that may be obtained from the use of the Services will be accurate or reliable. You accept and agree that any use of Output from our Services is at your sole risk and you will not rely on Output as a sole source of truth or factual information, or as a substitute for professional advice.

**Limitation of Liability**

You understand and agree that MoguChat and its affiliates or licensors will not be liable for any indirect, incidental, special, consequential, or exemplary damages, or damages for loss of profits including but not limited to damages for loss of goodwill, use, data or other intangible losses (even if MoguChat has been advised of the possibility of such damages), whether based on contract, tort, negligence, strict liability or otherwise, resulting from: (i) the use or the inability to use the Services; (ii) your access, use, creation of, or interaction with any Content, Character or Generations; (iii) your sharing with any third party of any Content, Character or Generations; (iv) unauthorized access to or alteration of your transmissions or data; (v) statements or conduct of any third party (including users) on the Services; or (vi) any other matter relating to the Services. In no event will MoguChat’s total liability to you for all damages, losses or causes of action exceed the greater of: (i) $100; or (ii) the amount you paid MoguChat (if any) in connection with your use of the Services. THE LIMITATIONS IN THIS SECTION APPLY ONLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.

Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the limitations set forth above may not apply to you. In that case, these Terms only limit our responsibilities to the maximum extent permissible in your country of residence. If you are dissatisfied with any portion of the site or with these terms of use, your sole and exclusive remedy is to discontinue use of the Services.

MoguChat’s affiliates, suppliers, licensors, and distributors are intended third party beneficiaries of this section.

**Prohibition of Class and Representative Actions and Non-Individualized Relief.**

You and MoguChat agree that each of us may bring claims against the other only on an individual basis and not as a plaintiff or class member in any purported class or representative action or proceeding. Unless both you and MoguChat agree otherwise, the arbitrator may not consolidate or join more than one person’s or party’s claims and may not otherwise preside over any form of a consolidated, representative, or class proceeding. Also, the arbitrator may award relief (including monetary, injunctive, and declaratory relief) only in favor of the individual party seeking relief and only to the extent necessary to provide relief necessitated by that party’s individual claims.

**Termination**

MoguChat may in its sole discretion and at any time discontinue providing the Services, or any part thereof, with or without notice. You agree that any termination of your access to the Services under any provision of these Terms may be effected without prior notice, and acknowledge and agree that MoguChat may (but has no obligation to) immediately deactivate or delete your account and all related information and files in your account and/or bar any further access to such files or the Services. Termination of your account or access to any component of the Services will not terminate MoguChat’s rights to your Content. Further, you agree that MoguChat will not be liable to you or any third party for any termination of your access to the Services.

**General**

**Entire Agreement.** These Terms constitute the entire agreement between you and MoguChat and govern your use of our Services, and other than any Service-specific terms, superseding any prior agreements between you and MoguChat with respect to the Services.

**Choice of Law, Jurisdiction, Venue.** These Terms of Service and any separate agreements whereby we provide you shall be governed by and construed in accordance with the laws where the service is provided.

**Severance.** If any provision of these Terms is found by a court of competent jurisdiction to be invalid, the parties agree that the court should try to give effect to the parties’ intentions as reflected in the provision, and the other provisions of these Terms remain in full force and effect.

**No Waiver.** Any failure of MoguChat to exercise or enforce any right or provision of these Terms does not constitute a waiver of such right or provision.

**Expiration of Claims.** You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Website or these Terms must be filed within one year after such claim or cause of action arose or be forever barred.

**Assignment.** You may not assign these Terms without the prior written consent of MoguChat, but MoguChat may assign or transfer these Terms, in whole or in part, without restriction.

**Notice.** Notices to you may be made via either email or regular mail. The Site may also provide notices to you of changes to these Terms or other matters by displaying notices or links to notices generally on the Services.

**Changes to these Terms**

We reserve the right, at our sole discretion, to change or modify portions of these Terms at any time. If we do this, we will post the changes on this page and will indicate at the top of this page the date these terms were last revised. Any such changes will become effective immediately after they are posted, except that changes addressing new functions of the Services or changes made for legal reasons will be effective immediately. Your continued use of the Services after the date any such changes become effective constitutes your acceptance of the new Terms. IF YOU DO NOT WANT TO AGREE TO THIS OR ANY UPDATED TERMS, YOU CAN DELETE YOUR ACCOUNT.

**Contact Us**

If you have any questions about our Services, or to report any violations of these Terms, please contact us at [inquiries@moguchat.ai](mailto:inquiries@moguchat.ai).
`

export default function TermsPage() {
  return (
    <div className="w-full overflow-y-auto">
      <div className="flex flex-col px-8 pt-16 pb-32 [&_h1]:text-4xl [&_h1]:font-bold [&_h1]:mb-4 [&_h2]:text-2xl [&_h2]:font-semibold [&_h2]:mt-8 [&_h2]:mb-4 [&_h3]:text-xl [&_h3]:font-semibold [&_h3]:mt-8 [&_h3]:mb-4 [&_p]:mb-4 [&_p]:leading-7 [&_ul]:list-disc [&_ul]:ml-5 [&_ul]:mb-4 [&_ol]:list-decimal [&_ol]:ml-5 [&_ol]:mb-4 [&_a]:text-blue-600 [&_a]:underline max-w-3xl mx-auto text-foreground">
        <Markdown>{TERMS_CONTENT}</Markdown>
      </div>
    </div>
  )
}
