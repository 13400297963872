import { setUser } from '@sentry/react-native'
import { Redirect, Stack, usePathname, useRouter } from 'expo-router'
import * as StoreReview from 'expo-store-review'
import { useUpdates } from 'expo-updates'
import { Fragment, useEffect } from 'react'
import { Alert, Platform } from 'react-native'
import MGLoadingOverlay from '../../components/common/MGLoadingOverlay'
import { ampli } from '../../lib/ampli'
import { useActiveSubscription } from '../../lib/hooks/useActiveSubscription'
import { useCurrentUser } from '../../lib/hooks/useAuth'
import { useIsTokenSet } from '../../lib/storage'
import { getStoreReviewFirstLogin, setStoreReviewFirstLogin } from '../../lib/storageDeviceBound'

export default function AppLayout() {
  const isTokenSet = useIsTokenSet()
  const path = usePathname()
  const router = useRouter()

  const { data, isPending } = useCurrentUser(undefined, {
    retry: 1,
  })

  const { data: activeSubscription, isPending: isActiveSubscriptionPending } = useActiveSubscription()

  useEffect(() => {
    if (!data) {
      ampli.identify(undefined)
      return
    }

    ampli.identify(data.id, {
      email: data.email,
      username: data.username,
      display_name: data.displayName,
      is_admin: data.isAdmin,
    })

    // sentry
    setUser({
      id: data.id,
      email: data.email,
      username: data.username,
      displayName: data.displayName,
      isAdmin: data.isAdmin,
    })

    // Check if this is first login and request app store review if needed
    const checkFirstLoginReview = async () => {
      if (Platform.OS === 'web') return

      const hasReviewed = getStoreReviewFirstLogin()
      if (!hasReviewed) {
        if (await StoreReview.hasAction()) {
          setStoreReviewFirstLogin(true)
          if (__DEV__) {
            Alert.alert(
              'Requesting first-login review (this is a dev-only alert. on production, it will actually request a review)',
            )
          } else {
            await StoreReview.requestReview()
          }
        }
      }
    }
    checkFirstLoginReview()
  }, [data?.id])

  useUpdates()

  useEffect(() => {
    if (isPending) return

    if (!data) return

    // Show purchase screen if user is not subscribed
    if (!isPending && !activeSubscription && !isActiveSubscriptionPending && !__DEV__) {
      router.push('/subscribe')
    }
  }, [data, isPending, activeSubscription, isActiveSubscriptionPending, router])

  if (
    (!isTokenSet || (!data && !isPending)) &&
    !(Platform.OS === 'web' && (RegExp(/^\/character\/character_[a-zA-Z0-9]+$/).exec(path) || path === '/'))
  ) {
    return <Redirect href="/login" />
  }

  // This layout can be deferred because it's not the root layout.
  return (
    <Fragment>
      <Stack
        screenOptions={{
          headerShown: false,
          contentStyle: Platform.OS === 'web' ? { backgroundColor: 'var(--background)' } : undefined,
        }}
      >
        <Stack.Screen name="(tabs)" />
        <Stack.Screen name="chat/create" />
        <Stack.Screen name="character/my" />
        <Stack.Screen name="profile/edit" />
        <Stack.Screen name="support/debug" />
        <Stack.Screen name="support/index" />
        <Stack.Screen name="character/create" />
        <Stack.Screen name="character/search" />
        <Stack.Screen name="user-inbox/index" />
        <Stack.Screen name="transaction/index" />
        <Stack.Screen name="character/edit-complete" />
        <Stack.Screen name="character/[characterId]/edit" />
        <Stack.Screen name="character/[characterId]/index" />
        <Stack.Screen name="chat/[chatId]/index" />
        <Stack.Screen name="event/[eventId]/index" />
        <Stack.Screen name="event/[eventId]/ranking" />
        <Stack.Screen name="request-featured/index" />
        <Stack.Screen name="event/+not-found" />
        <Stack.Screen
          name="subscribe/index"
          options={{
            presentation: 'fullScreenModal',
          }}
        />
      </Stack>
      <MGLoadingOverlay visible={isPending} />
    </Fragment>
  )
}
