import { Image } from 'expo-image'
import { router } from 'expo-router'
import { useTranslation } from 'react-i18next'
import { TouchableOpacity, View } from 'react-native'
import CharacterCard from '../../components/character/CharacterCard'
import NonIdealState from '../../components/common/NonIdealState'
import type { RouterOutputs } from '../../lib/services/trpc'
import CharacterSquareCard from './CharacterSquareCard'

export const renderItem = ({
  item: character,
  onPress = () => {
    router.push(`/character/${character.id}`)
  },
}: {
  item: RouterOutputs['character']['list']['items'][number]
  onPress: () => void
}) => {
  return (
    <View key={`character-${character.id}`}>
      <TouchableOpacity onPress={onPress}>
        <CharacterCard key={character.id} character={character} />
      </TouchableOpacity>
    </View>
  )
}
export const renderSquareItem = ({
  item: character,
  index,
  onPress = () => {
    router.push(`/character/${character.id}`)
  },
  numColumns = 2,
}: {
  item: RouterOutputs['character']['list']['items'][number]
  index: number
  onPress: () => void
  numColumns: number
}) => {
  return (
    <View
      key={`character-${character.id}`}
      style={{ width: `${100 / numColumns}%` }}
      className={index % numColumns === 0 ? 'pl-2' : 'pr-2'}
    >
      <TouchableOpacity onPress={onPress}>
        <CharacterSquareCard key={character.id} character={character} />
      </TouchableOpacity>
    </View>
  )
}
export const ListEmptyComponent = () => {
  const { t } = useTranslation()
  return (
    <View className="items-center justify-center w-full py-24">
      <NonIdealState
        title={t('error.empty-character-list')}
        description={t('error.empty-character-list.description')}
      />
    </View>
  )
}

export const ListFooterComponent = (isFetchingNextPage: boolean) =>
  isFetchingNextPage ? (
    <View className="py-4 items-center">
      <Image
        source={require('../../assets/images/status/loading.png')}
        style={{ width: 50, height: 50 }}
        autoplay={true}
      />
    </View>
  ) : null
