import { captureException } from '@sentry/react-native'
import * as Burnt from 'burnt'
import clsx from 'clsx'
import { Image } from 'expo-image'
import * as ImagePicker from 'expo-image-picker'
import { forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { type ImageStyle, Linking, type StyleProp, TouchableOpacity, View } from 'react-native'
import { uploadPixNEXTMedia } from '../../lib/uploadPixNEXTMedia'
import { MGActivityIndicator } from '../ui/MGActivityIndicator'
import IconModal from './IconModal'
import { MGIonicons } from './MGIonicons'

interface MGRemoteImageInputProps {
  value?: string
  onChange: (url?: string) => void
  type: 'avatar' | 'chat/background'
  aspectRatio?: number
  width?: number
  height?: number
  className?: string
}

const MGRemoteImageInput = forwardRef<View, MGRemoteImageInputProps>(
  ({ value, onChange, type, aspectRatio, width = 128, height = 128, className }, ref) => {
    const { t } = useTranslation()
    const [isUploading, setIsUploading] = useState(false)
    const [isPermissionRequiredDialogOpen, setIsPermissionRequiredDialogOpen] = useState(false)

    const handlePickImage = async () => {
      // Removed because we are using the system's image picker, so we don't even need to ask for permission.
      // const { status, accessPrivileges } = await ImagePicker.getMediaLibraryPermissionsAsync()
      // const consideredGranted =
      //   accessPrivileges === 'all' || accessPrivileges === 'limited' || status === ImagePicker.PermissionStatus.GRANTED
      // if (!consideredGranted) {
      //   setIsPermissionRequiredDialogOpen(true)
      //   return
      // }

      try {
        const result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: type === 'avatar', // TODO: allow outer layers to customize this parameter directly, instead of hardcoding it with a type
          aspect: type === 'avatar' ? [1, 1] : undefined,
          quality: 1,
        })

        if (!result.canceled) {
          setIsUploading(true)
          const response = await fetch(result.assets[0].uri)
          const blob = await response.blob()

          const uploadResult = await uploadPixNEXTMedia(blob, {
            category: `moguchat/${type}`,
            visibility: 'PUBLIC',
          })

          onChange(uploadResult.delivery_url)
        }
      } catch (error) {
        console.error('Failed to upload image:', error)
        Burnt.toast({
          title: t('error.upload-failed'),
          preset: 'error',
          message: error instanceof Error ? error.message : t('error.unknown-error'),
        })
        captureException(error)
      } finally {
        setIsUploading(false)
      }
    }

    const imageStyle: StyleProp<ImageStyle> = {
      width,
      height,
      ...(type === 'avatar' ? { borderRadius: width / 2 } : {}),
      ...(aspectRatio ? { aspectRatio } : {}),
      ...(value ? { opacity: 1 } : { opacity: 0.2 }),
    }

    return (
      <View className={clsx('flex-row items-center self-center gap-2', className)} ref={ref}>
        <TouchableOpacity
          className={clsx(
            'relative border border-neutral-200 overflow-hidden',
            type === 'avatar' ? 'rounded-full' : 'rounded-none',
          )}
          {...(!value && !isUploading ? { onPress: handlePickImage } : { disabled: true })}
        >
          <Image
            source={value ?? require('../../assets/images/character-avatar-placeholder.webp')}
            style={imageStyle}
            contentFit="cover"
          />
          {isUploading && (
            <View className="absolute inset-0 bg-neutral-900/90 z-10 flex items-center justify-center">
              <MGActivityIndicator size="small" />
            </View>
          )}
          {!value && !isUploading && (
            <View className="absolute inset-0 rounded-full bg-primary-600/50 z-10 flex items-center justify-center">
              <MGIonicons name="cloud-upload-outline" size={24} color="#ffffff" />
            </View>
          )}
        </TouchableOpacity>

        {value && (
          // edit button
          <View className="flex items-center gap-2 ml-2">
            <TouchableOpacity
              onPress={handlePickImage}
              className={clsx('rounded-full p-4', isUploading ? 'bg-neutral-200' : 'bg-primary-500')}
              disabled={isUploading}
            >
              <MGIonicons name="pencil" size={20} color="#ffffff" />
            </TouchableOpacity>
          </View>
        )}

        <IconModal
          visible={isPermissionRequiredDialogOpen}
          severity="info"
          title={t('components.remote-image-input.permission-required.title')}
          description={t('components.remote-image-input.permission-required.description')}
          primaryButtonContent={t('components.remote-image-input.permission-required.open-settings')}
          onCancel={() => setIsPermissionRequiredDialogOpen(false)}
          onConfirm={() => {
            Linking.openSettings()
            setIsPermissionRequiredDialogOpen(false)
          }}
          textAlignment="left"
        />
      </View>
    )
  },
)

export default MGRemoteImageInput
