import Ionicons from '@expo/vector-icons/Ionicons'
import { zodResolver } from '@hookform/resolvers/zod'
import { captureException } from '@sentry/react-native'
import * as Burnt from 'burnt'
import clsx from 'clsx'
import { useRouter } from 'expo-router'
import { useSetAtom } from 'jotai'
import { type FC, Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { Controller, type FieldErrors, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Alert, TouchableOpacity, View } from 'react-native'
import { KeyboardAvoidingView, KeyboardAwareScrollView } from 'react-native-keyboard-controller'
import { SafeAreaView } from 'react-native-safe-area-context'
import MGRemoteImageInput from '../../components/common/MGRemoteImageInput'
import { MGTextInput } from '../../components/common/MGTextInput'
import { ampli } from '../../lib/ampli'
import { useMyCharacters } from '../../lib/hooks/useMyCharacters'
import { usePrivileges } from '../../lib/hooks/usePrivilege'
import { purchaseSheetStateAtom } from '../../lib/purchaseSheet'
import { queryClient } from '../../lib/services/client'
import { trpcClient, trpcReact, trpcUtils } from '../../lib/services/trpc'
import { storage } from '../../lib/storage'
import IconModal from '../common/IconModal'
import MGHeader from '../common/MGHeader'
import MGHeaderText from '../common/MGHeaderText'
import { MGHelpDialog } from '../common/MGHelpDialog'
import { MGLoading } from '../common/MGLoading'
import { MGModalButton } from '../common/MGModalButton'
import MGRadioCard from '../common/MGRadioCard'
import MGText from '../common/MGText'
import { AccordionItem } from './AccordionItem'
import { CharacterDetailTextarea } from './CharacterDetailTextarea'
import { Separator } from './Separator'
import {
  type CharacterForm,
  type GenerateCharacterForm,
  characterFormSchema,
  generateCharacterFormSchema,
} from './form'

export const CreateCharacterSymbol = Symbol('CreateCharacter')

const persistenceKey = (characterId: string | typeof CreateCharacterSymbol) =>
  `character.edit-or-create.form-values.${characterId === CreateCharacterSymbol ? '#create' : characterId}`

const useFormPersistence = (characterId: string | typeof CreateCharacterSymbol) => {
  const { t } = useTranslation()
  const saveTimeoutRef = useRef<NodeJS.Timeout>()
  const lastSavedRef = useRef<string>('')
  // sentToServerRef.current is true means the current persistence could be
  // ignored and thus willLeavePersistenceBoundary will not show a toast anymore
  const sentToServerRef = useRef(false)

  const save = useCallback(
    (formValues: Partial<CharacterForm>) => {
      const key = persistenceKey(characterId)
      const formString = JSON.stringify(formValues)

      // Don't save if the values haven't changed
      if (formString === lastSavedRef.current) {
        return
      }

      lastSavedRef.current = formString
      storage.set(key, formString)
    },
    [characterId],
  )

  const debouncedSave = useCallback(
    (formValues: Partial<CharacterForm>) => {
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current)
      }

      saveTimeoutRef.current = setTimeout(() => {
        save(formValues)
      }, 500) // Debounce for 500ms
    },
    [save],
  )

  const didSentToServer = useCallback(() => {
    const key = persistenceKey(characterId)
    storage.delete(key)
    lastSavedRef.current = ''
    sentToServerRef.current = true
  }, [characterId])

  const willLeavePersistenceBoundary = useCallback(() => {
    if (sentToServerRef.current) return

    Burnt.toast({
      title: t('character.form.auto-save'),
      preset: 'done',
    })
  }, [t])

  useEffect(() => {
    return () => {
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current)
      }
    }
  }, [])

  return { save: debouncedSave, directSave: save, didSentToServer, willLeavePersistenceBoundary }
}

export const EditOrCreateCharacter = ({
  defaultValues,
  characterId,
  showCharacterGenerationPart = false,
}: {
  defaultValues?: Partial<CharacterForm>
  characterId: string | typeof CreateCharacterSymbol
  showCharacterGenerationPart?: boolean
}) => {
  const [initialValues, setInitialValues] = useState<Partial<CharacterForm> | undefined>(undefined)

  useEffect(() => {
    const key = persistenceKey(characterId)
    const persistedData = storage.getString(key)

    if (persistedData) {
      try {
        const parsedData = JSON.parse(persistedData)
        setInitialValues(parsedData)
      } catch (error) {
        console.error('Failed to parse persisted form data:', error)
        setInitialValues(defaultValues)
      }
    } else {
      setInitialValues(defaultValues)
    }
  }, [characterId, defaultValues])

  if (initialValues === undefined) {
    return <MGLoading show />
  }

  return (
    <EditOrCreateCharacterInner
      defaultValues={initialValues}
      characterId={characterId}
      showCharacterGenerationPart={showCharacterGenerationPart}
    />
  )
}

const calculateTotalDetailsLength = (details: CharacterForm['details']) => {
  return Object.values(details).reduce((acc, curr) => acc + (curr?.length ?? 0), 0)
}

const EditOrCreateCharacterInner: FC<{
  defaultValues?: Partial<CharacterForm>
  characterId: string | typeof CreateCharacterSymbol
  showCharacterGenerationPart?: boolean
}> = ({ defaultValues, characterId, showCharacterGenerationPart }) => {
  const { t } = useTranslation()
  const router = useRouter()
  const isCreating = characterId === CreateCharacterSymbol
  const { data: userPrivileges } = usePrivileges()
  const setPurchaseSheetState = useSetAtom(purchaseSheetStateAtom)
  const { save, directSave, didSentToServer, willLeavePersistenceBoundary } = useFormPersistence(characterId)

  const { data: tags } = trpcReact.tag.list.useQuery()

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
    watch,
  } = useForm<CharacterForm>({
    resolver: zodResolver(characterFormSchema),
    defaultValues,
  })

  const { control: generateCharacterControl, handleSubmit: generateCharacterSubmit } = useForm<GenerateCharacterForm>({
    resolver: zodResolver(generateCharacterFormSchema),
    defaultValues: {
      prompt: '',
    },
  })

  // Save form values to storage whenever they change
  const formValues = watch()
  useEffect(() => {
    save(formValues)
  }, [formValues, save])

  // Show toast when unmounting with unsaved changes
  useEffect(() => {
    return () => {
      if (isDirty) {
        // only if the form has actually been modified
        willLeavePersistenceBoundary()
      }
    }
  }, [isDirty, willLeavePersistenceBoundary])

  const [showPersonalityPart, setShowPersonalityPart] = useState(false)
  const [pending, setPending] = useState(false)
  const [submitError, setSubmitError] = useState<string | null>(null)

  const [currentTab, setCurrentTab] = useState<'edit' | 'generate'>(showCharacterGenerationPart ? 'generate' : 'edit')
  const [isGenerating, setIsGenerating] = useState(false)

  const { refetch: refetchMyCharacters } = useMyCharacters()

  const onSubmit = async (data: CharacterForm) => {
    setPending(true)

    try {
      if (isCreating) {
        const character = await trpcClient.character.create.mutate(data)

        didSentToServer()
        queryClient.invalidateQueries({
          queryKey: ['character', character.id],
        })
        trpcUtils.character.list.invalidate()
        refetchMyCharacters()
        router.replace(`/character/edit-complete?characterId=${character.id}&type=create`)
        ampli.characterCreated({
          total_details_length: calculateTotalDetailsLength(data.details),
          visibility: data.visibility,
        })
      } else {
        const character = await trpcClient.character.update.mutate({
          id: characterId,
          ...data,
        })

        didSentToServer()
        queryClient.invalidateQueries({
          queryKey: ['character', character.id],
        })
        trpcUtils.character.list.invalidate()
        trpcUtils.character.get.invalidate({ id: characterId })

        router.replace(`/character/edit-complete?characterId=${character.id}&type=edit`)
        refetchMyCharacters()
        Burnt.toast({
          title: t('character.action.edit.success'),
          preset: 'done',
        })
      }
    } catch (error) {
      console.error(error)
      // TODO: we really need to extract error handling so we can use a simple `formatTRPCClientError` function to format the error message.
      const message = (() => {
        if (error instanceof Error) {
          return error.message
        }
        return t('error.unknown-error')
      })()
      Burnt.toast({
        title: t('error.save-character-failed'),
        preset: 'error',
        message,
      })
      setSubmitError(message)
      captureException(error)
    } finally {
      setPending(false)
    }
  }

  const onInvalidSubmit = (errors: FieldErrors<CharacterForm>) => {
    console.log('errors', errors)
    if (errors.avatarUrl?.type) {
      // improve no icon toast
      Burnt.toast({
        title: t('error.avatar-required'),
        preset: 'error',
      })
      return
    }
    Burnt.toast({
      title: t('error.invalid-input'),
      preset: 'error',
    })
  }

  const onCharacterGenerateSubmit = async (data: GenerateCharacterForm) => {
    const currentDetails = watch('details')
    const name = watch('name')
    if (!name) {
      Burnt.toast({
        title: t('character.generate.error.name-required'),
        preset: 'error',
      })
      return
    }
    let canContinue = true
    if (currentDetails) {
      canContinue = await new Promise<boolean>((resolve) => {
        Alert.alert(
          t('character.generate.error.overwrite-warning'),
          t('character.generate.error.overwrite-warning-description'),
          [
            {
              text: t('character.generate.error.overwrite-warning-confirm'),
              onPress: () => {
                setIsGenerating(true)
                resolve(true)
              },
            },
            {
              text: t('action.cancel'),
              style: 'cancel',
              onPress: () => resolve(false),
            },
          ],
        )
      })
      if (!canContinue) {
        return
      }
    }
    setIsGenerating(true)
    const result = await trpcClient.character.generate.mutate({
      name,
      text: data.prompt,
    })
    if (result.success) {
      setValue('details', result.data.details)
    }
    setIsGenerating(false)
    setCurrentTab('edit')
  }

  return (
    <SafeAreaView className="flex-1 bg-background">
      <MGHeader headerBody={<MGHeaderText>{isCreating ? t('character.create') : t('character.edit')}</MGHeaderText>} />
      <View className="mx-auto w-full flex-1 max-w-3xl">
        <KeyboardAvoidingView keyboardVerticalOffset={44} className="flex-1">
          <KeyboardAwareScrollView bottomOffset={100} disableScrollOnKeyboardHide>
            <MGLoading show={isGenerating} />
            <View className="p-4 relative">
              <View>
                <View className="flex flex-row items-center gap-1">
                  <MGText className="text-red-500 text-lg">*</MGText>
                  <MGText bold className="text-lg">
                    {t('character.field.avatar')}
                  </MGText>
                  <View className="flex-1" />
                  <Controller
                    control={control}
                    name="avatarUrl"
                    render={({ field: { value, onChange, ref }, fieldState: { error } }) => (
                      <Fragment>
                        <MGRemoteImageInput
                          width={128}
                          height={128}
                          value={value}
                          onChange={onChange}
                          ref={ref}
                          type="avatar"
                        />
                        {error && <MGText className="mx-auto text-sm text-red-500 mt-1">{error.message}</MGText>}
                      </Fragment>
                    )}
                  />
                </View>
              </View>
              <View className="flex flex-col gap-4">
                <View>
                  <View className="flex flex-row items-start gap-1">
                    <MGText className="text-red-500 text-lg">*</MGText>
                    <MGText bold className="text-lg">
                      {t('character.field.name')}
                    </MGText>
                  </View>
                  <MGText className="text-neutral-500 mb-1">{t('character.field.name.description')}</MGText>
                  <Controller
                    control={control}
                    name="name"
                    render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
                      <Fragment>
                        <MGTextInput value={value} onChangeText={onChange} ref={ref} />
                        {error && <MGText className="text-sm text-red-500 mt-1">{error.message}</MGText>}
                      </Fragment>
                    )}
                  />
                </View>

                <Separator />
                {showCharacterGenerationPart && (
                  <View className="flex-row">
                    <TouchableOpacity
                      className={clsx('flex-1 items-center py-2 rounded-l-full', {
                        'bg-primary-300': currentTab === 'generate',
                        'bg-neutral-100': currentTab !== 'generate',
                      })}
                      onPress={() => setCurrentTab('generate')}
                      disabled={isGenerating}
                    >
                      <MGText className={clsx(isGenerating && 'text-neutral-500')}>
                        {t('character.create.tab.generate')}
                      </MGText>
                    </TouchableOpacity>
                    <TouchableOpacity
                      className={clsx('flex-1 items-center py-2 rounded-r-full', {
                        'bg-primary-300': currentTab === 'edit',
                        'bg-neutral-100': currentTab !== 'edit',
                      })}
                      onPress={() => setCurrentTab('edit')}
                      disabled={isGenerating}
                    >
                      <MGText className={clsx(isGenerating && 'text-neutral-500')}>
                        {t('character.create.tab.edit')}
                      </MGText>
                    </TouchableOpacity>
                  </View>
                )}
                {showCharacterGenerationPart && currentTab === 'generate' ? (
                  <View>
                    <CharacterDetailTextarea
                      className="!rounded-b-none !border-b-0 !border !border-primary-300"
                      control={generateCharacterControl}
                      name="prompt"
                      label={t('character.generate.field.input-prompt')}
                      placeholder={t('character.generate.field.input-prompt.placeholder')}
                      help={{
                        title: t('character.generate.field.input-prompt.help.title'),
                        content: t('character.generate.field.input-prompt.help.content'),
                      }}
                    />
                    <TouchableOpacity
                      className="border-primary-300 bg-primary-300 border rounded-b-xl p-4 flex flex-row items-center justify-center gap-2"
                      onPress={generateCharacterSubmit(onCharacterGenerateSubmit)}
                      disabled={isGenerating}
                    >
                      <MGText className={clsx('text-center', isGenerating && 'text-neutral-500')}>
                        {t('character.generate.action.generate')}
                      </MGText>
                    </TouchableOpacity>
                  </View>
                ) : (
                  <Fragment>
                    <CharacterDetailTextarea
                      control={control}
                      name="details.characterSetting"
                      label={t('character.field.character-setting')}
                      placeholder={t('character.field.character-setting.placeholder')}
                      help={{
                        title: t('character.field.character-setting.help.title'),
                        content: t('character.field.character-setting.help.content'),
                      }}
                    />
                    <CharacterDetailTextarea
                      control={control}
                      name="details.persona"
                      label={t('character.field.persona')}
                      placeholder={t('character.field.persona.placeholder')}
                      help={{
                        title: t('character.field.persona.help.title'),
                        content: t('character.field.persona.help.content'),
                      }}
                    />
                    <CharacterDetailTextarea
                      control={control}
                      name="details.backgroundSetting"
                      label={t('character.field.background-setting')}
                      placeholder={t('character.field.background-setting.placeholder')}
                      help={{
                        title: t('character.field.background-setting.help.title'),
                        content: t('character.field.background-setting.help.content'),
                      }}
                    />
                    <CharacterDetailTextarea
                      control={control}
                      name="details.exampleConversation"
                      label={t('character.field.example-conversation')}
                      placeholder={t('character.field.example-conversation.placeholder')}
                      help={{
                        title: t('character.field.example-conversation.help.title'),
                        content: t('character.field.example-conversation.help.content'),
                      }}
                    />
                    <View>
                      <View className="flex flex-row items-start gap-1">
                        <MGText className="text-red-500 text-lg">*</MGText>
                        <View className="flex flex-row items-center gap-1">
                          <MGText bold className="text-lg">
                            {t('character.field.intro-message')}
                          </MGText>
                          <MGHelpDialog
                            title={t('character.field.intro-message.help.title')}
                            content={t('character.field.intro-message.help.content')}
                          />
                        </View>
                      </View>
                      <MGText className="text-neutral-500 mb-1">
                        {t('character.field.intro-message.description')}
                      </MGText>

                      <Controller
                        control={control}
                        name="details.introMessage"
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <Fragment>
                            <MGTextInput
                              value={value}
                              onChangeText={onChange}
                              placeholder={t('character.field.intro-message.placeholder')}
                              multiline={true}
                              style={{
                                minHeight: 120,
                                maxHeight: 120,
                              }}
                            />
                            {error && <MGText className="text-sm text-red-500 mt-1">{error.message}</MGText>}
                          </Fragment>
                        )}
                      />
                    </View>

                    {/* details */}
                    <AccordionItem
                      isExpanded={showPersonalityPart}
                      onPress={() => setShowPersonalityPart(!showPersonalityPart)}
                      title={t('character.field.personality.title')}
                      description={t('character.field.personality.description')}
                    >
                      <View className="flex flex-col gap-4 bg-neutral-50 rounded-xl p-4 mt-2">
                        <View>
                          <MGText bold className="text-lg">
                            {t('character.field.personality.first-person')}
                          </MGText>
                          <MGText className="text-neutral-500 mb-1">
                            {t('character.field.personality.first-person.description')}
                          </MGText>
                          <Controller
                            control={control}
                            name="details.firstPersonPronoun"
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <Fragment>
                                <MGTextInput value={value} onChangeText={onChange} />
                                {error && <MGText className="text-sm text-red-500 mt-1">{error.message}</MGText>}
                              </Fragment>
                            )}
                          />
                        </View>
                        <View>
                          <MGText bold className="text-lg">
                            {t('character.field.personality.second-person')}
                          </MGText>
                          <MGText className="text-neutral-500 mb-1">
                            {t('character.field.personality.second-person.description')}
                          </MGText>
                          <Controller
                            control={control}
                            name="details.secondPersonPronoun"
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <Fragment>
                                <MGTextInput value={value} onChangeText={onChange} />
                                {error && <MGText className="text-sm text-red-500 mt-1">{error.message}</MGText>}
                              </Fragment>
                            )}
                          />
                        </View>
                        <View>
                          <MGText bold className="text-lg">
                            {t('character.field.personality.callout-style')}
                          </MGText>
                          <MGText className="text-neutral-500 mb-1">
                            {t('character.field.personality.callout-style.description')}
                          </MGText>
                          <Controller
                            control={control}
                            name="details.calloutStyle"
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <Fragment>
                                <MGTextInput value={value} onChangeText={onChange} />
                                {error && <MGText className="text-sm text-red-500 mt-1">{error.message}</MGText>}
                              </Fragment>
                            )}
                          />
                        </View>
                        <View>
                          <MGText bold className="text-lg">
                            {t('character.field.personality.speaking-tone')}
                          </MGText>
                          <MGText className="text-neutral-500 mb-1">
                            {t('character.field.personality.speaking-tone.description')}
                          </MGText>
                          <Controller
                            control={control}
                            name="details.speakingTone"
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <Fragment>
                                <MGTextInput value={value} onChangeText={onChange} />
                                {error && <MGText className="text-sm text-red-500 mt-1">{error.message}</MGText>}
                              </Fragment>
                            )}
                          />
                        </View>
                      </View>
                    </AccordionItem>
                  </Fragment>
                )}
                <Separator />

                {/* public */}

                <View>
                  <View className="flex flex-row items-start gap-1">
                    <MGText className="text-red-500 text-lg">*</MGText>
                    <MGText bold className="text-lg">
                      {t('character.field.visibility')}
                    </MGText>
                  </View>
                  <MGText className="text-sm text-neutral-500 mb-1">
                    {t('character.field.visibility.description')}
                  </MGText>
                  <Controller
                    control={control}
                    name="visibility"
                    render={({ field: { onChange, value } }) => (
                      <View className="flex flex-col gap-2">
                        <MGRadioCard
                          label={t('character.field.visibility.enum.public')}
                          description={t('character.field.visibility.enum.public.description')}
                          selected={value === 'PUBLIC'}
                          onPress={() => onChange('PUBLIC')}
                        />
                        <MGRadioCard
                          label={t('character.field.visibility.enum.private')}
                          description={t('character.field.visibility.enum.private.description')}
                          selected={value === 'PRIVATE'}
                          onPress={() => {
                            if (userPrivileges?.allowCreatePrivateCharacter) {
                              onChange('PRIVATE')
                            } else {
                              setPurchaseSheetState({
                                opened: true,
                                selected: 'subscribe',
                                reason: 'purchase.subscription.reason.private-character-limit-reached',
                              })
                            }
                          }}
                          disabled={userPrivileges?.allowCreatePrivateCharacter === undefined} // is loading
                        />
                      </View>
                    )}
                  />
                  {errors.visibility && (
                    <MGText className="text-sm text-red-500 mt-1">{errors.visibility.message}</MGText>
                  )}
                </View>

                <AccordionItem hideButton isExpanded={watch('visibility') === 'PUBLIC'}>
                  <View className="flex flex-col gap-4">
                    <View>
                      <View className="flex flex-row items-start gap-1">
                        <MGText className="text-red-500 text-lg">*</MGText>
                        <MGText bold className="text-lg">
                          {t('character.field.description')}
                        </MGText>
                      </View>
                      <MGText className="text-neutral-500 mb-1">{t('character.field.description.description')}</MGText>

                      <Controller
                        control={control}
                        name="description"
                        render={({ field: { onChange, value } }) => (
                          <MGTextInput
                            value={value}
                            onChangeText={onChange}
                            multiline={true}
                            style={{
                              minHeight: 120,
                              maxHeight: 120,
                            }}
                          />
                        )}
                      />
                      {errors.description && (
                        <MGText className="text-sm text-red-500 mt-1">{errors.description.message}</MGText>
                      )}
                    </View>
                    <View>
                      <MGText bold className="text-lg">
                        {t('character.field.tag')}
                      </MGText>
                      <MGText className="text-neutral-500 mb-1">{t('character.field.tag.description')}</MGText>
                      <Controller
                        control={control}
                        name="tagIds"
                        render={({ field: { onChange, value } }) => {
                          const tagIds = value
                          return (
                            <View className="flex flex-row flex-wrap gap-2">
                              {tags?.map((tag) => (
                                <TouchableOpacity
                                  key={tag.id}
                                  style={{
                                    paddingHorizontal: 8,
                                    paddingVertical: 4,
                                    borderRadius: 999,
                                    borderWidth: 1,
                                    borderStyle: 'solid',
                                  }}
                                  className={clsx(
                                    'border border-primary-600/50',
                                    tagIds?.includes(tag.id) ? 'bg-neutral-300' : 'bg-neutral-100',
                                  )}
                                  onPress={() => {
                                    if (tagIds?.includes(tag.id)) {
                                      // remove
                                      onChange(value?.filter((v) => v !== tag.id))
                                    } else {
                                      // add
                                      if (!value) {
                                        onChange([tag.id])
                                      } else {
                                        if (value.length >= 5) {
                                          Alert.alert(t('character.field.tag.max-length'))
                                          return
                                        }
                                        onChange([...value, tag.id])
                                      }
                                    }
                                  }}
                                >
                                  <MGText>{tag.name}</MGText>
                                </TouchableOpacity>
                              ))}
                            </View>
                          )
                        }}
                      />
                      {errors.description && (
                        <MGText className="text-sm text-red-500 mt-1">{errors.description.message}</MGText>
                      )}
                    </View>
                  </View>
                </AccordionItem>
              </View>
            </View>
          </KeyboardAwareScrollView>
        </KeyboardAvoidingView>

        <View className="px-4 py-2 bg-background border-t border-neutral-100">
          <View className="mb-2 flex flex-row items-center justify-center">
            <TouchableOpacity
              className="px-4 flex flex-row items-center gap-1"
              onPress={() => {
                directSave(formValues)
                Burnt.toast({
                  title: t('character.form.auto-save'),
                  preset: 'done',
                })
              }}
            >
              <Ionicons name="save-outline" size={16} color="#fe68c4" />
              <MGText className="text-primary-400">{t('character.temp-save')}</MGText>
            </TouchableOpacity>
          </View>

          <MGModalButton
            disabled={pending}
            onPress={handleSubmit(onSubmit, onInvalidSubmit)}
            leftAdornment={
              isCreating ? (
                <Ionicons name="add" size={24} color="#ffffff" />
              ) : (
                <Ionicons name="pencil" size={24} color="#ffffff" />
              )
            }
            className="bg-primary-500"
          >
            <MGText bold className="text-white">
              {isCreating ? t('character.create') : t('character.edit')}
            </MGText>
          </MGModalButton>
        </View>
      </View>

      <IconModal
        visible={!!submitError}
        severity="warning"
        title={t('error.save-character-failed')}
        description={submitError ?? ''}
        textAlignment="left"
        descriptionRequireScrollView
        primaryButtonContent={t('action.close')}
        onCancel={() => setSubmitError(null)}
        onConfirm={() => setSubmitError(null)}
        hideCancelButton
      />
      <MGLoading show={pending} />
    </SafeAreaView>
  )
}
