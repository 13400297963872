import {
  CreateCharacterSymbol,
  EditOrCreateCharacter,
} from '../../../components/characterManagement/EditOrCreateCharacter'
import type { CharacterForm } from '../../../components/characterManagement/form'

const defaultValues: Partial<CharacterForm> = {
  visibility: 'PUBLIC',
}

export default function CreateCharacterScreen() {
  return (
    <EditOrCreateCharacter
      // FIXME: disabled as backend DOES NOT SUPPORT
      // showCharacterGenerationPart
      defaultValues={defaultValues}
      characterId={CreateCharacterSymbol}
    />
  )
}
