import Ionicons from '@expo/vector-icons/Ionicons'
import clsx from 'clsx'
import { type FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TouchableOpacity } from 'react-native'
import i18n from '../../lib/i18n'
import { supportedLngs } from '../../locales'
import { MGActivityIndicator } from '../ui/MGActivityIndicator'
import MGBaseModal from './MGBaseModal'
import { MGModalButton } from './MGModalButton'
import MGText from './MGText'

// Language display names mapping
const LANGUAGE_DISPLAY_NAMES = {
  ja: '日本語',
  en: 'English',
  zh_TW: '繁體中文',
  ko: '한국어',
} as const

interface LanguageSelectorModalProps {
  visible: boolean
  onClose: () => void
}

const LanguageSelectorModal: FC<LanguageSelectorModalProps> = ({ visible, onClose }) => {
  const { t } = useTranslation()
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language)
  const [isChangingLanguage, setIsChangingLanguage] = useState(false)

  const handleLanguageSelect = useCallback(
    async (language: string) => {
      if (language === i18n.language) {
        onClose()
        return
      }

      setSelectedLanguage(language)
      setIsChangingLanguage(true)

      try {
        await i18n.changeLanguage(language)
        onClose()
      } catch (error) {
        console.error('Failed to change language:', error)
      } finally {
        setIsChangingLanguage(false)
      }
    },
    [onClose],
  )

  return (
    <MGBaseModal
      visible={visible}
      onClose={onClose}
      title={t('app-settings.language-selector.title')}
      icon="language"
      iconColor="#3b82f6"
      iconBgColor="bg-neutral-100"
      contentRequireScrollView={false}
    >
      {supportedLngs.map((language) => (
        <TouchableOpacity
          key={language}
          className={clsx(
            'flex-row items-center justify-between p-4 my-1 rounded-xl h-14',
            language === selectedLanguage ? 'bg-blue-100 dark:bg-blue-600/20' : 'bg-neutral-100',
          )}
          onPress={() => handleLanguageSelect(language)}
          disabled={isChangingLanguage}
        >
          <MGText className="text-base">
            {LANGUAGE_DISPLAY_NAMES[language as keyof typeof LANGUAGE_DISPLAY_NAMES]}
          </MGText>
          {language === selectedLanguage && !isChangingLanguage && (
            <Ionicons name="checkmark" size={24} color="#3b82f6" />
          )}
          {language === selectedLanguage && isChangingLanguage && <MGActivityIndicator size="small" color="#3b82f6" />}
        </TouchableOpacity>
      ))}

      <MGModalButton onPress={onClose} disabled={isChangingLanguage} className="bg-neutral-100 mt-4">
        {t('action.close')}
      </MGModalButton>
    </MGBaseModal>
  )
}

export default LanguageSelectorModal
