import clsx from 'clsx'
import type { FC } from 'react'
import { Trans } from 'react-i18next'
import { View } from 'react-native'
import { MGIonicons } from '../common/MGIonicons'
import MGText from '../common/MGText'

export const MoguPassBenefitsList: FC<{ benefitKeys: string[]; className?: string }> = ({ benefitKeys, className }) => {
  return (
    <View className={clsx('flex items-start w-full gap-1', className)}>
      {benefitKeys.map((benefitKey) => (
        <View key={benefitKey} className="flex flex-row items-start gap-2">
          <MGIonicons name="checkmark" size={24} color="#f83caa" />

          <MGText className="text-base text-foreground">
            <Trans
              i18nKey={benefitKey}
              components={{
                strong: <MGText bold className="text-base text-primary-500" />,
              }}
              parent={MGText}
            />
          </MGText>
        </View>
      ))}
    </View>
  )
}
