import { Image } from 'expo-image'
import { Link } from 'expo-router'
import { useTranslation } from 'react-i18next'
import { FlatList, RefreshControl, TouchableOpacity, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import CharacterCard, { type CharacterWithoutDetail } from '../../../components/character/CharacterCard'
import MGHeader from '../../../components/common/MGHeader'
import MGHeaderText from '../../../components/common/MGHeaderText'
import MGLoadingOverlay from '../../../components/common/MGLoadingOverlay'
import NonIdealState from '../../../components/common/NonIdealState'
import { useMyCharacters } from '../../../lib/hooks/useMyCharacters'

export default function MyCharacterScreen() {
  const { t } = useTranslation()

  const insets = useSafeAreaInsets()

  const {
    characters,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: isLoadingCharacters,
    refetch,
  } = useMyCharacters()
  const onRefresh = () => {
    refetch()
  }

  const renderItem = ({
    item: character,
    index,
  }: {
    item: CharacterWithoutDetail
    index: number
  }) => (
    <View key={character.id}>
      <Link href={`/character/${character.id}`} asChild>
        <TouchableOpacity>
          <CharacterCard key={character.id} character={character} />
        </TouchableOpacity>
      </Link>
    </View>
  )

  const loadMore = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }
  }

  const ListEmptyComponent = () => (
    <View className="items-center justify-center w-full py-24">
      <NonIdealState
        title={t('error.empty-character-list')}
        description={t('error.empty-character-list.description')}
      />
    </View>
  )

  const ListFooterComponent = () =>
    isFetchingNextPage ? (
      <View className="py-4 items-center">
        <Image
          source={require('../../../assets/images/status/loading.png')}
          style={{ width: 50, height: 50 }}
          autoplay={true}
        />
      </View>
    ) : null

  return (
    <View className="bg-background h-full">
      <View
        className="h-full relative"
        style={{
          paddingTop: insets.top,
          paddingLeft: insets.left,
          paddingRight: insets.right,
        }}
      >
        <MGHeader headerBody={<MGHeaderText>{t('character.my')}</MGHeaderText>} />
        <View
          className="flex-1 relative mx-auto size-full"
          style={{
            maxWidth: 728,
          }}
        >
          <MGLoadingOverlay visible={isLoadingCharacters} />

          <FlatList
            data={characters}
            renderItem={renderItem}
            keyExtractor={(item) => item.id}
            style={{ flex: 1, paddingBottom: insets.bottom + 20 }}
            onEndReached={loadMore}
            onEndReachedThreshold={0.5}
            ListEmptyComponent={ListEmptyComponent}
            ListFooterComponent={ListFooterComponent}
            refreshControl={<RefreshControl refreshing={isLoadingCharacters} onRefresh={onRefresh} />}
          />
        </View>
      </View>
    </View>
  )
}
