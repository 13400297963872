import { Modal, TouchableWithoutFeedback, View } from 'react-native'
import { trpcReact } from '../../lib/services/trpc'
import CharacterDetail from '../character/CharacterDetail'
import CharacterNotFound from '../character/CharacterNotFound'
import MGLoadingOverlay from './MGLoadingOverlay'

export default function MGCharacterModal({
  characterId,
  onClose,
}: {
  characterId: string
  onClose: () => void
}) {
  const { data: character, isLoading } = trpcReact.character.get.useQuery(
    {
      id: characterId,
    },
    {
      enabled: characterId !== 'create',
    },
  )

  if (isLoading) {
    return <MGLoadingOverlay visible={true} />
  }

  if (!character) {
    return <CharacterNotFound characterId={characterId.toString()} />
  }

  return (
    <View className="absolute bottom-0 left-0 right-0 pointer-events-none">
      <Modal visible transparent animationType="fade" onRequestClose={onClose}>
        <TouchableWithoutFeedback onPress={onClose}>
          <View className="flex-1 justify-center items-center bg-black/50 p-4 ">
            <TouchableWithoutFeedback>
              <View className="bg-background rounded-3xl py-6 px-2 mx-4 gap-2 relative overflow-hidden max-h-full max-w-full w-[48rem]">
                <CharacterDetail character={character} onClose={onClose} />
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    </View>
  )
}
